import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ReservationService } from '../reservation.service';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '@app/core/client.service';
import { AuthenticationService } from '@app/core';
import { MatSnackBar } from '@angular/material';

// Function to validate Phone-number regex
const phoneRegex = /^\+\d{2}\s\d{2}\s\d{9}$/;

function validatePhone(phoneNumber: string): boolean {
  return phoneRegex.test(phoneNumber);
}

@Component({
  selector: 'app-phone-assistant',
  templateUrl: './phone-assistant.component.html',
  styleUrls: ['./phone-assistant.component.scss']
})
export class PhoneAssistantComponent implements OnInit {
  loading: boolean = true;
  private ngUnsubscribe: Subject<any> = new Subject();
  phoneAssistant: FormGroup;
  showDiv: boolean = false;
  clientId: any;
  activeClient: any;
  phoneNumber: any;
  showErrorDiv: boolean = false;
  showFormDiv: boolean = false;
  showIsEnrolled: boolean;
  isShowForm: false;
  showIfNotRegistry: boolean;

  constructor(
    private fb: FormBuilder,
    private reservationService: ReservationService,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private ClientService: ClientService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    const clientId = this.route.snapshot.paramMap.get('clientId');
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    this.phoneAssistant = this.fb.group({
      clientId: this.clientId,
      isActive: false,
      phoneNo: '',
      roomChoice: false,
      isEnrolled: 0
    });
    // this.getPhoneAssistantSettings();
    this.getClient(clientId);
    this.showIfNotRegistry = false;
    // this.loading = false;
  }

  getClient(clientId: any) {
    this.ClientService.getClient(clientId).subscribe(client => {
      this.activeClient = client;
      console.log('active client', this.activeClient);
      console.log(this.activeClient.email);
      console.log(this.activeClient.telefon);
      let processedPhoneNumber = '';
      let formattedPhoneNumber = '';
      if (this.activeClient.telefon) {
        formattedPhoneNumber = this.formatPhoneNumber('4930219910285');
      }
      console.log('formattedPhoneNumber =====>', formattedPhoneNumber);
      let phoneNumberIsValid = validatePhone(formattedPhoneNumber);
      console.log('phoneNumberIsValid ===>', phoneNumberIsValid);
      if (!this.activeClient.email) {
        this.showErrorDiv = true;
        this.showIfNotRegistry = true;
      } else if (!phoneNumberIsValid || !this.activeClient.telefon) {
        this.showErrorDiv = true;
        this.showIfNotRegistry = true;
      }

      if (this.activeClient.email && this.activeClient.telefon) {
        this.getPhoneAssistantSettings();
      }
      this.loading = false;
    });
  }

  formatPhoneNumber(phoneNumber: string): string {
    // Check if the number already starts with a plus sign
    if (phoneNumber.startsWith('+')) {
      // Format the phone number with spaces
      return `${phoneNumber.substring(0, 3)} ${phoneNumber.substring(3, 5)} ${phoneNumber.substring(5)}`;
    } else {
      return `+${phoneNumber.substring(0, 2)} ${phoneNumber.substring(2, 4)} ${phoneNumber.substring(4)}`;
    }
  }

  getPhoneAssistantSettings() {
    this.reservationService
      .getPhoneAssistantSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe({
        next: res => {
          if (res) {
            this.showIsEnrolled = res.isEnrolled === 1 ? true : false;
            this.phoneNumber = res.phoneNo;
            this.phoneAssistant.patchValue(res);
            if (this.showIsEnrolled) {
              this.showFormDiv = true;
              this.showIfNotRegistry = false;
            } else {
              this.showIfNotRegistry = true;
            }
          } else {
            this.showIfNotRegistry = true;
          }
          this.loading = false;
        },
        error: error => {
          console.log(error);
          this.loading = false;
        }
      });
  }

  // changed(event: any) {
  //   if (event.checked === true) {
  //     this.createPhoneAssistant();
  //   }
  // }

  showValue(event: any) {
    if (event === true) {
      this.showFormDiv = true;
      this.showIsEnrolled = true;
    } else {
      this.showFormDiv = false;
    }
  }

  // TODO: Add client name first and last name test once we have prod data.
  createPhoneAssistant() {
    this.loading = true;
    console.log(this.phoneAssistant.value);
    this.reservationService
      .createPhoneAssistant(this.phoneAssistant.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe({
        next: res => {
          this.showIsEnrolled = res.isEnrolled === 1 ? true : false;
          if (res) {
            this.phoneAssistant.patchValue({
              clientId: res.clientId,
              isActive: res.isActive,
              phoneNo: res.phoneNo,
              roomChoice: res.roomChoice,
              isEnrolled: this.showIsEnrolled
            });
            this.showFormDiv = true;
            this.snackBar.open('Daten erfolgreich aktualisiert', '', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            this.getPhoneAssistantSettings();
          }
          this.loading = false;
        },
        error: error => {
          console.log(error);
          this.snackBar.open('Konnte nicht erstellt werden. Bitte überprüfen Sie E-Mail und Telefonnummer.', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
          this.loading = false;
        }
      });
  }
}

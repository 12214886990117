import { Component, OnInit, Input, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';
import { ColorPickerControl, Color } from '@iplab/ngx-color-picker';

@Component({
  selector: 'color-picker-wrapper',
  template:
    '<chrome-picker *ngIf="isVisible" [control]="colorControl"></chrome-picker><div *ngIf="isVisible" class="overlay" (click)="overlayClick($event)" > </div>'
})
export class ColorPickerWrapperComponent implements OnInit {
  public colorControl = new ColorPickerControl().hideAlphaChannel();
  public isVisible = false;
  @Input()
  public set color(color: string) {
    this.colorControl.setValueFrom(color);
  }
  @Output()
  public colorChange: EventEmitter<string> = new EventEmitter();

  @HostBinding('style.background-color')
  public get background(): string {
    return this.colorControl.value.toHexString();
  }

  public ngOnInit() {
    this.colorControl.valueChanges.subscribe((value: Color) => this.colorChange.emit(value.toHexString()));
  }

  @HostListener('click', ['$event'])
  public showColorPicker(event: MouseEvent) {
    if (this.isVisible === true) {
      return;
    }

    this.isVisible = !this.isVisible;
  }

  public overlayClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isVisible = false;
  }
}

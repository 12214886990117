import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import {
  MatSlideToggleModule,
  MatCheckboxModule,
  MatIconModule,
  MatTabsModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatRadioModule,
  MatGridListModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxSchedulerModule } from 'devextreme-angular/ui/scheduler';
import { DxTemplateModule } from 'devextreme-angular/core/template';
import { DateFnsModule } from 'ngx-date-fns';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ControlErrorComponent } from '@app/shared/control-error/control-error.component';
import { MomentModule } from 'ngx-moment';
import { ReservationOverviewComponent } from './reservation-overview.component';
import { ReservationRoutingModule } from '../reservation-routing.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    MatSlideToggleModule,
    ReservationRoutingModule,
    MatCheckboxModule,
    DragDropModule,
    MatIconModule,
    MatTabsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatRadioModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    NgSelectModule,
    FormsModule,
    DxSchedulerModule,
    DxTemplateModule,
    NgScrollbarModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    NgxDatatableModule,
    MomentModule,
    DateFnsModule.forRoot()
  ],
  declarations: [ReservationOverviewComponent]
})
export class ReservationOverviewModule {}

import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cancel-msg-box',
  templateUrl: './cancel-msg-box.component.html',
  styleUrls: ['./cancel-msg-box.component.scss']
})
export class CancelMsgBoxComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  msgAddressFormGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public activeModal: NgbActiveModal,
    public translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event: any) => {
      this.translate.setDefaultLang(event.lang);
    });
  }

  ngOnInit() {
    this.msgAddressFormGroup = this.formBuilder.group({
      msg: ['', Validators.required]
    });
  }

  saveCancelText() {
    if (!this.msgAddressFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.passEntry.emit(this.msgAddressFormGroup.value);
    this.activeModal.dismiss('');
  }
}

import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservationService } from '@app/reservation/reservation.service';
import { MatSnackBar } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/takeUntil';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-global-question',
  templateUrl: './add-global-question.component.html',
  styleUrls: ['./add-global-question.component.scss']
})
export class AddGlobalQuestionComponent implements OnInit, OnDestroy {
  @Input() public shifts: any;
  @Input() public editQuestionData: any; // only if editing
  @Input() public tags: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  questionFormGroup: FormGroup;
  error: string;
  addObservable: any;
  editObservable: any;
  deleteObservable: any;
  answers: FormArray;
  resMenuOrders: any = [];
  allQuestionTypes: any = [];
  foreignValuesFollowUpShift: any = [];
  questionTypeNoAnswers = [1, 4];
  showMultiSelectOption = [0, 4];
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private reservationService: ReservationService,
    private snackBar: MatSnackBar,
    public formBuilder: FormBuilder,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.allQuestionTypes = [
      { id: 0, order: 0, name: this.translate.instant('ResSettings.ResQuestions.PreSelectedAnswer') },
      { id: 1, order: 2, name: this.translate.instant('ResSettings.ResQuestions.FreeText') },
      { id: 4, order: 1, name: this.translate.instant('ResSettings.ResQuestions.Tags') }
    ];
    this.foreignValuesFollowUpShift = [
      { id: 1, name: this.translate.instant('ResSettings.ResQuestions.Before') },
      { id: 2, name: this.translate.instant('ResSettings.ResQuestions.After') },
      { id: 3, name: this.translate.instant('ResSettings.ResQuestions.No') }
    ];
    if (this.shifts && Object.keys(this.shifts).length > 0) {
      this.shifts = Object.values(this.shifts).map((item: any) => item);
      this.allQuestionTypes = [
        ...this.allQuestionTypes,
        { id: 3, order: 3, name: this.translate.instant('ResSettings.ResQuestions.FollowUpShift') }
      ];
    }

    if (this.tags) {
      this.tags = Object.values(this.tags);
    }

    this.questionFormGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(15)]],
      question: ['', Validators.required],
      questionType: [0],
      foreignId: [''],
      answers: this.formBuilder.array([]),
      tags: [''],
      multiSelect: 0
    });
    this.getAllResMenuOrders();
    if (this.editQuestionData) {
      this.questionFormGroup.addControl('id', new FormControl(this.editQuestionData.id, Validators.required));
      this.questionFormGroup.get('name').setValue(this.editQuestionData.name);
      this.questionFormGroup.get('question').setValue(this.editQuestionData.question);
      this.questionFormGroup.get('questionType').setValue(this.editQuestionData.questionType);
      this.questionFormGroup.get('foreignId').setValue(this.editQuestionData.foreignId);
      this.questionFormGroup.get('multiSelect').setValue(this.editQuestionData.multiSelect);

      const answers: any = [];
      if (this.editQuestionData.answers) {
        if (!this.questionTypeNoAnswers.includes(this.editQuestionData.questionType)) {
          this.editQuestionData.answers.forEach((st: any) => {
            answers.push(this.formBuilder.group(st));
          });
          this.questionFormGroup.setControl('answers', this.formBuilder.array(answers));
        } else {
          if (this.editQuestionData.questionType == 4) {
            let tagsId = this.editQuestionData.answers.map((item: any) => parseInt(item.answer));
            let tagsValue: any[] = [];
            this.tags.map((item: any) => {
              if (tagsId.includes(item.id)) {
                tagsValue.push(item);
              }
            });
            this.questionFormGroup.get('tags').setValue(tagsValue);
          }
        }
      }
    } else {
      this.questionAnswerDisplay();
    }
  }

  isVisibleMultiSelect() {
    return this.showMultiSelectOption.includes(this.questionFormGroup.value.questionType);
  }

  changeMultiSelect() {
    if (!this.showMultiSelectOption.includes(this.questionFormGroup.value.questionType)) {
      this.questionFormGroup.get('multiSelect').setValue(false);
    }
  }
  getAllResMenuOrders() {
    this.reservationService
      .getAllResMenuOrders()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resMenuOrders: any) => {
        this.resMenuOrders = resMenuOrders;
        if (this.resMenuOrders && Object.keys(this.resMenuOrders).length > 0) {
          this.allQuestionTypes = [
            ...this.allQuestionTypes,
            { id: 2, order: 4, name: this.translate.instant('ResSettings.ResQuestions.LimitedPreOrder') }
          ];
        }
        this.allQuestionTypes.sort(function(a: any, b: any) {
          return a.order - b.order || a.name.localeCompare(b.name);
        });
      });
  }
  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get formData() {
    return <FormArray>this.questionFormGroup.get('answers');
  }

  seatsAmountChanged(event: any) {
    this.questionFormGroup.get('seats').setValue(event);
  }

  add() {
    if (!this.questionFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    this.addObservable = this.reservationService
      .addQuestion(this.questionFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }

  save() {
    if (!this.questionFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    this.editObservable = this.reservationService
      .editQuestion(this.questionFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }

  delete() {
    this.deleteObservable = this.reservationService
      .deleteRoom(this.questionFormGroup.value.id)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((response: any) => {
        const snackBarRef = this.snackBar.open('Raum erfolgreich gelöscht', '', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        this.passEntry.emit('delete');
      });
  }

  createAnswer(): FormGroup {
    const numRegex = /^-?\d*[.,]?\d{0,2}$/;
    return this.formBuilder.group({
      answer: ['', Validators.required],
      foreignValue: ['', Validators.pattern(numRegex)]
    });
  }

  addNewAnswer() {
    this.answers = this.questionFormGroup.get('answers') as FormArray;
    this.answers.push(this.createAnswer());
  }

  removeAnswer(index: number) {
    this.answers = this.questionFormGroup.get('answers') as FormArray;
    this.answers.removeAt(index);
  }

  questionAnswerDisplay() {
    this.questionFormGroup.setControl('answers', this.formBuilder.array([]));
    this.formBuilder.array([]);
    this.answers = this.questionFormGroup.get('answers') as FormArray;
    for (let i = 0; i < this.answers.length; i++) {
      this.answers.removeAt(i);
    }
    if (!this.questionTypeNoAnswers.includes(this.questionFormGroup.value.questionType)) {
      // Add two new answer fields to start with
      this.addNewAnswer();
      this.addNewAnswer();
    }
  }
  changeQuestionType() {
    this.questionFormGroup.get('foreignId').setValue('');
    if (this.questionFormGroup.value.questionType == 2 || this.questionFormGroup.value.questionType == 3) {
      this.questionFormGroup.get('foreignId').setValidators([Validators.required]);
      this.questionFormGroup.get('foreignId').updateValueAndValidity();
    } else {
      this.questionFormGroup.get('foreignId').clearValidators();
      this.questionFormGroup.get('foreignId').setErrors(null);
    }
    // this.answers = this.questionFormGroup.get('answers') as FormArray;
    // for (let i = 0; i < this.answers.length; i++) {
    //   this.answers
    //     .at(i)
    //     .get('foreignValue')
    //     .setValue('');
    // }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { AuthenticationService, CredentialsService } from '@app/core';
import { environment } from '@env/environment';
import moment, { Moment } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class VouchersService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private credentialsService: CredentialsService
  ) {}

  getSettings() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `voucher/settings/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((settings: any) => {
          return settings;
        })
      );
  }

  getClientPool(startDate: Date, endDate: Date) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(
        environment.serverUrl +
          `voucher/pool/` +
          this.authService.activeClientId +
          '/' +
          moment(startDate).format('YYYY-MM-DD') +
          '/' +
          moment(endDate).format('YYYY-MM-DD'),
        {
          headers: headers
        }
      )
      .pipe(
        map((settings: any) => {
          return settings;
        })
      );
  }

  getPoolLiability(startDate: Date, endDate: Date) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(
        environment.serverUrl +
          `voucher/pool-liability/` +
          this.authService.activeClientId +
          '/' +
          moment(startDate).format('YYYY-MM-DD') +
          '/' +
          moment(endDate).format('YYYY-MM-DD'),
        {
          headers: headers
        }
      )
      .pipe(
        map((liability: any) => {
          return liability;
        })
      );
  }

  getCommunityPoolLiability(startDate: Date, endDate: Date) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(
        environment.serverUrl +
          `voucher/pool-community-liability/` +
          this.authService.activeClientId +
          '/' +
          moment(startDate).format('YYYY-MM-DD') +
          '/' +
          moment(endDate).format('YYYY-MM-DD'),
        {
          headers: headers
        }
      )
      .pipe(
        map((liability: any) => {
          return liability;
        })
      );
  }

  getTransactions(clientId: number, startDate: any, endDate: any, page: number = 0, status = '', voucherCode = '') {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `voucher/transactions/` + (clientId ? clientId : this.authService.activeClientId),
        {
          startDate,
          endDate,
          page,
          status: status,
          voucherCode
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((transactions: any) => {
          return transactions;
        })
      );
  }

  getTransactionsDataTil(clientId: number, startDate: any, endDate: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `voucher/transactionsData/` + (clientId ? clientId : this.authService.activeClientId),
        {
          startDate,
          endDate
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((transactions: any) => {
          return transactions;
        })
      );
  }

  getVouchers(startDate: any = '', endDate: any = '', page: number = 0) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `voucher/vouchers/` + this.authService.activeClientId,
        {
          startDate,
          endDate,
          page
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((transactions: any) => {
          return transactions;
        })
      );
  }

  getBalanceTransactions(startDate: any, endDate: any, page: number = 0) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `voucher/balance-transactions/` + this.authService.activeClientId,
        {
          startDate,
          endDate,
          page
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((transactions: any) => {
          return transactions;
        })
      );
  }

  saveVoucherSettings(data: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(
        environment.serverUrl + `voucher/voucher-settings/save/` + this.authService.activeClientId,
        { data },
        { headers: headers }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getExcelEvaluationList(startDate: any, endDate: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    window.open(
      environment.serverUrl +
        `voucher/evaluation-xls/` +
        this.authService.activeClientId +
        '/' +
        moment(startDate).format('YYYY-MM-DD') +
        '/' +
        moment(endDate).format('YYYY-MM-DD'),
      '_blank'
    );
    return true;
    /*
    return this.http
      .get<any>(
        environment.serverUrl +
          `voucher/evaluation-xls/` +
          this.authService.activeClientId +
          '/' +
          startDate +
          '/' +
          endDate,
        {
          headers: headers
        }
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
      */
  }

  getPayouts(type: string = '') {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `voucher/payouts/` + this.authService.activeClientId + type, {
        headers: headers
      })
      .pipe(
        map((payouts: any) => {
          return payouts;
        })
      );
  }

  getVoucherBalance(payoutId: number = 0) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `voucher/balance/${this.authService.activeClientId}/${payoutId}`, {
        headers: headers
      })
      .pipe(
        map((payouts: any) => {
          return payouts;
        })
      );
  }

  requestPayout(path: string = 'voucher'): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + path + `/payouts/`,
        { clientId: this.authService.activeClientId },
        {
          headers: headers
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getVoucherTransactionsPdf(data: any = { orders: [] }): Observable<Blob> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token,
      Accept: 'application/pdf'
    });

    return this.http
      .post(environment.serverUrl + `voucher/createVoucherTransactionsPdf/`, data, { headers, responseType: 'blob' })
      .pipe(
        map(response => {
          if (response.type !== 'application/pdf') {
            throw new Error('Received non-PDF response');
          }
          return new Blob([response], { type: 'application/pdf' });
        })
      );
  }

  getVoucherOrders(from: any = null, to: any = null, filterBy = '') {
    if (from === null) {
      from = moment()
        .subtract(7, 'days')
        .format();
    }
    if (to === null) {
      to = moment().format();
    }
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `voucher/voucher-orders/` + this.authService.activeClientId,
        {
          startDate: from,
          endDate: to,
          filterBy: filterBy
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((stats: any) => {
          return stats;
        })
      );
  }
  setShippingDate(orderId: number, voucherCodes: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `voucher/set-shipping-date`,
        {
          orderId: orderId,
          voucherCodes: voucherCodes
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((order: any) => {
          return order;
        })
      );
  }
  downloadReceipt(receipt: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    this.http
      .get(environment.serverUrl + `voucher/downloadReceipt/${receipt.betriebId}/${receipt.id}`, {
        headers,
        responseType: 'blob'
      }) //set response Type properly (it is not part of headers)
      .toPromise()
      .then(blob => {
        saveAs(blob, 'receipt.pdf');
      })
      .catch(err => console.error('download error = ', err));
  }

  sendReceipt(receipt: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    this.http
      .get<any>(environment.serverUrl + `voucher/sendReceipt/${receipt.betriebId}/${receipt.id}`, {
        headers: headers
      })
      .toPromise()
      .then(data => {
        alert('Quittung wurde erfolgreich an ' + receipt.email + ' versendet!');
        console.log('send email to ', receipt);
      })
      .catch(err => console.error('send email error = ', err));
  }
  sendVoucherPdf(voucherPdf: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `voucher/sendVoucherPdf`,
        {
          clientId: voucherPdf.betriebId,
          id: voucherPdf.id,
          email: voucherPdf.email
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  getVoucherTransactions(clientId: number, voucherCode: string) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `voucher/all-vouchers`,
        {
          clientId,
          voucherCode,
          limit: 100
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((transactions: any) => {
          return transactions;
        })
      );
  }

  getVoucherStatistics(from: any = null, to: any = null) {
    if (from === null) {
      from = moment()
        .subtract(7, 'days')
        .format();
    }
    if (to === null) {
      to = moment().format();
    }
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `voucher/voucher-statistics/` + this.authService.activeClientId,
        {
          startDate: from,
          endDate: to
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((stats: any) => {
          return stats;
        })
      );
  }
  getVoucherValidity() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .get<any>(environment.serverUrl + `voucher/vouchers-validity/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getAllPoolClientsData(poolClientId: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `voucher/pool-client-data`,
        {
          clientIds: poolClientId ? poolClientId : this.authService.activeClientId
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((transactions: any) => {
          return transactions;
        })
      );
  }

  getVoucherResidualValues(startDate: any, endDate: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    window.open(
      environment.serverUrl +
        `voucher/pool-stats/` +
        this.authService.activeClientId +
        '/' +
        moment(startDate).format('YYYY-MM-DD') +
        '/' +
        moment(endDate).format('YYYY-MM-DD'),
      '_blank'
    );
    return true;
  }

  uploadHeaderBackgroundVouchers(uploadData: any): Observable<any> {
    uploadData.append('clientId', this.authService.activeClientId);
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `voucher/upload-header-background-vouchers`, uploadData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /* For Voucher APIs*/
  getVoucherDetails(data: any) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + data.token
    });
    return this.http
      .post<any>(environment.serverUrl + `rest-api/1.0/voucher/get-details/`, data, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  redeemVoucher(data: any) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + data.token
    });
    return this.http
      .post<any>(environment.serverUrl + `rest-api/1.0/voucher/redeem-voucher/`, data, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  rechargeVoucher(data: any) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + data.token
    });
    return this.http
      .post<any>(environment.serverUrl + `rest-api/1.0/voucher/recharge-voucher/`, data, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  cancelVoucherOrder(voucher: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(
        environment.serverUrl + `voucher/cancel`,
        {
          betriebId: voucher.betriebId,
          id: voucher.id,
          total: voucher.total
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getBalancedCanceledVouchers(clientId: number, startDate: any, endDate: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl +
          `voucher/balanced-canceled-vouchers/` +
          (clientId ? clientId : this.authService.activeClientId),
        {
          startDate,
          endDate
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((transactions: any) => {
          return transactions;
        })
      );
  }

  getVoucherStatDoughnut(from: any = null, to: any = null) {
    if (from === null) {
      from = moment()
        .subtract(7, 'days')
        .format();
    }
    if (to === null) {
      to = moment().format();
    }
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `voucher/voucher-statistics-chart/` + this.authService.activeClientId,
        {
          startDate: from,
          endDate: to
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((stats: any) => {
          return stats;
        })
      );
  }

  cancelVoucherTransaction(transactionId: number, cancelReasonText: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `voucher/cancel-voucher-transaction/` + transactionId,
        {
          memberId: cred.id,
          cancelReasonText
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getVoucherDetail(code: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `voucher/voucher-detail/${this.authService.activeClientId}/${code}`, {
        headers: headers
      })
      .pipe(
        map((areas: any) => {
          return areas;
        })
      );
  }

  prepareVoucherPdf(voucherId: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(
        environment.mainServerUrl + `voucher/prepare-voucher-pdf`,
        {
          voucherId: voucherId
        },
        {
          headers: headers,
          reportProgress: true
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}

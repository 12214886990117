import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReservationService } from '../reservation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-reservation-user-page',
  templateUrl: './reservation-user-page.component.html',
  styleUrls: ['./reservation-user-page.component.scss']
})
export class ReservationUserPageComponent implements OnInit {
  reservation: any;
  welcomeMsg: string = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private reservationService: ReservationService,
    private modalService: NgbModal,
    private snackBar: MatSnackBar
  ) {
    const token = this.route.snapshot.paramMap.get('token');

    // Check if token is valid
    this.reservationService.checkUserToken(token).subscribe(
      reservation => {
        this.reservation = reservation;
        if (this.reservation.client && this.reservation.client.pronominalForm === 'sie') {
          this.welcomeMsg = `Hallo Herr/Frau ${this.reservation.guestData.name}`;
        } else {
          this.welcomeMsg = `Hallo ${this.reservation.guestData.firstName}`;
        }
      },
      err => this.router.navigate(['/404'])
    );
  }

  ngOnInit() {}

  cancelReservation() {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Reservierung stornieren';
    modalRef.componentInstance.message = `Bist du dir sicher dass du deine Reservierung stornieren möchtest?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.reservationService.cancelReservation(this.reservation.id).subscribe(
            res => {
              this.reservation.status = res.status;
              this.updateReservation(res.status.toUpperCase(), this.reservation);
              this.snackBar.open('Reservierung erfolgreich storniert', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            },
            err => {
              this.snackBar.open('Es ist ein Fehler aufgetreten: ' + err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  updateReservation(status: string, reservation: any) {
    const referrer = reservation.referrer ? reservation.referrer : '';
    if (referrer && referrer.includes('Reservierung mit Google')) {
      this.reservationService.getupdateGoogleReseration(status, reservation).subscribe((settings: any) => {
        if (settings) {
          console.log('Canceled google reservation');
        }
      });
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReservationSettingsComponent } from './reservation-settings.component';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import {
  MatSlideToggleModule,
  MatCheckboxModule,
  MatIconModule,
  MatTabsModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatRadioModule,
  MatGridListModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddTableComponent } from './add-table/add-table.component';
import { AddRoomComponent } from './add-room/add-room.component';
import { AddShiftComponent } from './add-shift/add-shift.component';
import { Ng5SliderModule } from 'ng5-slider';
import { DxSchedulerModule } from 'devextreme-angular/ui/scheduler';
import { DxTemplateModule } from 'devextreme-angular/core/template';
import { DxFormModule } from 'devextreme-angular';
import { AddAlertComponent } from './add-alert/add-alert.component';
import { AddNotificationComponent } from './add-notification/add-notification.component';
import { DateFnsModule } from 'ngx-date-fns';
import { MomentModule } from 'ngx-moment';
import { ReservationRoutingModule } from '../reservation-routing.module';
import { ControlErrorComponent } from '@app/shared/control-error/control-error.component';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MatSelectModule } from '@angular/material/select';
import { ColorPickerModule } from 'ngx-color-picker';
import { AddGlobalQuestionComponent } from './add-global-question/add-global-question.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AddPaymentTemplateComponent } from './add-payment-template/add-payment-template.component';
import { Angular2CsvModule } from 'angular2-csv';
import { TablePlanComponent } from './table-plan/table-plan.component';
import { MatListModule } from '@angular/material';
import { IncomingReservationsComponent } from './table-plan/incoming-reservations/incoming-reservations.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AddPatternComponent } from './table-plan/add-pattern/add-pattern.component';
import { AddDecorationComponent } from './table-plan/add-decoration/add-decoration.component';
import { GridComponent } from './table-plan/grid/grid.component';
import { MatInputModule } from '@angular/material/input';
import { AddResSeriesComponent } from './add-res-series/add-res-series.component';
import { SatDatepickerModule } from 'saturn-datepicker';
import { AddReservationAddOnTemplate } from './add-reservation-addon-template/add-reservation-addon-template.component';
import { AddResMenuOrders } from './add-res-menu-orders/add-res-menu-orders.component';
import { AddTimeFilterComponent } from './add-time-filter/add-time-filter.component';
import { NgEncodeURIPipeModule } from '@app/shared/encode-uri/encode-uri';
import { CopyShiftLayerComponent } from './copy-shift-layer/copy-shift-layer.component';
import { AddStatusComponent } from './add-status/add-status.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    ReservationRoutingModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    DragDropModule,
    MatIconModule,
    MatTabsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatRadioModule,
    MatGridListModule,
    MatSelectModule,
    ColorPickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    DxSchedulerModule,
    DxTemplateModule,
    DxFormModule,
    DxContextMenuModule,
    NgxMaskModule.forRoot(options),
    MomentModule,
    FlexLayoutModule,
    MatListModule,
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fas fa-spinner fa-spin button-loading"></span>'
    }),
    DateFnsModule.forRoot(),
    Angular2CsvModule,
    MatAutocompleteModule,
    NgScrollbarModule,
    MatSidenavModule,
    MatInputModule,
    SatDatepickerModule,
    NgEncodeURIPipeModule
  ],
  declarations: [
    ReservationSettingsComponent,
    AddTableComponent,
    AddRoomComponent,
    AddShiftComponent,
    AddAlertComponent,
    AddNotificationComponent,
    AddGlobalQuestionComponent,
    AddPaymentTemplateComponent,
    TablePlanComponent,
    IncomingReservationsComponent,
    AddPatternComponent,
    AddDecorationComponent,
    GridComponent,
    AddResSeriesComponent,
    AddTimeFilterComponent,
    AddReservationAddOnTemplate,
    AddResMenuOrders,
    CopyShiftLayerComponent,
    AddStatusComponent
  ],
  entryComponents: [
    AddTableComponent,
    AddRoomComponent,
    AddShiftComponent,
    AddAlertComponent,
    AddNotificationComponent,
    ControlErrorComponent,
    AddGlobalQuestionComponent,
    AddPaymentTemplateComponent,
    AddResSeriesComponent,
    AddTimeFilterComponent,
    AddReservationAddOnTemplate,
    AddResMenuOrders,
    CopyShiftLayerComponent,
    AddStatusComponent
  ],
  exports: [TablePlanComponent]
})
export class ReservationSettingsModule {}

import { Component, OnInit, OnDestroy } from '@angular/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/takeUntil';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VouchersService } from '../vouchers.service';

@Component({
  selector: 'app-vouchers-api',
  templateUrl: './vouchers-api.component.html',
  styleUrls: ['./vouchers-api.component.scss']
})
export class VouchersApiComponent implements OnInit, OnDestroy {
  voucherApiFormGroup: FormGroup;
  sendObservable: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  showData: any;
  error: any;
  isFormSubmitting: Boolean = false;

  constructor(
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    private voucherService: VouchersService
  ) {}

  ngOnInit() {
    this.voucherApiFormGroup = this.formBuilder.group({
      code: ['', Validators.required],
      value: [''],
      token: ['', Validators.required],
      secret: ['']
    });
  }

  getVoucherDetails() {
    if (!this.voucherApiFormGroup.valid) {
      return;
    }
    this.isFormSubmitting = true;
    this.error = '';
    this.showData = '';
    this.voucherService
      .getVoucherDetails(this.voucherApiFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.showData = response;
          this.isFormSubmitting = false;
        },
        err => {
          console.log('err', err);
          this.error = err.error.msg;
          this.isFormSubmitting = false;
        }
      );
  }
  redeemVoucher() {
    if (!this.voucherApiFormGroup.valid) {
      return;
    }
    this.isFormSubmitting = true;
    this.error = '';
    this.showData = '';
    this.voucherService
      .redeemVoucher(this.voucherApiFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.showData = response;
          this.isFormSubmitting = false;
        },
        err => {
          console.log('err', err);
          this.error = err.error.msg;
          this.isFormSubmitting = false;
        }
      );
  }
  rechargeVoucher() {
    if (!this.voucherApiFormGroup.valid) {
      return;
    }
    this.isFormSubmitting = true;
    this.error = '';
    this.showData = '';
    this.voucherService
      .rechargeVoucher(this.voucherApiFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.showData = response;
          this.isFormSubmitting = false;
        },
        err => {
          console.log('err', err);
          this.error = err.error.msg;
          this.isFormSubmitting = false;
        }
      );
  }

  ngOnDestroy() {}
}

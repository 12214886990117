import {
  Component,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
  SimpleChanges,
  HostListener,
  AfterContentChecked,
  ChangeDetectorRef,
  Output,
  ElementRef
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ReservationService } from '@app/reservation/reservation.service';
import { Point } from 'electron';
import { Options, LabelType } from 'ng5-slider';
import { Subject } from 'rxjs';
import { GeneralSettings, ReservationBookSettings, Room, RoomTable } from '../reservation-settings.model';
import { Reservation } from '@app/reservation/reservation.model';
import { MatTabGroup, MatSnackBar, MatSidenav } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, resStatus } from '@app/core';
import { DxContextMenuComponent } from 'devextreme-angular/ui/context-menu';
import { AddReservationComponent } from '@app/reservation/reservations/add-reservation/add-reservation.component';
import { AddPatternComponent } from './add-pattern/add-pattern.component';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { AddDecorationComponent } from './add-decoration/add-decoration.component';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

export enum TableType {
  SQUARE = 'Square',
  ROUND = 'Round',
  RECTANGLE = 'Rectangle',
  RECTANGLE_ROTATE = 'Rectangle_Rotated',
  AUTO = 'Auto',
  AUTO_ROTATE = 'Auto_Rotated'
}

@Component({
  selector: 'app-table-plan',
  templateUrl: './table-plan.component.html',
  styleUrls: ['./table-plan.component.scss']
})
export class TablePlanComponent implements OnInit, OnChanges, OnDestroy, AfterContentChecked {
  @ViewChild('room', { static: false }) room: ElementRef;
  @ViewChild('reservationMenu', { static: false }) reservationMenu: DxContextMenuComponent;
  @ViewChild('roomTabsGroup', { static: false }) tabGroup: MatTabGroup;
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  @Input() isEditing: boolean;
  @Input() reservationBookSettings: ReservationBookSettings;
  @Input() date: any;
  @Input() reservations: any[];
  @Input() restaurantRooms: any[] = [];
  @Input() allowDragging = false;
  @Input() isFullscreen = false;
  @Output() activeRoom: any;
  rooms: any[] = [];
  tablesThatAreNotInTheRoom: any[] = [];
  tablesInTheRoomRoom: any[] = [];
  dragableTables: any[] = [];
  resStatusList: any[] = [];
  allStatus: any[] = resStatus;
  timeValue = 0;
  timeOptions: Options = {
    floor: 0,
    ceil: 0,
    step: 0,
    showTicks: false,
    showSelectionBar: true,
    translate: (timeValue: number, label: LabelType): string => {
      return this.formatTime(timeValue);
    }
  };
  sizeValue: number = localStorage.getItem('tableSizeFactor') ? +localStorage.getItem('tableSizeFactor') * 100 : 100;
  sizeOptions: Options = {
    floor: 50,
    ceil: 150,
    translate: (sizeValue: number, label: LabelType): string => {
      return this.translateService.instant('tablePlan.size') + sizeValue + ' %';
    }
  };
  // zoomValue: number = 100;
  zoomValue: number = localStorage.getItem('zoomSizeFactor')
    ? Math.round(+localStorage.getItem('zoomSizeFactor') * 100)
    : 100;
  zoomOptions: Options = {
    floor: 50,
    ceil: 150,
    translate: (zoomValue: number, label: LabelType): string => {
      return 'Zoom ' + zoomValue + ' %';
    }
  };

  tableSizeFactor: number = localStorage.getItem('tableSizeFactor') ? +localStorage.getItem('tableSizeFactor') : 1;
  zoomSizeFactor: number = localStorage.getItem('zoomSizeFactor') ? +localStorage.getItem('zoomSizeFactor') : 1;
  scale = 100;
  reservationsForRoom: Reservation[] = [];
  selectedReservationTables: RoomTable[];
  roomWidth = 0;
  roomLength = 0;
  tableStartingPositionX = 0;
  tableStartingPositionY = 0;
  contextMenuItems = [
    {
      text: this.translateService.instant('tablePlan.changeType'),
      items: [
        { text: this.translateService.instant('tablePlan.square') },
        { text: this.translateService.instant('tablePlan.round') },
        { text: this.translateService.instant('tablePlan.rectangle') },
        { text: TableType.AUTO }
      ]
    },
    {
      text: this.translateService.instant('tablePlan.rotate'),
      icon: 'pulldown',
      beginGroup: true
    },
    {
      text: this.translateService.instant('tablePlan.copyTableSize'),
      icon: 'unselectall',
      beginGroup: true
    },
    {
      text: this.translateService.instant('tablePlan.removeFromTablePlan'),
      icon: 'trash',
      beginGroup: true
    }
  ];
  reservationMenuItems = [
    {
      text: this.translateService.instant('Neue Reservierung'),
      icon: 'add',
      beginGroup: true
    },
    {
      text: this.translateService.instant('Neuen Walk-In'),
      icon: 'add',
      beginGroup: true
    }
  ];
  statusItems = [
    { text: this.translateService.instant('tablePlan.pending'), color: '#9f9f9f', value: 'pending', isActive: false },
    {
      text: this.translateService.instant('tablePlan.confirmed'),
      color: '#24b314',
      value: 'confirmed',
      isActive: false
    },
    { text: this.translateService.instant('tablePlan.arrived'), color: '#ebb12b', value: 'arrived', isActive: false },
    { text: this.translateService.instant('tablePlan.noShow'), color: '#912f5e', value: 'noShow', isActive: false },
    { text: this.translateService.instant('tablePlan.canceled'), color: '#e65252', value: 'canceled', isActive: false },
    { text: this.translateService.instant('tablePlan.placed'), color: '#1371fe', value: 'placed', isActive: false },
    { text: this.translateService.instant('tablePlan.finished'), color: '#495057', value: 'finished', isActive: false }
  ];
  copiedTableDimensionsAndType: any;
  selectedTableOnClick: any;
  contextMenuStatusItems: any[] = [];
  newOrEditReservationMenu: any[] = [];
  newReservationMenu = [{ text: this.translateService.instant('tablePlan.createNewReservation') }];
  editReservationMenu = [{ text: this.translateService.instant('tablePlan.editReservation') }];
  activeClient: any;
  isDoubleClick = 0;
  reservationContextMenuItems: any = [];
  allDecorations: any[] = [];
  decorationsForRestaurant: any[] = [];
  contextDecorationMenu = [
    {
      text: this.translateService.instant('tablePlan.rotate'),
      icon: 'pulldown'
    },
    {
      text: this.translateService.instant('tablePlan.removeFromTablePlan'),
      icon: 'trash',
      beginGroup: true
    }
  ];
  roomFloorSettings: any;
  patterns: any[] = [];
  isSuperAdmin = false;
  interirorDrawerIsOpened = false;
  dragingOverTable: number;
  dragedReservation: any;
  dragingReservation: any;
  isTableDraging = false;

  tableType = {
    square: {
      width: 80,
      height: 80,
      borderRadius: 4
    },

    round: {
      width: 80,
      height: 80,
      borderRadius: 9999
    },

    rectangle: {
      width: 160,
      height: 80,
      borderRadius: 5
    },

    rotatedRectangle: {
      width: 80,
      height: 160,
      borderRadius: 5
    }
  };
  idOfSelectedReservationFromTable: string;
  generalSettings: GeneralSettings;
  color1: any;
  color2: any;
  color3: any;

  isDraggingReservationToRoom = true;
  private selectedRoomTables: any[] = [];
  private roomOffset: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  private longPressTimer: any;
  private ghostElement: HTMLElement;
  private startingPositionOfTouch: { x: number; y: number };

  constructor(
    private reservationService: ReservationService,
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
    private elementRef: ElementRef
  ) {
    this.getAllStatus();
  }

  ngOnInit() {
    this.getAllTables();
    this.getSettings();
    this.getRooms();
    this.getActiveClient();
    this.reservations = this.sortAndFilterReservations();
    this.getAllDecorations();
    this.getDecorationsForRestaurant();
    this.getRestaturantFloorPatterns();
    this.getRestaurantFloorSettings();
    this.isCurentUserSuperAdmin();

    this.reservationService
      .getSettings()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((settings: any) => {
        this.generalSettings = settings;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reservations) {
      this.reservations = this.sortAndFilterReservations();
      if (this.activeRoom) {
        this.getReservationsForSelectedRoom();
        this.getValues(this.activeRoom.id);
      }
    }

    if (changes.allowDragging) {
      this.allowDragging = changes.allowDragging.currentValue;
    }

    if (this.activeRoom) {
      this.roomWidth = this.activeRoom.width * 100;
      this.roomLength = this.activeRoom.length * 100;
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  getAllTables() {
    this.reservationService
      .getAllTablesForActiveClient()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.tablesInTheRoomRoom = [];
        for (let index = 0; index < response.length; index++) {
          const table = response[index];
          table.dimensions = table.dimensions ? JSON.parse(table.dimensions) : null;
          table.position = table.position ? JSON.parse(table.position) : null;
          if (!table.isCombined) {
            this.tablesInTheRoomRoom.push(table);
          }
        }
      });
  }

  getSettings() {
    this.reservationService
      .getReservationBookSettings()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((settings: any) => {
        this.timeOptions.floor = settings.startTime * 60;
        this.timeOptions.ceil = settings.endTime * 60;
        // this.timeOptions.step = settings.timeInterval;
        this.timeValueToNow();
      });
  }

  setTimeOnSliderToNow() {
    this.timeValueToNow();
  }

  timeValueToNow() {
    const now = new Date();
    this.timeValue = now.getHours() * 60 + now.getMinutes();
    if (this.activeRoom) {
      this.getValues(this.activeRoom.id);
    }
  }

  getRooms() {
    this.reservationService
      .getRooms()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        this.rooms = result;
        if (this.rooms.length > 0) {
          if (!this.activeRoom) {
            this.activeRoom = this.rooms[0];
            this.getValues(this.activeRoom.id);
          } else {
            this.activeRoom = this.rooms.find((room: Room) => {
              return this.activeRoom.id === room.id;
            });
            this.getValues(this.activeRoom.id);
          }
          if (this.activeRoom) {
            this.roomWidth = this.activeRoom.width * 100;
            this.roomLength = this.activeRoom.length * 100;
          }
        }
      });
  }

  getActiveClient() {
    this.authService
      .getActiveClient()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((clientData: any) => {
        this.activeClient = clientData;
      });
  }

  isCurentUserSuperAdmin() {
    this.reservationService
      .isActiveMemberSuperAdmin()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        if (data && data.data && data.data.type === 'superAdmin') {
          this.isSuperAdmin = true;
        }
      });
  }

  sortAndFilterReservations() {
    if (!this.reservations || !this.reservations.length) {
      // If this.reservations is undefined, return empty array because filter function will throw error
      return [];
    }
    console.log(this.reservations);
    const reservationArray = this.reservations.filter((res: any) => {
      return res.status !== 'canceled' && res.status !== 'noShow' && res.status !== 'finished';
    });

    reservationArray.sort((a: Reservation, b: Reservation) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });
    return reservationArray;
  }

  onReservationClick(reservation: any) {
    this.isDoubleClick++;
    setTimeout(() => {
      if (this.isDoubleClick === 2) {
        this.openNewReservationModal(reservation, null, false);
      } else if (this.isDoubleClick === 1) {
        if (reservation.tables[0]) {
          this.onReservationSelected(reservation);
        }
      }
      this.isDoubleClick = 0;
    }, 300);
  }

  onObjectClick(object: any) {
    if (this.isEditing) {
      if (this.selectedTableOnClick) {
        this.removeShowAsMovableAttributeFromTable();
        this.removeShowAsMovableAttributeFromDecoration();
      }
      object.showAsMovable = true;
      this.selectedTableOnClick = object;
    }
  }

  removeShowAsMovableAttributeFromTable() {
    for (let i = 0; i < this.dragableTables.length; i++) {
      const t = this.dragableTables[i];
      t.showAsMovable = false;
    }
  }

  removeShowAsMovableAttributeFromDecoration() {
    for (let i = 0; i < this.decorationsForRestaurant.length; i++) {
      const t = this.decorationsForRestaurant[i];
      t.showAsMovable = false;
    }
  }

  refreshStatusOfMenuItems(reservation: any) {
    if (reservation) {
      this.contextMenuStatusItems = this.statusItems;
      for (let i = 0; i < this.contextMenuStatusItems.length; i++) {
        const status = this.contextMenuStatusItems[i];
        if (status.value === reservation.status) {
          status.isActive = true;
        } else {
          status.isActive = false;
        }
      }
    }
  }

  statusItemClick(event: any, reservation: any) {
    const selectedStatus = event.itemData.value;
    reservation.status = selectedStatus;
    this.reservationService
      .editReservation(reservation)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.snackBar.open(this.translateService.instant('tablePlan.reservationStatusChanged'), '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
  }

  editPattern(pattern?: any) {
    const appendContainer = 'body';
    const patternsModalRef = this.modalService.open(AddPatternComponent, {
      size: 'lg',
      container: appendContainer
    });

    patternsModalRef.componentInstance.pattern = pattern;
    patternsModalRef.componentInstance.passEntry.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      this.snackBar.open(this.translateService.instant('tablePlan.patternSaved'), '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      this.getRestaturantFloorPatterns();
      this.getRestaurantFloorSettings();
      patternsModalRef.close();
    });
  }

  createPattern() {
    const appendContainer = 'body';
    const patternsModalRef = this.modalService.open(AddPatternComponent, {
      size: 'lg',
      container: appendContainer
    });

    patternsModalRef.componentInstance.passEntry.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      this.snackBar.open(this.translateService.instant('tablePlan.patternCreated'), '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      this.getRestaturantFloorPatterns();
      patternsModalRef.close();
    });
  }

  deletePattern(pattern: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = this.translateService.instant('tablePlan.deletePattern');
    modalRef.componentInstance.message = this.translateService.instant('tablePlan.deletePatternConfirmation');
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = this.translateService.instant('tablePlan.yes');
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.reservationService
            .deletePattern(pattern.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
              () => {
                this.snackBar.open(this.translateService.instant('tablePlan.patternDeleted'), '', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
                this.getRestaturantFloorPatterns();
                this.getRestaurantFloorSettings();
              },
              err => {
                this.snackBar.open(err.error.msg, '', {
                  duration: 2000,
                  panelClass: ['snackbar-error']
                });
              }
            );
        }
      },
      () => {}
    );
  }

  createDecoration() {
    const appendContainer = 'body';
    const decorationModalRef = this.modalService.open(AddDecorationComponent, {
      size: 'lg',
      container: appendContainer
    });
    decorationModalRef.componentInstance.passEntry.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      this.snackBar.open(this.translateService.instant('tablePlan.decorationCreated'), '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      this.getAllDecorations();
      decorationModalRef.close();
    });
  }

  editDecoration(decoration: any) {
    const appendContainer = 'body';
    const decorationModalRef = this.modalService.open(AddDecorationComponent, {
      size: 'lg',
      container: appendContainer
    });

    decorationModalRef.componentInstance.decoration = decoration;
    decorationModalRef.componentInstance.passEntry.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      this.snackBar.open(this.translateService.instant('tablePlan.decorationSaved'), '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      this.getAllDecorations();
      this.getDecorationsForRestaurant();
      decorationModalRef.close();
    });
  }

  deleteDecoration(decoration: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = this.translateService.instant('tablePlan.deleteDecoration');
    modalRef.componentInstance.message = this.translateService.instant('tablePlan.deleteDecorationConfirmation');
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = this.translateService.instant('tablePlan.yes');
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.reservationService
            .deleteDecoration(decoration.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
              () => {
                this.snackBar.open(this.translateService.instant('tablePlan.decorationDeleted'), '', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
                this.getAllDecorations();
                this.getDecorationsForRestaurant();
              },
              err => {
                this.snackBar.open(err.error.msg, '', {
                  duration: 2000,
                  panelClass: ['snackbar-error']
                });
              }
            );
        }
      },
      () => {}
    );
  }

  openNewReservationModal(reservation: any, table: any, isWalkIn: boolean) {
    if (this.isEditing) {
      return;
    }
    const time = this.formatTime(this.timeValue);
    const appendContainer = 'body';
    const newReservationModalRef = this.modalService.open(AddReservationComponent, {
      size: 'lg',
      container: appendContainer
    });

    if (reservation) {
      newReservationModalRef.componentInstance.reservation = reservation;
      newReservationModalRef.componentInstance.table = reservation.tables[0];
    } else {
      newReservationModalRef.componentInstance.isWalkIn = isWalkIn;
      newReservationModalRef.componentInstance.defaultDate = this.date;
      newReservationModalRef.componentInstance.selectedTimeOnTablePlanTimeSlider = isWalkIn ? null : time;
      newReservationModalRef.componentInstance.selectedTablePlanTable = table;
    }

    newReservationModalRef.componentInstance.passEntry
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((receivedEntry: any) => {
        this.snackBar.open(this.translateService.instant('tablePlan.reservationCreaterd'), '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        this.getValues(this.activeRoom.id);
        this.reservationService
          .getAllReservations(this.date, this.date)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(response => {
            this.reservations = response.reservations;
            this.getReservationsForSelectedRoom();
            newReservationModalRef.close();
          });
      });
  }

  openAddNewReservation(event: any, table: RoomTable) {
    let walkIn = false;

    // if user click on add new walk-in
    if (event.itemIndex === 1) {
      walkIn = true;
    }
    this.openNewReservationModal(null, table, walkIn);
  }

  itemClick(event: any, table: any) {
    const { itemData, itemIndex } = event;

    if (itemIndex !== 0) {
      if (itemData.text === this.translateService.instant('tablePlan.removeFromTablePlan')) {
        this.removeTableFromRoom(table);
        return;
      }

      if (itemData.text === this.translateService.instant('tablePlan.rotate')) {
        const tempWidth = table.dimensions.width;
        table.dimensions.width = table.dimensions.height;
        table.dimensions.height = tempWidth;
        let angle = table.position.angle;
        if (!table.position.angle) {
          angle = 90;
        } else if (table.position.angle === 270) {
          angle = 0;
        } else {
          angle += 90;
        }
        table.position = {
          ...table.position,
          angle
        };
      } else if (itemData.text === this.translateService.instant('tablePlan.copyTableSize')) {
        this.copiedTableDimensionsAndType = { dimensions: table.dimensions, type: table.type };
      } else if (itemData.text === this.translateService.instant('tablePlan.pasteTableSize')) {
        table.dimensions = this.copiedTableDimensionsAndType.dimensions;
        table.type = this.copiedTableDimensionsAndType.type;
      } else {
        if (itemData.text === this.translateService.instant('tablePlan.square')) {
          table.type = TableType.SQUARE;
        } else if (itemData.text === this.translateService.instant('tablePlan.round')) {
          table.type = TableType.ROUND;
        } else if (itemData.text === this.translateService.instant('tablePlan.rectangle')) {
          table.type = TableType.RECTANGLE;
        } else if (itemData.text === this.translateService.instant('tablePlan.auto')) {
          table.type = TableType.AUTO;
        }
        if (table.type === TableType.RECTANGLE) {
          table.dimensions = { width: table.dimensions.width * 2, height: table.dimensions.width, borderRadius: 5 };
        } else if (table.type === TableType.RECTANGLE_ROTATE) {
          table.dimensions = { width: table.dimensions.width, height: table.dimensions.width * 2, borderRadius: 5 };
        } else if (table.type === TableType.SQUARE) {
          table.dimensions = { width: table.dimensions.width, height: table.dimensions.width, borderRadius: 5 };
        } else if (table.type === TableType.ROUND) {
          table.dimensions = { width: table.dimensions.width, height: table.dimensions.width, borderRadius: 9999 };
        } else if (table.type === TableType.AUTO) {
          table.dimensions = this.calculateAutoTabledimensions(table, TableType.AUTO);
        } else if (table.type === TableType.AUTO_ROTATE) {
          table.dimensions = this.calculateAutoTabledimensions(table, TableType.AUTO_ROTATE);
        }
      }
      this.reservationService
        .editTable(table)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: any) => {
          this.getValues(this.activeRoom.id);
        });
    }
  }

  refreshStatusContextTableItems() {
    if (this.copiedTableDimensionsAndType && this.contextMenuItems.length === 4) {
      this.contextMenuItems.splice(3, 0, {
        text: this.translateService.instant('tablePlan.pasteTableSize'),
        icon: 'selectall',
        beginGroup: true
      });
    }
  }

  formatTime(n: any) {
    // tslint:disable-next-line:no-bitwise
    return `0${(n / 60) ^ 0}`.slice(-2) + ':' + ('0' + (n % 60)).slice(-2);
  }

  getValues(roomId: number, roomOrDataIsChanged: boolean = false) {
    if (roomOrDataIsChanged) {
      this.dragableTables = [];
    }
    const newTables: any[] = [];
    const newDragableTables: any[] = [];
    this.tablesInTheRoomRoom.forEach(async (table: any) => {
      if (table.roomId === roomId) {
        table.dimensions = table.dimensions ? table.dimensions : this.tableType.square;
        table.chairPositions = await this.setChairPositions(table);
        if (table.position) {
          table.isSelected = this.checkIfTableIsSelected(table, roomOrDataIsChanged);
          const isReservedData = this.getTableIsReservedData(table);
          table.isReserved = isReservedData.isReserved;
          table.statusOfCurrentlySelectedReservation = isReservedData.status;

          if (table.statusOfCurrentlySelectedReservation) {
            table.isFree = false;
          } else {
            table.isFree = true;
          }

          const isOutOfRoomRange: boolean = this.checkIfTableIsOutOfRoomRange(this.activeRoom, table);

          if (isOutOfRoomRange) {
            table.position = { angle: table.position.angle, x: 10, y: 10 };
          }
          newDragableTables.push(table);
        } else {
          newTables.push(table);
        }
      }
    });
    this.tablesThatAreNotInTheRoom = newTables;
    this.dragableTables = newDragableTables;
    this.getReservationsForSelectedRoom();
  }

  async setChairPositions(table: any): Promise<any[]> {
    if (!table.position) {
      return;
    }
    const { type, dimensions, seats } = table;
    let tables: any[] = [];
    if (type === 'Round') {
      const rotationsDegrees = 360 / seats;
      const translateX = dimensions.width / 2;

      for (let i = 0; i < seats; i++) {
        tables.push({ position: i + 1, rotationsDegrees, translateX });
      }
    } else {
      const translateX = dimensions.width / 2;
      const translateY = dimensions.height / 2;
      if (seats <= 4) {
        let fourOrLessSeatsPosition: any[] = [];
        if (table.position.angle === 90) {
          fourOrLessSeatsPosition = [
            { position: 1, translateX: translateX - 10, translateY: -10 },
            { position: 2, translateX: translateX - 10, translateY: dimensions.height - 10 },
            { position: 3, translateX: dimensions.width - 10, translateY: translateY - 10 },
            { position: 4, translateX: -10, translateY: translateY - 10 }
          ];
        } else if (table.position.angle === 180) {
          fourOrLessSeatsPosition = [
            { position: 1, translateX: dimensions.width - 10, translateY: translateY - 10 },
            { position: 2, translateX: -10, translateY: translateY - 10 },
            { position: 3, translateX: translateX - 10, translateY: dimensions.height - 10 },
            { position: 4, translateX: translateX - 10, translateY: -10 }
          ];
        } else if (table.position.angle === 270) {
          fourOrLessSeatsPosition = [
            { position: 1, translateX: translateX - 10, translateY: dimensions.height - 10 },
            { position: 2, translateX: translateX - 10, translateY: -10 },
            { position: 3, translateX: -10, translateY: translateY - 10 },
            { position: 4, translateX: dimensions.width - 10, translateY: translateY - 10 }
          ];
        } else {
          fourOrLessSeatsPosition = [
            { position: 1, translateX: -10, translateY: translateY - 10 },
            { position: 2, translateX: dimensions.width - 10, translateY: translateY - 10 },
            { position: 3, translateX: translateX - 10, translateY: -10 },
            { position: 4, translateX: translateX - 10, translateY: dimensions.height - 10 }
          ];
        }
        tables = fourOrLessSeatsPosition.slice(0, seats);
      } else {
        let chairArray = [];
        if (table.position.angle === 90) {
          chairArray = [
            { position: 1, translateX: translateX - 10, translateY: -10 },
            { position: 2, translateX: translateX - 10, translateY: dimensions.height - 10 }
          ];
        } else if (table.position.angle === 180) {
          chairArray = [
            { position: 1, translateX: dimensions.width - 10, translateY: translateY - 10 },
            { position: 2, translateX: -10, translateY: translateY - 10 }
          ];
        } else if (table.position.angle === 270) {
          chairArray = [
            { position: 1, translateX: translateX - 10, translateY: dimensions.height - 10 },
            { position: 2, translateX: translateX - 10, translateY: -10 }
          ];
        } else {
          chairArray = [
            { position: 1, translateX: -10, translateY: translateY - 10 },
            { position: 2, translateX: dimensions.width - 10, translateY: translateY - 10 }
          ];
        }
        const chairsAtTableSide = Math.ceil((seats - 2) / 2);
        let positionSize = table.dimensions.width / chairsAtTableSide;
        if (table.position.angle === 90 || table.position.angle === 270) {
          positionSize = table.dimensions.height / chairsAtTableSide;
        }

        if (table.position.angle === 90) {
          let index = 0;
          for (let i = 2; i < seats; i++) {
            if (i % 2 === 0) {
              const chair = {
                position: i + 1,
                translateX: table.dimensions.width - 10,
                translateY: positionSize * index + (positionSize / 2 - 10)
              };
              chairArray.push(chair);
            } else {
              const chair = {
                position: i + 1,
                translateX: -10,
                translateY: positionSize * index + (positionSize / 2 - 10)
              };
              chairArray.push(chair);
              index++;
            }
          }
        } else if (table.position.angle === 180) {
          let index = chairsAtTableSide - 1;
          for (let i = 2; i < seats; i++) {
            if (i % 2 === 0) {
              const chair = {
                position: i + 1,
                translateX: positionSize * index + (positionSize / 2 - 10),
                translateY: table.dimensions.height - 10
              };
              chairArray.push(chair);
            } else {
              const chair = {
                position: i + 1,
                translateX: positionSize * index + (positionSize / 2 - 10),
                translateY: -10
              };
              chairArray.push(chair);
              index--;
            }
          }
        } else if (table.position.angle === 270) {
          let index = chairsAtTableSide - 1;
          for (let i = 2; i < seats; i++) {
            if (i % 2 === 0) {
              const chair = {
                position: i + 1,
                translateX: -10,
                translateY: positionSize * index + (positionSize / 2 - 10)
              };
              chairArray.push(chair);
            } else {
              const chair = {
                position: i + 1,
                translateX: table.dimensions.width - 10,
                translateY: positionSize * index + (positionSize / 2 - 10)
              };
              chairArray.push(chair);
              index--;
            }
          }
        } else {
          let index = 0;
          for (let i = 2; i < seats; i++) {
            if (i % 2 === 0) {
              const chair = {
                position: i + 1,
                translateX: positionSize * index + (positionSize / 2 - 10),
                translateY: -10
              };
              chairArray.push(chair);
            } else {
              const chair = {
                position: i + 1,
                translateX: positionSize * index + (positionSize / 2 - 10),
                translateY: table.dimensions.height - 10
              };
              chairArray.push(chair);
              index++;
            }
          }
        }

        tables = chairArray;

        // if (table.dimensions.width > table.dimensions.height) {
        //   const distanceBetweenChairs = table.dimensions.width / Math.round((seats - 2) / 2);
        //   tables.push({translateX: -10, translateY: translateY - 10});
        //   tables.push({translateX: dimensions.width - 10, translateY: translateY - 10});
        //
        //   let position = distanceBetweenChairs / 2 - 10;
        //   const hasOddNumberOfChairs = seats % 2 !== 0 ? true : false;
        //   const numberOfSeatsOnOneSide = Math.round((seats - 2) / 2);
        //
        //   for (let i = 0; i < numberOfSeatsOnOneSide; i++) {
        //     if (i + 1 === numberOfSeatsOnOneSide) {
        //       if (hasOddNumberOfChairs) {
        //         tables.push({translateX: position, translateY: -10});
        //       } else {
        //         tables.push({translateX: position, translateY: -10});
        //         tables.push({translateX: position, translateY: dimensions.height - 10});
        //       }
        //     } else {
        //       tables.push({translateX: position, translateY: -10});
        //       tables.push({translateX: position, translateY: dimensions.height - 10});
        //     }
        //     position += distanceBetweenChairs;
        //   }
        // } else {
        //   const distanceBetweenChairs = table.dimensions.height / Math.round((seats - 2) / 2);
        //   tables.push({translateX: translateX - 10, translateY: -10});
        //   tables.push({translateX: translateX - 10, translateY: dimensions.height - 10});
        //
        //   let position = distanceBetweenChairs / 2 - 10;
        //   const hasOddNumberOfChairs = seats % 2 !== 0 ? true : false;
        //   const numberOfSeatsOnOneSide = Math.round((seats - 2) / 2);
        //
        //   for (let i = 0; i < numberOfSeatsOnOneSide; i++) {
        //     if (i + 1 === numberOfSeatsOnOneSide) {
        //       if (hasOddNumberOfChairs) {
        //         tables.push({translateX: -10, translateY: position});
        //       } else {
        //         tables.push({translateX: -10, translateY: position});
        //         tables.push({translateX: dimensions.width - 10, translateY: position});
        //       }
        //     } else {
        //       tables.push({translateX: -10, translateY: position});
        //       tables.push({translateX: dimensions.width - 10, translateY: position});
        //     }
        //     position += distanceBetweenChairs;
        //   }
        // }
      }
    }
    return tables;
  }

  checkIfTableIsOutOfRoomRange(room: Room, table: RoomTable) {
    if (room.width * 100 < table.position.x || room.length * 100 < table.position.y) {
      return true;
    }
    return false;
  }

  checkIfTableIsSelected(table: RoomTable, roomOrDataIsChanged: boolean): boolean {
    if (!this.selectedReservationTables) {
      return;
    }

    for (let i = 0; i < this.selectedReservationTables.length; i++) {
      const element: any = this.selectedReservationTables[i];
      if (table.id === element.id) {
        return true;
      }
    }
    return false;
  }

  getTableIsReservedData(table: any): any {
    if (this.reservations) {
      const statusesOfReservationInsideTable: any[] = [];
      for (let i = 0; i < this.reservations.length; i++) {
        const reservation = this.reservations[i];
        const reservationStart = this.getTimeInMinutes(new Date(reservation.startDate));
        const reservationEnd = this.getTimeInMinutes(new Date(reservation.endDate));
        if (this.timeValue >= reservationStart && reservationEnd > this.timeValue) {
          const found = reservation.tables.find((t: any) => {
            return t.id === table.id;
          });
          // found reservation for selected time in slider
          if (found) {
            statusesOfReservationInsideTable.push(reservation.status);
          }
        }
      }
      // This part is to check if some reservation has status finsihed and to avoid coloring table to gray if there are new reservation at same time with different status
      // If there are more reservations selected at same time inside table
      if (statusesOfReservationInsideTable.length > 1) {
        // if one of those has status finished return different status so table wont be gray at selected time
        const status = statusesOfReservationInsideTable.find(res => {
          return res !== 'finished';
        });
        return { isReserved: true, status: status };
      } else {
        // there is only one reservation at selected time retunr that status even if it is finsihed
        // then it is ok for table to be gray - color of finished reservation
        return { isReserved: true, status: statusesOfReservationInsideTable[0] };
      }
    }

    return { isReserved: false, status: null };
  }

  getTimeInMinutes(date: Date): number {
    let hours = date.getHours();
    if (hours === 0) {
      hours = 24;
    }
    const mintes = date.getMinutes();
    return hours * 60 + mintes;
  }

  changeRoom(event: any) {
    this.activeRoom = this.rooms[event.index];
    if (this.activeRoom) {
      this.getValues(this.activeRoom.id, true);
      this.roomWidth = this.activeRoom.width * 100;
      this.roomLength = this.activeRoom.length * 100;
    }
    this.tableStartingPositionX = 0;
    this.tableStartingPositionY = 0;
  }

  getReservationsForSelectedRoom() {
    if (this.reservations) {
      this.reservationsForRoom = [];
      this.reservationsForRoom = this.reservations.filter((res: any) => {
        // if (res.tables[0] && this.activeRoom && res.status !== 'canceled' && res.status !== 'noShow') {
        //   return res.tables[0].roomId === this.activeRoom.id;
        // } else {
        //   return;
        // }
        let isPresent = false;
        if (this.activeRoom && res.status !== 'canceled' && res.status !== 'noShow') {
          if (res.tables && res.tables.length > 0) {
            for (let i = 0; i < res.tables.length; i++) {
              if (res.tables[i].roomId === this.activeRoom.id) {
                isPresent = true;
                break;
              }
            }
            return isPresent;
          } else {
            return;
          }
        } else {
          return;
        }
      });

      this.reservationsForRoom.sort((a: any, b: any) => {
        return new Date(a.reservedFor).getTime() - new Date(b.reservedFor).getTime();
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tablesThatAreNotInTheRoom, event.previousIndex, event.currentIndex);
  }

  onTableDragStart(table: any) {
    this.removeShowAsMovableAttributeFromTable();
    table.showAsMovable = true;
    this.selectedTableOnClick = table;
  }

  onDragEnded(event: any, table: any) {
    this.isTableDraging = true;
    if (this.isEditing) {
      const position: Point = event.source.getFreeDragPosition();
      table.position = {
        angle: table.position.angle,
        x: parseInt((position.x / this.zoomSizeFactor).toFixed(2), 10),
        y: parseInt((position.y / this.zoomSizeFactor).toFixed(2), 10)
      };
      this.reservationService
        .editTable(table)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(response => {
          const updatedTable = response;
          updatedTable.position = JSON.parse(response.position);
          const index = this.dragableTables.findIndex(ta => {
            return ta.id === table.id;
          });
          this.dragableTables[index].position = updatedTable.position;
        });
    }
  }

  async insertTableInRoom(table: RoomTable) {
    let selectedTable = this.tablesThatAreNotInTheRoom.find(t => {
      return t.id === table.id;
    });
    if (selectedTable) {
      this.tablesThatAreNotInTheRoom = this.tablesThatAreNotInTheRoom.filter(t => {
        return t.id !== table.id;
      });
      selectedTable.position = { x: 10 + this.tableStartingPositionX, y: 10 + this.tableStartingPositionY };
      if (this.tableStartingPositionY + 200 > this.roomLength && this.tableStartingPositionX + 200 > this.roomWidth) {
        this.tableStartingPositionY = 50;
        this.tableStartingPositionX = 50;
      } else if (this.tableStartingPositionX + 200 > this.roomWidth) {
        this.tableStartingPositionY += 100;
        this.tableStartingPositionX = this.tableStartingPositionX % 100;
      } else {
        this.tableStartingPositionX += 100;
      }
      const borderRadius = table.type === TableType.ROUND ? 9999 : 5;
      const chairPositions = await this.setChairPositions(selectedTable);

      selectedTable = {
        ...selectedTable,
        dimensions: {
          ...selectedTable.dimensions,
          borderRadius
        },
        chairPositions
      };

      this.reservationService
        .editTable(selectedTable)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: any) => {
          this.dragableTables.push(selectedTable);
        });
    }
  }

  removeTableFromRoom(table: any) {
    this.dragableTables = this.dragableTables.filter(t => {
      return t.id !== table.id;
    });

    this.tablesThatAreNotInTheRoom.push(table);
    table.position = null;
    this.reservationService
      .editTable(table)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.getValues(this.activeRoom.id);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onReservationSelected(reservation: any) {
    if (!reservation.active || reservation.active === false) {
      this.reservations.forEach((res: any) => {
        if (res.active) {
          res.active = false;
        }
      });
      reservation.active = true;
      const tempIndex = this.tabGroup.selectedIndex;
      this.rooms.forEach(room => {
        if (reservation.tables[0].room && room.name === reservation.tables[0].room.name) {
          this.tabGroup.selectedIndex = this.rooms.indexOf(room);
          this.selectedReservationTables = reservation.tables;
          if (tempIndex === this.rooms.indexOf(room)) {
            this.getValues(room.id);
          }
        }
      });
    } else {
      reservation.active = false;
    }
  }

  handleOnTableReservationClick(event: any): void {
    const id = event.reservationId;
    this.idOfSelectedReservationFromTable = `reservation-${id}`;

    this.reservations.forEach((res: any) => {
      if (res.active) {
        res.active = false;
      }
      if (res.id === id) {
        res.active = true;
      }
    });

    const el = document.getElementById(this.idOfSelectedReservationFromTable);
    el.scrollIntoView({ behavior: 'smooth' });
  }

  calculateAutoTabledimensions(table: RoomTable, type: TableType) {
    const dimensions = {
      width: 80,
      height: 80,
      borderRadius: 5
    };

    if (table.seats <= 5) {
      return this.tableType.square;
    } else {
      if (type === TableType.AUTO) {
        dimensions.width = dimensions.width * ((table.seats - 2) / 2);
      } else {
        dimensions.height = dimensions.height * ((table.seats - 2) / 2);
      }
      return dimensions;
    }
  }

  setSizeOfTables() {
    this.tableSizeFactor = this.sizeValue / 100;
  }

  saveCurrentTableFactorSize() {
    localStorage.setItem('tableSizeFactor', this.tableSizeFactor.toString());
  }

  setZoomValueOfTheRoom() {
    this.zoomSizeFactor = this.zoomValue / 100;
  }

  saveCurrentZoomValue() {
    localStorage.setItem('zoomSizeFactor', this.zoomSizeFactor.toString());
  }

  zoomInOrOut(zoom: string) {
    const zoomDiff = zoom === 'in' ? 10 : -10;
    this.zoomSizeFactor = (Math.round(+localStorage.getItem('zoomSizeFactor') * 100) + zoomDiff) / 100;
    localStorage.setItem('zoomSizeFactor', this.zoomSizeFactor.toString());
    this.zoomValue = Math.round(+localStorage.getItem('zoomSizeFactor') * 100);
  }

  onToggleChange() {
    this.interirorDrawerIsOpened = !this.interirorDrawerIsOpened;
    this.sidenav.toggle();
  }

  updateTableSize(table: any, htmlTable: any, event: MouseEvent) {
    if (this.isEditing && !this.isTableDraging) {
      const borderRadius = table.type === TableType.ROUND ? 9999 : 5;
      const dimensions = {
        height: Math.round(htmlTable.offsetHeight / this.zoomSizeFactor / this.tableSizeFactor),
        width: Math.round(htmlTable.offsetWidth / this.zoomSizeFactor / this.tableSizeFactor),
        borderRadius
      };
      table.dimensions = dimensions;
      this.reservationService
        .editTable(table)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
          this.getValues(this.activeRoom.id);
        });
    }
    this.isTableDraging = false;
  }

  getAllDecorations() {
    this.reservationService
      .getAllDecorations()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        this.allDecorations = response;
      });
  }

  getDecorationsForRestaurant() {
    this.reservationService
      .getRestaurantDecorations()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        for (let i = 0; i < response.length; i++) {
          const decoration = response[i];
          decoration.size = JSON.parse(decoration.size);
          decoration.position = JSON.parse(decoration.position);
        }
        this.decorationsForRestaurant = response;
      });
  }

  getRestaturantFloorPatterns() {
    this.reservationService
      .getRestaurantFloorPatterns()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.patterns = response;
      });
  }

  getRestaurantFloorSettings() {
    this.reservationService
      .getRestaurantFloorSettings()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.roomFloorSettings = response;
      });
  }

  addDecorationToRoom(activeRoomId: number, decoration: any) {
    let size = { height: 80, width: 80 };
    if (decoration.type === 'furniture') {
      size = { height: 150, width: 150 };
    } else if (decoration.type === 'chair') {
      size = { height: 25, width: 25 };
    }
    const newDecorationInRoom: any = {
      clientId: this.activeClient.id,
      roomId: activeRoomId,
      imageId: decoration.id,
      position: { x: 10, y: 10 },
      size
    };
    this.reservationService
      .addNewDecorationInRoom(newDecorationInRoom)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getDecorationsForRestaurant();
      });
  }

  addInnerWallToRoom(activeRoomId: number) {
    const size = { height: 200, width: 20 };
    const newInnerWall: any = {
      clientId: this.activeClient.id,
      roomId: activeRoomId,
      imageId: null,
      position: { x: 10, y: 10 },
      size
    };
    this.reservationService
      .addNewDecorationInRoom(newInnerWall)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getDecorationsForRestaurant();
      });
  }

  onDecorationDragEnded(event: any, decoration: any) {
    const position: Point = event.source.getFreeDragPosition();
    decoration.position = {
      x: position.x / this.zoomSizeFactor,
      y: position.y / this.zoomSizeFactor
    };
    this.reservationService
      .editDecorationInRoom(decoration)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        const updatedDecoration = response;
        updatedDecoration.position = JSON.parse(response.position);
        const index = this.decorationsForRestaurant.findIndex(ta => {
          return ta.id === decoration.id;
        });
        this.decorationsForRestaurant[index].position = updatedDecoration.position;
      });
  }

  updateDecorationSize(decoration: any, decorationHtmlElem: any, event: any) {
    if ((<HTMLElement>event.target).id !== 'dragableHandle') {
      const dimensions = {
        height: Math.round(decorationHtmlElem.offsetHeight / this.zoomSizeFactor / this.tableSizeFactor),
        width: Math.round(decorationHtmlElem.offsetWidth / this.zoomSizeFactor / this.tableSizeFactor)
      };
      decoration.size = dimensions;
      this.reservationService
        .editDecorationInRoom(decoration)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(response => {});
    }
  }

  deleteDecorationFromRoom(decorationId: number) {
    this.reservationService
      .deleteDecorationFromRoom(decorationId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        this.getDecorationsForRestaurant();
      });
  }

  decorationClick(event: any, decoration: any) {
    const { itemData } = event;

    if (itemData.text === this.translateService.instant('tablePlan.rotate')) {
      let rotatAng: number = decoration.rotationAngle;
      if (decoration.room_decoration) {
        if (rotatAng < 270) {
          rotatAng += 90;
        } else {
          rotatAng = 0;
        }
      } else {
        // If decoration is a inner wall element
        const tempWidth = decoration.size.width;
        decoration.size.width = decoration.size.height;
        decoration.size.height = tempWidth;
      }

      decoration.rotationAngle = rotatAng;
      this.reservationService
        .editDecorationInRoom(decoration)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(response => {});
    } else if (itemData.text === this.translateService.instant('tablePlan.removeFromTablePlan')) {
      this.deleteDecorationFromRoom(decoration.id);
    }
  }

  onFloorPatternClick(patternId: number) {
    this.roomFloorSettings.roomImageId = patternId;
    this.reservationService
      .updateRestaurantFloorSettings(this.roomFloorSettings)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.getRestaurantFloorSettings();
      });
  }

  onSurroundingsPatternClick(patternId: number) {
    this.roomFloorSettings.surroundingsImageId = patternId;
    this.reservationService
      .updateRestaurantFloorSettings(this.roomFloorSettings)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.getRestaurantFloorSettings();
      });
  }

  setFloorColor(event: any) {
    this.roomFloorSettings.roomFloorColor = event;
    this.roomFloorSettings.roomPattern = null;
    this.roomFloorSettings.roomImageId = null;
  }

  setSurroundingsColor(event: any) {
    this.roomFloorSettings.surroundingsColor = event;
    this.roomFloorSettings.surroundingsPattern = null;
    this.roomFloorSettings.surroundingsImageId = null;
  }

  setWallColor(event: any) {
    this.roomFloorSettings.wallColor = event;
  }

  onColorPickerClose(color: any, colorPicker: number) {
    if (color) {
      this.reservationService
        .updateRestaurantFloorSettings(this.roomFloorSettings)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {});
    }
  }

  getSanitizedSafeUrl(img: string) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${img})`);
  }

  getSanitizedSafeCssTransformed(
    chairRotationDegrees: number,
    index: number,
    chairTranslateX: number,
    angle: number = 0
  ) {
    return this.sanitizer.bypassSecurityTrustStyle(
      `rotate(${chairRotationDegrees * index + angle}deg) translateX(${chairTranslateX *
        this.tableSizeFactor *
        this.zoomSizeFactor}px)`
    );
  }

  // Drag and drop - change reservation table

  onDragStart(event: DragEvent, reservation: Reservation) {
    const reservationData = {
      end: reservation.endDate,
      reservationId: reservation.id,
      start: reservation.startDate,
      tableId: reservation.isTablePlan,
      peopleCount: reservation.peopleCount
    };

    this.onReservationStartToDrag(reservationData);
    // Hide dragging element
    // event.target.style.opacity = 0;
  }

  // onDrag(event: DragEvent) {}

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent | TouchEvent, table: any) {
    this.dragingOverTable = null;
    const reservation = this.reservations.find(res => {
      return res.id === this.dragingReservation.reservationId;
    });
    this.dragingReservation = null;

    reservation.isTablePlan = table.id;
    this.reservationService
      .editReservation(reservation)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.getValues(this.activeRoom.id);
        this.dragedReservation = null;
      });
  }

  onDragEnd(event: DragEvent) {
    this.dragingReservation = null;

    // event.target.style.opacity = 1;
  }

  onDragLeave(event: DragEvent) {
    this.dragingOverTable = null;
  }

  onDragEnter(event: DragEvent | TouchEvent, table: any) {
    event.preventDefault();
    this.dragingOverTable = table.id;
  }

  onReservationStartToDrag(event: any) {
    if ((this.deviceService.isTablet() || this.deviceService.isMobile()) && !this.allowDragging) {
      return;
    }

    if (!event) {
      this.dragingReservation = null;
    } else {
      this.dragingReservation = event;
    }

    for (let i = 0; i < this.dragableTables.length; i++) {
      const table = this.dragableTables[i];

      // Get all reservations inside table
      const reservationsInTable = this.reservations.filter(res => {
        if (res.tables.length) {
          for (const t of res.tables) {
            if (t.id === table.id) {
              return true;
            }
          }
          return false;
        }
      });

      // Table does not have any reservations
      let canBeDroppedHere = false;

      // If there is more seats over the table then persons in reservation
      if (table.seats >= event.peopleCount) {
        // If table is free(no reservation inside)
        if (reservationsInTable.length === 0) {
          canBeDroppedHere = true;

          // Table has reservations inside
        } else {
          for (let j = 0; j < reservationsInTable.length; j++) {
            const reservation = reservationsInTable[j];
            const clickedReservationStartDate = new Date(event.start).getTime();
            const clickedReservationEndDate = new Date(event.end).getTime();
            const reservationStartTime = new Date(reservation.startDate).getTime();
            const reservationEndTime = new Date(reservation.endDate).getTime();

            // Comparing time of reservation with times of reservations in table and checking can reservation be dropped here
            if (
              (reservationStartTime <= clickedReservationStartDate &&
                reservationEndTime <= clickedReservationStartDate) ||
              (reservationStartTime >= clickedReservationEndDate && reservationEndTime > clickedReservationEndDate) ||
              reservation.status === 'finished'
            ) {
              canBeDroppedHere = true;
            } else {
              canBeDroppedHere = false;
              break;
            }
          }
        }
      }
      table.canMakeDropReservation = canBeDroppedHere;
    }
  }

  onReservationDragEnd() {
    this.dragingReservation = null;
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.selectedTableOnClick) {
      const width = this.selectedTableOnClick.dimensions
        ? this.selectedTableOnClick.dimensions.width
        : this.selectedTableOnClick.size.width;
      const height = this.selectedTableOnClick.dimensions
        ? this.selectedTableOnClick.dimensions.height
        : this.selectedTableOnClick.size.height;

      if (event.key === 'ArrowUp' && this.selectedTableOnClick.position.y > 0) {
        this.selectedTableOnClick.position.y -= 1;
        event.preventDefault();
      } else if (
        event.key === 'ArrowDown' &&
        this.selectedTableOnClick.position.y < this.activeRoom.length * 100 - height
      ) {
        this.selectedTableOnClick.position.y += 1;
        event.preventDefault();
      } else if (event.key === 'ArrowLeft' && this.selectedTableOnClick.position.x > 0) {
        this.selectedTableOnClick.position.x -= 1;
        event.preventDefault();
      } else if (
        event.key === 'ArrowRight' &&
        this.selectedTableOnClick.position.x < this.activeRoom.width * 100 - width
      ) {
        this.selectedTableOnClick.position.x += 1;
        event.preventDefault();
      } else if (event.key === 'Enter') {
        this.reservationService
          .editTable(this.selectedTableOnClick)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(response => {
            this.getValues(this.activeRoom.id);
          });
        this.removeShowAsMovableAttributeFromTable();
        this.selectedTableOnClick = null;
      } else if (event.key === 'Escape') {
        this.removeShowAsMovableAttributeFromTable();
        this.selectedTableOnClick = null;
      }
    }
  }

  dragTouchstart(event: any, reservation: Reservation) {
    this.startingPositionOfTouch = { x: event.touches[0].clientX, y: event.touches[0].clientY };

    let bgColor = '#24b314';

    switch (reservation.status) {
      case 'arrived': {
        bgColor = '#ebb12b';
        break;
      }
      case 'placed': {
        bgColor = '#1371fe';
        break;
      }
      case 'finished': {
        bgColor = '#495057';
        break;
      }
      case 'noShow': {
        bgColor = '#912f5e';
        break;
      }
      case 'canceled': {
        bgColor = '#e65252';
        break;
      }
    }
    const elementId = 'reservation-' + reservation.id;
    this.ghostElement = document.getElementById(elementId).cloneNode(true) as HTMLElement;
    this.ghostElement.setAttribute('id', 'ghost-el-id');
    this.ghostElement.style.visibility = 'hidden';
    this.ghostElement.style.position = 'absolute';
    this.ghostElement.style.zIndex = '1';
    this.ghostElement.style.backgroundColor = bgColor;
    this.ghostElement.style.color = '#fff';
    this.ghostElement.style.maxWidth = '250px';
    if (this.isFullscreen && document) {
      document.getElementById('fullscreenElement').appendChild(this.ghostElement);
    } else {
      document.body.appendChild(this.ghostElement);
    }
    this.handleLongPressOnMobiles(event, reservation);
    // }
  }

  handleLongPressOnMobiles(event: any, reservation: any) {
    this.selectedRoomTables = [];
    for (const table of this.tablesInTheRoomRoom) {
      if (table.roomId === this.activeRoom.id) {
        this.selectedRoomTables.push(table);
      }
    }
    const { x, y } = this.room.nativeElement.getBoundingClientRect();
    this.roomOffset = { left: x, top: y };
    this.onDragStart(event, reservation);
  }

  dragTouchmove(event: TouchEvent) {
    if (!this.allowDragging) {
      return;
    } else {
      event.preventDefault();
    }

    this.isDraggingReservationToRoom = event.touches[0].clientX - this.startingPositionOfTouch.x > 80;
    if (!this.isDraggingReservationToRoom && this.ghostElement.style.visibility === 'hidden') {
      return;
    }

    let draggingOverTable = false;
    const zoom = this.zoomValue / 100;
    const touchPosition = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    this.ghostElement.style.left = event.touches[0].pageX + 'px';
    this.ghostElement.style.top = event.touches[0].pageY + 'px';
    if (this.ghostElement.style.visibility === 'hidden') {
      this.ghostElement.style.visibility = 'visible';
    }

    for (const table of this.selectedRoomTables) {
      if (table.position) {
        const x1 = table.position.x * zoom + this.roomOffset.left;
        const x2 = x1 + table.dimensions.width * zoom;
        const y1 = table.position.y * zoom + this.roomOffset.top;
        const y2 = y1 + table.dimensions.height * zoom;
        if (touchPosition.x >= x1 && touchPosition.x <= x2 && touchPosition.y >= y1 && touchPosition.y <= y2) {
          draggingOverTable = true;
          this.dragingOverTable = table;
          this.onDragEnter(event, table);
        }
      }
    }

    if (!draggingOverTable) {
      this.dragingOverTable = null;
    }
  }

  dragTouchend(event: TouchEvent) {
    // event.preventDefault();
    this.isDraggingReservationToRoom = false;
    this.ghostElement.style.visibility = 'hidden';
    if (this.allowDragging) {
      if (this.dragingOverTable) {
        const reservation = this.reservations.find(res => {
          return res.id === this.dragingReservation.reservationId;
        });
        this.dragingReservation = null;

        reservation.isTablePlan = this.dragingOverTable;
        this.reservationService
          .editReservation(reservation)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((response: any) => {
            this.getValues(this.activeRoom.id);
            this.dragedReservation = null;
          });
      }

      this.dragingReservation = null;
      this.dragingOverTable = null;
    }
    // const el = document.getElementById('ghost-el-id');

    this.ghostElement.remove();
    this.ghostElement = null;
  }

  getAllStatus() {
    this.reservationService
      .getResStatus()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resStatusList: any) => {
        this.resStatusList = resStatusList;
        this.resStatusList.forEach((item: any) => {
          item['type'] = 'single';
        });
        this.allStatus = this.allStatus.concat(this.resStatusList);
      });
  }

  getTableBackgroundColor(status: string): string {
    if (!status || !this.allStatus || !this.allStatus.length) {
      return 'white';
    }
    const foundStatus = this.allStatus.find(s => s.value === status);
    return foundStatus ? foundStatus.color : 'white';
  }
}

import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-email-address',
  templateUrl: './change-email-address.component.html',
  styleUrls: ['./change-email-address.component.scss']
})
export class ChangeEmailAddressComponent implements OnInit {
  @Input() email: string;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  emailAddressFormGroup: FormGroup;

  constructor(private formBuilder: FormBuilder, private snackBar: MatSnackBar, public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.emailAddressFormGroup = this.formBuilder.group({
      email: [this.email, [Validators.required, Validators.email]]
    });
  }

  sendVoucher() {
    if (!this.emailAddressFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.passEntry.emit(this.emailAddressFormGroup.value);
    this.activeModal.dismiss('');
  }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, OnDestroy } from '@angular/core';
import { VouchersService } from '../vouchers.service';
import { Page } from '../vouchers.model';
import moment from 'moment';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/takeUntil';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CancelMsgBoxComponent } from './cancel-msg-box/cancel-msg-box.component';
import { MatSnackBar } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
export class VouchersLogComponent {
    constructor(voucherService, clientResolver, translate, modalService, snackBar) {
        this.voucherService = voucherService;
        this.clientResolver = clientResolver;
        this.translate = translate;
        this.modalService = modalService;
        this.snackBar = snackBar;
        this.startDate = new Date();
        this.endDate = new Date();
        this.page = new Page();
        this.pageLimit = 25;
        this.transactionsBalance = {
            start: 0,
            end: 0
        };
        this.csvTransactions = [];
        this.sumRedeemed = {
            total: 0,
            pos: 0,
            online: 0
        };
        this.sumRecharged = {
            total: 0,
            pos: 0,
            online: 0
        };
        this.filter = {
            client: null,
            voucher: null
        };
        this.date = {
            begin: Date,
            end: Date
        };
        this.clients = [];
        this.vouchers = [];
        this.poolClients = { a: 'name' };
        this.csvOptions = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: false,
            headers: ['Datum', 'Uhrzeit', 'Code', 'Betrag'],
            showTitle: false,
            title: '',
            useBom: false,
            removeNewLines: true,
            keys: ['dateFormatted', 'timeFormatted', 'voucherCode', 'amount']
        };
        this.message = {};
        this.cancelVoucherAmount = 0.0;
        this.ngUnsubscribe = new Subject();
        this.translate.onLangChange.subscribe((event) => {
            this.translate.setDefaultLang(event.lang);
            this.translator();
        });
    }
    ngOnInit() {
        this.date.end = new Date();
        this.date.begin = new Date(this.date.end.getFullYear(), this.date.end.getMonth(), 1);
        this.getVoucherSettings();
        this.getPoolClients();
        this.translator();
        //this.getTransactions() is called from withing getPoolClient
        //this.getTransactions();
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    translator() {
        this.message = {
            emptyMessage: this.translate.instant('Vouchers.Transactions.NoTransactions'),
            total: this.translate.instant('Vouchers.Transactions.ATotalOf')
        };
    }
    getPoolClients() {
        let pcs = [];
        let poolClientId = [];
        this.voucherService
            .getClientPool(this.date.begin, this.date.end)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(res => {
            pcs = res || [];
            pcs.forEach((client) => {
                this.poolClients[client.clientId] = client.name;
                poolClientId.push(client.clientId);
            });
            this.getAllPoolClientsData(poolClientId);
            this.getTransactions();
        });
    }
    getAllPoolClientsData(poolClientId) {
        this.voucherService
            .getAllPoolClientsData(poolClientId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(res => {
            this.clients = res;
        });
    }
    getVoucherSettings() {
        this.voucherService
            .getSettings()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => {
            this.settings = res;
            if (this.settings.poolSettings && this.settings.poolSettings.isMother) {
                this.csvOptions.headers.push('Einlösender Betrieb');
                this.csvOptions.keys.push('betriebId');
                this.csvOptions.headers.push('Inhaber-Betrieb');
                this.csvOptions.keys.push('ownerBetriebId');
            }
        });
    }
    getTransactionsDataTil(clientId = null) {
        return __awaiter(this, void 0, void 0, function* () {
            this.transactionsBalance.start = 0;
            this.transactionsBalance.end = 0;
            yield this.voucherService
                .getTransactionsDataTil(clientId, moment(this.date.begin).format('YYYY-MM-DD'), moment(this.date.end).format('YYYY-MM-DD'))
                .toPromise()
                .then((data) => {
                this.transactionsBalance.start = data.balanceStart || 0;
                this.transactionsBalance.end = data.balanceEnd || 0;
            });
        });
    }
    getBalancedCanceledVouchers(clientId, startDate, endDate) {
        this.cancelVoucherAmount = 0.0;
        this.voucherService
            .getBalancedCanceledVouchers(clientId, startDate, endDate)
            .toPromise()
            .then((data) => {
            console.log('test', data);
            data.map((item) => {
                this.cancelVoucherAmount = parseFloat(this.cancelVoucherAmount) + parseFloat(item.transValue);
            });
        });
    }
    getTransactions(clientId = null, voucherCode = '') {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.getTransactionsDataTil();
            yield this.voucherService
                .getTransactions(clientId, moment(this.date.begin).format('YYYY-MM-DD'), moment(this.date.end).format('YYYY-MM-DD'), this.page.pageNumber)
                .toPromise()
                .then((data) => __awaiter(this, void 0, void 0, function* () {
                console.log(134, this.date);
                this.transactions = data;
                yield this.getBalancedCanceledVouchers(clientId, moment(this.date.begin).format('YYYY-MM-DD'), moment(this.date.end).format('YYYY-MM-DD'));
                // Reset voucher list
                this.vouchers = [];
                let voucherKeys = [];
                // Reset sums
                this.sumRedeemed = {
                    total: 0,
                    pos: 0,
                    online: 0
                };
                this.sumRecharged = {
                    total: 0,
                    pos: 0,
                    online: 0
                };
                this.csvTransactions = [];
                if (clientId) {
                    this.transactions = this.transactions.filter(function (row) {
                        return row.betriebId == clientId;
                    });
                }
                // else {
                //   if (this.clientResolver.client.id) {
                //     const resClientId = this.clientResolver.client.id;
                //     this.transactions = this.transactions.filter(function(row: any) {
                //       return row.betriebId == resClientId;
                //     });
                //   }
                // }
                if (voucherCode && voucherCode != '') {
                    this.transactions = this.transactions.filter(function (el) {
                        return el.voucher.code.includes(voucherCode);
                    });
                }
                this.allTransactions = this.transactions;
                this.transactions = this.transactions.filter((item) => !item.canceledAt);
                // Sum transaction values
                this.transactions.forEach((row) => {
                    if (row.voucher) {
                        if (!voucherKeys.includes(row.voucher.code)) {
                            voucherKeys.push(row.voucher.code);
                            this.vouchers.push({ code: row.voucher.code });
                        }
                    }
                    if (row.transType === 'withdraw') {
                        this.sumRedeemed.total = this.sumRedeemed.total + row.transValue;
                        if (row.voucher) {
                            if (row.voucher.type === 'gg' || row.voucher.type === 'app') {
                                this.sumRedeemed.online = this.sumRedeemed.online + row.transValue;
                            }
                            if (row.voucher.type === 'pos') {
                                this.sumRedeemed.pos = this.sumRedeemed.pos + row.transValue;
                            }
                        }
                        else {
                            console.error(197, 'row.voucher is null', row);
                        }
                        // if (
                        //   row.transReferrer === 'gg' ||
                        //   row.transReferrer === 'app' ||
                        //   row.transReferrer === 'business' ||
                        //   row.transReferrer === 'admin'
                        // ) {
                        //   this.sumRedeemed.online = this.sumRedeemed.online + row.transValue;
                        // } else {
                        //   // POS Api Codes
                        //   this.sumRedeemed.pos = this.sumRedeemed.pos + row.transValue;
                        // }
                        // }
                    }
                    console.log(`${this.sumRecharged.total} + ${row.transValue} #${row.id} ${row.transType}`, this.transactions);
                    if (row.transType === 'recharge' || row.transType === 'create') {
                        this.sumRecharged.total = this.sumRecharged.total + row.transValue;
                        if (row.voucher) {
                            if (row.voucher.type === 'gg') {
                                this.sumRecharged.online = this.sumRecharged.online + row.transValue;
                            }
                            if (row.voucher.type === 'pos') {
                                this.sumRecharged.pos = this.sumRecharged.pos + row.transValue;
                            }
                        }
                        else {
                            console.error(223, 'row.voucher is null', row);
                        } /* else {
                        if (
                          row.transReferrer === 'gg' ||
                          row.transReferrer === 'app' ||
                          row.transReferrer === 'business' ||
                          row.transReferrer === 'admin'
                        ) {
                          this.sumRecharged.online = this.sumRecharged.online + row.transValue;
                        } else {
                          // POS Api Codes
                          this.sumRecharged.pos = this.sumRecharged.pos + row.transValue;
                        }
                        // }
                        */
                    }
                    let calcAmount = row.transValue.toFixed(2).replace('.', ',');
                    if (row.transType === 'withdraw' || row.transType === 'debit') {
                        calcAmount = '-' + calcAmount;
                    }
                    if (row.transType !== 'payout') {
                        this.csvTransactions.push({
                            dateFormatted: moment(row.createdAt).format('DD.MM.YYYY'),
                            timeFormatted: moment(row.createdAt).format('H:mm'),
                            voucherCode: row.voucher ? row.voucher.code : row.transType,
                            amount: calcAmount,
                            betriebId: row.client.name,
                            ownerBetriebId: this.poolClients[row.ownerBetriebId] || ''
                        });
                    }
                    // console.log('this.poolClients', this.poolClients, row.ownerBetriebId, this.poolClients[row.ownerBetriebId]);
                    // Add to clients
                    const findInArray = this.clients.findIndex((c) => c.id === row.client.id);
                    if (findInArray === -1) {
                        this.clients.push(row.client);
                        // To trigger change detection
                        this.clients = this.clients.slice(0);
                    }
                });
            }));
        });
    }
    onDateRangesChange() {
        this.getTransactions();
    }
    filterClient(client) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(client);
            if (client) {
                this.filter.client = client;
                this.getTransactions(client.id);
            }
            else {
                this.getTransactions();
            }
        });
    }
    filterVoucher(voucher) {
        return __awaiter(this, void 0, void 0, function* () {
            if (voucher === undefined) {
                voucher = { code: '' };
            }
            console.log('filterVoucher', voucher);
            if (voucher.code) {
                this.filter.voucher = voucher.code;
                if (this.filter.client) {
                    this.getTransactions(this.filter.client.id, this.filter.voucher);
                }
                else {
                    this.getTransactions(null, this.filter.voucher);
                }
            }
            else {
                if (this.filter.client) {
                    this.getTransactions(this.filter.client.id);
                }
                else {
                    this.getTransactions();
                }
            }
        });
    }
    downloadPdf() {
        const pdfData = this.preparePdfData();
        this.voucherService
            .getVoucherTransactionsPdf(pdfData)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((pdfBlob) => {
            this.saveBlobAsPdf(pdfBlob, 'voucher-export.pdf');
        }, error => {
            console.error('Error downloading PDF:', error);
            alert('Error downloading PDF. Please check the console for details.');
        });
    }
    saveBlobAsPdf(blob, fileName) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
    }
    preparePdfData() {
        let orders = [];
        this.transactions.forEach(order => {
            /*
        
                            <span *ngIf="row.transType === 'withdraw'">Gutschein eingelöst</span>
                            <span *ngIf="row.transType === 'recharge'">Gutschein aufgeladen</span>
                            <span *ngIf="row.transType === 'create'">Gutschein erstellt</span>
                            <span *ngIf="row.transType === 'payout'">Auszahlung</span>
                            <span *ngIf="row.transType === 'debit'">Einzug</span>
            */
            let tValue = parseFloat(`${order.transValue}`);
            if (order.transType === 'withdraw' || order.transType === 'debit') {
                tValue = tValue * -1;
            }
            orders.push({
                sorting: moment(order.createdAt).format('YYYY-MM-DD HH:mm'),
                createdAt: moment(order.createdAt).format('DD.MM.YYYY HH:mm'),
                transType: order.transType
                    .replace('withdraw', 'Gutschein eingelöst')
                    .replace('recharge', 'Gutschein aufgeladen')
                    .replace('create', 'Gutschein erstellt')
                    .replace('payout', 'Auszahlung')
                    .replace('debit', 'Einzug'),
                staffId: order.staff && order.staff.staffId
                    ? order.staff.staffId + (order.staff.name ? ' (' + order.staff.name + ')' : '')
                    : '',
                voucher: order.voucher,
                transValue: tValue.toFixed(2).replace('.', ',') + ' €',
                actionClient: order.actionClient
            });
        });
        orders.sort(function (a, b) {
            const keyA = new Date(a.sorting), keyB = new Date(b.sorting);
            // Compare the 2 dates
            if (keyA < keyB)
                return -1;
            if (keyA > keyB)
                return 1;
            return 0;
        });
        const bis = moment(orders[0].sorting).format('DD.MM.YYYY');
        const von = moment(orders[orders.length - 1].sorting).format('DD.MM.YYYY');
        const adresse = {
            z1: `Kunden-Nr.: ${this.clientResolver.client.id}`,
            z2: this.clientResolver.client.name,
            z3: this.clientResolver.client.street,
            z4: this.clientResolver.client.zipCode,
            z5: this.clientResolver.client.location
        };
        return {
            orders,
            von,
            bis,
            adresse,
            sumRecharged: this.sumRecharged,
            sumRedeemed: this.sumRedeemed,
            balanceStart: this.transactionsBalance.start.toFixed(2).replace('.', ',') + ' €',
            balanceEnd: this.transactionsBalance.end.toFixed(2).replace('.', ',') + ' €'
        };
    }
    cancelVoucherTransaction(transactionId) {
        const modalRef = this.modalService.open(CancelMsgBoxComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.passEntry.subscribe((receivedEntry) => __awaiter(this, void 0, void 0, function* () {
            yield this.voucherService
                .cancelVoucherTransaction(transactionId, receivedEntry.msg)
                .toPromise()
                .then((data) => __awaiter(this, void 0, void 0, function* () {
                this.snackBar.open('Erfolgreich.', '', {
                    duration: 2000,
                    panelClass: ['snackbar-success']
                });
                this.ngOnInit();
            }));
        }));
    }
    canCancelTransaction(createdAt) {
        const { role } = JSON.parse(localStorage.getItem('credentials'));
        const createdAtDate = new Date(createdAt);
        const now = new Date();
        const timeDifference = (now.getTime() - createdAtDate.getTime()) / 1000 / 60; // difference in minutes
        if (role === 'admin') {
            return timeDifference <= 30 * 24 * 60; // 30 days in minutes
        }
        else {
            return timeDifference <= 30; // 30 minutes
        }
    }
}

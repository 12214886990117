var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as deLocale from 'date-fns/locale/de/index.js';
import moment from 'moment';
import { LabelType } from 'ng5-slider';
import { ReservationService } from '@app/reservation/reservation.service';
import { RoomTable, Shift } from '@app/reservation/reservation-settings/reservation-settings.model';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { ClientService } from '@app/core/client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { formatNumber } from '@angular/common';
import { CredentialsService } from '@app/core';
import { ChangeDetectorRef } from '@angular/core';
import parseISO from 'date-fns/parse';
import addDays from 'date-fns/add_days';
import addWeeks from 'date-fns/add_weeks';
import addMonths from 'date-fns/add_months';
import { NgSelectComponent } from '@ng-select/ng-select';
import { takeUntil } from 'rxjs/operators';
import { SpecialCharactersPipe } from '@app/shared/special-characters.pipe';
import { ClientResolverService } from '@app/core/client-resolver.service';
export class AddReservationComponent {
    constructor(activeModal, formBuilder, reservationService, clientService, snackBar, modalService, credentialsService, cdRef, clientResolver) {
        this.activeModal = activeModal;
        this.formBuilder = formBuilder;
        this.reservationService = reservationService;
        this.clientService = clientService;
        this.snackBar = snackBar;
        this.modalService = modalService;
        this.credentialsService = credentialsService;
        this.cdRef = cdRef;
        this.clientResolver = clientResolver;
        this.isWalkIn = false;
        this.passEntry = new EventEmitter();
        this.filteredGuests = [];
        this.selectedTables = [];
        this.disabledTables = [];
        this.usedSeatCount = 0;
        this.dateoptions = {
            locale: deLocale
        };
        this.isLoadingAutocomplete = false;
        this.showAdditionalGuestInputs = false;
        this.arrayOfPrefixes = ['Herr', 'Frau', 'Divers', 'Firma'];
        this.times = [];
        this.refreshSlider = new EventEmitter();
        this.maxRecurrenceValue = 1000;
        this.confirmTime = true;
        this.showAllTimes = false;
        this.newTimes = [];
        this.resStatusList = [];
        this.stayTimeOptions = {
            floor: 15,
            ceil: 600,
            step: 15,
            showTicks: true,
            showSelectionBar: true,
            translate: (value, label) => {
                value = value / 60; // Convert to hours
                const fractionalPart = value % 1;
                const mainPart = parseInt(value + '', 10);
                switch (label) {
                    case LabelType.Floor:
                        if (value === 0.25) {
                            return '15 Minuten';
                        }
                        else if (value === 0.5) {
                            return '30 Minuten';
                        }
                        else if (value === 0.75) {
                            return '45 Minuten';
                        }
                        else if (value === 1) {
                            return value + ' Stunde';
                        }
                        else {
                            if (fractionalPart > 0) {
                                const stunde = mainPart === 1 ? `${mainPart} Stunde` : `${mainPart} Stunden`;
                                if (fractionalPart === 0.25) {
                                    return stunde + ' 15 Minuten';
                                }
                                else if (fractionalPart === 0.5) {
                                    return stunde + ' 30 Minuten';
                                }
                                else if (fractionalPart === 0.75) {
                                    return stunde + ' 45 Minuten';
                                }
                            }
                            else {
                                return value + ' Stunden';
                            }
                        }
                        break;
                    case LabelType.Ceil:
                        if (value === 0.25) {
                            return '15 Minuten';
                        }
                        else if (value === 0.5) {
                            return '30 Minuten';
                        }
                        else if (value === 0.75) {
                            return '45 Minuten';
                        }
                        else if (value === 1) {
                            return value + ' Stunde';
                        }
                        else {
                            if (fractionalPart > 0) {
                                const stunde = mainPart === 1 ? `${mainPart} Stunde` : `${mainPart} Stunden`;
                                if (fractionalPart === 0.25) {
                                    return stunde + ' 15 Minuten';
                                }
                                else if (fractionalPart === 0.5) {
                                    return stunde + ' 30 Minuten';
                                }
                                else if (fractionalPart === 0.75) {
                                    return stunde + ' 45 Minuten';
                                }
                            }
                            else {
                                return value + ' Stunden';
                            }
                        }
                        break;
                    case LabelType.Low:
                        if (value === 0.25) {
                            return '15 Minuten';
                        }
                        else if (value === 0.5) {
                            return '30 Minuten';
                        }
                        else if (value === 0.75) {
                            return '45 Minuten';
                        }
                        else if (value === 1) {
                            return value + ' Stunde';
                        }
                        else {
                            if (fractionalPart > 0) {
                                const stunde = mainPart === 1 ? `${mainPart} Stunde` : `${mainPart} Stunden`;
                                if (fractionalPart === 0.25) {
                                    return stunde + ' 15 Minuten';
                                }
                                else if (fractionalPart === 0.5) {
                                    return stunde + ' 30 Minuten';
                                }
                                else if (fractionalPart === 0.75) {
                                    return stunde + ' 45 Minuten';
                                }
                            }
                            else {
                                return value + ' Stunden';
                            }
                        }
                        break;
                    case LabelType.High:
                        if (value === 0.25) {
                            return '15 Minuten';
                        }
                        else if (value === 0.5) {
                            return '30 Minuten';
                        }
                        else if (value === 0.75) {
                            return '45 Minuten';
                        }
                        else if (value === 1) {
                            return value + ' Stunde';
                        }
                        else {
                            if (fractionalPart > 0) {
                                const stunde = mainPart === 1 ? `${mainPart} Stunde` : `${mainPart} Stunden`;
                                if (fractionalPart === 0.25) {
                                    return stunde + ' 15 Minuten';
                                }
                                else if (fractionalPart === 0.5) {
                                    return stunde + ' 30 Minuten';
                                }
                                else if (fractionalPart === 0.75) {
                                    return stunde + ' 45 Minuten';
                                }
                            }
                            else {
                                return value + ' Stunden';
                            }
                        }
                        break;
                    default:
                        return value + '';
                }
            }
        };
        this.clientStaff = [];
        this.tableIsFreeFilter = { isFree: true };
        this.paymentTemplates = [];
        this.files = [];
        this.canDeleteFile = false;
        this.isAdmin = false;
        this.recurrenceRepeatOptions = [
            {
                label: 'Täglich',
                value: 'daily'
            },
            {
                label: 'Wöchentlich',
                value: 'weekly'
            },
            {
                label: 'Monatliches Datum',
                value: 'monthly_date'
            },
            {
                label: 'Monatlicher Wochentag',
                value: 'monthly_weekday'
            }
            // {
            //   label: 'Monatlich',
            //   value: 'monthly'
            // },
            // {
            //   label: 'Jährlich',
            //   value: 'yearly'
            // }
        ];
        this.weekdays = [
            {
                day: 'Montag',
                isChecked: false,
                value: 'Mo'
            },
            {
                day: 'Dienstag',
                isChecked: false,
                value: 'Tu'
            },
            {
                day: 'Mittwoch',
                isChecked: false,
                value: 'We'
            },
            {
                day: 'Donnerstag',
                isChecked: false,
                value: 'Th'
            },
            {
                day: 'Freitag',
                isChecked: false,
                value: 'Fr'
            },
            {
                day: 'Samstag',
                isChecked: false,
                value: 'Sa'
            },
            {
                day: 'Sonntag',
                isChecked: false,
                value: 'Su'
            }
        ];
        this.weekdaysMonthly = [
            {
                day: 'Montag',
                isChecked: false,
                value: 'Mo'
            },
            {
                day: 'Dienstag',
                isChecked: false,
                value: 'Tu'
            },
            {
                day: 'Mittwoch',
                isChecked: false,
                value: 'We'
            },
            {
                day: 'Donnerstag',
                isChecked: false,
                value: 'Th'
            },
            {
                day: 'Freitag',
                isChecked: false,
                value: 'Fr'
            },
            {
                day: 'Samstag',
                isChecked: false,
                value: 'Sa'
            },
            {
                day: 'Sonntag',
                isChecked: false,
                value: 'Su'
            }
        ];
        this.lastReservationDate = '';
        this.resMenuOrder = [];
        this.resMenuOrderItems = [];
        this.resMenuError = '';
        this.todayDate = new Date();
        this.isPastDate = false;
        this.ngUnsubscribe = new Subject();
        this.tags = [];
        this.endTimes = [];
        this.timeError = '';
        this.incompatibilities = [];
        this.manualRes = 1;
        this.activeClient = this.clientResolver.client;
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.getSettings();
            this.clientService
                .getPermissionsOfUser(this.credentialsService.getCredentials().id)
                .takeUntil(this.ngUnsubscribe)
                .subscribe((permisions) => {
                if (this.credentialsService.isAdmin()) {
                    this.canDeleteFile = true;
                    this.isAdmin = true;
                }
                else {
                    this.canDeleteFile = permisions.includes('manageReservationSettings');
                    if (permisions.includes('admin') || permisions.includes('manageReservationLogs')) {
                        this.isAdmin = true;
                    }
                }
            });
            this.reservationService.getIncompatibilities().subscribe(incompatibilities => {
                this.incompatibilities = incompatibilities.data;
            });
            this.defaultTime = this.selectedTimeOnTablePlanTimeSlider
                ? this.selectedTimeOnTablePlanTimeSlider
                : moment().format('HH:mm');
            this.currentDate = this.defaultDate ? this.defaultDate : new Date();
            if (this.currentDate && moment(this.currentDate) && moment(this.currentDate).day() >= 0) {
                let day = moment(this.currentDate).day() > 0 ? moment(this.currentDate).day() - 1 : moment(this.currentDate).day() + 6;
                this.weekdays[day].isChecked = true;
                this.weekdaysMonthly[day].isChecked = true;
            }
            this.reservationFormGroup = this.formBuilder.group({
                status: ['confirmed', Validators.required],
                guestCount: ['2', Validators.required],
                date: [this.defaultDate ? this.defaultDate : '', Validators.required],
                time: [this.isWalkIn ? this.defaultTime : '', Validators.required],
                stayTime: [this.convertStayTimeData(120, 'hrs'), Validators.required],
                selectedTags: [[]],
                notes: [''],
                staffId: [
                    null,
                    this.bookSettings && this.bookSettings.requestStaff && this.clientStaff.length && !this.isWalkIn
                        ? Validators.required
                        : ''
                ],
                shiftId: [null],
                guestData: this.formBuilder.group({
                    name: ['', !this.isWalkIn ? Validators.required : ''],
                    form: ['', this.showAdditionalGuestInputs ? Validators.required : ''],
                    firstName: [''],
                    address: [''],
                    zip: [''],
                    place: [''],
                    email: ['', Validators.email],
                    phone: ['', Validators.required],
                    guestInform: [],
                    guestInformSMS: [],
                    company: ['', this.showAdditionalGuestInputs ? Validators.required : ''],
                    intolerance: [''],
                    notes: ['']
                }),
                isRecurringReservation: [false],
                changeAllRecurringReservation: [false],
                recurrenceData: this.formBuilder.group({
                    repeatOption: [''],
                    repeatEvery: [1],
                    repeatOn: [],
                    // recurrenceEnd: [''],
                    ahead: [1]
                }),
                paymentTemplate: [],
                files: [null],
                needsPayment: [false],
                sendCheckin: [false],
                includeLockedRooms: [false],
                includeUnavailableTimes: [false],
                lockTable: [false],
                endTime: []
            });
            this.getShifts();
            yield this.getReservationBookSettings();
            this.getSmsSettings();
            this.checkIsPastDate();
            // Only id is being passed, so get data from API
            if (this.reservationId) {
                this.reservation = yield this.reservationService.getReservation(this.reservationId).toPromise();
            }
            // If reservation is passed (editing)
            if (this.reservation) {
                this.sortTicketsBySubCategory();
                this.reservationFormGroup.get('status').setValue(this.reservation.status);
                this.reservationFormGroup.get('guestCount').setValue(this.reservation.peopleCount);
                this.reservationFormGroup.get('date').setValue(this.reservation.reservedFor);
                this.currentDate = moment(this.reservation.reservedFor).toDate();
                this.reservationFormGroup.get('time').setValue(moment(this.reservation.reservedFor).format('HH:mm'));
                this.reservationFormGroup.get('notes').setValue(this.reservation.notes);
                // this.reservationFormGroup.get('selectedTags').setValue(this.reservation.tags);
                this.reservationFormGroup.get('stayTime').setValue(this.convertStayTimeData(this.reservation.stayTime, 'hrs'));
                this.reservationFormGroup.get('lockTable').setValue(this.reservation.locked);
                this.reservationFormGroup.get('includeUnavailableTimes').setValue(true);
                this.reservationFormGroup.get('shiftId').setValue(this.reservation.shiftId);
                if (this.reservation.tags) {
                    this.tags = this.reservation.tags.filter((item) => item.id);
                }
                if (this.reservation.guestData && this.reservation.gastId) {
                    // if (
                    //   this.reservation.guestData.form ||
                    //   this.reservation.guestData.company ||
                    //   this.reservation.guestData.address ||
                    //   this.reservation.guestData.zip ||
                    //   this.reservation.guestData.place ||
                    //   this.reservation.guestData.intolerance ||
                    //   this.reservation.guestData.notes
                    // ) {
                    //   this.showAdditionalGuestInputs = true;
                    // } else {
                    //   this.showAdditionalGuestInputs = false;
                    // }
                    this.reservationFormGroup.get('guestData.name').setValue(this.reservation.guestData.name);
                    this.reservationFormGroup.get('guestData.phone').setValue(this.reservation.guestData.phone);
                    this.reservationFormGroup.get('guestData.email').setValue(this.reservation.guestData.email);
                    this.reservationFormGroup.get('guestData.form').setValue(this.reservation.guestData.form);
                    this.reservationFormGroup.get('guestData.firstName').setValue(this.reservation.guestData.firstName);
                    this.reservationFormGroup.get('guestData.address').setValue(this.reservation.guestData.address);
                    this.reservationFormGroup.get('guestData.zip').setValue(this.reservation.guestData.zip);
                    this.reservationFormGroup.get('guestData.place').setValue(this.reservation.guestData.place);
                    this.reservationFormGroup.get('guestData.company').setValue(this.reservation.guestData.company);
                    this.reservationFormGroup.get('guestData.notes').setValue(this.reservation.guestData.notes);
                    this.reservationFormGroup
                        .get('guestData.intolerance')
                        .setValue(JSON.parse(this.reservation.guestData.intolerance));
                }
                else {
                    this.isWalkIn = true;
                }
                if (this.reservation.tables && this.reservation.tables.length) {
                    // Select tables
                    this.reservation.tables.forEach((table) => {
                        this.selectTable(table, false);
                        // this.selectedTables.push(table.id);
                    });
                    // Dont show tables which are not free @GG-I41
                    // this.reservationFormGroup.get('includeUnavailableTimes').setValue(false);
                }
            }
            else {
                //Select table which is clicked inside table plan
                if (this.selectedTablePlanTable) {
                    this.selectTable(this.selectedTablePlanTable, true);
                }
                // if (this.defaultTable) {
                //   this.selectedTables.push(this.defaultTable);
                // }
            }
            // If Walkin, set status to placed
            if (this.isWalkIn && !this.reservation) {
                this.reservationFormGroup.get('status').setValue('placed');
            }
            // If start- and end-date are set
            if (this.startDate) {
                this.reservationFormGroup.get('date').setValue(this.startDate);
                this.reservationFormGroup.get('time').setValue(moment(this.startDate).format('HH:mm'));
            }
            if (this.endDate) {
                const momentEndDate = moment(this.endDate);
                const duration = moment.duration(momentEndDate.diff(this.startDate));
                this.reservationFormGroup.get('stayTime').setValue(this.convertStayTimeData(duration.asMinutes(), 'hrs'));
            }
            this.getTables();
            this.getTags();
            this.getStaffCodes();
            this.getPaymentTemplates();
            // Get all times for select and check opening hour
            this.getTimes();
            this.getAlerts();
            // Autocomplete for guest name
            if (!this.reservation) {
                this.reservationFormGroup
                    .get('guestData')
                    .get('name')
                    .valueChanges.pipe(debounceTime(300), tap(() => (this.isLoadingAutocomplete = true)), switchMap(value => {
                    if (value && value.length >= 3) {
                        return this.reservationService
                            .searchGuest({ name: value })
                            .pipe(finalize(() => (this.isLoadingAutocomplete = false)));
                    }
                    else {
                        return [];
                    }
                }))
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe(guests => (this.filteredGuests = guests));
                // Autocomplete for guest phone
                this.reservationFormGroup
                    .get('guestData')
                    .get('phone')
                    .valueChanges.pipe(debounceTime(300), tap(() => (this.isLoadingAutocomplete = true)), switchMap(value => {
                    if (value && value.length >= 3) {
                        return this.reservationService
                            .searchGuest({ phone: value })
                            .pipe(finalize(() => (this.isLoadingAutocomplete = false)));
                    }
                    else {
                        return [];
                    }
                }))
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe(guests => (this.filteredGuests = guests));
            }
            this.getAllStatus();
        });
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    getAllStatus() {
        this.reservationService
            .getResStatus()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((resStatusList) => {
            this.resStatusList = resStatusList;
        });
    }
    getTimes() {
        this.times = [];
        let tStart = 0;
        const interval = 15;
        for (let i = 0; tStart < 24 * 60; i++) {
            const hh = Math.floor(tStart / 60); // getting hours of day in 0-24 format
            const mm = tStart % 60; // getting minutes of the hour in 0-55 format
            const timeObj = {
                time: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
                isClosed: true
            };
            this.times.push(timeObj);
            console.log('get times');
            const hhNext = Math.floor((tStart + interval) / 60); // getting hours of day in 0-24 format
            const mmNext = (tStart + interval) % 60; // getting minutes of the hour in 0-55 format
            const dateObjNext = new Date();
            dateObjNext.setHours(hhNext);
            dateObjNext.setMinutes(mmNext);
            const dateObj = new Date();
            dateObj.setHours(hh);
            dateObj.setMinutes(mm);
            const defaultTimeDateObj = new Date();
            const defaultTimeSplit = this.defaultTime.split(':');
            defaultTimeDateObj.setHours(+defaultTimeSplit[0]);
            defaultTimeDateObj.setMinutes(+defaultTimeSplit[1]);
            if (dateObj < defaultTimeDateObj && dateObjNext > defaultTimeDateObj) {
                const defaultTimeObj = {
                    time: this.defaultTime,
                    isClosed: false
                };
                if (this.isWalkIn) {
                    this.times.push(defaultTimeObj);
                }
            }
            tStart = tStart + interval;
        }
        // Check opening hours and append Closed label to times which are closed
        this.clientService
            .getOpeningHourOfDay(moment(this.reservationFormGroup.value.date).format('YYYY-MM-DD'))
            .takeUntil(this.ngUnsubscribe)
            .subscribe((openingHours) => __awaiter(this, void 0, void 0, function* () {
            if (openingHours.length) {
                // Loop trough all entries to check time
                yield openingHours.reduce((prev, oh) => prev.then(() => __awaiter(this, void 0, void 0, function* () {
                    this.times = yield this._checkTimesWithOpeningHours(oh.tFrom, oh.tTo, oh.closed);
                })), Promise.resolve());
            }
            else {
                // No opening hours for this day, so its closed
                const newTimes = yield this.times.map((time) => {
                    time.isClosed = true;
                    return time;
                });
                this.times = [...newTimes];
            }
            if (this.isWalkIn && !this.reservation) {
                // Get closest current time
                // defaultTime = closestTo(new Date(), this.times);
                // Current time in millis
                const now = +moment(new Date(), 'HH:mm').format('x');
                // Get all open times
                const openTimes = this.times.filter((t) => {
                    return !t.isClosed;
                });
                const next = openTimes
                    .map((s) => {
                    const today = moment(new Date(), 'MM/DD/YYYY').format('MM/DD/YYYY');
                    return moment(today + ' ' + s.time, 'MM/DD/YYYY HH:mm');
                })
                    .sort((m) => {
                    return m.valueOf();
                })
                    .find((m) => m.isAfter());
                if (next) {
                    // Disable for now (Jens request)
                    // this.reservationFormGroup.get('time').setValue(next.format('HH:mm'));
                }
            }
            // if (!this.isWalkIn && !this.reservation) {
            //   this.getClosestGreaterTime(this.times, this.defaultTime);
            // }
            this.endTimes = [...this.times];
            this.setEndTime();
            this.getAllTimes();
        }));
    }
    getClosestGreaterTime(array, targetTime, isReturn = false) {
        const closestTime = array
            .filter((entry) => entry.time >= targetTime)
            .reduce((closest, entry) => {
            const difference = Math.abs(this.getTimeDifference(entry.time, targetTime));
            const closestDifference = Math.abs(this.getTimeDifference(closest, targetTime));
            return difference < closestDifference ? entry.time : closest;
        }, array[0].time);
        if (isReturn) {
            return closestTime;
        }
        else {
            this.closestTimeValue = closestTime;
        }
    }
    getTimeDifference(time1, time2) {
        const [hours1, minutes1] = time1.split(':').map(Number);
        const [hours2, minutes2] = time2.split(':').map(Number);
        return (hours1 - hours2) * 60 + (minutes1 - minutes2);
    }
    onDropdownOpen() {
        setTimeout(() => {
            this.scrollToItem();
        });
    }
    scrollToItem() {
        if (this.closestTimeValue && !this.reservationFormGroup.value.time) {
            const itemValue = this.closestTimeValue;
            const itemIndex = this.timeSelect.items.findIndex(item => item.time === itemValue);
            if (itemIndex > -1) {
                const optionElement = this.findOptionElement(itemValue);
                if (optionElement) {
                    optionElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                }
            }
        }
    }
    findOptionElement(value) {
        const options = this.timeSelect.dropdownPanel.contentElementRef.nativeElement.querySelectorAll('.ng-option');
        for (let i = 0; i < options.length; i++) {
            const optionElement = options[i];
            if (optionElement.innerText.trim() === value) {
                return optionElement;
            }
        }
        return null;
    }
    closestTime(arr, time) {
        return arr.reduce((prev, curr) => {
            return curr - time < prev - time ? curr : prev;
        });
    }
    displayFnName(guest) {
        if (guest) {
            return guest.name;
        }
    }
    selectedAutocomplete(guest) {
        // if (guest.form || guest.company || guest.address || guest.zip || guest.place || guest.intolerance || guest.notes) {
        //   this.showAdditionalGuestInputs = true;
        // } else {
        //   this.showAdditionalGuestInputs = false;
        // }
        this.reservationFormGroup.get('guestData.email').patchValue(guest.email);
        this.reservationFormGroup.get('guestData.phone').setValue(guest.phone);
        this.reservationFormGroup.get('guestData.name').setValue(guest.name);
        this.reservationFormGroup.get('guestData.form').setValue(guest.form);
        this.reservationFormGroup.get('guestData.firstName').setValue(guest.firstName);
        this.reservationFormGroup.get('guestData.address').setValue(guest.address);
        this.reservationFormGroup.get('guestData.zip').setValue(guest.zip);
        this.reservationFormGroup.get('guestData.place').setValue(guest.place);
        this.reservationFormGroup.get('guestData.company').setValue(guest.company);
        this.reservationFormGroup.get('guestData.intolerance').setValue(JSON.parse(guest.intolerance));
        this.reservationFormGroup.get('guestData.notes').setValue(guest.notes);
    }
    // getSettings() {
    //   this.reservationService
    //     .getSettings()
    //     .takeUntil(this.ngUnsubscribe)
    //     .subscribe((settings: any) => {
    //       this.reservationSettings = settings;
    //       if (this.reservationSettings && this.reservationSettings.versionSettings) {
    //         this.manualRes = this.reservationSettings.versionSettings.manualRes;
    //       }
    //     });
    // }
    getSettings() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const settings = yield this.reservationService
                    .getSettings()
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .toPromise();
                this.reservationSettings = settings;
                if (this.reservationSettings && this.reservationSettings.versionSettings) {
                    this.manualRes = this.reservationSettings.versionSettings.manualRes;
                }
            }
            catch (error) {
                console.error('Error fetching settings', error);
            }
        });
    }
    getShifts() {
        this.reservationService
            .getShifts()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((shifts) => {
            if (!this.reservation) {
                let filteredShifts = [];
                for (let shift of shifts) {
                    if (shift.isOnlineResShift) {
                        filteredShifts.push(shift);
                    }
                }
                this.shifts = [...filteredShifts];
            }
            else {
                this.shifts = shifts;
            }
            if (this.shifts && this.shifts.length > 0) {
                this.shifts.map((item, key) => {
                    if (item['questions'] && item['questions'].length > 0) {
                        item['questions'] = item['questions'].map((item2, key2) => {
                            if (item2.questionType == 2 && item2.foreignId) {
                                this.getResMenuOrder(item2, key, key2, item);
                                return item2;
                            }
                            else {
                                return item2;
                            }
                        });
                    }
                });
            }
        });
    }
    getResMenuOrder(menu, key, key2, item) {
        this.reservationService
            .getResMenuOrder(menu.foreignId, this.currentDate)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => {
            this.shifts[key]['questions'][key2].menuOrder = res;
            this.shifts[key]['questions'][key2].foreignId = res.id;
            if (this.reservationFormGroup.value.shiftId && this.reservationFormGroup.value.shiftId == item.id) {
                this.changeShiftData(item);
            }
            return res;
        });
    }
    changeShiftData(event) {
        event = JSON.parse(JSON.stringify(event));
        this.resMenuOrder = [];
        if (event.questions && event.questions.length > 0) {
            event.questions.map((item) => {
                let addedAmount = 0.0;
                let extraAddedAmount = 0.0;
                let totalAmount = item.menuOrder && item.menuOrder.totalAmount ? item.menuOrder.totalAmount : 0;
                if (item.questionType == 2 && item.foreignId) {
                    if (item.menuOrder && item.menuOrder.menuOrdersItem) {
                        item.menuOrder.menuOrdersItem.map((item3) => {
                            if (!this.reservation ||
                                (this.reservation &&
                                    Object.keys(this.reservation).length > 0 &&
                                    this.reservation.id &&
                                    this.reservation.id !== item3.reservationId)) {
                                addedAmount += parseFloat(item3.amount);
                            }
                            extraAddedAmount += parseFloat(item3.amount);
                        });
                    }
                    this.resMenuOrder.push({
                        question: item.question,
                        answers: item.answers.filter((item3) => {
                            if (item3.foreignValue) {
                                item3.orderValue = 0;
                                if (this.reservation &&
                                    Object.keys(this.reservation).length > 0 &&
                                    item.menuOrder &&
                                    item.menuOrder.menuOrdersItem) {
                                    item.menuOrder.menuOrdersItem.map((item5) => {
                                        if (item3.id == item5.answerId && this.reservation.id == item5.reservationId) {
                                            item3.orderValue += 1;
                                        }
                                    });
                                }
                                item3.orderValue = item3.orderValue ? item3.orderValue : null;
                                return item3;
                            }
                        }),
                        totalAmount: totalAmount,
                        addedAmount: addedAmount,
                        foreignId: item.foreignId,
                        name: item.name,
                        remainingAmount: totalAmount - extraAddedAmount
                    });
                    this.changeResMenuOrder();
                }
            });
        }
    }
    changeResMenuOrder() {
        this.resMenuError = '';
        this.resMenuOrderItems = [];
        this.resMenuOrder.map((item) => {
            if (item.answers && item.answers.length > 0) {
                let orderedAmount = 0.0;
                let orderedValue = 0;
                item.answers.map((item2) => {
                    if (item2.orderValue && item2.foreignValue) {
                        orderedAmount += item2.orderValue * parseFloat(item2.foreignValue);
                        orderedValue += item2.orderValue;
                        for (let i = 0; i < item2.orderValue; i++) {
                            this.resMenuOrderItems.push({
                                menuOrderId: item.foreignId,
                                amount: parseFloat(item2.foreignValue),
                                answerId: item2.id
                            });
                        }
                    }
                });
                if (orderedAmount > parseFloat(item.totalAmount) - parseFloat(item.addedAmount)) {
                    this.resMenuError = `Kein/e ${item.name} mehr verfügbar`;
                }
                if (this.reservationFormGroup.value.guestCount < orderedValue) {
                    this.resMenuError = 'Die Anzahl Bestellungen kann nicht grösser sein als die Anzahl Gäste.';
                }
            }
        });
    }
    getReservationBookSettings() {
        this.reservationService
            .getReservationBookSettings()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((settings) => {
            this.bookSettings = settings;
            if (this.bookSettings.includeShift == 2) {
                this.reservationFormGroup.get('shiftId').setValidators([Validators.required]);
            }
            if (!this.bookSettings.phoneNoRequired || this.isWalkIn) {
                this.reservationFormGroup.get('guestData.phone').clearValidators();
                this.reservationFormGroup.get('guestData.phone').setErrors(null);
            }
            if (!this.reservation && !this.endDate) {
                this.reservationFormGroup.get('stayTime').setValue(this.convertStayTimeData(settings.defaultStaytime));
            }
        });
    }
    displayFnEmail(guest) {
        if (guest) {
            return guest.email;
        }
    }
    displayFnPhone(guest) {
        if (guest) {
            return guest.phone;
        }
    }
    getTables(removeOccupiedTables = false) {
        if (this.reservationFormGroup && this.reservationFormGroup.value && this.reservationFormGroup.value.time) {
            this.rooms = null;
            return this.reservationService
                .checkFreeTables(this.reservationFormGroup.value.date, this.reservationFormGroup.value.time, this.reservationFormGroup.value.guestCount, this.convertStayTimeData(this.reservationFormGroup.value.stayTime, 'mins'), this.reservationFormGroup.value.includeUnavailableTimes, this.reservationFormGroup.value.includeLockedRooms, this.reservation && this.reservation.id ? this.reservation.id : '')
                .toPromise()
                .then((data) => {
                this.rooms = data;
                // Select the tables again
                for (let index = 0; index < this.rooms.length; index++) {
                    let freeTablesCounter = 0;
                    for (let tIndex = 0; tIndex < this.rooms[index].tables.length; tIndex++) {
                        const table = this.rooms[index].tables[tIndex];
                        const selectedIndex = this.selectedTables.indexOf(table.id);
                        if (selectedIndex !== -1) {
                            const selectedDate = this.reservationFormGroup.get('date').value;
                            // if (this.reservation && moment(this.reservation.reservedFor).isSame(selectedDate)) {
                            //   // if it is editing, change isFree on selected Table to true, to prevent hiding
                            //   table.isFree = true;
                            // }
                            if (table.isFree) {
                                table.selected = true;
                            }
                            else {
                                // If they change date or whatever, it should check if the table is free, and if not unselect and notice
                                if (removeOccupiedTables) {
                                    this.selectedTables.splice(selectedIndex, 1);
                                    this.snackBar.open('Der Tisch ' + table.name + ' wurde abgewählt, da er zur gewünschten Zeit nicht frei ist.', '', {
                                        duration: 2000,
                                        panelClass: ['snackbar-error']
                                    });
                                }
                                table.selected = false;
                            }
                        }
                        else {
                            table.selected = false;
                        }
                        // Count how many free tables in this room
                        if (table.isFree) {
                            freeTablesCounter = freeTablesCounter + 1;
                        }
                    }
                    this.rooms[index].freeTablesCounter = freeTablesCounter;
                }
                this.confirmTime = true;
                this.refreshSlider.emit();
            });
        }
    }
    showAllTablesToggle(event) {
        this.getTables();
    }
    showAllRoomsToggle(event) {
        this.getTables();
    }
    showAdditionalGuestInfo() {
        this.showAdditionalGuestInputs = !this.showAdditionalGuestInputs;
    }
    checkSelected(table) {
        if (this.selectedTables.includes(table.id)) {
            return true;
        }
        /*
        if (table.combinedTables) {
          const combinedTables = table.combinedTables.split(',');
          return combinedTables.every((t: any) => this.selectedTables.includes(+t));
        }
        */
        return false;
    }
    checkDisabled(table) {
        return this.disabledTables.includes(table.id);
    }
    getTags() {
        this.reservationService
            .getTags()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((tags) => {
            this.reservationTags = tags;
            this.filterTag();
        });
    }
    selectTable(table, checkIsFree = true) {
        const isSelected = this.checkSelected(table);
        if (!table.isFree && checkIsFree && !isSelected) {
            // Table is not free so show alert
            const modalRef = this.modalService.open(ConfirmModalComponent);
            modalRef.componentInstance.title = 'Tisch ist belegt';
            modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Reservierung auf den belegten Tisch ändern möchten?`;
            modalRef.componentInstance.showInfo = false;
            modalRef.componentInstance.buttonText = 'Ja';
            modalRef.result.then(result => {
                if (result === 'ok') {
                    this.processSelectTable(table, isSelected);
                }
            }, () => { });
            return;
        }
        this.processSelectTable(table, isSelected);
    }
    processSelectTable(table, isSelected) {
        if (!isSelected) {
            this.selectedRoomId = table.roomId;
            this.usedSeatCount = this.usedSeatCount + table.seats;
            if (table.isCombined) {
                /*
                const combinedTables = table.combinedTables.split(',');
                combinedTables.forEach(t => {
                  // Search table object from combined tables to disable buttons and set selected
                  const roomIndex = this.rooms.findIndex((room: any) => room.id === table.roomId);
                  const tablesIndex = this.rooms[roomIndex].tables.findIndex((val: any) => val.id === +t);
                  this.disabledTables.push(this.rooms[roomIndex].tables[tablesIndex].id);
        
                  if (this.selectedTables.includes(t) === false) {
                    this.selectedTables.push(+t);
                  }
                });
                */
            }
            else {
                this.selectedTables.push(table.id);
            }
        }
        else {
            this.usedSeatCount = this.usedSeatCount - table.seats;
            if (table.isCombined) {
                /*
                const combinedTables = table.combinedTables.split(',');
                combinedTables.forEach(t => {
                  // Search table object from combined tables to remove disabled and selected state
                  const roomIndex = this.rooms.findIndex((room: any) => room.id === table.roomId);
                  const tablesIndex = this.rooms[roomIndex].tables.findIndex((val: any) => val.id === +t);
                  this.disabledTables = this.disabledTables.filter((obj: any) => {
                    return obj !== this.rooms[roomIndex].tables[tablesIndex].id;
                  });
        
                  this.selectedTables = this.selectedTables.filter((obj: any) => {
                    return obj !== +t;
                  });
                });
                */
            }
            else {
                this.selectedTables = this.selectedTables.filter((obj) => {
                    return obj !== table.id;
                });
            }
            // Remove roomId if no more tables selected
            if (this.selectedTables.length) {
                this.selectedRoomId = null;
            }
        }
    }
    onFilesSelected(files) {
        this.files = files;
        this.reservationFormGroup.get('files').setValue(files);
    }
    removeFiles() {
        this.files = [];
    }
    removeFile(file) {
        const fileArray = Array.from(this.files);
        const reducedFileArray = fileArray.filter((f) => {
            return file !== f;
        });
        this.files = reducedFileArray;
    }
    createRepeatOptionRecurrance() {
        this.reservationFormGroup.get('recurrenceData.repeatOn').setValue('');
        let weeklyDays = [];
        if (this.reservationFormGroup.value && this.reservationFormGroup.value.recurrenceData.repeatOption == 'weekly') {
            weeklyDays = this.weekdays.filter(opt => opt.isChecked).map(opt => opt.value);
            this.reservationFormGroup.get('recurrenceData.repeatOn').setValue(weeklyDays.toString());
            if (!this.reservationFormGroup.value.recurrenceData.repeatOn) {
                this.snackBar.open('Bitte mindestens einen Wochentag wählen', '', {
                    duration: 2000,
                    panelClass: ['snackbar-error']
                });
                return;
            }
        }
        if (this.reservationFormGroup.value &&
            this.reservationFormGroup.value.recurrenceData.repeatOption == 'monthly_weekday') {
            weeklyDays = this.weekdaysMonthly.filter(opt => opt.isChecked).map(opt => opt.value);
            this.reservationFormGroup.get('recurrenceData.repeatOn').setValue(weeklyDays.toString());
            if (!this.reservationFormGroup.value.recurrenceData.repeatOn) {
                this.snackBar.open('Bitte mindestens einen Wochentag wählen', '', {
                    duration: 2000,
                    panelClass: ['snackbar-error']
                });
                return;
            }
        }
    }
    createReservation() {
        if (!this.reservationFormGroup.valid || this.resMenuError || this.timeError) {
            this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
        if (!this.selectedTables.length) {
            const modalRef = this.modalService.open(ConfirmModalComponent);
            modalRef.componentInstance.title = 'Keinen Tisch ausgewählt';
            modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Reservierung ohne einen Tisch erstellen möchten?`;
            modalRef.componentInstance.showInfo = false;
            modalRef.componentInstance.buttonText = 'Ja';
            modalRef.result.then(result => {
                if (result === 'ok') {
                    this.processReservation();
                }
            }, () => { });
            return;
        }
        else {
            this.processReservation();
        }
    }
    processReservation() {
        let roomId = this.selectedRoomId;
        if (!roomId) {
            if (this.rooms && this.rooms.length == 1) {
                roomId = this.rooms[0].id;
            }
        }
        this.disableBtn = true;
        const reservationFormData = this.reservationFormGroup.value;
        const reservation = {
            guestData: !this.isWalkIn ? reservationFormData.guestData : null,
            stayTime: this.convertStayTimeData(reservationFormData.stayTime, 'mins'),
            guestCount: reservationFormData.guestCount,
            reservedFor: moment(reservationFormData.date).format('YYYY-MM-DD'),
            time: reservationFormData.time,
            notes: reservationFormData.notes,
            status: reservationFormData.status,
            files: reservationFormData.files,
            tags: this.tags ? this.tags : [],
            staffId: reservationFormData.staffId,
            shiftId: reservationFormData.shiftId,
            locked: reservationFormData.lockTable,
            sendCheckin: reservationFormData.sendCheckin,
            paymentTemplate: reservationFormData.needsPayment ? reservationFormData.paymentTemplate : null,
            isTablePlan: this.selectedTables.join(','),
            roomId: roomId,
            isRecurringReservation: reservationFormData.isRecurringReservation,
            recurrenceData: reservationFormData.recurrenceData,
            resMenuOrderItems: this.resMenuOrderItems
        };
        console.log('reservation => ', reservation);
        this.reservationService
            .addReservation(reservation)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(res => {
            this.disableBtn = false;
            this.passEntry.emit(reservation);
        });
    }
    saveReservation() {
        if (!this.reservationFormGroup.valid || this.resMenuError || this.timeError) {
            this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
        const reservationFormData = this.reservationFormGroup.value;
        if (reservationFormData && reservationFormData.status == 'canceled' && this.reservation.paymentTransaction) {
            const modalRef = this.modalService.open(ConfirmModalComponent);
            modalRef.componentInstance.title = 'Reservierung stornieren';
            modalRef.componentInstance.message = `Für diese Reservierung gibt es eine erfolgreiche Anzahlung. Trotzdem stornieren?`;
            modalRef.componentInstance.showInfo = false;
            modalRef.componentInstance.buttonText = 'Ja';
            modalRef.result.then(result => {
                if (result === 'ok') {
                    this.confirmReservationPopup(reservationFormData);
                }
            }, () => { });
            return;
        }
        else {
            this.confirmReservationPopup(reservationFormData);
        }
    }
    confirmReservationPopup(reservationFormData) {
        if (this.reservation.locked && reservationFormData.lockTable) {
            const modalRef = this.modalService.open(ConfirmModalComponent);
            modalRef.componentInstance.title = 'Reservierung ist gesperrt';
            modalRef.componentInstance.message = `Achtung! Tisch war fest! Trotzdem ändern?`;
            modalRef.componentInstance.showInfo = false;
            modalRef.componentInstance.buttonText = 'Ja';
            modalRef.result.then(result => {
                if (result === 'ok') {
                    this.editReservation(reservationFormData);
                }
            }, () => { });
            return;
        }
        if (!this.selectedTables.length) {
            const modalRef = this.modalService.open(ConfirmModalComponent);
            modalRef.componentInstance.title = 'Keinen Tisch ausgewählt';
            modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Reservierung ohne einen Tisch speichern möchten?`;
            modalRef.componentInstance.showInfo = false;
            modalRef.componentInstance.buttonText = 'Ja';
            modalRef.result.then(result => {
                if (result === 'ok') {
                    this.editReservation(reservationFormData);
                }
            }, () => { });
            return;
        }
        else {
            this.editReservation(reservationFormData);
        }
    }
    editReservation(reservationFormData) {
        this.disableBtn = true;
        const reservation = {
            id: this.reservation.id,
            guestData: !this.isWalkIn ? reservationFormData.guestData : null,
            stayTime: this.convertStayTimeData(reservationFormData.stayTime, 'mins'),
            guestCount: reservationFormData.guestCount,
            reservedFor: moment(reservationFormData.date).format('YYYY-MM-DD'),
            time: reservationFormData.time,
            notes: reservationFormData.notes,
            status: reservationFormData.status,
            tags: this.tags ? this.tags : [],
            files: reservationFormData.files,
            staffId: reservationFormData.staffId,
            shiftId: reservationFormData.shiftId,
            locked: reservationFormData.lockTable,
            isTablePlan: this.selectedTables.join(','),
            roomId: this.selectedRoomId,
            changeAllRecurringReservation: reservationFormData.changeAllRecurringReservation,
            resMenuOrderItems: this.resMenuOrderItems
        };
        this.reservationService
            .editReservation(reservation)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(res => {
            if (reservationFormData.status == 'blocked') {
                this.sendPaymentLinkViaEmail(reservation);
            }
            this.disableBtn = false;
            this.passEntry.emit(reservation);
        });
    }
    guestCountChanged(event) {
        this.reservationFormGroup.get('guestCount').setValue(event);
    }
    repeatEveryChanged(event) {
        this.reservationFormGroup.get('recurrenceData.repeatEvery').setValue(event);
        this.changeRecurssionValue();
    }
    aheadChanged(event) {
        this.reservationFormGroup.get('recurrenceData.ahead').setValue(event);
        this.changeRecurssionValue();
    }
    getStaffCodes() {
        this.clientService
            .getStaffCodes()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => {
            this.clientStaff = res;
        });
    }
    getPaymentTemplates() {
        this.reservationService
            .getPaymentTemplates()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((paymentTemplates) => {
            this.paymentTemplates = paymentTemplates;
        });
    }
    transformLogText(action, oldValue, newValue, detail, user, browserDetails) {
        let userDetails = '';
        if (this.isAdmin && (user || browserDetails)) {
            userDetails = `<br>`;
            if (user && (user.firstName || user.lastName)) {
                userDetails += `${user.firstName} ${user.lastName}`;
            }
            else if (user) {
                userDetails += `${user.email}`;
            }
            if (browserDetails) {
                userDetails += ` | ${browserDetails}`;
            }
        }
        switch (action) {
            case 'datetimeChanged':
                return ('Das Reservierungs-Datum wurde von ' +
                    moment(oldValue).format('DD. MMMM YYYY HH:mm') +
                    ' auf <span class="text-primary">' +
                    moment(newValue).format('DD. MMMM YYYY') +
                    ' um ' +
                    moment(newValue).format('HH:mm') +
                    ' Uhr</span> geändert' +
                    userDetails);
                break;
            case 'newReservation':
                if (!detail || detail === 'manual') {
                    detail = 'das Reservierungsbuch';
                }
                return 'Neue Reservierung über <span class="text-primary">' + detail + '</span> erstellt ' + userDetails;
                break;
            case 'tableChanged':
                return ('Der Tisch wurde ' +
                    (oldValue ? ' von ' : '') +
                    '<span class="text-primary">' +
                    oldValue +
                    '</span> auf <span class="text-primary">' +
                    newValue +
                    '</span> geändert ' +
                    userDetails);
                break;
            case 'statusUpdated':
                return ('Der Status wurde von ' +
                    this.translateStatus(oldValue) +
                    ' auf <span class="text-primary">' +
                    this.translateStatus(newValue) +
                    '</span> geändert ' +
                    userDetails);
                break;
            case 'staytimeUpdated':
                return ('Die Aufenthaltsdauer wurde von <span class="text-primary">' +
                    oldValue +
                    '</span> auf <span class="text-primary">' +
                    newValue +
                    ' Minuten</span> geändert ' +
                    userDetails);
                break;
            case 'peopleCountChanged':
                return 'Die Personenanzahl wurde von ' + oldValue + ' auf ' + newValue + ' Personen geändert' + userDetails;
                break;
            case 'emailConfSent':
                return 'E-Mail wurde an <span class="text-primary">' + newValue + '</span> verschickt' + userDetails;
                break;
            case 'emailConfBounce':
                return "The email was not received as it was bounced. May be The email account that you tried to reach does not exist. Please try double-checking the recipient's email address for typos or unnecessary spaces.";
                break;
            case 'emailConfDropped':
                return 'The email was not received because the email address previously bounced.';
                break;
            case 'emailConfDeferred':
                return "The email was not received because the recipient's mail server has temporarily rejected the message.";
                break;
            case 'emailConfBlocked':
                return 'Receiving server could not or would not accept the message temporarily. If a recipient has previously unsubscribed from your emails, the message is dropped.';
                break;
            case 'emailConfDelivered':
                return 'Email has been successfully delivered.';
                break;
            case 'emailConfOpen':
                return 'E-Mail wurde vom Gast geöffnet';
                break;
            case 'smsSent':
                return 'SMS wurde an <span class="text-primary">' + newValue + '</span> verschickt';
                break;
            case 'reminderEmailSent':
                return 'A reminder email has been sent';
                break;
            case 'reservationAutoCanceled':
                return 'Die Reservierung wurde wegen fehlender Anzahlung automatisch storniert' + userDetails;
                break;
            case 'guestCanceled':
                return 'Der Gast hat die Reservierung storniert';
                break;
            case 'notesChanged':
                if (oldValue) {
                    return ('Der Admin-Hinweis wurde von "' +
                        oldValue +
                        '" auf <span class="text-primary">"' +
                        newValue +
                        '"</span> geändert ' +
                        userDetails);
                }
                else {
                    return 'Admin-Hinweis wurde hinzugefügt';
                }
                break;
            case 'paymentCompleted':
                let paymentMethod;
                switch (newValue) {
                    case 'paypal':
                        paymentMethod = 'PayPal';
                        break;
                    case 'klarna':
                        paymentMethod = 'Sofort/Klarna';
                        break;
                    case 'creditcard':
                        paymentMethod = 'Kreditkarte';
                        break;
                    default:
                        break;
                }
                return ('Der Gast hat eine Anzahlung in Höhe von ' +
                    formatNumber(+oldValue, 'en-US', '1.2') +
                    ' EUR mit <span class="text-primary">' +
                    paymentMethod +
                    ' </span> getätigt ');
                break;
            case 'checkinEmailSent':
                return 'Covid19-Registrierungs-Email versendet';
                break;
            case 'fileUpload':
                return 'Datei ' + '<span class="text-primary">' + newValue + ' </span> ' + ' wurde hochgeladen.' + userDetails;
                break;
            case 'fileDeleted':
                return 'Datei ' + '<span class="text-primary">' + newValue + ' </span> ' + ' wurde gelöscht.' + userDetails;
                break;
            case 'checkin':
                if (detail) {
                    return 'Covid19-Daten wurden für ' + detail + ' hinterlegt';
                }
                else {
                    return 'Covid19-Daten wurden hinterlegt';
                }
            case 'resCancelMsg':
                return 'Nachricht zur Stornierung: ' + '<span class="text-primary">' + newValue + ' </span> ' + userDetails;
                break;
            default:
                break;
        }
    }
    translateStatus(status) {
        switch (status) {
            case 'confirmed':
                return 'Bestätigt';
                break;
            case 'canceled':
                return 'Storniert';
                break;
            case 'noShow':
                return 'No-Show';
                break;
            case 'arrived':
                return 'Angekommen';
                break;
            case 'placed':
                return 'Platziert';
                break;
            case 'pending':
                return 'Ausstehend';
                break;
            case 'finished':
                return 'Fertig';
                break;
            case 'blocked':
                return 'Ausstehende Zahlung';
                break;
            default:
                return status;
                break;
        }
    }
    changeNeedsPayment(value) {
        if (value.checked) {
            this.reservationFormGroup.get('status').setValue('pending');
            this.reservationFormGroup.controls['status'].disable();
            this.reservationFormGroup.get('paymentTemplate').setValidators([Validators.required]);
        }
        else {
            this.reservationFormGroup.get('paymentTemplate').setValue([]);
            this.reservationFormGroup.controls['status'].enable();
            this.reservationFormGroup.get('paymentTemplate').clearValidators();
            this.reservationFormGroup.get('paymentTemplate').setErrors(null);
        }
        this.reservationFormGroup.get('paymentTemplate').updateValueAndValidity();
    }
    deleteFile(file, reservationId) {
        if (!this.reservationFormGroup.valid) {
            this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
        const stafId = this.reservationFormGroup.get('staffId').value;
        this.reservationService
            .deleteReservationFile(file.id, stafId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response) => {
            if (this.reservation.files.length) {
                this.reservation.files = this.reservation.files.filter((f) => {
                    return f.id !== file.id;
                });
            }
            else {
                this.reservation.files = [];
            }
        });
    }
    _checkTimesWithOpeningHours(tFrom, tTo, closed = false) {
        const from = moment(tFrom, 'HH:mm');
        const to = moment(tTo, 'HH:mm');
        return Promise.all(this.times.map((t) => {
            if (t.isClosed && !closed) {
                const time = moment(t.time, 'HH:mm');
                if (from.isBefore(to)) {
                    t.isClosed = !(time.isBetween(from, to) ||
                        time.format('HH:mm') === from.format('HH:mm') ||
                        time.format('HH:mm') === to.format('HH:mm'));
                }
                else {
                    t.isClosed = time.isBetween(to, from);
                }
                return t;
            }
            else if (!t.isClosed && closed) {
                const time = moment(t.time, 'HH:mm');
                if (from.isBefore(to)) {
                    t.isClosed =
                        time.isBetween(from, to) ||
                            time.format('HH:mm') === from.format('HH:mm') ||
                            time.format('HH:mm') === to.format('HH:mm');
                }
                else {
                    t.isClosed = !time.isBetween(to, from);
                }
                return t;
            }
            else {
                // Time oh was alredy set
                return t;
            }
        }));
    }
    getAlerts() {
        return this.reservationService
            .getAlerts('staff', moment(this.currentDate).format('YYYY-MM-DD'))
            .takeUntil(this.ngUnsubscribe)
            .subscribe((alerts) => {
            this.alerts = alerts;
        });
    }
    closeAlert(alert) {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }
    selectAllTables(room) {
        if (room.tables && room.tables.length) {
            room.tables.forEach((table) => {
                if (!table.isCombined) {
                    this.selectTable(table, false);
                }
            });
        }
    }
    selectRecurringReservation(value) {
        if (value) {
            this.reservationFormGroup.get('recurrenceData.repeatOption').setValidators([Validators.required]);
        }
        else {
            this.reservationFormGroup.get('recurrenceData.repeatOption').clearValidators();
            this.reservationFormGroup.get('recurrenceData.repeatOption').setErrors(null);
        }
        this.reservationFormGroup.get('recurrenceData.repeatOption').updateValueAndValidity();
    }
    recurringReservationChanged() {
        const recurrenceValue = this.reservationFormGroup.get('recurrenceData.repeatOption').value;
        if (recurrenceValue) {
            if (recurrenceValue == 'monthly_date') {
                this.maxRecurrenceValue = 31;
            }
            else if (recurrenceValue == 'monthly_weekday') {
                this.maxRecurrenceValue = 4;
            }
            else {
                this.maxRecurrenceValue = 1000;
            }
        }
        this.changeRecurssionValue();
    }
    addTag(event) {
        this.reservationFormGroup.controls['selectedTags'].reset();
        if (event && !this.tags.includes(event)) {
            this.tags.push(event);
            this.reservationTags = this.reservationTags.filter((tag) => tag.label !== event.label);
        }
    }
    filterTag() {
        if (this.tags) {
            this.reservationTags = this.reservationTags.filter(({ label: reservationTagsId }) => !this.tags.some(({ label: tagId }) => tagId === reservationTagsId));
        }
    }
    deleteTag(tagData, i) {
        this.reservationTags.splice(i, 0, tagData);
        this.tags = this.tags.filter((tag) => tag.label !== tagData.label);
    }
    onStatusChange() {
        if (this.reservation) {
            const oldValueStatus = this.reservation.status;
            const newValueStatus = this.reservationFormGroup.value['status'];
            if ((oldValueStatus != 'confirmed' && newValueStatus == 'confirmed') ||
                (oldValueStatus != 'canceled' && newValueStatus == 'canceled')) {
                this.reservationFormGroup.get('guestData.guestInform').setValue(true);
            }
        }
    }
    savemonthlyWeekday(event) {
        this.weekdaysMonthly = this.weekdaysMonthly.map(day => {
            day.isChecked = false;
            return day;
        });
        let day = this.weekdaysMonthly.findIndex((day) => day.value === event.value);
        this.weekdaysMonthly[day].isChecked = true;
        this.changeRecurssionValue();
    }
    stayTimeChanged(event) {
        this.reservationFormGroup.get('stayTime').setValue(event);
    }
    sliderChange(type) {
        if (this.manualRes == 1) {
            if (type == 'stayTime') {
                this.setEndTime();
            }
            else if (type == 'endTime') {
                const stayTime = this.calculateStayTime('timeToHrs');
                if (stayTime > 0) {
                    this.reservationFormGroup.get('stayTime').setValue(stayTime);
                }
                this.compareStartEndTime();
            }
            this.confirmTime = false;
        }
        else {
            this.confirmTime = false;
        }
    }
    changeRecurssionValue() {
        this.cdRef.detectChanges();
        this.createRepeatOptionRecurrance();
        if (this.reservationFormGroup.value.isRecurringReservation) {
            let rec = this.reservationFormGroup.get('recurrenceData').value;
            let lastReservedFor = moment(this.reservationFormGroup.value.date).format('YYYY-MM-DD');
            const difference = rec.repeatOption == 'daily' ? rec.ahead - 1 : rec.ahead;
            const weekday = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
            if (difference > 0) {
                let reservationDates = [];
                // const recurrenceReservationData = JSON.parse(rec.reservationData);
                // const timeArray = recurrenceReservationData.time.split(':');
                // let currentDate = new Date();
                // currentDate = setHours(currentDate, timeArray[0]);
                // currentDate = setMinutes(currentDate, timeArray[1]);
                let currentDate = parseISO(lastReservedFor);
                let stopDate;
                if (rec.repeatOption == 'daily') {
                    stopDate = addDays(currentDate, difference);
                }
                else if (rec.repeatOption == 'weekly') {
                    stopDate = addWeeks(currentDate, difference);
                }
                else {
                    stopDate = addMonths(currentDate, difference);
                }
                let getMonth = currentDate.getMonth();
                // Check interval
                switch (rec.repeatOption) {
                    case 'daily':
                        while (currentDate <= stopDate) {
                            reservationDates.push(new Date(currentDate));
                            currentDate = addDays(currentDate, rec.repeatEvery);
                        }
                        break;
                    case 'weekly':
                        while (currentDate <= stopDate) {
                            if (rec.repeatOn) {
                                const repeatOn = rec.repeatOn.split(',');
                                if (repeatOn.includes(weekday[currentDate.getDay()])) {
                                    if (reservationDates.length < repeatOn.length) {
                                        reservationDates.push(new Date(currentDate));
                                    }
                                    else {
                                        break;
                                    }
                                }
                            }
                            currentDate = addDays(currentDate, 1);
                        }
                        let currentDate1;
                        let element;
                        for (element of reservationDates) {
                            currentDate1 = addDays(element, 7 * rec.repeatEvery);
                            if (currentDate1 <= stopDate) {
                                reservationDates.push(new Date(currentDate1));
                            }
                            else {
                                break;
                            }
                        }
                        // const repeatOn = rec.repeatOn.split(',');
                        // repeatOn.forEach(element => {
                        //   let currentDate1 = new Date(currentDate);
                        //   let d1 = new Date(currentDate1);
                        //   for (i = 0; i <= rec.ahead; i++) {
                        //     // currentDate.setMonth(getMonth + i);
                        //     currentDate1.setDate(1);
                        //     if (currentDate1 <= stopDate) {
                        //       let month = d1.getMonth();
                        //       days = []
                        //       weekdays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
                        //       d1.setDate(1);
                        //       while (d1.getDay() !== (weekdays.indexOf(element) + 1)) {
                        //         d1.setDate(d1.getDate() + 1);
                        //       }
                        //       // Get all the other days in the month
                        //       while (d1.getMonth() === month) {
                        //         days.push(new Date(d1.getTime()));
                        //         d1.setDate(d1.getDate() + 7);
                        //       }
                        //       if (days[rec.repeatEvery - 1] <= stopDate) {
                        //       reservationDates.push(days[rec.repeatEvery - 1])
                        //       }
                        //       currentDate1 = addMonths(currentDate1, 1);
                        //     }
                        //   }
                        // });
                        break;
                    case 'monthly_date':
                        currentDate = new Date(currentDate.setDate(rec.repeatEvery));
                        for (let i = 0; i <= rec.ahead; i++) {
                            if (currentDate <= stopDate) {
                                // currentDate.setMonth(getMonth + i);
                                reservationDates.push(new Date(currentDate));
                                currentDate = addMonths(currentDate, 1);
                            }
                        }
                        break;
                    case 'monthly_weekday':
                        let d = new Date(currentDate);
                        for (let i = 0; i <= rec.ahead; i++) {
                            // currentDate.setMonth(getMonth + i);
                            if (currentDate <= stopDate) {
                                let month = d.getMonth();
                                let days = [];
                                let weekdays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
                                d.setDate(1);
                                while (d.getDay() !== weekdays.indexOf(rec.repeatOn)) {
                                    d.setDate(d.getDate() + 1);
                                }
                                // Get all the other days in the month
                                while (d.getMonth() === month) {
                                    days.push(new Date(d.getTime()));
                                    d.setDate(d.getDate() + 7);
                                }
                                reservationDates.push(days[rec.repeatEvery - 1]);
                                currentDate = addMonths(currentDate, 1);
                            }
                        }
                        break;
                    default:
                        break;
                }
                if (reservationDates && reservationDates.length > 0) {
                    reservationDates = reservationDates.filter((item, key) => {
                        let firstEntry = new Date(item);
                        if (!(firstEntry.setHours(0, 0, 0, 0) <= parseISO(lastReservedFor).setHours(0, 0, 0, 0))) {
                            return item;
                        }
                    });
                }
                if (reservationDates.length > 0) {
                    let finalReservation = reservationDates[reservationDates.length - 1];
                    this.lastReservationDate = moment(parseISO(finalReservation)).format('DD.MM.YYYY');
                }
                else {
                    this.lastReservationDate = 'No date exists';
                }
            }
        }
    }
    getSmsSettings() {
        this.reservationService
            .getSmsSettings()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((smsSettings) => {
            this.smsSettings = smsSettings;
        });
    }
    checkIsPastDate() {
        if (this.todayDate && this.reservationFormGroup.value.date) {
            this.isPastDate =
                this.todayDate.setHours(0, 0, 0, 0) > new Date(this.reservationFormGroup.value.date).setHours(0, 0, 0, 0)
                    ? true
                    : false;
        }
        else {
            this.isPastDate = false;
        }
    }
    setTime() {
        let time;
        if (this.manualRes == 1) {
            time = this.roundToNearest5Minutes(moment().format('HH:mm'));
        }
        else {
            time = moment().format('HH:mm');
        }
        const defaultTimeObj = {
            time,
            isClosed: false
        };
        this.times.push(defaultTimeObj);
        this.times.sort(function (a, b) {
            var timeA = a.time;
            var timeB = b.time;
            if (timeA < timeB) {
                return -1;
            }
            if (timeA > timeB) {
                return 1;
            }
            return 0;
        });
        this.reservationFormGroup.get('time').setValue(time);
        this.times = [...this.times];
        this.getTables();
        this.setEndTime();
    }
    roundToNearest5Minutes(timeString) {
        const [hours, minutes] = timeString.split(':').map(Number);
        const totalMinutes = hours * 60 + minutes;
        const roundedMinutes = Math.round(totalMinutes / 5) * 5;
        const newHours = Math.floor(roundedMinutes / 60);
        const newMinutes = roundedMinutes % 60;
        const result = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
        return result;
    }
    convertStayTimeData(stayTime, type = 'hrs') {
        if (this.manualRes == 1) {
            if (type == 'hrs') {
                return (stayTime / 60).toFixed(2);
            }
            else if (type == 'mins') {
                return Math.floor(stayTime * 60);
            }
        }
        else {
            return stayTime;
        }
    }
    calculateStayTime(type) {
        if (type == 'hrsToTime') {
            return this.addHoursToTime(this.reservationFormGroup.value.time, this.reservationFormGroup.value.stayTime);
        }
        else if (type == 'timeToHrs') {
            if (this.reservationFormGroup.value.time) {
                return this.calculateTimeDifference(this.reservationFormGroup.value.time, this.reservationFormGroup.value.endTime);
                // const startTimeInMinutes = this.timeToMinutes(this.reservationFormGroup.value.time);
                // const endTimeInMinutes = this.timeToMinutes(this.reservationFormGroup.value.endTime);
                // const timeDifferenceInMinutes = endTimeInMinutes - startTimeInMinutes;
                // return (timeDifferenceInMinutes / 60).toFixed(2);
                // return Math.round((timeDifferenceInMinutes / 60) * 2) / 2;
            }
        }
    }
    calculateTimeDifference(startTime, endTime) {
        const [startHours, startMinutes] = startTime.split(':').map(Number);
        const [endHours, endMinutes] = endTime.split(':').map(Number);
        // Convert start and end times to total minutes since midnight
        const startTimeInMinutes = startHours * 60 + startMinutes;
        const endTimeInMinutes = endHours * 60 + endMinutes;
        // Calculate the time difference
        let timeDifferenceInMinutes;
        if (endTimeInMinutes >= startTimeInMinutes) {
            // End time is on the same day or later
            timeDifferenceInMinutes = endTimeInMinutes - startTimeInMinutes;
        }
        else {
            // End time is on the next day
            timeDifferenceInMinutes = 24 * 60 - startTimeInMinutes + endTimeInMinutes;
        }
        // Convert the time difference to hours with two decimal places
        const hoursDifference = (timeDifferenceInMinutes / 60).toFixed(2);
        return hoursDifference;
    }
    timeToMinutes(time) {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    }
    // addHoursToTime(time: any, hoursToAdd: any) {
    //   const [hours, minutes] = time.split(':').map(Number);
    //   const totalMinutes = hours * 60 + minutes + hoursToAdd * 60;
    //   const newHours = Math.floor(totalMinutes / 60);
    //   const newMinutes = Math.floor(totalMinutes % 60);
    //   return `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
    // }
    addHoursToTime(time, hoursToAdd) {
        const [hours, minutes] = time.split(':').map(Number);
        const totalMinutes = hours * 60 + minutes + hoursToAdd * 60;
        // Calculate new hours and minutes
        const newHours = Math.floor(totalMinutes / 60) % 24; // Ensure hours stay within 0-23
        const newMinutes = Math.floor(totalMinutes % 60);
        // Ensure that newHours and newMinutes are non-negative
        const adjustedHours = newHours < 0 ? 24 + newHours : newHours;
        const adjustedMinutes = newMinutes < 0 ? 60 + newMinutes : newMinutes;
        return `${adjustedHours.toString().padStart(2, '0')}:${adjustedMinutes.toString().padStart(2, '0')}`;
    }
    setEndTime() {
        if (this.reservationFormGroup.value.time && this.reservationFormGroup.value.stayTime) {
            let setTime = this.calculateStayTime('hrsToTime');
            const [endHours, endMinutes] = setTime.split(':').map(Number);
            if (!(endHours < 24 && endMinutes < 60)) {
                this.timeError = 'Bitte wählen Sie eine gültige Uhrzeit aus.';
                return;
            }
            if (setTime !== '00:00') {
                const isInList = this.endTimes.some((item) => item.time === setTime);
                if (!isInList) {
                    const defaultTimeObj = {
                        time: setTime,
                        isClosed: false
                    };
                    this.endTimes.push(defaultTimeObj);
                    this.endTimes.sort(function (a, b) {
                        var timeA = a.time;
                        var timeB = b.time;
                        if (timeA < timeB) {
                            return -1;
                        }
                        if (timeA > timeB) {
                            return 1;
                        }
                        return 0;
                    });
                }
                this.reservationFormGroup.get('endTime').setValue(setTime);
                this.endTimes = [...this.endTimes];
            }
        }
        // this.compareStartEndTime();
    }
    compareStartEndTime() {
        // this.timeError = '';
        // if (this.reservationFormGroup.value.time && this.reservationFormGroup.value.endTime) {
        //   const today = new Date();
        //   const startTime = new Date(today.toDateString() + ' ' + this.reservationFormGroup.value.time);
        //   const endTime = new Date(today.toDateString() + ' ' + this.reservationFormGroup.value.endTime);
        //   if (endTime <= startTime) {
        //     this.timeError = 'Die Endzeit darf nicht kleiner als die Startzeit sein.';
        //     return;
        //   }
        // }
    }
    getAllTimes() {
        if (this.showAllTimes) {
            this.newTimes = [...this.times];
        }
        else {
            let openTimes = [];
            for (let time of this.times) {
                if (!time.isClosed) {
                    openTimes.push(time);
                }
            }
            this.newTimes = [...openTimes];
        }
    }
    setShowAllTimes() {
        this.showAllTimes = this.showAllTimes ? false : true;
        this.getAllTimes();
    }
    saveVersionSettings() {
        this.reservationService
            .saveVersionSettings({ manualRes: this.manualRes })
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response) => {
            this.ngOnInit();
            this.snackBar.open('Erfolgreich.', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
            });
        }, err => {
            console.log('err', err);
        });
    }
    parseOptionData(optionData) {
        try {
            return JSON.parse(optionData);
        }
        catch (_a) {
            return [];
        }
    }
    sortTicketsBySubCategory() {
        if (this.reservation) {
            if (this.reservation.ticketOrder && this.reservation.ticketOrder.tickets) {
                this.reservation.ticketOrder;
                this.reservation.ticketOrder.tickets.sort((a, b) => a.subCategory.id - b.subCategory.id);
            }
        }
    }
    sendPaymentLinkViaEmail(reservation) {
        const baseURL = `https://reservierung.gastroguide.de/${this.processName(this.activeClient.name, true)}/${reservation.clientId}`;
        this.reservationService
            .sendPaymentLinkViaEmail(baseURL, reservation.id)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => {
            // this.snackBar.open('Email sent successfully.', '', {
            //   duration: 2000,
            //   panelClass: ['snackbar-success']
            // });
        }, err => {
            console.log('error');
        });
    }
    processName(name, removeSlashAndDot = false) {
        const specialCharactersPipe = new SpecialCharactersPipe();
        return specialCharactersPipe.transform(name, removeSlashAndDot);
    }
}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { VouchersService } from '../vouchers.service';
import { Page } from '../vouchers.model';
import { SatDatepickerRangeValue } from 'saturn-datepicker';
import moment from 'moment';
import { Subject, fromEvent } from 'rxjs';
import 'rxjs/add/operator/takeUntil';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
@Component({
  selector: 'app-vouchers-list',
  templateUrl: './vouchers-list.component.html',
  styleUrls: ['./vouchers-list.component.scss']
})
export class VouchersListComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild('voucherData', { static: true }) voucherData: ElementRef;
  public transactions: any[] = [];
  public vouchers: any[] = [];
  public vouchersForExport: any[] = [];
  public filter: any = {
    client: null,
    voucher: null
  };
  public pageLimit: number = 50;

  public csvOptions: any = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: ['Gutschein', 'Wert', 'Restwert'],
    showTitle: false,
    title: '',
    useBom: false,
    removeNewLines: true,
    keys: ['code', 'value', 'residualValue']
  };
  public now: any = new Date();
  message: any = {};
  voucherDetails: any = [];
  errorMsg: string = '';
  code: string = '';

  constructor(private voucherService: VouchersService, public translate: TranslateService) {
    this.translate.onLangChange.subscribe((event: any) => {
      this.translate.setDefaultLang(event.lang);
      this.translator();
    });
  }

  ngOnInit() {
    // let code = window.location.href.split('/list/')[1] || null;
    // if (code) {
    //   this.filterVoucher({ code });
    // } else {
    //   this.getTransactions();
    // }

    // this.getVouchers();

    this.code = window.location.href.split('/list/')[1] || null;
    if (this.code) {
      this.searchTransactions(this.code);
    }
    this.translator();
  }

  ngAfterViewInit() {
    if (this.code) {
      this.voucherData.nativeElement.value = this.code;
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  translator() {
    this.message = {
      emptyMessage: this.translate.instant('Vouchers.Coupons.NoTransactionWithThisCode')
    };
    this.csvOptions.headers = [
      this.translate.instant('Vouchers.Coupons.Voucher'),
      this.translate.instant('Vouchers.Coupons.Value'),
      this.translate.instant('Vouchers.Coupons.ResidualValue')
    ];
    const element = document.querySelector('angular2csv button');
    if (element) {
      element.textContent = this.translate.instant('Vouchers.Coupons.DownloadCSVFile');
    }
  }
  getVouchers() {
    this.voucherService.getVouchers().subscribe(res => {
      this.vouchersForExport = res || [];
      // this.vouchersForExport = res.map((item: any) => {
      //   if (item.status == 0) {
      //     item.status = 'aktiv';
      //   } else if (item.status == 1) {
      //     item.status = 'wartend';
      //   } else if (item.status == 2) {
      //     item.status = 'gesperrt';
      //   } else {
      //     item.status = '';
      //   }
      //   return item;
      // });
      console.log(41, this.vouchersForExport);
    });
  }

  // ngAfterViewInit(): void {
  //   fromEvent(this.voucherData.nativeElement, 'keyup')
  //     .pipe(
  //       filter(Boolean),
  //       debounceTime(500),
  //       distinctUntilChanged(),
  //       tap((event: any) => {
  //         if (this.voucherData.nativeElement.value && this.voucherData.nativeElement.value.trim().length >= 3) {
  //           this.getTransactions(null, this.voucherData.nativeElement.value);
  //         }
  //       })
  //     )
  //     .takeUntil(this.ngUnsubscribe)
  //     .subscribe();
  // }

  searchTransactions(code: any) {
    this.voucherDetails = [];
    this.errorMsg = '';
    if (code && code.trim()) {
      this.voucherService.getVoucherDetail(code).subscribe(
        res => {
          this.voucherDetails = res;
          if (this.voucherDetails.length > 0) {
            this.errorMsg = this.errorMsg = this.translate.instant('Vouchers.Coupons.NoVoucherWithThisCode');
          }
        },
        error => {
          this.errorMsg = this.errorMsg = this.translate.instant('Vouchers.Coupons.NoVoucherWithThisCode');
          console.log('err', error);
        }
      );
      this.getTransactions(null, code);
    }
  }

  async getTransactions(clientId: number = null, voucherCode: string = '') {
    await this.voucherService
      .getTransactions(
        clientId,
        moment()
          .subtract(80, 'months')
          .format('YYYY-MM-DD'),
        moment()
          .add(1, 'months')
          .format('YYYY-MM-DD'),
        0,
        '',
        voucherCode
      )
      .toPromise()
      .then((data: any) => {
        this.transactions = data;
        // console.log('data', this.transactions);

        // Reset voucher list
        this.vouchers = [];
        let voucherKeys: any[] = [];

        // Sum transaction values
        this.transactions.forEach((row: any) => {
          if (row.voucher) {
            if (!voucherKeys.includes(row.voucher.code)) {
              voucherKeys.push(row.voucher.code);
              this.vouchers.push({ code: row.voucher.code });
            }
          }

          /*
          if (row.transType === 'withdraw') {
            if (row.transReferrer === 'gg') {
            }
            if (row.transReferrer === 'pos') {
            }
          }

          if (row.transType === 'recharge' || row.transType === 'create') {
            if (row.transReferrer === 'gg') {
            }
            if (row.transReferrer === 'pos') {
            }
          }
          */
        });
        this.vouchers.sort(this.dynamicSort('code'));
        if (voucherCode && voucherCode != '') {
          this.filter.voucher = voucherCode;
          this.transactions = this.transactions.filter(function(el: any) {
            if (el.voucher) {
              return el.voucher.code.includes(voucherCode);
            } else {
              return false;
            }
          });
        }
      });
  }

  async filterVoucher(voucher: any) {
    this.transactions = [];
    if (voucher === undefined || !voucher) {
      voucher = { code: '' };
    }
    if (voucher.code) {
      this.filter.voucher = voucher.code;
      if (this.filter.client) {
        this.getTransactions(this.filter.client.id, this.filter.voucher);
      } else {
        this.getTransactions(null, this.filter.voucher);
      }
    } else {
      if (this.filter.client) {
        this.getTransactions(this.filter.client.id);
      } else {
        this.getTransactions();
      }
    }
  }

  dynamicSort(property: string) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function(a: any, b: any) {
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  getArrayCopy(arr: any[]) {
    let vouchers: any[] = [];
    arr.forEach(row => {
      let tempRow = { ...row };
      tempRow.value = `${tempRow.value}`.replace('.', ',');
      tempRow.residualValue = `${tempRow.residualValue}`.replace('.', ',');
      vouchers.push(tempRow);
    });
    return vouchers;
  }

  getStatusMessage(status: number): string {
    if (status === 0) {
      return this.translate.instant('Vouchers.Coupons.Inactive');
    } else if (status === 1) {
      return 'OK';
    } else {
      return this.translate.instant('Vouchers.Coupons.Deactive');
    }
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Alert } from '../reservation-settings.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
moment.locale('de');

@Component({
  selector: 'app-add-alert',
  templateUrl: './add-alert.component.html',
  styleUrls: ['./add-alert.component.scss']
})
export class AddAlertComponent implements OnInit {
  @Input() public editAlertData: any; // only if editing
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  alertFormGroup: FormGroup;
  fileName: String = '';

  constructor(public activeModal: NgbActiveModal, public formBuilder: FormBuilder, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.alertFormGroup = this.formBuilder.group(
      {
        type: ['staff', Validators.required],
        dateFrom: [new Date(), Validators.required],
        dateTo: [],
        info: ['', Validators.required],
        fileUrl: ['']
      },
      { validator: this.dateLessThan('dateFrom', 'dateTo') }
    );
    if (this.editAlertData) {
      this.alertFormGroup.addControl('id', new FormControl(this.editAlertData.id, Validators.required));
      this.alertFormGroup.get('type').setValue(this.editAlertData.type);
      this.alertFormGroup.get('dateFrom').setValue(this.editAlertData.dateFrom);
      this.alertFormGroup.get('dateTo').setValue(this.editAlertData.dateTo);
      this.alertFormGroup.get('info').setValue(this.editAlertData.info);
      this.alertFormGroup.addControl('alertFile', new FormControl(this.editAlertData.fileUrl));
      this.fileName = this.editAlertData.fileUrl === 'null' ? '' : this.editAlertData.fileUrl;
    }
  }

  add() {
    if (!this.alertFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.passEntry.emit(this.alertFormGroup.value);
  }

  clearInput() {
    this.alertFormGroup.get('dateTo').setValue(null);
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];
      if (t && t.value && moment(f.value) > moment(t.value)) {
        return {
          dates: 'Das End-Datum darf nicht vor dem Start-Datum liegen!'
        };
      }
      return {};
    };
  }
  onFilesSelected(files: File) {
    const file = files[0];
    const fileTypes = ['image/jpeg', 'image/jpg', 'application/pdf'];
    if (file) {
      if (!fileTypes.includes(file.type)) {
        this.snackBar.open('Nicht unterstütztes Dateiformat. Nur jpg und pdf', '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
        return;
      }
      this.fileName = file.name;
      this.alertFormGroup.get('fileUrl').setValue(file);
    }
  }

  removeFile() {
    this.fileName = null;
    this.alertFormGroup.get('alertFile').setValue('');
    this.alertFormGroup.get('fileUrl').setValue('');
  }
}

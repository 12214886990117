<div class="modal-header">
  <h4 class="modal-title" *ngIf="!editShiftData">{{ 'ResSettings.ResAddShift.CreateNewTemplate' | translate }}</h4>
  <h4 class="modal-title" *ngIf="editShiftData">{{ 'ResSettings.ResAddShift.EditTemplate' | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="shiftFormGroup">
  <div class="modal-body row">
    <div class="col-sm-6">
      <fieldset class="form-group">
        <legend>
          <span>{{ 'ResSettings.ResAddShift.Generally' | translate }}</span>
        </legend>
        <div class="row">
          <div class="col-sm-2">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResAddShift.Color' | translate }}</label>
              <app-color-picker
                [color]="shiftFormGroup.get('color').value"
                (event)="changeColor($event)"
              ></app-color-picker>
            </div>
          </div>
          <div class="col-sm-10">
            <div class="form-group">
              <label for="">Name</label>
              <input
                class="form-control"
                placeholder="Geben Sie einen Namen ein..."
                type="text"
                formControlName="name"
                ngbAutofocus
                [customErrors]="{ email: 'invalidEmail' }"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResAddShift.DescriptionForInternal' | translate }}</label>
              <input class="form-control" placeholder="Optional" type="text" formControlName="description" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResAddShift.InformationForGuest' | translate }} </label>
              <textarea class="form-control" formControlName="info"></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResAddShift.AdditionalInformation' | translate }}</label>
              <textarea class="form-control" formControlName="emailInfo"></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResAddShift.AvailableRooms' | translate }}</label>
              <ng-select
                [items]="rooms"
                bindLabel="name"
                [multiple]="true"
                placeholder="Räume auswählen"
                formControlName="rooms"
                required
              >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-slide-toggle formControlName="isAutomatic">
                {{ 'ResSettings.ReservationSetting.ActivateAutomatic' | translate }}
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResAddShift.MinPeopleReservation' | translate }}</label>
              <number-spinner
                name="minGuests"
                [min]="1"
                [max]="1000"
                [step]="1"
                [precision]="1"
                [inputDisabled]="false"
                [defaultValue]="+shiftFormGroup.value.minGuests"
                (whenChange)="minGuestsChanged($event)"
                required
              ></number-spinner>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResAddShift.MaxGroupSizeForAutoRes' | translate }}</label>
              <number-spinner
                name="maxGuests"
                [min]="1"
                [max]="1000"
                [step]="1"
                [precision]="1"
                [inputDisabled]="false"
                [defaultValue]="+shiftFormGroup.value.maxGuests"
                (whenChange)="maxGuestsChanged($event)"
                required
              ></number-spinner>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-slide-toggle formControlName="groupReservation">
                {{ 'ResSettings.ResAddShift.AllowGroupRequest' | translate }}
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <!-- <ng-template [ngIf]="isAccess"> -->
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResAddShift.BookingType' | translate }}</label>
              <select class="form-control" formControlName="type" required>
                <option *ngFor="let type of shiftTypes" [ngValue]="type.value">{{ type.label }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="shiftFormGroup.value.type && shiftFormGroup.value.type == 2">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResAddShift.AmountLabelSingle' | translate }}</label>
              <input
                class="form-control"
                placeholder="{{ 'ResSettings.ResAddShift.SingleTablePlaceholder' | translate }}"
                type="text"
                formControlName="amountLabelSingle"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResAddShift.AmountLabelPlural' | translate }}</label>
              <input
                class="form-control"
                placeholder="{{ 'ResSettings.ResAddShift.PluralTablePlaceholder' | translate }}"
                type="text"
                formControlName="amountLabelPlural"
              />
            </div>
          </div>
        </div>
        <!-- </ng-template> -->
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResAddShift.InformationTextForLargeGroup' | translate }}</label>
              <textarea class="form-control" formControlName="maxGuestsMsg"></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResAddShift.NoFreePlaces' | translate }}</label>
              <textarea class="form-control" formControlName="noSlotAvailableMsg"></textarea>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="form-group" formArrayName="questions" *ngIf="globalQuestions.length">
        <legend style="margin-bottom: 10px;">
          <span>{{ 'ResSettings.ResAddShift.AdditionalQueries' | translate }}</span>
        </legend>
        <div class="help-block form-text text-muted">
          {{ 'ResSettings.ResAddShift.LinkForRequiredFiled' | translate }}
        </div>
        <ng-template ngFor let-question [ngForOf]="questionsFormData.controls" let-i="index">
          <div style="display: flex; margin-bottom: 5px" [formGroupName]="i">
            <div style="flex: 1.5">
              <ng-select
                [clearable]="false"
                formControlName="questionId"
                [items]="globalQuestions"
                bindLabel="name"
                bindValue="id"
                placeholder="Abfrage auswählen"
                (change)="changeQuestions($event, i)"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  {{ item.name }}<br />
                  <small>{{ item.question }}</small>
                </ng-template>
              </ng-select>
            </div>
            <div style="flex: 1">
              <ng-select
                [clearable]="false"
                [searchable]="false"
                formControlName="required"
                required
                style="margin-right: 5px"
              >
                <ng-option *ngIf="question.value.questionType !== 3" value="0">{{
                  'ResSettings.ResAddShift.SingleQueOptions' | translate
                }}</ng-option>
                <ng-option value="1">Einzelfrage Pflicht</ng-option>
                <ng-option *ngIf="question.value.questionType !== 1 && question.value.questionType !== 3" value="2">{{
                  'ResSettings.ResAddShift.MenuQueryOptional' | translate
                }}</ng-option>
                <ng-option *ngIf="question.value.questionType !== 1 && question.value.questionType !== 3" value="3">{{
                  'ResSettings.ResAddShift.MenuQueryMandatory' | translate
                }}</ng-option>
              </ng-select>
            </div>
            <div style="align-self: center">
              <button class="btn btn-white" type="button" (click)="removeQuestion(i)">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </ng-template>
        <button class="btn btn-dark btn-circle" type="button" style="margin-top: 5px" (click)="addQuestion()">
          {{ 'ResSettings.ResAddShift.NewQuery' | translate }}
        </button>
      </fieldset>

      <fieldset class="form-group" *ngIf="paymentTemplates.length && generalSettings.paymentSystem">
        <legend style="margin-bottom: 10px;">
          <span>{{ 'ResSettings.ResAddShift.DepositTemplate' | translate }}</span>
        </legend>
        <div class="help-block form-text text-muted">
          {{ 'ResSettings.ResAddShift.SelectDepositTemplate' | translate }}
        </div>
        <ng-select
          [clearable]="true"
          formControlName="paymentTemplate"
          [items]="paymentTemplates"
          bindLabel="name"
          bindValue="id"
          placeholder="Anzahlungsvorlage auswählen"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            {{ item.name }}<br />
            <small
              >Min. {{ item.minGuests }} Personen • {{ item.priceOption === 'guest' ? 'Pro Gast' : 'Pauschal' }} •
              {{ item.price | number: '1.2' }} EUR</small
            >
          </ng-template>
        </ng-select>
      </fieldset>

      <fieldset class="form-group">
        <legend style="margin-bottom: 10px;"><span>Add-Ons</span></legend>
        <div class="help-block form-text text-muted">
          {{ 'ResSettings.ResAddShift.OrderAdditionalProduct' | translate }}
        </div>
        <ng-select
          [items]="addOns"
          bindLabel="title"
          [multiple]="true"
          placeholder="Add-Ons auswählen"
          formControlName="addOns"
        >
        </ng-select>
      </fieldset>
    </div>
    <div class="col-sm-6">
      <fieldset class="form-group">
        <legend>
          <span>{{ 'ResSettings.ResAddShift.Time' | translate }}</span>
        </legend>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResAddShift.ReservationInterval' | translate }}</label>
              <div class="help-block form-text text-muted">
                {{ 'ResSettings.ResAddShift.ReservationTimes' | translate }}
              </div>
              <select class="form-control" formControlName="interval" required>
                <option *ngFor="let interval of reservationIntervals" [ngValue]="interval.value"
                  >{{ interval.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResAddShift.BufferTime' | translate }}</label>
              <div class="help-block form-text text-muted">
                {{ 'ResSettings.ResAddShift.MinPeriodOfReservation' | translate }}
              </div>
              <select class="form-control" formControlName="buffer" required>
                <option *ngFor="let time of bufferTimes" [ngValue]="time.value">{{ time.label }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-slide-toggle formControlName="noReservationAfterShiftStart">
                {{ 'ResSettings.ResAddShift.ResNoLongerPossible' | translate }}
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group" formArrayName="maxGuestsPerUnit">
              <label for="">{{ 'ResSettings.ResAddShift.MaxPeoplePerUnit' | translate }}</label>
              <div class="help-block form-text text-muted">
                {{ 'ResSettings.ResAddShift.MaxPeopleWhoCanMakeResPerTime' | translate }}
              </div>
              <ng-template ngFor let-unit [ngForOf]="formData.controls" let-i="index">
                <div style="display: flex; margin-bottom: 5px" [formGroupName]="i">
                  <div style="flex: 1.5">
                    <select class="form-control" formControlName="day" required style="margin-right: 5px">
                      <option value="0" selected>{{ 'ResSettings.ResAddShift.EveryDay' | translate }}</option>
                      <option value="1">{{ 'ResSettings.ResAddShift.Monday' | translate }}</option>
                      <option value="2">{{ 'ResSettings.ResAddShift.Tuesday' | translate }}</option>
                      <option value="3">{{ 'ResSettings.ResAddShift.Wednesday' | translate }}</option>
                      <option value="4">{{ 'ResSettings.ResAddShift.Thursday' | translate }}</option>
                      <option value="5">{{ 'ResSettings.ResAddShift.Friday' | translate }}</option>
                      <option value="6">{{ 'ResSettings.ResAddShift.Saturday' | translate }}</option>
                      <option value="7">{{ 'ResSettings.ResAddShift.Sunday' | translate }}</option>
                    </select>
                  </div>
                  <div style="flex: 1">
                    <input
                      type="text"
                      formControlName="time"
                      placeholder="Uhrzeit"
                      class="form-control"
                      style="margin-right: 5px"
                      mask="00:00"
                    />
                  </div>
                  <div style="flex: 1">
                    <input type="text" formControlName="guestCount" placeholder="Personen" class="form-control" />
                  </div>
                  <div>
                    <button class="btn btn-white" type="button" (click)="removeUnit(i)">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </ng-template>
              <button class="btn btn-dark btn-circle" type="button" style="margin-top: 5px" (click)="addNewUnit()">
                {{ 'ResSettings.AddReservationAddOn.NewUnit' | translate }}
              </button>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="form-group">
        <legend>
          <span>{{ 'ResSettings.ResAddShift.LengthToStay' | translate }}</span>
        </legend>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-radio-group aria-label="Wähle eine Option" formControlName="stayTimeType">
                <mat-radio-button value="static">{{ 'ResSettings.ResAddShift.Static' | translate }}</mat-radio-button>
                <mat-radio-button value="dynamic">{{ 'ResSettings.ResAddShift.Dynamic' | translate }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <ng-template [ngIf]="shiftFormGroup.get('stayTimeType').value === 'static'">
          <div class="row" *ngFor="let stayTime of stayTimes">
            <div class="col-sm-12">
              <div class="form-group">
                <label for=""
                  >{{ 'ResSettings.ResAddShift.LengthToStay' | translate }}
                  <span *ngIf="!stayTime.guestCountTo">ab</span> {{ stayTime.guestCountFrom }}
                  <span *ngIf="stayTime.guestCountTo">-</span> {{ stayTime.guestCountTo }}
                  {{ 'ResSettings.ResAddShift.Persons' | translate }}</label
                >
                <div class="custom-slider">
                  <ng5-slider [(value)]="stayTime.defaultStayTime" [options]="staticStayTimeOptions"> </ng5-slider>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="shiftFormGroup.get('stayTimeType').value === 'dynamic'">
          <div class="row" *ngFor="let stayTime of stayTimes; let i; of: index">
            <div class="col-sm-8">
              <div class="form-group">
                <label for=""
                  >{{ 'ResSettings.ResAddShift.LengthToStay' | translate }}
                  <span *ngIf="!stayTime.guestCountTo">ab</span> {{ stayTime.guestCountFrom }}
                  <span *ngIf="stayTime.guestCountTo">-</span> {{ stayTime.guestCountTo }}
                  {{ 'ResSettings.ResAddShift.Persons' | translate }}</label
                >
                <div class="custom-slider">
                  <ng5-slider
                    [(value)]="stayTime.minStayTime"
                    [(highValue)]="stayTime.maxStayTime"
                    [options]="sliderOptions"
                  >
                  </ng5-slider>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label>{{ 'ResSettings.ResAddShift.DefaultValue' | translate }}</label>
                <ng-select
                  [searchable]="false"
                  [clearable]="false"
                  [(ngModel)]="stayTime.defaultStayTime"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <ng-option
                    *ngFor="let value of getDefaultValues(stayTime.minStayTime, stayTime.maxStayTime)"
                    [value]="value"
                  >
                    <span *ngIf="value / 60 !== 0.5">
                      {{ value / 60 }} {{ 'ResSettings.ResAddShift.Hour' | translate
                      }}<ng-template [ngIf]="value / 60 > 1">n</ng-template>
                    </span>
                    <span *ngIf="value / 60 === 0.5">30 {{ 'ResSettings.ResAddShift.Minutes' | translate }}</span>
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </ng-template>
      </fieldset>
      <fieldset class="form-group">
        <legend style="margin-bottom: 10px;">
          <span>{{ 'ResSettings.ResAddShift.PreOrder' | translate }}</span>
        </legend>
        <div class="help-block form-text text-muted">
          <span *ngIf="menuItems && menuItems.length < 1">
            {{ 'ResSettings.ResAddShift.ActiveCardAvailable' | translate }}
          </span>
          <span *ngIf="menuItems && menuItems.length > 0">
            {{ 'ResSettings.ResAddShift.SelectCard' | translate }}
          </span>
        </div>
        <ng-select
          *ngIf="menuItems && menuItems.length > 0"
          [searchable]="false"
          [items]="menuItems"
          [clearable]="true"
          formControlName="menuItem"
          bindLabel="name"
          bindValue="id"
        >
        </ng-select>
      </fieldset>
      <fieldset class="form-group">
        <legend style="margin-bottom: 10px;"><span>Event</span></legend>
        <ng-select
          *ngIf="activeEventList && activeEventList.length > 0"
          [searchable]="false"
          [items]="activeEventList"
          [clearable]="true"
          formControlName="eventId"
          bindLabel="name"
          bindValue="id"
          (change)="getAllRelatedTicket()"
        >
        </ng-select>
      </fieldset>
      <!-- <ng-template [ngIf]="isAccess"> -->
      <fieldset class="form-group" *ngIf="shiftFormGroup.value.eventId">
        <legend style="margin-bottom: 10px;"><span>Ticket</span></legend>
        <ng-select
          *ngIf="activeEventList && activeEventList.length > 0"
          [searchable]="false"
          [items]="activeTicketList"
          [clearable]="true"
          formControlName="ticketId"
          bindLabel="name"
          bindValue="id"
        >
        </ng-select>
      </fieldset>
      <!-- </ng-template> -->
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-primary btn-block btn-lg"
      (click)="add()"
      *ngIf="!editShiftData"
      [promiseBtn]="addObservable"
    >
      {{ 'ResSettings.ResAddShift.CreateTemplate' | translate }}
    </button>
    <button
      class="btn btn-primary btn-block btn-lg"
      (click)="save()"
      *ngIf="editShiftData"
      [promiseBtn]="editObservable"
    >
      {{ 'ResSettings.ResAddShift.SaveTemplate' | translate }}
    </button>
  </div>
</form>

import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservationService } from '@app/reservation/reservation.service';
import { MatSnackBar } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-room',
  templateUrl: './add-room.component.html',
  styleUrls: ['./add-room.component.scss']
})
export class AddRoomComponent implements OnInit, OnDestroy {
  @Input() public editRoomData: any; // only if editing
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  roomFormGroup: FormGroup;
  error: string;
  addObservable: any;
  editObservable: any;
  deleteObservable: any;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private reservationService: ReservationService,
    private snackBar: MatSnackBar,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.roomFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      alert: [''],
      seats: ['1', Validators.required],
      isActive: [1],
      width: [7, Validators.required],
      length: [5, Validators.required]
    });

    if (this.editRoomData) {
      this.roomFormGroup.addControl('id', new FormControl(this.editRoomData.id, Validators.required));
      this.roomFormGroup.get('name').setValue(this.editRoomData.name);
      this.roomFormGroup.get('alert').setValue(this.editRoomData.alert);
      this.roomFormGroup.get('seats').setValue(this.editRoomData.seats);
      this.roomFormGroup.get('isActive').setValue(this.editRoomData.isActive);
      if (this.editRoomData.width && this.editRoomData.length) {
        this.roomFormGroup.get('width').setValue(this.editRoomData.width);
        this.roomFormGroup.get('length').setValue(this.editRoomData.length);
      }
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  seatsAmountChanged(event: any) {
    this.roomFormGroup.get('seats').setValue(event);
  }

  add() {
    if (!this.roomFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    this.addObservable = this.reservationService
      .addRoom(this.roomFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          console.log('err', err);
          this.error = err.error.msg;
        }
      );
  }

  save() {
    if (!this.roomFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    this.editObservable = this.reservationService
      .editRoom(this.roomFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          console.log('err', err);
          this.error = err.error.msg;
        }
      );
  }

  delete() {
    this.deleteObservable = this.reservationService
      .deleteRoom(this.roomFormGroup.value.id)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((response: any) => {
        const snackBarRef = this.snackBar.open('Raum erfolgreich gelöscht', '', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        this.passEntry.emit('delete');
      });
  }
}

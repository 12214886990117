import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ReservationService } from '../../reservation.service';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update-guest-data',
  templateUrl: './update-guest-data.component.html',
  styleUrls: ['./update-guest-data.component.scss']
})
export class updateGuestDataComponent implements OnInit {
  @Input() guestData: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  guestDataForm: FormGroup;
  error: any;
  formInvalid: string;
  private ngUnsubscribe: Subject<any> = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    private reservationService: ReservationService,
    private snackBar: MatSnackBar,
    public activeModal: NgbActiveModal,
    public translate: TranslateService
  ) {
    this.guestDataForm = this.formBuilder.group({
      name: [],
      firstName: ['']
    });
    this.loadTranslation();
    this.translate.onLangChange.subscribe((event: any) => {
      this.loadTranslation();
    });
  }

  async ngOnInit() {
    this.guestDataForm.addControl('id', new FormControl(this.guestData.id, Validators.required));
    this.guestDataForm.get('name').setValue(this.guestData.name);
    this.guestDataForm.get('firstName').setValue(this.guestData.firstName);
  }

  private loadTranslation() {}

  save() {
    if (!this.guestDataForm.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    console.log('data', this.guestDataForm.value);

    this.reservationService
      .updateGuestData(this.guestDataForm.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.message;
        }
      );
  }
}

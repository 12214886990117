import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatStepperModule,
  MatDatepickerModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatCheckboxModule,
  MatTooltipModule
} from '@angular/material';
import { Ng5SliderModule } from 'ng5-slider';
import { DateFnsModule } from 'ngx-date-fns';
import { ControlErrorComponent } from '@app/shared/control-error/control-error.component';
import { ReservationUserPageComponent } from './reservation-user-page.component';
import { ReservationRoutingModule } from '../reservation-routing.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    ReservationRoutingModule,
    SharedModule,
    MatStepperModule,
    MatDatepickerModule,
    MatIconModule,
    MatProgressSpinnerModule,
    Ng5SliderModule,
    MatCheckboxModule,
    DateFnsModule.forRoot()
  ],
  declarations: [ReservationUserPageComponent],
  entryComponents: [ControlErrorComponent]
})
export class ReservationUserPageModule {}

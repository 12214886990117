import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NewCompanyService } from '../new-company/new-company.service';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-select-draft-company',
  templateUrl: './select-draft-company.component.html',
  styleUrls: ['./select-draft-company.component.scss']
})
export class SelectDraftCompanyComponent implements OnInit {
  dataList: any;
  userId: number;

  constructor(private newCompanyService: NewCompanyService, private modalService: NgbModal, private router: Router) {
    const { id } = JSON.parse(localStorage.getItem('credentials'));
    this.userId = id;
  }

  ngOnInit() {
    this.newCompanyService.getDraftData({ userId: this.userId }).subscribe((response: any) => {
      console.log('Response => ', response);
      if (response && response.length) {
        this.dataList = response;
        for (let i = 0; i < this.dataList.length; i++) {
          this.dataList[i]['masterData'] = JSON.parse(this.dataList[i]['masterData']);
        }
      } else {
        this.router.navigate(['new-client']);
      }
    });
  }

  loadForm(data: any) {
    this.router.navigate(['/new-client'], { queryParams: { id: data.id } });
    // this.router.navigate(['billing/pricing-run-date-configuration/view'], { queryParams: { id: row['pricingIncreaseRunDateConfigId'], divisionId: row['divisionId'], financialYearId: row['financialYearId']  }, skipLocationChange: true });
  }

  remove(id: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Remove Company';
    modalRef.componentInstance.message = `Do you want to delete?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'löschen';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.newCompanyService.removeDraftData(id).subscribe((response: any) => {
            this.dataList = this.dataList.filter((data: any) => data.id !== id);
          });
        }
      },
      () => {}
    );
  }
}

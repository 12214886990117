import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import {
  MatIconModule,
  MatDatepickerModule,
  MatTabsModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatButtonToggleModule
} from '@angular/material';
import { DateFnsModule } from 'ngx-date-fns';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MomentModule } from 'ngx-moment';
import { VouchersRoutingModule } from '../vouchers-routing.module';
import { VouchersSettingsComponent } from './vouchers-settings.component';
import { SatNativeDateModule, SatDatepickerModule } from 'saturn-datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { Angular2CsvModule } from 'angular2-csv';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    VouchersRoutingModule,
    MatIconModule,
    NgScrollbarModule,
    NgxDatatableModule,
    MomentModule,
    NgxDatatableModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDatepickerModule,
    MatTabsModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    NgSelectModule,
    FormsModule,
    FlexModule,
    DateFnsModule.forRoot(),
    Angular2CsvModule
  ],
  declarations: [VouchersSettingsComponent],
  entryComponents: []
})
export class VouchersSettingsModule {}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { VouchersService } from '../vouchers.service';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import moment from 'moment';
import { RangesFooterComponent } from '../../../app/shared/ranges-footer/ranges-footer.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vouchers-pool',
  templateUrl: './vouchers-pool.component.html',
  styleUrls: ['./vouchers-pool.component.scss']
})
export class VouchersPoolComponent implements OnInit, OnDestroy {
  settings: any;
  poolClients: any = [];
  poolLiability: any = [];
  date: any = {
    begin: Date,
    end: Date
  };
  liabilityDate: any = {
    begin: Date,
    end: Date
  };
  rangesFooter = RangesFooterComponent;
  showVoucherResBtn = false;
  voucherResidualClients = [258482, 257122, 260384, 261185];

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private voucherService: VouchersService, public translate: TranslateService) {}

  ngOnInit() {
    this.date.end = new Date();
    this.date.begin = new Date(this.date.end.getFullYear(), this.date.end.getMonth(), 1);
    this.liabilityDate.end = new Date();
    this.liabilityDate.begin = new Date(this.date.end.getFullYear(), this.date.end.getMonth(), 1);
    // this.getPoolClients();
    this.getBranchPoolLiability();

    this.voucherService
      .getSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((res: any) => {
        this.settings = res;
        if (this.voucherResidualClients.includes(this.settings.betriebId)) {
          this.showVoucherResBtn = true;
        }
      });
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getPoolClients() {
    this.poolClients = [];
    this.voucherService
      .getClientPool(this.date.begin, this.date.end)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        this.poolClients = res;
      });
  }

  getPoolLiability() {
    this.getBranchPoolLiability();
    /*
    this.poolLiability = [];
    this.voucherService
      .getPoolLiability(this.liabilityDate.begin, this.liabilityDate.end)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        this.poolLiability = res;
      });
      */
  }

  getCommunityPoolLiability() {
    this.poolLiability = [];
    this.voucherService
      .getCommunityPoolLiability(this.liabilityDate.begin, this.liabilityDate.end)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        console.log('res', res);
        this.poolLiability = res;
      });
  }

  getBranchPoolLiability() {
    this.poolLiability = [];
    this.voucherService
      .getPoolLiability(this.liabilityDate.begin, this.liabilityDate.end)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        console.log('res', res);
        this.poolLiability = res;
      });
  }

  downloadExcelList(startDate: any, endDate: any) {
    this.voucherService.getExcelEvaluationList(startDate, endDate);
  }

  downloadVoucherResidualValues(endDateValue: any) {
    let startDate = `${moment(endDateValue).year() - 1}-04-01`;
    let endDate = `${moment(endDateValue).year()}-03-31`;
    this.voucherService.getVoucherResidualValues(startDate, endDate);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationLogsComponent } from './reservation-logs.component';
import { SharedModule } from '@app/shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatProgressSpinnerModule,
  MatTabsModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatSlideToggleModule
} from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { Angular2CsvModule } from 'angular2-csv';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MomentModule } from 'ngx-moment';
import { RouterModule } from '@angular/router';
import { updateGuestDataComponent } from './update-guest-data/update-guest-data.component';
import { ReservationListByDateModule } from '../reservation-list-by-date/reservations-list-by-date.module';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [ReservationLogsComponent, updateGuestDataComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgxDatatableModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    NgSelectModule,
    MatProgressSpinnerModule,
    Angular2CsvModule,
    BrowserModule,
    MatPaginatorModule,
    MatInputModule,
    MatSelectModule,
    MomentModule,
    RouterModule,
    ReservationListByDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  entryComponents: [updateGuestDataComponent]
})
export class ReservationLogsModule {}

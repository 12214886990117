import { InjectionToken } from '@angular/core';
export const defaultErrors = {
  required: (error: any) => `isRequired`,
  pattern: (error: any) => `invalidInput`,
  email: (error: any) => `invalidEmail`,
  minlength: ({ requiredLength, actualLength }: any) => `minlengthError (${actualLength}/${requiredLength})`,
  maxlength: ({ requiredLength, actualLength }: any) => `maxlengthError (${actualLength}/${requiredLength})`
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});

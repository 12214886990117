import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reservation-overview',
  templateUrl: './reservation-overview.component.html',
  styleUrls: ['./reservation-overview.component.scss']
})
export class ReservationOverviewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

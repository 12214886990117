import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Notification } from '../reservation-settings.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.scss']
})
export class AddNotificationComponent implements OnInit {
  channel: Notification = {
    type: 'email',
    value: ''
  };
  @Input() public editChannelData: any; // only if editing
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (this.editChannelData) {
      this.channel = this.editChannelData;
    }
  }

  add() {
    this.passEntry.emit(this.channel);
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { ShellComponent, LogoOptionsComponent } from './shell.component';
import { MaterialModule } from '@app/core/material.module';
import { MenuListItemComponent } from '@app/core/menu-list-item/menu-list-item.component';
import {
  MatBottomSheetModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatSlideToggleModule
} from '@angular/material';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DisabledNavitemComponent } from '@app/core/menu-list-item/disabled-navitem/disabled-navitem.component';
import { DateFnsModule } from 'ngx-date-fns';
import { HighlightSearchPipe } from '@app/highlight-search.pipe';
import { SearchService } from '@app/core/search.service';
import { AuthenticationGuard } from '@app/core';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { StripHtmlPipe } from '@app/striphtml.pipe';
import { SharedModule } from '@app/shared';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    TranslateModule,
    NgbModule,
    RouterModule,
    MaterialModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    NgScrollbarModule,
    NgxPermissionsModule,
    SharedModule,
    DateFnsModule,
    NgxPermissionsModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [HighlightSearchPipe, SearchService, AuthenticationGuard],
  exports: [HighlightSearchPipe],
  declarations: [
    HighlightSearchPipe,
    ShellComponent,
    MenuListItemComponent,
    LogoOptionsComponent,
    DisabledNavitemComponent
  ],
  entryComponents: [LogoOptionsComponent, DisabledNavitemComponent]
})
export class ShellModule {}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';
import { ReservationService } from '../reservation.service';
declare var AG_GRID_LOCALE_DE: any;

@Component({
  selector: 'app-sms-service-confirmation',
  templateUrl: './sms-service-confirmation.component.html',
  styleUrls: ['./sms-service-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SMSServiceConfirmationComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  smsConfirmationSettings: any = {
    sendSMS: false
  };
  public loading: boolean = false;
  constructor(private snackBar: MatSnackBar, private reservationService: ReservationService) {}

  ngOnInit() {
    this.loading = true;
    this.reservationService
      .getSMSConfirmationSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((data: any) => {
        if (data && Object.keys(data).length > 0) {
          this.smsConfirmationSettings = data;
        }
        this.loading = false;
      });
  }

  saveSMSSettings() {
    this.reservationService
      .saveSMSConfirmationSettings(this.smsConfirmationSettings)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resp: any) => {
        this.snackBar.open('Einstellungen erfolgreich aktualisiert', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
  }
}

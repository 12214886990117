import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectDraftCompanyComponent } from './select-draft-company.component';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{ path: 'select-from-draft', component: SelectDraftCompanyComponent }];

@NgModule({
  declarations: [SelectDraftCompanyComponent],
  imports: [CommonModule, RouterModule.forChild(routes)]
})
export class SelectDraftCompanyRoutingModule {}

import { Component, OnInit, ViewChild } from '@angular/core';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { Subscription, Subject } from 'rxjs';
import { SocketService } from '@app/socket.service';
import { MatSnackBar } from '@angular/material';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { filter } from 'rxjs/operators';

import moment from 'moment';
import { ReservationService } from '../reservation.service';
import { GeneralSettings } from '../reservation-settings/reservation-settings.model';
moment.locale('de');

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {
  csvOptions: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: ['ID', 'Datum', 'Uhrzeit', 'Besteller', 'Betrag', 'Gebühr', 'Zahlart', 'Typ', 'Status', 'Kontostand'],
    showTitle: false,
    title: '',
    useBom: false,
    removeNewLines: true,
    keys: [
      'id',
      'dateFormatted',
      'timeFormatted',
      'person',
      'subtotal',
      'commissionFee',
      'paymentMethodText',
      'typeText',
      'statusText',
      'balance'
    ]
  };

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  allOrders: any[] = [];
  allOrdersCopy: any[] = [];
  filterFrom: string = '';
  filterTo: string = '';
  now: any = new Date();

  pendingOrders: any;
  finishedOrders: any;
  inprogressOrders: any;
  currentDate = new Date();
  tableLimit: number = 1;
  filterStats: any = {
    orders: {
      count: 0,
      total: 0,
      cash: 0,
      gastropay: 0,
      online: 0,
      commission: 0,
      tip: 0
    }
  };
  stats: any = {
    orders: {
      total: 0,
      yesterday: 0,
      today: 0
    },
    balance: {
      total: 0
    },
    sum: {
      total: 0,
      yesterday: 0,
      today: 0
    }
  };
  dates: any = {
    from: '',
    to: ''
  };
  pushedPayouts: any[] = [];
  pushedOrders: any[] = [];
  generalSettings: GeneralSettings;
  paymentProvision: number;
  paymentTransactionFee: number;
  reminderTextError: string = '';
  alert: string = '';

  private _ordersSub: Subscription;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private gastropayService: GastroPayService,
    private socketService: SocketService,
    private snackBar: MatSnackBar,
    private modalService: NgbModal,
    private clientResolver: ClientResolverService,
    private reservationService: ReservationService
  ) {}

  ngOnInit() {
    this.getTransactions(true); //true
    this.getSettings();
  }

  getSettings() {
    this.reservationService
      .getSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((settings: any) => {
        this.generalSettings = settings;
        console.log(this.generalSettings);
        this.paymentProvision = settings.paymentProvision;
        this.paymentTransactionFee = settings.paymentTransactionFee;
      });
  }

  getTransactions(initialStart: boolean = false) {
    console.log('start getTransactions');

    this.gastropayService
      .getReservationsTransactions()
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        console.log('getTransactions', res);
        this.allOrders = res || [];
        /*
        res.orders.forEach((order: any) => {
          if (order.gastropayOrders && order.type != 'refund') {
            this.pushOrder(order.gastropayOrders, order.balance);
          } else {
            if (order.type == 'refund') {
              this.pushOrder(res.refundOrders[order.foreignId], order.balance);
            } else {
              if (order.type == 'payout') {
                if (!this.pushedPayouts.includes(order.createdAt)) {
                  this.allOrders.push({
                    id: order.payoutId,
                    createdAt: order.createdAt,
                    firstName: '',
                    lastName: 'Auszahlung',
                    subtotal: order.value,
                    deliveryFee: 0,
                    commissionFee: 0,
                    paymentMethod: '',
                    type: 'payout',
                    status: 'confirmed',
                    balance: 0.00001
                  });
                  this.pushedPayouts.push(order.createdAt);
                }
              } else {
                console.log(order.type, order);
              }
            }
          }
        });
        */
        this.tableLimit = 50;

        if (initialStart) {
          let tempDate: Date = new Date();
          this.dates.to = moment(tempDate); //.format('YYYY-MM-DD H:mm:59');
          tempDate.setDate(tempDate.getDate() - 6);
          this.filterDates('from', { value: { _d: tempDate } });
        }
      });
  }

  saveSettings() {
    if (this.reminderTextError) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.reservationService
      .saveSettings(this.generalSettings)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resp: any) => {
        this.alert = '';
        this.snackBar.open('Einstellungen erfolgreich aktualisiert', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
  }

  /*
  pushOrder(order: any, balance: number = 0) {
    if (!this.pushedOrders.includes(order.id)) {
      if (!order.paymentId) {
        order.paymentId = '';
      }
      if (!order.zipCode) {
        order.zipCode = '';
      }

      if (order.gastropayTransaction !== undefined && order.gastropayTransaction !== null) {
        order.balance = order.gastropayTransaction.balance;
      } else {
        order.balance = balance;
      }
      if (order.firstName || order.lastName) {
        order.person = order.firstName + ' ' + order.lastName;
      } else {
        order.person = '';
      }
      order.paymentMethodText = '';
      if (order.paymentMethod === 'cash') {
        order.paymentMethodText = 'Bar';
      }
      if (order.paymentMethod === 'gastropay') {
        order.paymentMethodText = 'Gastropay';
      }
      if (order.paymentMethod === 'online' && order.paymentTransaction) {
        order.paymentMethodText = order.paymentTransaction.paymentMethod;
      }
      if (order.paymentMethodText === 'paypal') {
        order.paymentMethodText = 'PayPal';
      }
      if (order.paymentMethodText === 'creditcard') {
        order.paymentMethodText = 'Kreditkarte';
      }

      order.typeText = '';
      if (order.type === 'delivery') {
        order.typeText = 'Lieferung';
      }
      if (order.type === 'takeaway') {
        order.typeText = 'Abholung';
      }

      order.statusText = '';
      if (order.type === 'pending') {
        order.statusText = 'Ausstehend';
      }
      if (order.type === 'confirmed') {
        order.statusText = 'Fertig';
      }
      if (order.type === 'canceled') {
        order.statusText = 'Storniert';
      }

      // let date = moment(order.createdAt);
      // const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
      // const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);

      order.dateFormatted = moment(order.createdAt).format('DD.MM.YYYY');
      order.timeFormatted = moment(order.createdAt).format('H:mm');

      if (order.status === 'pending' && !order.deliveryTime && order.deliveryTime !== 0) {
        this.pendingOrders.push(order);
      }
      if (order.status === 'pending' && (order.deliveryTime || order.deliveryTime === 0)) {
        this.inprogressOrders.push(order);
      }
      if (order.status === 'confirmed' || order.status === 'canceled') {
        this.finishedOrders.push(order);
      }
      this.allOrders.push(order);
      this.pushedOrders.push(order.id);
    }
  }
  */

  /*
    exportArrayToCsv() {
      this.csvAllOrders = [];

      this.allOrders.forEach((row: any) => {
        this.csvAllOrders.push({
          id: row.id,
          date: row.createdAt,
          status: row.status,
          type: row.type,
          paymentMethod: row.paymentMethod,
          paymentId: row.paymentId,
          subtotal: row.subtotal,
          deliveryFee: row.deliveryFee,
          commissionFee: row.commissionFee,
          balance: row.gastropayTransaction,
          firstName: row.firstName,
          lastName: row.lastName,
          street: row.street,
          zipCode: row.zipCode,
          city: row.city,
          email: row.email,
          phone: row.phone,
        });
      }, this);
    }
    */
  /*
  arrayToCSV(objArray: any = []) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map(value => `"${value}"`)
        .join(',')}` + '\r\n';

    return array.reduce((str: any, next: any) => {
      str +=
        `${Object.values(next)
          .map(value => `"${value}"`)
          .join(',')}` + '\r\n';
      return str;
    }, str);
  }
  */

  filterDates(filterBy: string, event: any) {
    let filterDate = moment(event.value._d).format('YYYY-MM-DD 00:00:00');

    if (filterBy == 'to') {
      filterDate = moment(event.value._d).format('YYYY-MM-DD 23:59:59');
    }

    if (this.allOrdersCopy.length) {
      this.allOrders = [...this.allOrdersCopy];
      this.allOrdersCopy = [];
    }
    if (filterDate) {
      this.filterOrdersBy(filterBy, filterDate);
      this.getFilterStats();
    }
  }

  getFilterStats() {
    this.filterStats.orders.count = 0;
    this.filterStats.orders.total = 0;
    this.filterStats.orders.commission = 0;
    this.filterStats.orders.cash = 0;
    this.filterStats.orders.gastropay = 0;
    this.filterStats.orders.online = 0;
    this.filterStats.orders.tip = 0;

    this.allOrders.forEach((order: any) => {
      if (order.type != 'payout') {
        this.filterStats.orders.count++;
        this.filterStats.orders.total += this.returnPrice(order.value * (order.type == 'payment' ? 1 : -1));
        this.filterStats.orders.online += this.returnPrice(order.value * (order.type == 'payment' ? 1 : -1));
        this.filterStats.orders.commission += this.returnPrice(order.commission);
        this.filterStats.orders.tip += this.returnPrice(order.tip);
      }
    });
  }

  filterOrdersBy(filterBy: string = '', filterVal: string = '') {
    switch (filterBy) {
      case 'from':
        this.dates.from = moment(filterVal);
        this.filterFrom = filterVal;
        console.log('filterVal', filterVal);

        this.allOrdersCopy = [...this.allOrders];
        this.allOrders = this.allOrdersCopy.filter(function(order: any) {
          if (
            (order.createdAt >= this.filterFrom || this.filterFrom == '') &&
            (order.createdAt <= this.filterTo || this.filterTo == '')
          ) {
            return order;
          }
        }, this);
        this.allOrders = [...this.allOrders];
        if (this.table) {
          this.table.offset = 0;
        }

        break;

      case 'to':
        this.dates.to = filterVal;
        this.filterTo = filterVal;
        this.allOrdersCopy = [...this.allOrders];
        this.allOrders = this.allOrdersCopy.filter(function(order: any) {
          if (
            (order.createdAt >= this.filterFrom || this.filterFrom == '') &&
            (order.createdAt <= this.filterTo || this.filterTo == '')
          ) {
            return order;
          }
        }, this);
        this.allOrders = [...this.allOrders];
        if (this.table) {
          this.table.offset = 0;
        }
        break;

      default:
        this.allOrders = [...this.allOrdersCopy];
        this.allOrdersCopy = [];
        if (this.table) {
          this.table.offset = 0;
        }
        break;
    }
  }
  setDates(type: string) {
    if (type == 'today') {
      this.filterDates('from', { value: { _d: new Date() } });
      this.filterDates('to', { value: { _d: new Date() } });
    }
    if (type == 'yesterday') {
      let yesterday: Date = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      this.filterDates('from', { value: { _d: yesterday } });
      this.filterDates('to', { value: { _d: yesterday } });
    }
    if (type == 'thisWeek') {
      const date = new Date();
      this.filterDates('from', { value: { _d: this.getMonday(date) } });

      const date2 = new Date();
      this.filterDates('to', { value: { _d: this.getSunday(date2) } });
    }
    if (type == 'lastSeven') {
      let date = new Date();
      date.setDate(date.getDate() - 6);
      this.filterDates('from', { value: { _d: date } });
      date.setDate(date.getDate() + 6);
      this.filterDates('to', { value: { _d: date } });
    }
    if (type == 'lastWeek') {
      let date = new Date();
      date.setDate(date.getDate() - 7);
      this.filterDates('from', { value: { _d: this.getMonday(date) } });
      this.filterDates('to', { value: { _d: this.getSunday(date) } });
    }
    if (type == 'lastMonth') {
      let date = new Date();
      let startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      let endDate = new Date(date.getFullYear(), date.getMonth(), 1);
      endDate.setDate(endDate.getDate() - 1);

      this.filterDates('from', { value: { _d: startDate } });
      this.filterDates('to', { value: { _d: endDate } });
    }
    if (type == 'thisMonth') {
      let date = new Date();
      let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      endDate.setDate(endDate.getDate() - 1);

      this.filterDates('from', { value: { _d: startDate } });
      this.filterDates('to', { value: { _d: endDate } });
    }
  }
  getMonday(d: any) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  getSunday(d: any) {
    d = new Date(d);
    const s = moment(d).day(7);
    return s;
  }

  returnPrice(price: any) {
    if (typeof price == 'string') {
      return parseFloat(price.replace(',', '.'));
    } else {
      return price || 0;
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { SelectClientComponent } from './select-client.component';

const routes: Routes = [
  {
    path: 'select',
    component: SelectClientComponent,
    data: { title: extract('Eintrag auswählen | GastroDigital') }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SelectClientRoutingModule {}

// site-visit-tracking.service.ts

import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SiteVisitTrackingService {
  constructor(private router: Router, private http: HttpClient) {
    this.trackPageViews();
  }

  private trackPageViews() {
    const credentials = JSON.parse(localStorage.getItem('credentials'));
    if (
      credentials &&
      credentials.id &&
      credentials.memberSince &&
      this.checkMemberSinceWithin2Days(credentials.memberSince)
    ) {
      // console.log('Tracking service initialized');
      this.router.events.subscribe(event => {
        // console.log('Router event:', event);
        if (event instanceof NavigationEnd) {
          const url = event.urlAfterRedirects; // Get the URL of the visited page
          this.sendTrackingData(url, credentials.id).subscribe(
            () => console.log('Page visit tracked successfully'),
            error => console.error('Error tracking page visit:', error)
          );
        }
      });
    }
  }

  private sendTrackingData(url: string, memberId: any) {
    console.log('url', url);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .post<any>(
        environment.serverUrl + `public/user/track-page-visit`,
        { url, memberId },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  private checkMemberSinceWithin2Days(memberSince: any) {
    const memberSinceDate = new Date(memberSince);

    const currentDate = new Date();

    const timeDifference = currentDate.getTime() - memberSinceDate.getTime();

    const daysDifference = Math.floor(Math.abs(timeDifference / (1000 * 3600 * 24)));

    if (daysDifference <= 2) {
      return true;
    } else {
      return false;
    }
  }
}

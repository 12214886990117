<div *ngIf="reservationFormGroup" class="modal-header">
  <h4 class="modal-title" *ngIf="!reservation && !isWalkIn">Neue Reservierung anlegen</h4>
  <h4 class="modal-title" *ngIf="!reservation && isWalkIn">Neuen Walk-In anlegen</h4>
  <h4 class="modal-title" *ngIf="reservation">Reservierung bearbeiten</h4>
  <span class="version-toggle">
    <a *ngIf="!manualRes" (click)="manualRes = true; saveVersionSettings()">Neue Verweildauer</a>
    <a *ngIf="manualRes" (click)="manualRes = false; saveVersionSettings()">Alte Verweildauer</a>
  </span>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('')">
    <span class="close-btn" aria-hidden="true">&times;</span>
  </button>
</div>
<form *ngIf="reservationFormGroup" [formGroup]="reservationFormGroup">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <div class="section">
          <legend><span>Reservierungsdaten</span></legend>

          <div class="row">
            <div class="col-md-12" *ngIf="manualRes == 1">
              <div class="form-group">
                <label for="">Anzahl Gäste</label>
                <number-spinner
                  name="guestCount"
                  [min]="1"
                  [max]="1000"
                  [step]="1"
                  [precision]="1"
                  [inputDisabled]="false"
                  [defaultValue]="+reservationFormGroup.value.guestCount"
                  (whenChange)="guestCountChanged($event); changeResMenuOrder()"
                  ngbAutofocus
                  required
                ></number-spinner>
              </div>
            </div>
            <div class="col-md-7">
              <div class="form-group">
                <label for="">Datum</label>
                <div class="input-group">
                  <div class="input-group-prepend" (click)="dateFromPicker.open()">
                    <div class="input-group-text">
                      <i class="fas fa-calendar-alt"></i>
                    </div>
                  </div>
                  <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                  <input
                    class="form-control"
                    #dateFromInput
                    formControlName="date"
                    placeholder=""
                    type="text"
                    name="date"
                    [matDatepicker]="dateFromPicker"
                    (focus)="dateFromPicker.open()"
                    (dateChange)="
                      getTimes(); getTables(true); getAlerts(); changeRecurssionValue(); getShifts(); checkIsPastDate()
                    "
                    [(ngModel)]="currentDate"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="col-md-1">
              <div class="form-group">
                <label for="">&nbsp; &nbsp; </label>
                <span>
                  <i
                    *ngIf="!reservation && !isWalkIn"
                    (click)="setTime()"
                    class="fas fa-clock clock-icon"
                    matTooltip="Aktuelle Uhrzeit setzen"
                  ></i>
                </span>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for=""
                  >Uhrzeit &nbsp;
                  <i
                    (click)="setShowAllTimes()"
                    class="fas fa-lock"
                    [ngClass]="{ 'show-all-times': showAllTimes, 'open-times': !showAllTimes }"
                    matTooltip="Show all times"
                  ></i>
                </label>
                <ng-select
                  [searchable]="false"
                  [items]="newTimes"
                  [clearable]="false"
                  formControlName="time"
                  bindLabel="time"
                  bindValue="time"
                  (change)="getTables(true); compareStartEndTime(); setEndTime()"
                  class="ng-select-container"
                  required
                >
                  <ng-template class="option-select" ng-label-tmp let-item="item">
                    <div>{{ item.time }}</div>
                  </ng-template>
                  <ng-template
                    class="option-select"
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                    let-search="searchTerm"
                  >
                    <div>{{ item.time }}</div>
                    <div class="text-danger" *ngIf="item.isClosed" style="font-style:italic">Geschlossen</div>
                  </ng-template>
                </ng-select>
              </div>
            </div>

            <div class="col-md-12 text-danger" style="padding-bottom: 0.5rem;" *ngIf="isPastDate">
              Achtung! Das Datum liegt in der Vergangenheit!
            </div>
            <div class="col-md-12">
              <p *ngFor="let alert of alerts">
                <ngb-alert class="alert-res-book" type="warning" (close)="closeAlert(alert)"
                  ><strong>Hinweis:</strong>
                  <a *ngIf="alert.fileUrl" href="https://cdn.gastroguide.de/{{ alert.fileUrl }}" class="pdf-files"
                    ><i class="fas fa-file-download file-url-book"></i>
                  </a>
                  {{ alert.info }}
                </ngb-alert>
              </p>
            </div>
          </div>
          <div class="section stayTimeCustom" *ngIf="bookSettings && manualRes == 0">
            <legend><span>Verweildauer</span></legend>
            <div class="staytime-slider">
              <ng5-slider
                [options]="stayTimeOptions"
                formControlName="stayTime"
                [manualRefresh]="refreshSlider"
                (userChange)="sliderChange()"
              >
              </ng5-slider>
            </div>
          </div>

          <div class="row" *ngIf="manualRes == 1">
            <div class="col-md-7">
              <div class="form-group" *ngIf="bookSettings">
                <label for="">Verweildauer (Stunden)</label>
                <number-spinner
                  name="stayTime"
                  [min]="0.25"
                  [max]="1000"
                  [step]="0.25"
                  [precision]="2"
                  [inputDisabled]="false"
                  [defaultValue]="+reservationFormGroup.value.stayTime"
                  (whenChange)="stayTimeChanged($event); sliderChange('stayTime')"
                  required
                ></number-spinner>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <label for="">Ende (Uhrzeit)</label>
                <span class="clock-select-container">
                  <ng-select
                    [searchable]="false"
                    [items]="endTimes"
                    [clearable]="false"
                    formControlName="endTime"
                    bindLabel="time"
                    bindValue="time"
                    (change)="sliderChange('endTime')"
                    class="ng-select-container"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <div>{{ item.time }}</div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div>{{ item.time }}</div>
                      <!-- <div class="text-danger" *ngIf="item.isClosed" style="font-style:italic">Geschlossen</div> -->
                    </ng-template>
                  </ng-select>
                </span>
              </div>
            </div>
            <div class="alert alert-danger borderless" *ngIf="timeError" style="width: 100%">{{ timeError }}</div>
          </div>

          <div class="form-group">
            <button
              type="button"
              [ngClass]="confirmTime ? 'grey-color' : 'blue-color'"
              class="btn btn-lg btn-block"
              (click)="getTables()"
            >
              Verweildauer übernehmen
            </button>
          </div>
          <div class="form-group" *ngIf="bookSettings && bookSettings.includeShift !== 0">
            <label>Schicht wählen </label>
            <ng-select
              [searchable]="false"
              [items]="shifts"
              [clearable]="false"
              formControlName="shiftId"
              bindLabel="name"
              bindValue="id"
              (change)="changeShiftData($event); changeResMenuOrder()"
            >
            </ng-select>
          </div>
          <div class="form-group" *ngIf="reservationFormGroup.value.shiftId && resMenuOrder.length > 0">
            <div *ngFor="let order of resMenuOrder">
              <label>{{ order.question }}</label
              ><span class="form-desc" style="display:inline"> (Verfügbare Menge: {{ order.remainingAmount }})</span
              ><br />
              <span *ngFor="let answer of order.answers">
                <span class="col-md-3">
                  <label>{{ answer.answer }} </label> &nbsp;
                  <input
                    type="number"
                    min="0"
                    class="form-control col-md-2"
                    style="display:inline;"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="answer.orderValue"
                    (ngModelChange)="changeResMenuOrder()"
                  />
                </span>
              </span>
            </div>
            <div class="alert alert-danger borderless" *ngIf="resMenuError" style="width: 100%">{{ resMenuError }}</div>
          </div>
          <div class="form-group" *ngIf="manualRes == 0">
            <label for="">Anzahl Gäste</label>
            <number-spinner
              name="guestCount"
              [min]="1"
              [max]="1000"
              [step]="1"
              [precision]="1"
              [inputDisabled]="false"
              [defaultValue]="+reservationFormGroup.value.guestCount"
              (whenChange)="guestCountChanged($event); changeResMenuOrder()"
              ngbAutofocus
              required
            ></number-spinner>
          </div>
          <div class="form-group">
            <label for="">Status</label>
            <ng-select [searchable]="false" [clearable]="false" formControlName="status" (change)="onStatusChange()">
              <ng-option value="pending">Ausstehend</ng-option>
              <ng-option value="confirmed">Bestätigt</ng-option>
              <ng-option value="arrived" *ngIf="bookSettings && bookSettings.arrivedStatus">Angekommen</ng-option>
              <ng-option value="placed">Platziert</ng-option>
              <ng-option value="finished">Fertig</ng-option>
              <ng-option value="noShow">No-Show</ng-option>
              <ng-option value="canceled">Storniert</ng-option>
              <ng-option value="waiting" *ngIf="bookSettings && bookSettings.waitingStatus">Warteliste</ng-option>
              <ng-option value="blocked">Ausstehende Zahlung</ng-option>
              <ng-option *ngFor="let status of resStatusList" [value]="status.value">{{ status.label }}</ng-option>
            </ng-select>
            <small
              *ngIf="reservationFormGroup.value.needsPayment"
              class="text-info"
              style="padding-top: 10px;display: block;"
            >
              Wenn Sie "Reservierung benötigt Anzahlung" auswählen, wird der Status automatisch auf "Ausstehend" gesetzt
              und kann nicht geändert werden.
            </small>
          </div>
        </div>
        <div class="section" *ngIf="!isWalkIn" formGroupName="guestData">
          <legend class="mb-0"><span>Kontaktdaten</span></legend>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <span class="btn btn-light" (click)="showAdditionalGuestInfo()" style="cursor: pointer;">
              <i class="fas fa-plus-square fa-fw" *ngIf="!showAdditionalGuestInputs"></i>
              <i class="fas fa-minus-square fa-fw" *ngIf="showAdditionalGuestInputs"></i>
            </span>
          </div>
          <div class="row" *ngIf="showAdditionalGuestInputs">
            <div class="col-md-3 form-group">
              <label>Anrede</label>
              <ng-select
                [items]="arrayOfPrefixes"
                [clearable]="false"
                [searchable]="false"
                formControlName="form"
                bindLabel="form"
                bindValue="form"
              >
              </ng-select>
            </div>
            <div class="col-md-9 form-group">
              <label>Firmenname</label>
              <input class="form-control" formControlName="company" type="text" />
            </div>
            <div class="col-md-12 form-group">
              <label>Adresse</label>
              <input class="form-control" name="address" formControlName="address" type="text" value="" />
            </div>
            <div class="col-md-6 form-group">
              <label>PLZ</label>
              <input class="form-control" name="zip" formControlName="zip" type="text" value="" maxlength="5" />
            </div>
            <div class="col-md-6 form-group">
              <label>Ort</label>
              <input class="form-control" name="place" formControlName="place" type="text" value="" />
            </div>
            <div class="col-md-12 form-group">
              <label for="intolerance">
                Hinweis Zum Gast
              </label>
              <textarea
                class="form-control"
                rows="5"
                formControlName="notes"
                placeholder="Hinweis Zum Gast."
              ></textarea>
            </div>
            <div class="col-md-12 form-group">
              <label for="intolerance">
                Unverträglichkeiten
              </label>
              <ng-select
                multiple="true"
                [searchable]="false"
                [clearable]="false"
                formControlName="intolerance"
                placeholder="Wählen Sie Intoleranz"
              >
                <ng-option *ngFor="let intolerance of incompatibilities" value="{{ intolerance.id }}">
                  {{ intolerance.longName }}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 form-group">
              <label for="">Name</label>
              <input
                class="form-control"
                formControlName="name"
                type="text"
                [matAutocomplete]="nameAuto"
                autocomplete="nope"
              />
              <mat-autocomplete
                #nameAuto="matAutocomplete"
                (optionSelected)="selectedAutocomplete($event.option.value)"
              >
                <mat-option *ngFor="let guest of filteredGuests" [value]="guest">
                  <span class="title">{{ guest.name }} {{ guest.firstName ? guest.firstName : '' }}</span>
                  <br />
                  <small><i class="fas fa-envelope fa-fw"></i> {{ guest.email ? guest.email : '-' }}</small>
                  <br />
                  <small><i class="fas fa-phone fa-fw"></i> {{ guest.phone ? guest.phone : '-' }}</small>
                </mat-option>
              </mat-autocomplete>
            </div>
            <div class="col-md-6 form-group">
              <label>Vorname</label>
              <input class="form-control" formControlName="firstName" type="text" />
            </div>
          </div>
          <div class="form-group">
            <label for="">Telefonnummer</label>
            <input
              class="form-control"
              formControlName="phone"
              type="text"
              autocomplete="nope"
              [matAutocomplete]="phoneAuto"
            />
            <mat-autocomplete #phoneAuto="matAutocomplete" (optionSelected)="selectedAutocomplete($event.option.value)">
              <mat-option *ngFor="let guest of filteredGuests" [value]="guest">
                <span>{{ guest.name }}</span>
                <br />
                <small><i class="fas fa-phone fa-fw"></i> {{ guest.phone }}</small>
                <br />
                <small><i class="fas fa-envelope fa-fw"></i> {{ guest.email }}</small>
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="form-group">
            <label for="">E-Mail</label>
            <input
              class="form-control"
              formControlName="email"
              type="email"
              autocomplete="nope"
              [customErrors]="{ email: 'invalidEmail' }"
            />
          </div>
          <mat-slide-toggle
            formControlName="guestInform"
            [disabled]="
              !reservationFormGroup.get('guestData.email').valid || !reservationFormGroup.value.guestData.email.length
            "
          >
            Bestätigung per E-Mail senden
          </mat-slide-toggle>
          <mat-slide-toggle
            formControlName="guestInformSMS"
            [disabled]="
              !(reservationFormGroup.value.guestData && reservationFormGroup.value.guestData.phone) ||
              !reservationFormGroup.get('guestData.phone').valid ||
              !reservationFormGroup.value.guestData.phone.length
            "
            *ngIf="smsSettings && smsSettings.reservationActive"
          >
            Bestätigung per SMS senden
          </mat-slide-toggle>
        </div>

        <mat-slide-toggle
          formControlName="needsPayment"
          (change)="changeNeedsPayment($event)"
          *ngIf="!reservation"
          [disabled]="
            !reservationFormGroup.get('guestData.email').valid || !reservationFormGroup.value.guestData.email.length
          "
        >
          Reservierung benötigt Anzahlung
        </mat-slide-toggle>
        <mat-slide-toggle
          formControlName="sendCheckin"
          *ngIf="!reservation && reservationSettings?.requestCheckin"
          [disabled]="
            !reservationFormGroup.get('guestData.email').valid || !reservationFormGroup.value.guestData.email.length
          "
        >
          E-Mail für CoVid19 Datenerfassung verschicken
        </mat-slide-toggle>
        <mat-slide-toggle
          *ngIf="!reservation"
          formControlName="isRecurringReservation"
          (change)="selectRecurringReservation($event.checked)"
        >
          Als Dauerreservierung anlegen
        </mat-slide-toggle>

        <mat-slide-toggle
          *ngIf="reservation && reservation.reservedFor && reservation.recurrenceId"
          formControlName="changeAllRecurringReservation"
        >
          Alle wiederkehrenden Reservierungen ändern
        </mat-slide-toggle>

        <div class="section" formGroupName="recurrenceData" *ngIf="reservationFormGroup.value.isRecurringReservation">
          <legend><span>Dauerreservierung</span></legend>

          <div class="form-group">
            <label for="">Intervall der Wiederholung</label>
            <ng-select
              [searchable]="false"
              [clearable]="false"
              formControlName="repeatOption"
              [items]="recurrenceRepeatOptions"
              bindLabel="label"
              bindValue="value"
              placeholder="Wiederholung auswählen"
              (change)="recurringReservationChanged()"
            >
            </ng-select>
          </div>

          <div
            class="form-group row"
            *ngIf="
              reservationFormGroup.get('recurrenceData.repeatOption').value &&
              reservationFormGroup.get('recurrenceData.repeatOption').value !== 'daily'
            "
          >
            <div class="col-md-12" *ngIf="reservationFormGroup.get('recurrenceData.repeatOption').value === 'weekly'">
              <label for="">Alle</label>
            </div>
            <div
              class="col-md-12"
              *ngIf="reservationFormGroup.get('recurrenceData.repeatOption').value === 'monthly_date'"
            >
              <label for="">An jedem x. Tag eines Monats</label>
            </div>
            <div
              class="col-md-12"
              *ngIf="reservationFormGroup.get('recurrenceData.repeatOption').value === 'monthly_weekday'"
            >
              <label for="">Jeder x. Wochentag eines Monats</label>
            </div>
            <div class="col-md-5">
              <number-spinner
                name="repeatEvery"
                [min]="1"
                [max]="maxRecurrenceValue"
                [step]="1"
                [precision]="1"
                [inputDisabled]="false"
                [defaultValue]="1"
                (whenChange)="repeatEveryChanged($event)"
              ></number-spinner>
            </div>
            <div class="col-md-7" *ngIf="reservationFormGroup.get('recurrenceData.repeatOption').value === 'weekly'">
              Wochen
            </div>
          </div>

          <div class="form-group" *ngIf="reservationFormGroup.get('recurrenceData.repeatOption').value === 'weekly'">
            <label for="">Tage, an denen die Reservierung wiederholt wird</label>
            <br />
            <span *ngFor="let weekday of weekdays" class="checkbox">
              <label class="days-checkbox">
                <input
                  type="checkbox"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="weekday.isChecked"
                  (change)="weekday.isChecked = $event.target.checked; changeRecurssionValue()"
                />&nbsp; {{ weekday.day }}&nbsp;
              </label>
            </span>
          </div>

          <div
            class="form-group"
            *ngIf="reservationFormGroup.get('recurrenceData.repeatOption').value === 'monthly_weekday'"
          >
            <mat-radio-group name="monthlyWeekday" (change)="savemonthlyWeekday($event)">
              <mat-radio-button
                class="days-checkbox"
                *ngFor="let option of weekdaysMonthly"
                [checked]="option.isChecked"
                value="{{ option.value }}"
              >
                &nbsp; {{ option.day }} &nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <!-- <div class="form-group">
            <label for="">Ende der Wiederholung</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="recurrenceEndPicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <mat-datepicker #recurrenceEndPicker (closed)="recurrenceEndInput.blur()"></mat-datepicker>
              <input
                class="form-control"
                #recurrenceEndInput
                formControlName="recurrenceEnd"
                placeholder=""
                type="text"
                name="recurrenceEnd"
                [matDatepicker]="recurrenceEndPicker"
                (focus)="recurrenceEndPicker.open()"
                required
              />
            </div>
          </div> -->

          <div class="form-group">
            <label *ngIf="reservationFormGroup.get('recurrenceData.repeatOption').value === 'weekly'" for=""
              >Laufzeit: x Wochen</label
            >
            <label *ngIf="reservationFormGroup.get('recurrenceData.repeatOption').value === 'daily'" for=""
              >Laufzeit: x Tage</label
            >
            <label
              *ngIf="
                reservationFormGroup.get('recurrenceData.repeatOption').value === 'monthly_date' ||
                reservationFormGroup.get('recurrenceData.repeatOption').value === 'monthly_weekday'
              "
              for=""
              >Laufzeit: x Monate</label
            >
            <number-spinner
              name="ahead"
              [min]="1"
              [max]="1000"
              [step]="1"
              [precision]="1"
              [inputDisabled]="false"
              [defaultValue]="reservationFormGroup.get('recurrenceData.repeatOption').value === 'weekly' ? 2 : 1"
              (whenChange)="aheadChanged($event)"
            ></number-spinner>
          </div>
          <span *ngIf="lastReservationDate"> <label>Letzte Reservierung:</label> {{ lastReservationDate }} </span>
        </div>

        <div
          class="form-group"
          style="margin-top: 10px;"
          *ngIf="reservationFormGroup.value.needsPayment && !reservation"
        >
          <label for="">Anzahlungsvorlage</label>
          <ng-select
            [clearable]="true"
            formControlName="paymentTemplate"
            [items]="paymentTemplates"
            bindLabel="name"
            bindValue="id"
            placeholder="Anzahlungsvorlage auswählen"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              {{ item.name }}<br />
              <small
                >Min. {{ item.minGuests }} Personen • {{ item.priceOption === 'guest' ? 'Pro Gast' : 'Pauschal' }} •
                {{ item.price | number: '1.2' }} EUR</small
              >
            </ng-template>
          </ng-select>
        </div>
        <div class="form-group" *ngIf="reservation" style="margin-top: 20px;">
          <label for="">Hinweis vom Gast</label>
          <div *ngIf="reservation.msg">{{ reservation.msg }}</div>
          <div *ngIf="!reservation.msg" class="disabled">- Keine Hinweise hinterlegt -</div>
        </div>

        <div class="section">
          <legend><span>Notizen (nur für Sie)</span></legend>
          <textarea
            class="form-control text-area-section"
            rows="5"
            formControlName="notes"
            placeholder="Hinterlegen Sie Notizen zu dieser Reservierung. Nur für Sie sichtbar."
          ></textarea>
        </div>

        <div class="section form-group" *ngIf="reservation && reservation.ticketOrder">
          <legend><span>Gebuchte Leistungen</span></legend>

          <!-- Ticket Name -->
          <label>{{ reservation.ticketOrder.tickets[0].ticket.name }}</label>

          <!-- Loop through tickets and display subcategory and options -->
          <div *ngFor="let ticket of reservation.ticketOrder.tickets; let i = index">
            <!-- Subcategory Name -->
            <p>{{ i + 1 }}. {{ ticket.subCategory.name }}</p>
          </div>

          <div *ngFor="let ticket of reservation.ticketOrder.tickets; let i = index">
            <!-- Option Data -->
            <div *ngIf="ticket.optionData">
              <label>Add Ons:</label>
              <ul>
                <li *ngFor="let option of parseOptionData(ticket.optionData)">
                  {{ option.name }} - {{ option.count }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <mat-autocomplete #emailAuto="matAutocomplete" [displayWith]="displayFnEmail">
        <mat-option *ngFor="let guest of filteredGuests" [value]="guest">
          <span>{{ guest.name }}</span>
          <br />
          <small><i class="fas fa-phone fa-fw"></i> {{ guest.phone }}</small>
          <br />
          <small><i class="fas fa-envelope fa-fw"></i> {{ guest.email }}</small>
        </mat-option>
      </mat-autocomplete>
      <div class="col-md-6">
        <div class="section">
          <legend><span>Tischverwaltung</span></legend>
          <div class="loading" *ngIf="!rooms">
            <span class="text-danger" *ngIf="!reservationFormGroup.value.time; else timeSelected">
              Bitte eine Uhrzeit auswählen.
            </span>
            <ng-template #timeSelected>
              <i class="fa fa-spinner fa-spin"></i>
              Räume und Tische werden geladen
            </ng-template>
          </div>
          <div class="room" *ngFor="let room of rooms">
            <ng-template [ngIf]="room.isActive || reservationFormGroup.value.includeLockedRooms">
              <div>
                <h6>{{ room.name }}</h6>
                <span *ngIf="!room.isActive" class="text-danger">(Gesperrt)</span>
                <img
                  *ngIf="!(room.freeTablesCounter === 0 && !reservationFormGroup.value.includeUnavailableTimes)"
                  style="height:38px;width:30px;"
                  (click)="selectAllTables(room)"
                  src="assets/icons/check-all.svg"
                />
              </div>
              <div class="tables">
                <span
                  class="text-danger"
                  *ngIf="room.freeTablesCounter === 0 && !reservationFormGroup.value.includeUnavailableTimes"
                >
                  Es wurden keine freien/passenden Tische und Kombinationen gefunden.
                </span>
                <ng-template ngFor let-table [ngForOf]="room.tables">
                  <button
                    type="button"
                    class="btn btn-light"
                    (click)="selectTable(table)"
                    [disabled]="checkDisabled(table)"
                    [hidden]="
                      (!table.isFree && !reservationFormGroup.value.includeUnavailableTimes) || table.isCombined
                    "
                    [class.selected]="checkSelected(table)"
                    [class.notfree]="!table.isFree"
                    [class.nospace]="table.seats < reservationFormGroup.value.guestCount"
                    *ngIf="table.seats && !table.isRoom"
                  >
                    <i class="fas fa-puzzle-piece" *ngIf="table.isCombined" matTooltip="Tischkombination"></i>
                    {{ table.name }} [{{ table.seats }}]
                  </button>
                  <span class="text-danger" *ngIf="!table.seats && reservationFormGroup.value.includeUnavailableTimes">
                    Keine Tische gefunden
                  </span>
                </ng-template>
              </div>
            </ng-template>
          </div>

          <div class="tables-info" *ngIf="selectedTables.length">
            <span
              [class.text-success]="usedSeatCount - reservationFormGroup.value.guestCount === 0"
              [class.text-warning]="usedSeatCount - reservationFormGroup.value.guestCount > 0"
              *ngIf="usedSeatCount - reservationFormGroup.value.guestCount >= 0"
            >
              <i class="fas fa-check-circle fa-fw"></i>
              Es {{ usedSeatCount - reservationFormGroup.value.guestCount > 1 ? 'bleiben' : 'bleibt' }}
              {{
                usedSeatCount - reservationFormGroup.value.guestCount === 0
                  ? 'kein'
                  : usedSeatCount - reservationFormGroup.value.guestCount
              }}
              {{ usedSeatCount - reservationFormGroup.value.guestCount > 1 ? 'Plätze' : 'Platz' }} unbelegt
            </span>

            <span class="text-danger" *ngIf="usedSeatCount - reservationFormGroup.value.guestCount < 0">
              <i class="fas fa-exclamation-triangle fa-fw"></i>
              Es {{ usedSeatCount - reservationFormGroup.value.guestCount === -1 ? 'fehlt' : 'fehlen' }}
              {{ (usedSeatCount - reservationFormGroup.value.guestCount) * -1 }}
              {{ usedSeatCount - reservationFormGroup.value.guestCount === -1 ? 'Platz' : 'Plätze' }}
            </span>
          </div>

          <div *ngIf="rooms">
            <mat-slide-toggle formControlName="includeLockedRooms" (change)="showAllRoomsToggle($event)"
              >Alle Räume anzeigen</mat-slide-toggle
            >
            <mat-slide-toggle formControlName="includeUnavailableTimes" (change)="showAllTablesToggle($event)"
              >Alle Tische anzeigen</mat-slide-toggle
            >
            <mat-slide-toggle
              formControlName="lockTable"
              matTooltip="Wenn der Tisch gesperrt ist, kann dieser nicht mehr in der Reservierungsübersicht geändert werden."
              style="margin-top: 10px;"
              >Tisch fest (Sperren)</mat-slide-toggle
            >
          </div>
        </div>
        <div class="section" *ngIf="reservation && reservation.files && reservation.files.length > 0">
          <legend><span>Hochgeladene Dokumente</span></legend>
          <div class="container">
            <div class="row" *ngFor="let file of reservation.files; let i = index">
              <div [ngClass]="canDeleteFile ? 'col-md-7' : 'col-md-8'" style="overflow: hidden; white-space: nowrap;">
                {{ i + 1 }}. {{ file.fileName || 'Unbekannter Dateiname' }}
              </div>
              <div class="col-md-3">
                {{ (file.createdAt | date) || 'Kein Datum' }}
              </div>
              <div [ngClass]="canDeleteFile ? 'col-md-2' : 'col-md-1'">
                <a class="p-1" href="https://cdn.gastroguide.de/{{ file.fileUrl | urlFix }}">
                  <i class="fa fa-download" aria-hidden="true"></i>
                </a>

                <div *ngIf="canDeleteFile; then thenTemplate1; else elseTemplate2"></div>

                <ng-template #thenTemplate1>
                  <button class="btn p-1 float-right" (click)="deleteFile(file, reservation)">
                    <i class="fa fa-trash delete-file" aria-hidden="true"></i>
                  </button>
                </ng-template>

                <ng-template #elseTemplate2>
                  <button
                    class="btn p-1 float-right"
                    *ngxPermissionsOnly="['admin']"
                    (click)="deleteFile(file, reservation)"
                  >
                    <i class="fa fa-trash delete-file" aria-hidden="true"></i>
                  </button>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <legend><span>Dateien hochladen</span></legend>
          <div class="form-group">
            <div>
              <input
                class="file-input form-control"
                style="display:none"
                type="file"
                name="files"
                multiple
                #fileInput
                (change)="onFilesSelected(fileInput.files)"
              />
              <div class="input-group">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-secondary" (click)="fileInput.click()">Dateien auswählen</button>
                </div>
                <input
                  class="form-control"
                  placeholder="Keine ausgewählten Dateien"
                  [value]="files && files.length > 0 ? 'Ausgewählte ' + files.length + ' Dateien' : ''"
                  readonly
                />
                <div class="input-group-append" *ngIf="files && files.length > 0">
                  <button type="button" class="btn btn-danger" (click)="removeFiles()">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div class="mt-1" *ngFor="let file of files">
                <div class="input-group">
                  <input class="form-control" placeholder="Keine ausgewählten Dateien" [value]="file.name" readonly />
                  <div class="input-group-append" *ngIf="files && files.length > 0">
                    <button type="button" class="btn btn-warning" (click)="removeFile(file)">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section" *ngIf="bookSettings?.requestStaff && clientStaff.length && !isWalkIn">
          <legend><span>Mitarbeiter</span></legend>
          <ng-select
            [searchable]="true"
            [clearable]="false"
            formControlName="staffId"
            placeholder="-- Mitarbeiter auswählen --"
            [items]="clientStaff"
            bindValue="id"
            bindLabel="name"
            required
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <span class="badge badge-dark">{{ item.shorthand | uppercase }}</span> {{ item.name }}
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
              <span class="badge badge-dark">{{ item.shorthand | uppercase }}</span> {{ item.name }}
            </ng-template>
          </ng-select>
        </div>
        <!-- <div class="section">
          <legend><span>Tags</span></legend>
          <div class="form-group">
            <ng-select
              [items]="reservationTags"
              [addTag]="false"
              [hideSelected]="true"
              multiple="true"
              bindLabel="label"
              formControlName="selectedTags"
              placeholder="-- Tags auswählen --"
            >
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-label">
                  <i class="fas fa-circle" [style.color]="item.color"></i> {{ item.label }}
                </span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </ng-template>

              <ng-template ng-option-tmp let-item="item">
                <i class="fas fa-circle" [style.color]="item.color"></i> {{ item.label }}
              </ng-template>
            </ng-select>
          </div>
        </div> -->

        <div class="section">
          <legend><span>Tags</span></legend>
          <div class="form-group">
            <ng-select [searchable]="true" [clearable]="true" (change)="addTag($event)" formControlName="selectedTags">
              <ng-option *ngFor="let tag of reservationTags; index as i" [value]="tag">
                {{ tag.label }}
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="tags-list" [ngStyle]="{ 'margin-bottom': tags && tags.length ? '8px' : 0 }">
          <div class="tag" *ngFor="let tag of tags; index as i">
            {{ tag.label }}&nbsp;
            <button class="btn btn-link" (click)="deleteTag(tag, i)">
              <i class="far fa-trash-alt cancel-icon"></i>
            </button>
          </div>
        </div>

        <div class="section" *ngIf="reservation && reservation.log && reservation.log.length">
          <legend><span>Letzte Aktivitäten</span></legend>
          <div class="log">
            <ng-scrollbar [trackX]="false" [shown]="'hover'" [compact]="true">
              <div class="timed-activities compact" *ngFor="let log of reservation.log">
                <div class="timed-activity">
                  <div class="ta-date">
                    <span>{{ log.date | dfnsFormat: 'Do MMMM, YYYY' }}</span>
                  </div>
                  <div class="ta-record-w">
                    <div class="ta-record" *ngFor="let entry of log.entries">
                      <div class="ta-timestamp">
                        <strong>{{ entry.createdAt | dfnsFormat: 'HH:mm' }}</strong> Uhr
                        <ng-template [ngIf]="entry.staff">von {{ entry.staff.shorthand }}</ng-template>
                        <ng-template [ngIf]="entry.staffId == -1">von Guest</ng-template>
                      </div>
                      <div
                        class="ta-activity"
                        [innerHTML]="
                          transformLogText(
                            entry.action,
                            entry.oldValue,
                            entry.newValue,
                            entry.details,
                            entry.user,
                            entry.browserDetails
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-primary btn-lg btn-block"
      [disabled]="disableBtn"
      (click)="createReservation()"
      *ngIf="!reservation"
    >
      Reservierung erstellen
    </button>
    <button
      class="btn btn-primary btn-lg btn-block"
      [disabled]="disableBtn"
      (click)="saveReservation()"
      *ngIf="reservation"
    >
      Reservierung speichern
    </button>
  </div>
</form>

import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Room, RoomTable } from '../../reservation-settings/reservation-settings.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservationService } from '@app/reservation/reservation.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/takeUntil';
import { CredentialsService } from '@app/core';

@Component({
  selector: 'app-add-table',
  templateUrl: './add-table.component.html',
  styleUrls: ['./add-table.component.scss']
})
export class AddTableComponent implements OnInit, OnDestroy {
  @Input() public room: Room;
  @Input() public isCombined = false;
  @Input() public editTableData: any; // only if editing
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  tableFormGroup: FormGroup;
  error: string;
  addObservable: any;
  editObservable: any;
  showGpaySettings = false;
  previewTableWidth = 160;
  previewTableHeight = 80;
  chairPositions: any[] = [{ position: 1, top: 27, left: -10 }];
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private reservationService: ReservationService,
    public formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public credentialsService: CredentialsService
  ) {}

  ngOnInit() {
    this.tableFormGroup = this.formBuilder.group({
      roomId: [this.room.id],
      name: ['', Validators.required],
      isActive: [true],
      selfOrdering: [false],
      instantPayment: [false],
      bringService: [true],
      isCombined: [false],
      seats: [1, Validators.required],
      minOccupancy: [1, Validators.required],
      type: ['Auto', Validators.required],
      dimensions: [{ width: 160, height: 80, borderRadius: 5 }, Validators.required],
      position: [{ left: 0, top: 0 }, Validators.required],
      combinedTables: [''],
      POSName: ['']
    });

    this.tableFormGroup
      .get('isCombined')
      .valueChanges.takeUntil(this.ngUnsubscribe)
      .subscribe(isCombined => {
        if (isCombined) {
          this.tableFormGroup.get('combinedTables').setValidators([Validators.required, Validators.minLength(2)]);
        } else {
          this.tableFormGroup.get('combinedTables').clearValidators();
          this.tableFormGroup.get('combinedTables').updateValueAndValidity();
        }
      });

    if (this.isCombined) {
      this.tableFormGroup.get('isCombined').setValue(true);
    }

    if (this.editTableData) {
      this.tableFormGroup.addControl('id', new FormControl(this.editTableData.id, Validators.required));
      this.tableFormGroup.get('name').setValue(this.editTableData.name);
      this.tableFormGroup.get('minOccupancy').setValue(this.editTableData.minOccupancy);
      this.tableFormGroup.get('seats').setValue(this.editTableData.seats);
      this.tableFormGroup.get('isActive').setValue(this.editTableData.isActive);
      this.tableFormGroup.get('selfOrdering').setValue(this.editTableData.selfOrdering);
      this.tableFormGroup.get('instantPayment').setValue(this.editTableData.instantPayment);
      this.tableFormGroup.get('bringService').setValue(this.editTableData.bringService);
      this.tableFormGroup.get('isCombined').setValue(this.editTableData.isCombined);
      this.tableFormGroup.get('combinedTables').setValue(this.editTableData.combinedTables);
      if (!this.editTableData.type || this.editTableData.type == 'Auto') {
        this.editTableData.type = 'null';
      }
      this.tableFormGroup.get('type').setValue(this.editTableData.type);
      this.positionChairsAroundTable(this.editTableData.seats);
      this.tableFormGroup.get('POSName').setValue(this.editTableData.POSName);
    }
    if (this.tableFormGroup.value.isCombined && this.room.tables) {
      this.room.tables = this.room.tables.filter(item => !item.isCombined);
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  addTable() {
    if (!this.tableFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    const addData = this.tableFormGroup.value;
    if (addData.combinedTables.length) {
      addData.combinedTables = addData.combinedTables.map((table: any) => table.id);
    }
    this.error = null;
    this.addObservable = this.reservationService
      .addTable(addData)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          console.log('err', err);
          this.error = err.error.msg;
        }
      );
  }

  saveTable() {
    if (!this.tableFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    const editData = this.tableFormGroup.value;
    if (editData.combinedTables) {
      editData.combinedTables = editData.combinedTables.map((table: any) => table.id);
    }
    this.error = null;
    this.editObservable = this.reservationService
      .editTable(this.tableFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          console.log('err', err);
          this.error = err.error.msg;
        }
      );
  }

  seatsAmountChanged(event: any) {
    this.tableFormGroup.patchValue({ seats: event });
    // this.tableFormGroup.value.seats = event;
    this.positionChairsAroundTable(event);
  }

  minOccupancyChanged(event: any) {
    this.tableFormGroup.patchValue({ minOccupancy: event });
    // this.tableFormGroup.value.minOccupancy = event;
  }

  changeCombined(event: any) {
    if (!event.checked) {
      this.tableFormGroup.get('combinedTables').patchValue([]);
    }
  }

  toggleGpaySettings() {
    this.showGpaySettings = !this.showGpaySettings;
  }

  selectTableType(event: any) {
    const type = event.target.value;
    let borderRadius = 5;
    this.tableFormGroup.get('type').setValue(type);
    switch (type) {
      case 'null': {
        this.previewTableWidth = 160;
        this.previewTableHeight = 80;
        break;
      }
      case 'Rectangle': {
        this.previewTableWidth = 160;
        this.previewTableHeight = 80;
        break;
      }
      case 'Round': {
        this.previewTableWidth = 80;
        this.previewTableHeight = 80;
        borderRadius = 99999;
        break;
      }
      case 'Square': {
        this.previewTableWidth = 80;
        this.previewTableHeight = 80;
        break;
      }
    }
    const dimensions = { width: this.previewTableWidth, height: this.previewTableHeight, borderRadius: borderRadius };
    this.tableFormGroup.get('dimensions').setValue(dimensions);
    const chairs = this.tableFormGroup.get('seats').value;
    this.positionChairsAroundTable(chairs);
  }

  positionChairsAroundTable(chairs: number) {
    let chairArray = [];
    const type = this.tableFormGroup.get('type').value;
    if (chairs <= 4) {
      const chair1 = { position: 1, left: -13, top: (this.previewTableHeight - 26) / 2 };
      const chair2 = { position: 2, left: this.previewTableWidth - 13, top: (this.previewTableHeight - 26) / 2 };
      const chair3 = { position: 3, left: (this.previewTableWidth - 26) / 2, top: -13 };
      const chair4 = { position: 4, left: (this.previewTableWidth - 26) / 2, top: this.previewTableHeight - 13 };
      chairArray = [chair1, chair2, chair3, chair4];
      this.chairPositions = chairArray.slice(0, chairs);
    } else {
      const chair1 = { position: 1, left: -13, top: (this.previewTableHeight - 26) / 2 };
      const chair2 = { position: 2, left: this.previewTableWidth - 13, top: (this.previewTableHeight - 26) / 2 };
      chairArray = [chair1, chair2];
      const chairsAtTableSide = Math.ceil((chairs - 2) / 2);
      const positionSize = this.previewTableWidth / chairsAtTableSide;
      let index = 0;
      for (let i = 2; i < chairs; i++) {
        // add table on top
        if (i % 2 === 0) {
          const chair = { position: i + 1, left: positionSize * index + (positionSize / 2 - 13), top: -13 };
          chairArray.push(chair);
        } else {
          const chair = {
            position: i + 1,
            left: positionSize * index + (positionSize / 2 - 13),
            top: this.previewTableHeight - 13
          };
          chairArray.push(chair);
          index++;
        }
      }
      this.chairPositions = chairArray;
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectClientRoutingModule } from './select-client-routing.module';
import { SelectClientComponent } from './select-client.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material';
import { CommonOuterModule } from '@app/shared/common-outer.module';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    SelectClientRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    CommonOuterModule
  ],
  declarations: [SelectClientComponent]
})
export class SelectClientModule {}

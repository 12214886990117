import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ReservationService } from '@app/reservation/reservation.service';
import moment from 'moment';
import { Subscription } from 'rxjs';
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-generate-pdf-reservation',
  templateUrl: './generate-pdf-reservation.component.html',
  styleUrls: ['./generate-pdf-reservation.component.scss']
})
export class GeneratePdfReservationComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  intolerance: any[];
  searchReservationGroup: FormGroup;
  reservation: any;
  errorMessage: string;
  constructor(private formBuilder: FormBuilder, private reservationService: ReservationService) {}

  ngOnInit() {
    this.searchReservationGroup = this.formBuilder.group({
      reservationNumber: [null, Validators.required],
      roomNumber: [null, Validators.required]
    });

    this.subscriptions.push(
      this.reservationService.getIncompatibilities().subscribe(response => {
        this.intolerance = response;
      })
    );
  }

  findReservation() {
    this.errorMessage = null;
    if (this.searchReservationGroup.valid) {
      const reservationNumber = this.searchReservationGroup.get('reservationNumber').value;
      const roomNumber = this.searchReservationGroup.get('roomNumber').value;
      this.subscriptions.push(
        this.reservationService.getSpecialHotelReservation(reservationNumber, roomNumber).subscribe(
          res => {
            this.reservation = res;
          },
          err => {
            this.errorMessage = `Ihre Suche ergab leider keine Treffer.`;
          }
        )
      );
    } else {
      this.reservation = null;
      this.errorMessage = 'Bitte geben Sie eine Reservierungs-Nr. und Zimmer-Nr. ein.';
    }
  }

  createPDF() {
    const { guest, reservation, reservations, tablesAndRooms } = this.reservation;
    const doc = new jsPDF('l', 'mm', 'a4');
    // doc.setFontSize(22);
    // doc.text(`Reservation No: ${reservation.bookingNumber}`, 60, 20);
    // doc.setFontSize(16);
    // doc.text(`Total persons: ${reservations[0].peopleCount}`, 20, 50);
    // doc.text(`Adults: ${reservations[0].peopleCount}`, 70, 50);
    // doc.text(`Kids: ${reservations[0].children}`, 100, 50);
    // doc.text(`High chairs: ${reservations[0].highChairs}`, 130, 50);
    // doc.text(`Reservation date: ${moment(reservations[0].createdAt).format('DD.MM.YYYY')}`, 20, 60);
    // doc.text(`Room number: ${reservation.roomNo}`, 20, 70);
    // doc.text(`Arrival date: ${moment(reservation.arrivalDate).format('DD.MM.YYYY')}`, 20, 80);
    // doc.text(`Departure date: ${moment(reservation.departureDate).format('DD.MM.YYYY')}`, 100, 80);
    // doc.text(`Board: ${this.getBoardType(reservation.board)}`, 20, 90);
    // doc.text(`Intolerance: ${this.getIntolerance(guest.intolerance)}`, 20, 100);
    // doc.text(`Reservierungen für den 02.06.2020`, 20, 40);

    doc.autoTable({
      bodyStyles: {
        valign: 'top'
      },
      styles: {
        overflow: 'linebreak'
      },
      // startY: 110,
      headStyles: { fillColor: [0, 0, 0] },
      body: this.getReservationsForEachDay(reservations, tablesAndRooms),
      columns: [
        { header: 'Datum', dataKey: 'date' },
        { header: 'Uhrzeit', dataKey: 'time' },
        { header: 'Personen', dataKey: 'persons' },
        { header: 'Kinder', dataKey: 'kids' },
        { header: 'Hochstühle', dataKey: 'highChairs' },
        { header: 'Restaurant', dataKey: 'restaurant' },
        { header: 'Tisch', dataKey: 'table' }
      ]
    });
    doc.save(`Reservirung für ${guest.name}.pdf`);
  }

  getBoardType(board: string): string {
    let boardType: string = '';
    switch (board) {
      case 'AI':
        boardType = 'All Inclusive';
        break;
      case 'HP': {
        boardType = 'Halb Pension';
        break;
      }
    }
    return boardType;
  }

  getIntolerance(intolerance: string): string {
    const intolerances = JSON.parse(intolerance);
    let result = '';
    for (let i = 0; i < intolerances.length; i++) {
      const element = intolerances[i];
      const intoleranceRes = this.intolerance.find(el => {
        return element == el.id;
      });
      result += `${intoleranceRes.longName}; `;
    }
    return result;
  }

  getReservationsForEachDay(reservations: any[], tablesAndRooms: any[]) {
    const reservationArray = [];

    for (let i = 0; i < reservations.length; i++) {
      const element = reservations[i];
      const tempObject = { date: '', time: '', persons: '', kids: '', highChairs: '', restaurant: '', table: '' };
      tempObject.date = moment(element.reservedFor).format('DD.MM.YYYY');
      tempObject.time = moment(element.reservedFor).format('HH:mm');
      tempObject.persons = element.peopleCount;
      tempObject.kids = element.children;
      tempObject.highChairs = element.highChairs;
      const resAndTable = this.getRestaurantAndTable(element.isTablePlan, tablesAndRooms);
      tempObject.restaurant = resAndTable.restaurantName;
      tempObject.table = resAndTable.tableName;
      reservationArray.push(tempObject);
    }

    return reservationArray;
  }

  getRestaurantAndTable(table: string, tablesAndRooms: any): any {
    let restaurantName = '';
    let tableName = '';
    const result = tablesAndRooms.find((data: any) => {
      return table == data.id;
    });
    restaurantName = result.room.name;
    tableName = result.name;
    return { restaurantName, tableName };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}

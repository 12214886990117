<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">{{ 'ResSettings.ReservationSetting.SettingResSystem' | translate }}</h6>
        <!-- <ngb-alert type="warning" (close)="closeAlert(alert)" *ngIf="alert">
                {{ alert }}
            </ngb-alert> -->
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group">
              <div
                class="system-setting-div"
                [ngClass]="{ 'toggle-success': generalSettings.isActive, 'toggle-danger': !generalSettings.isActive }"
              >
                <mat-slide-toggle
                  name="isActive"
                  (change)="changedSettings('toggleChanged')"
                  [(ngModel)]="generalSettings.isActive"
                >
                  {{ 'ResSettings.ReservationSetting.OnlineResSystem' | translate }}
                  <div class="info">
                    {{ 'ResSettings.ReservationSetting.GuestMakeReservation' | translate }}
                  </div>
                </mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row" *ngIf="!generalSettings.isActive">
                <div class="col-sm-12">
                <div class="form-group">
                    <label for=""
                    >Falls das Online-Reservierungssystem deaktiviert ist, wird dem Benutzer die folgende Nachricht
                    angezeigt</label
                    >
                    <input
                    class="form-control"
                    placeholder=""
                    type="text"
                    name="isActiveAlert"
                    (change)="changedSettings()"
                    [(ngModel)]="generalSettings.reservationInactiveText"
                    />
                </div>
                </div>
            </div> -->
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="selectedTabChange($event)" [selectedIndex]="tab">
          <mat-tab label="{{ 'ResSettings.ResAddShift.Generally' | translate }}">
            <div class="element-box">
              <h5 class="form-header">{{ 'ResSettings.ReservationSetting.Generally' | translate }}</h5>
              <div class="form-desc">{{ 'ResSettings.ReservationSetting.FindImportantSetting' | translate }}</div>

              <div class="row" *ngIf="currentDate < showSMSServiceConfirmation">
                <div class="col-sm-12" style="padding: 1%;">
                  <div class="sms-service-confirmation">
                    <h5 class="element-header">
                      {{ 'ResSettings.ReservationSetting.SmsSendingFree' | translate }}
                      {{ 'ResSettings.ReservationSetting.InfoBlog' | translate }}
                    </h5>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <mat-slide-toggle
                            name="smsConfirmationSettings"
                            (change)="saveSMSSettings()"
                            [(ngModel)]="smsConfirmationSettings.sendSMS"
                          >
                            {{ 'ResSettings.ReservationSetting.ContinueSmsService' | translate }}
                          </mat-slide-toggle>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ngb-alert type="warning" (close)="closeAlert(alert)" *ngIf="alert">
                {{ alert }}
              </ngb-alert>
              <!-- <div class="row">
                        <div class="col-sm-12">
                        <div class="form-group">
                            <mat-slide-toggle
                            name="isActive"
                            (change)="changedSettings()"
                            [(ngModel)]="generalSettings.isActive"
                            >
                            Reservierungssystem aktiv
                            <div class="info">Kann der Gast selbst online reservieren?</div>
                            </mat-slide-toggle>
                        </div>
                        </div>
                    </div> -->
              <!-- only if isActive is off -->
              <!-- <div class="row" *ngIf="!generalSettings.isActive">
                        <div class="col-sm-12">
                        <div class="form-group">
                            <label for=""
                            >Falls das Online-Reservierungssystem deaktiviert ist, wird dem Benutzer die folgende Nachricht
                            angezeigt</label
                            >
                            <input
                            class="form-control"
                            placeholder=""
                            type="text"
                            name="isActiveAlert"
                            (change)="changedSettings()"
                            [(ngModel)]="generalSettings.reservationInactiveText"
                            />
                        </div>
                        </div>
                    </div> -->
              <!-- <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="isAutomatic"
                      (change)="changedSettings()"
                      [(ngModel)]="generalSettings.isAutomatic"
                    >
                      {{ 'ResSettings.ReservationSetting.ActivateAutomatic' | translate }}
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.ReceiveAutomaticConfirmation' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div> -->
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="autoPlace"
                      (change)="changedSettings()"
                      [(ngModel)]="generalSettings.autoPlace"
                    >
                      {{ 'ResSettings.ReservationSetting.PlaceGuest' | translate }}
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.PlaceResSystemAutomatically' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="roomChoice"
                      (change)="changedSettings()"
                      [(ngModel)]="generalSettings.roomChoice"
                    >
                      {{ 'ResSettings.ReservationSetting.EnableRoomSelection' | translate }}
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.CanGuestChooseRoom' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <!-- only if room choice is on -->
              <div class="row" *ngIf="generalSettings.roomChoice">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="roomChoiceMandatory"
                      (change)="changedSettings()"
                      [(ngModel)]="generalSettings.roomChoiceMandatory"
                    >
                      {{ 'ResSettings.ReservationSetting.RoomSelectionMandatory' | translate }}
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.TheGuest' | translate }}
                        <b>{{ 'ResSettings.ReservationSetting.Must' | translate }}</b>
                        {{ 'ResSettings.ReservationSetting.SelectRoom' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <!-- end if -->
              <!-- <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="paymentSystem"
                      (change)="changedSettings()"
                      [(ngModel)]="generalSettings.paymentSystem"
                    >
                      {{ 'ResSettings.ReservationSetting.ActivateDepositSystem' | translate }}
                      <i
                        class="fa fa-info-circle details-icon"
                        title="{{ 'Vouchers.Settings.PaymentFeesInfo' | translate }}"
                      ></i>
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.GuestMustPayDeposit' | translate }}
                      </div>
                      <div class="info payment-provison">
                       ( {{ 'ResSettings.ReservationSetting.PaymentFees' | translate }} {{ paymentProvision }} % + {{ paymentTransactionFee }} )
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div> -->
              <!-- <div class="row">
                        <div class="col-sm-12">
                        <div class="form-group">
                            <mat-slide-toggle
                            name="requestCheckin"
                            (change)="changedSettings()"
                            [(ngModel)]="generalSettings.requestCheckin"
                            >
                            CoVid19 Datenerfassung aktivieren
                            <div class="info">
                                Der Gast erhält nach der Reservierung eine E-Mail mit der Aufforderung zur Datenerfassung
                            </div>
                            </mat-slide-toggle>
                        </div>
                        </div>
                    </div> -->
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="showSubscribeNewsletter"
                      (change)="changedSettings()"
                      [(ngModel)]="generalSettings.showSubscribeNewsletter"
                      >{{ 'ResSettings.ReservationSetting.DeclarationOfConsentNewsPaper' | translate }}
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.CollectEmailForNewsLetter' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="isGoogleReserve"
                      (change)="changedSettings()"
                      [(ngModel)]="generalSettings.isGoogleReserve"
                    >
                      {{ 'ResSettings.ReservationSetting.ResUsingGoogleService' | translate }}
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.CanGuestMakeResOnline' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="showTranslation"
                      (change)="changedSettings()"
                      [(ngModel)]="generalSettings.showTranslation"
                    >
                      {{ 'ResSettings.ReservationSetting.ActiveTranslation' | translate }}
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.InfoTextTranslation' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="">{{ 'ResSettings.ReservationSetting.DefaultLanguageForOnlineRes' | translate }}</label>
                    <div class="info-data">
                      {{ 'ResSettings.ReservationSetting.DescriptionDefaultLanguage' | translate }}
                    </div>
                    <ng-select
                      [clearable]="false"
                      [searchable]="false"
                      [(ngModel)]="generalSettings.defaultLang"
                      (change)="changedSettings()"
                    >
                      <ng-option *ngFor="let val of languageArray" [value]="val.value">
                        <img [src]="val.flag" alt="Flag" class="language-flag" />
                        <span>{{ val.lang }}</span>
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for=""> {{ 'ResSettings.ReservationSetting.TimeLimitForOnlineRes' | translate }}</label>
                    <div class="info-data">
                      {{ 'ResSettings.ReservationSetting.AdvanceGuest' | translate }}
                    </div>
                    <select
                      class="form-control"
                      name="reservationUntil"
                      [(ngModel)]="generalSettings.reservationUntil"
                      (change)="changedSettings()"
                    >
                      <option value="">{{ 'ResSettings.ReservationSetting.Unlimited' | translate }}</option>
                      <option *ngFor="let day of reservationUntilDays" [value]="day">
                        {{ day }} {{ 'ResSettings.ReservationSetting.Days' | translate }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="!generalSettings.isActive">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="">{{ 'ResSettings.ReservationSetting.InCaseOfResSystemDisable' | translate }}</label>
                    <input
                      class="form-control"
                      placeholder=""
                      type="text"
                      name="isActiveAlert"
                      (change)="changedSettings()"
                      [(ngModel)]="generalSettings.reservationInactiveText"
                    />
                  </div>
                </div>
              </div>
              <!--
                    <div class="row">
                        <div class="col-sm-12">
                        <div class="form-group">
                            <mat-slide-toggle
                            name="sendFeedback"
                            (change)="changedSettings()"
                            [(ngModel)]="generalSettings.sendFeedback"
                            >
                            Autom. Feedback E-Mail senden
                            <div class="info">
                                Der Gast erhält nach dem Besuch eine E-Mail mit der Aufforderung zum Feedback
                            </div>
                            </mat-slide-toggle>
                        </div>
                        </div>
                    </div>
                    -->
              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for=""> {{ 'ResSettings.ReservationSetting.WhichEmailAddressGuestRes' | translate }}</label>
                    <input
                      class="form-control"
                      placeholder="Kontakt E-Mail-Adresse"
                      type="email"
                      name="email"
                      (change)="changedSettings()"
                      [(ngModel)]="generalSettings.email"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for=""> {{ 'ResSettings.ReservationSetting.TermAndCondition' | translate }}</label>
                    <input
                      class="form-control"
                      placeholder=""
                      type="text"
                      name="termsConditions"
                      (change)="changedSettings()"
                      [(ngModel)]="generalSettings.termsConditions"
                    />
                  </div>
                </div>
              </div>

              <div class="email-reminder">
                <h5 class="form-header">{{ 'ResSettings.ReservationSetting.ReminderGuest' | translate }}</h5>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <mat-slide-toggle
                        name="remindGuests"
                        [(ngModel)]="generalSettings.remindGuests"
                        (change)="changedSettings()"
                      >
                        {{ 'ResSettings.ReservationSetting.RemindGuestRes' | translate }}
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <mat-slide-toggle
                        name="remindGuestsEmail"
                        [(ngModel)]="generalSettings.remindGuestsEmail"
                        (change)="changedSettings()"
                      >
                        {{ 'ResSettings.ReservationSetting.ByEmail' | translate }}
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <mat-slide-toggle
                        name="remindGuestsTextMessage"
                        [(ngModel)]="generalSettings.remindGuestsTextMessage"
                        (change)="changedSettings(); changedReminderSMS()"
                      >
                        {{ 'ResSettings.ReservationSetting.BySMS' | translate }}
                      </mat-slide-toggle>
                      <textarea
                        class="form-control"
                        placeholder=""
                        type="text"
                        name="alert"
                        rows="5"
                        (change)="changedSettings(); changedReminderSMS()"
                        [(ngModel)]="generalSettings.reminderSMSText"
                      ></textarea>
                      <div class="alert alert-danger" *ngIf="reminderTextError">
                        {{ reminderTextError }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-sm-4 reminder-slider" for="">
                    {{ 'ResSettings.ReservationSetting.ManyDaysInAdvance' | translate }}</label
                  >
                  <div class="col-sm-8">
                    <div class="reservation-email-slider">
                      <ng5-slider
                        [options]="reminderDaysOptions"
                        [(value)]="generalSettings.reminderDays"
                        (userChange)="changedSettings()"
                      >
                      </ng5-slider>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-sm-4 reminder-slider" for="">
                    {{ 'ResSettings.ReservationSetting.GroupSize' | translate }}</label
                  >
                  <div class="col-sm-8">
                    <div class="reservation-email-slider">
                      <ng5-slider
                        [options]="reminderGroupSizeOptions"
                        [(value)]="generalSettings.reminderGroupSize"
                        (userChange)="changedSettings()"
                      >
                      </ng5-slider>
                    </div>
                  </div>
                </div>
                <small class="form-desc">{{ 'ResSettings.ReservationSetting.AdditionalMessage' | translate }}</small>
              </div>
              <button class="btn btn-primary btn-block btn-lg" (click)="saveSettings()">
                {{ 'ResSettings.ReservationSetting.SaveSetting' | translate }}
              </button>
            </div>
          </mat-tab>
          <mat-tab label="{{ 'ResSettings.ResAddShift.Time' | translate }}">
            <div class="element-box">
              <h5 class="form-header">{{ 'ResSettings.ReservationSetting.Times' | translate }}</h5>
              <div class="form-desc reservation-time-beta">
                {{ 'ResSettings.ReservationSetting.DefineTimeForRes' | translate }}
                <button class="btn btn-primary" (click)="addReservationSeries()" style="margin-top:15px">
                  <i class="fa fa-plus-circle fa-fw reservation-time-beta"></i
                  >{{ 'ResSettings.ReservationSetting.NewResTime' | translate }}
                </button>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="reservation-time-beta" for="">{{
                      'ResSettings.ReservationSetting.Period' | translate
                    }}</label>
                    <div class="date-input">
                      <input
                        class="form-control single-daterange"
                        placeholder="Select Date"
                        (click)="picker.open()"
                        [satDatepicker]="picker"
                        [(ngModel)]="timeSeriesDate"
                        (ngModelChange)="getTimeTableSeries($event)"
                      />
                    </div>
                    <sat-datepicker #picker [rangeMode]="true" [calendarFooterComponent]="rangesFooter">
                    </sat-datepicker>
                  </div>
                  <div *ngIf="finalTimeSeries && finalTimeSeries.length < 0" class="alert alert-info">
                    {{ 'ResSettings.ReservationSetting.NoResTimeSeriesAdded' | translate }}
                  </div>
                  <div class="table-responsive shifts" *ngIf="finalTimeSeries && finalTimeSeries.length > 0">
                    <table class="table table-padded">
                      <tbody>
                        <ng-container *ngFor="let item of finalTimeSeries; let i = index">
                          <span *ngIf="item[0] !== 'Blocked'"> {{ item[0] }} </span>
                          <span class="blocked-series" *ngIf="item[0] == 'Blocked'">
                            {{ 'ResSettings.ReservationSetting.BlockedTimeForSelectedPeriod' | translate }}
                          </span>
                          <tr
                            *ngFor="let series of item[1]"
                            [ngClass]="{ 'blocked-date': series.type && series.type == 'blocked' }"
                          >
                            <td>
                              <span>{{ series.value }}</span>
                            </td>
                            <td class="nowrap">
                              <span class="status-pill smaller" [ngStyle]="{ 'background-color': series.color }"></span>
                              <span class="badge badge-default">
                                {{ series.shiftDescription ? series.shiftDescription : series.shiftName }}</span
                              >
                              <img alt="" src="./assets/img/google.png" *ngIf="series.isRWGShift" />
                            </td>
                            <td>
                              <span *ngIf="series.endingAt"
                                >({{ 'ResSettings.ReservationSetting.EndsAt' | translate }} {{ series.endingAt }})</span
                              >
                            </td>
                            <td>
                              <span>
                                <button
                                  class="btn btn-light"
                                  *ngIf="!series.type"
                                  (click)="editResTime(series.details, series.type, series.currentDate)"
                                  matTooltip="Bearbeiten"
                                >
                                  <i class="fas fa-edit"></i>
                                </button>
                                <button
                                  class="btn btn-danger"
                                  *ngIf="!series.type"
                                  (click)="deleteResTime(series.details.id)"
                                  matTooltip="Löschen"
                                >
                                  <i class="far fa-trash-alt"></i>
                                </button>
                                <button
                                  class="btn btn-light"
                                  *ngIf="!series.type"
                                  (click)="blockResTime(series.details, series.currentDate)"
                                  matTooltip="Blocken"
                                >
                                  <i class="fa fa-ban"></i>
                                </button>
                                <button
                                  class="btn btn-light"
                                  *ngIf="item[0] == 'Blocked' && series.type && series.type == 'blocked'"
                                  (click)="unBlockResTime(series.details)"
                                  matTooltip="Entblocken"
                                >
                                  <i class="fa fa-undo"></i>
                                </button>
                              </span>
                            </td>
                          </tr>
                          <tr *ngIf="item[1].length == 0">
                            <td>
                              <span>-</span>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <!-- <mat-tab label="Räume und Tische">
                <div class="element-box">
                <h5 class="form-header">Räume und Tische</h5>
                <div class="form-desc">
                    Legen Sie Ihre Räume und Tische an in denen reserviert werden soll. Sie können die Sortierung der Räume
                    und Tische mittels Drag & Drop vornehmen.
                    <button class="btn btn-primary" (click)="addRoom()" style="margin-top:15px">
                    <i class="fa fa-plus-circle fa-fw"></i> Neuen Raum anlegen
                    </button>
                </div>

                <div
                    cdkDropList
                    cdkDropListOrientation="horizontal"
                    [cdkDropListData]="rooms"
                    (cdkDropListDropped)="dropGroup($event)"
                    class="row"
                    style="margin-top: 10px"
                >
                    <div
                    class="drag-container col-sm-6 col-md-4"
                    *ngFor="let room of rooms; let i = index"
                    cdkDropListGroup
                    cdkDrag
                    [cdkDragData]="room"
                    >
                    <h5>
                        <div>
                        <button
                            class="btn btn-white btn-sm"
                            matTooltip="Klicken und verschieben um Raum zu sortieren"
                            cdkDragHandle
                        >
                            <mat-icon svgIcon="move"></mat-icon>
                        </button>
                        {{ room.name }}
                        </div>
                        <div>
                        <button class="btn btn-white btn-sm" (click)="editRoom(room, i)" matTooltip="Raum bearbeiten">
                            <mat-icon svgIcon="edit"></mat-icon>
                        </button>
                        <div
                            ngbDropdown
                            style="display:inline-block"
                            class="btn btn-white btn-sm"
                            matTooltip="Tisch oder Kombination hinzufügen"
                        >
                            <mat-icon svgIcon="plus" ngbDropdownToggle style="width: 24px;height: 24px"></mat-icon>
                            <div ngbDropdownMenu aria-labelledby="shiftDropdown">
                            <button ngbDropdownItem (click)="addTable(room)">Tisch</button>
                            <button ngbDropdownItem (click)="addCombination(room)">Kombination</button>
                            </div>
                        </div>
                        </div>
                    </h5>

                    <div
                        cdkDropList
                        [cdkDropListData]="room.tables"
                        class="rooms-list"
                        id="{{ room.id }}"
                        (cdkDropListDropped)="dropItem($event)"
                        [cdkDropListConnectedTo]="getConnectedList()"
                    >
                        <div class="pipeline-item" *ngFor="let item of room.tables" cdkDrag [cdkDragData]="item">
                        <div class="pi-controls">
                            <div class="pi-settings os-dropdown-trigger" ngbDropdown>
                            <mat-icon svgIcon="settings" ngbDropdownToggle></mat-icon>
                            <div ngbDropdownMenu>
                                <button ngbDropdownItem (click)="editTable(room, item)">Bearbeiten</button>
                                <button ngbDropdownItem (click)="deleteTable(item.id)">Löschen</button>
                            </div>
                            </div>
                            <div
                            class="status"
                            [class.status-green]="item.isActive"
                            [class.status-red]="!item.isActive"
                            ></div>
                        </div>
                        <div class="pi-body">
                            <div class="pi-info">
                            <div class="h6 pi-name">
                                <ng-template [ngIf]="!item.isCombined">Tisch</ng-template>
                                <ng-template [ngIf]="item.isCombined">Kombi</ng-template> #{{ item.name }}
                            </div>
                            <div class="pi-sub" *ngIf="!item.isCombined">
                                {{ item.seats }} <span *ngIf="item.seats === 1">Platz</span>
                                <span *ngIf="item.seats !== 1">Plätze</span>
                            </div>
                            <div class="pi-sub" *ngIf="item.isCombined">
                                <span class="badge badge-default" *ngFor="let cTable of item.combinedTables">{{
                                cTable.name
                                }}</span>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                    </div>
                </mat-tab>
                <mat-tab label="{{ 'tablePlan.tablePlan' | translate }}">
                    <app-table-plan isEditing="true" [reservationBookSettings]="reservationBook"></app-table-plan>
                </mat-tab> -->
          <mat-tab label="{{ 'ResSettings.ReservationSetting.Templates' | translate }}">
            <div class="element-box">
              <h5 class="form-header">{{ 'ResSettings.ReservationSetting.Templates' | translate }}</h5>
              <div class="form-desc">
                {{ 'ResSettings.ReservationSetting.CreateResTemplate' | translate }}

                <button class="btn btn-primary" (click)="addShift()" style="margin-top:15px">
                  <i class="fa fa-plus-circle fa-fw"></i>
                  {{ 'ResSettings.ReservationSetting.CreateNewTemplate' | translate }}
                </button>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div *ngIf="shifts && !shifts.length" class="alert alert-info">
                    {{ 'ResSettings.ReservationSetting.YouHaveNotCreateAnyTemplate' | translate }}
                  </div>
                  <div class="table-responsive shifts" *ngIf="shifts && shifts.length">
                    <table class="table table-padded">
                      <thead>
                        <tr>
                          <th>{{ 'ResSettings.ReservationSetting.NameOfTheTemplate' | translate }}</th>
                          <th>{{ 'ResSettings.ReservationSetting.Description' | translate }}</th>
                          <th>{{ 'ResSettings.ReservationSetting.Rooms' | translate }}</th>
                          <th>Online</th>
                          <th *ngIf="generalSettings.isGoogleReserve">Google</th>
                          <th *ngIf="phoneAssistantSettings && phoneAssistantSettings.isActive">Phone Assistant</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let shift of shifts; let i = index">
                          <td class="nowrap">
                            <span class="status-pill smaller" [ngStyle]="{ 'background-color': shift.color }"></span>
                            <span>{{ shift.name }}</span>
                            <code>
                              <a
                                class="btn btn-light"
                                style="padding: 0"
                                href="{{ onlineResURL }}/{{ activeClient?.name | encodeURI }}/{{ activeClient?.id }}/{{
                                  shift.id
                                }}"
                                target="_blank"
                              >
                                <mat-icon style="font-size: 18px;line-height: 22px;">launch</mat-icon>
                              </a>
                            </code>
                          </td>
                          <td>
                            <span>{{ shift.description ? shift.description : '' }}</span>
                          </td>
                          <td class="rooms">
                            <a class="badge badge-light" *ngFor="let room of shift.rooms">{{ room.name }}</a>
                          </td>
                          <td>
                            <div class="form-group">
                              <mat-slide-toggle
                                name="isOnlineResShift"
                                [(ngModel)]="shift.isOnlineResShift"
                                (change)="
                                  saveShiftSettings(
                                    shift.id,
                                    'isOnlineResShift',
                                    shift.isOnlineResShift,
                                    shift.clientId
                                  )
                                "
                              >
                              </mat-slide-toggle>
                            </div>
                          </td>
                          <td *ngIf="generalSettings.isGoogleReserve">
                            <div class="form-group">
                              <mat-slide-toggle
                                name="isRWGShift"
                                [(ngModel)]="shift.isRWGShift"
                                (change)="saveShiftSettings(shift.id, 'isRWGShift', shift.isRWGShift, shift.clientId)"
                              >
                              </mat-slide-toggle>
                            </div>
                          </td>
                          <td *ngIf="phoneAssistantSettings && phoneAssistantSettings.isActive">
                            <div class="form-group">
                              <mat-slide-toggle
                                name="isPhoneAssistantShift"
                                [(ngModel)]="shift.isPhoneAssistantShift"
                                (change)="
                                  saveShiftSettings(shift.id, 'isPhoneAssistantShift', shift.isPhoneAssistantShift)
                                "
                              >
                              </mat-slide-toggle>
                            </div>
                          </td>
                          <td style="width: 55px;">
                            <div class="shift-options" ngbDropdown>
                              <mat-icon svgIcon="settings" ngbDropdownToggle id="shiftDropdown"></mat-icon>
                              <div ngbDropdownMenu aria-labelledby="shiftDropdown">
                                <button ngbDropdownItem (click)="editShift(shift, i)">
                                  {{ 'ResSettings.ReservationSetting.Edit' | translate }}
                                </button>
                                <button ngbDropdownItem (click)="deleteShift(shift.id)">
                                  {{ 'ResSettings.ReservationSetting.Delete' | translate }}
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div style="margin-top: 50px">
                <h5 class="form-header">{{ 'ResSettings.ReservationSetting.AdditionalQueriesRes' | translate }}</h5>
                <div class="form-desc">
                  {{ 'ResSettings.ReservationSetting.CreateGlobalAddQueries' | translate }}

                  <button class="btn btn-primary" (click)="addGlobalQuestion()" style="margin-top:15px">
                    <i class="fa fa-plus-circle fa-fw"></i>
                    {{ 'ResSettings.ReservationSetting.CreateNewQuery' | translate }}
                  </button>
                </div>

                <div class="alert alert-info" *ngIf="globalQuestions && !globalQuestions.length">
                  {{ 'ResSettings.ReservationSetting.NotCreateQuery' | translate }}
                </div>

                <div class="table-responsive shifts" *ngIf="globalQuestions && globalQuestions.length">
                  <table class="table table-padded">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>{{ 'ResSettings.ReservationSetting.Ask' | translate }}</th>
                        <th>{{ 'ResSettings.ReservationSetting.Answer' | translate }}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let question of globalQuestions; let i = index">
                        <td class="nowrap">
                          <span>{{ question.name }}</span>
                        </td>
                        <td>
                          <span>{{ question.question }}</span>
                        </td>
                        <td class="rooms">
                          <a class="badge badge-light"
                            >{{ question.answers.length }} {{ 'ResSettings.ReservationSetting.Answer' | translate }}</a
                          >
                        </td>
                        <td style="width: 55px;">
                          <div class="shift-options" ngbDropdown>
                            <mat-icon svgIcon="settings" ngbDropdownToggle id="questionDropdown"></mat-icon>
                            <div ngbDropdownMenu aria-labelledby="questionDropdown">
                              <button ngbDropdownItem (click)="editGlobalQuestion(question, i)">
                                {{ 'ResSettings.ReservationSetting.Edit' | translate }}
                              </button>
                              <button ngbDropdownItem (click)="deleteGlobalQuestion(question.id)">
                                {{ 'ResSettings.ReservationSetting.Delete' | translate }}
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div style="margin-top: 50px" *ngIf="generalSettings.paymentSystem">
                <h5 class="form-header">{{ 'ResSettings.ReservationSetting.DepositTemplate' | translate }}</h5>
                <div class="form-desc">
                  {{ 'ResSettings.ReservationSetting.CreateTemplatesLinkRes' | translate }}
                  <button class="btn btn-primary" (click)="addPaymentTemplate()" style="margin-top:15px">
                    <i class="fa fa-plus-circle fa-fw"></i>
                    {{ 'ResSettings.ReservationSetting.CreateNewTemplate' | translate }}
                  </button>
                </div>

                <div class="alert alert-info" *ngIf="paymentTemplates && !paymentTemplates.length">
                  {{ 'ResSettings.ReservationSetting.NoCreatedAnyDownPaymentTemp' | translate }}
                </div>

                <div class="table-responsive shifts" *ngIf="paymentTemplates && paymentTemplates.length">
                  <table class="table table-padded">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>{{ 'ResSettings.ReservationSetting.GuestFrom' | translate }}</th>
                        <th>{{ 'ResSettings.ReservationSetting.PricingOption' | translate }}</th>
                        <th>{{ 'ResSettings.ReservationSetting.Price' | translate }}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let template of paymentTemplates; let i = index">
                        <td class="nowrap">
                          <span>{{ template.name }}</span>
                        </td>
                        <td>
                          <span
                            >{{ template.minGuests }} {{ 'ResSettings.ReservationSetting.Persons' | translate }}</span
                          >
                        </td>
                        <td class="rooms">
                          <a class="badge badge-light">{{
                            template.priceOption === 'guest' ? 'Pro Gast' : 'Pauschal'
                          }}</a>
                        </td>
                        <td>
                          <span>{{ template.price | number: '1.2' }} EUR</span>
                        </td>
                        <td style="width: 55px;">
                          <div class="shift-options" ngbDropdown>
                            <mat-icon svgIcon="settings" ngbDropdownToggle id="paymentTemplateDropdown"></mat-icon>
                            <div ngbDropdownMenu aria-labelledby="paymentTemplateDropdown">
                              <button ngbDropdownItem (click)="editPaymentTemplate(template, i)">
                                {{ 'ResSettings.ReservationSetting.Edit' | translate }}
                              </button>
                              <button ngbDropdownItem (click)="deletePaymentTemplate(template.id)">
                                {{ 'ResSettings.ReservationSetting.Delete' | translate }}
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div style="margin-top: 50px">
                <h5 class="form-header">Add-Ons</h5>
                <div class="form-desc">
                  {{ 'ResSettings.ReservationSetting.OfferGuestsAdditional' | translate }}
                  <button class="btn btn-primary" (click)="addReservationAddOnTemplate()" style="margin-top:15px">
                    <i class="fa fa-plus-circle fa-fw"></i>
                    {{ 'ResSettings.ReservationSetting.CreateAddOns' | translate }}
                  </button>
                </div>

                <div class="alert alert-info" *ngIf="reservationAddOnTemplates && !reservationAddOnTemplates.length">
                  {{ 'ResSettings.ReservationSetting.HaventCreatedAddOns' | translate }}
                </div>

                <div
                  class="table-responsive shifts"
                  *ngIf="reservationAddOnTemplates && reservationAddOnTemplates.length"
                >
                  <table class="table table-padded">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>{{ 'ResSettings.ReservationSetting.Price' | translate }}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let template of reservationAddOnTemplates; let i = index">
                        <td class="nowrap" style="width:20%">
                          <span class="addon-image" *ngIf="template.image">
                            <img src="https://cdn.gastroguide.de/{{ template.image }}" height="100" width="100" />
                          </span>
                          <span class="addon-image" *ngIf="!template.image">
                            <img src="../../../assets/img/icons8-event-64.png" height="64" width="64" />
                          </span>
                        </td>
                        <td class="nowrap">
                          <span>{{ template.title }}</span>
                        </td>
                        <td>
                          <span>{{ template.startingPrice | number: '1.2' }} EUR</span>
                        </td>
                        <td style="width: 55px;">
                          <div class="shift-options" ngbDropdown>
                            <mat-icon svgIcon="settings" ngbDropdownToggle id="reservationAddOnTemplateDropdown">
                            </mat-icon>
                            <div ngbDropdownMenu aria-labelledby="reservationAddOnTemplateDropdown">
                              <button ngbDropdownItem (click)="editReservationAddOnTemplate(template, i)">
                                {{ 'ResSettings.ReservationSetting.Edit' | translate }}
                              </button>
                              <button ngbDropdownItem (click)="deleteReservationAddOnTemplate(template.id)">
                                {{ 'ResSettings.ReservationSetting.Delete' | translate }}
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div style="margin-top: 50px">
                <h5 class="form-header">{{ 'ResSettings.ResQuestions.ResMenuOrder' | translate }}</h5>
                <div class="form-desc">
                  {{ 'ResSettings.ResMenuOrder.DescriptionText' | translate }}
                  <button class="btn btn-primary" (click)="addResMenuOrders()" style="margin-top:15px">
                    <i class="fa fa-plus-circle fa-fw"></i> {{ 'ResSettings.ResMenuOrder.AddResMenuOrder' | translate }}
                  </button>
                </div>
                <div class="alert alert-info" *ngIf="resMenuOrders && !resMenuOrders.length">
                  {{ 'ResSettings.ResMenuOrder.NoDataFound' | translate }}
                </div>
                <div class="table-responsive shifts" *ngIf="resMenuOrders && resMenuOrders.length">
                  <table class="table table-padded">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>{{ 'ResSettings.ResMenuOrder.TotalAmount' | translate }}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody class="table-row-not-expanded" *ngFor="let order of resMenuOrders; let i = index">
                      <tr>
                        <td>
                          <span>{{ order.name }}</span>
                        </td>
                        <td>
                          <span>{{ order.totalAmount }} </span>
                        </td>
                        <td style="width: 55px;">
                          <div class="shift-options" ngbDropdown>
                            <mat-icon svgIcon="settings" ngbDropdownToggle id="resMenuOrdersDropdown"> </mat-icon>
                            <div ngbDropdownMenu aria-labelledby="resMenuOrdersDropdown">
                              <button ngbDropdownItem (click)="editResMenuOrders(order, i)">
                                {{ 'ResSettings.ReservationSetting.Edit' | translate }}
                              </button>
                              <button ngbDropdownItem (click)="deleteResMenuOrders(order.id)">
                                {{ 'ResSettings.ReservationSetting.Delete' | translate }}
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="showOldCalendar" label="Reservierungszeiten">
            <div class="element-box" *ngIf="timeplanData">
              <h5 class="form-header">{{ 'ResSettings.ReservationSetting.ReservationTimes' | translate }}</h5>
              <div class="form-desc">
                <h6 class="alert-note">
                  {{ 'ResSettings.ReservationSetting.ProblemsDisplayingResTime' | translate }}
                </h6>
                {{ 'ResSettings.ReservationSetting.DefineScheduleTemp' | translate }}
              </div>
              <dx-scheduler
                [dataSource]="timeplanData"
                [views]="['day', 'week', 'month']"
                currentView="week"
                [currentDate]="currentDate"
                [startDayHour]="
                  reservationBook.startTime === 0 ? reservationBook.startTime : reservationBook.startTime - 1
                "
                [endDayHour]="reservationBook.endTime === 24 ? reservationBook.endTime : reservationBook.endTime + 1"
                [showAllDayPanel]="false"
                [showCurrentTimeIndicator]="false"
                [adaptivityEnabled]="false"
                appointmentTemplate="shift-template"
                dataCellTemplate="dataCellTemplate"
                (onAppointmentFormOpening)="onAppointmentFormOpening($event)"
                (onAppointmentAdded)="onAppointmentAdded($event)"
                (onAppointmentClick)="onAppointmentClick($event)"
                (onAppointmentUpdating)="onAppointmentUpdating($event)"
                (onAppointmentAdding)="onAppointmentAdding($event)"
                (onAppointmentDeleted)="onAppointmentDeleted($event)"
                (onAppointmentContextMenu)="onAppointmentContextMenu($event)"
                (selectedCellDataChange)="selectedCellDataChange($event)"
                (window:mouseup)="mouseup()"
                allowDragging="true"
                class="clearfix timetable-scheduler"
              >
                <div
                  *dxTemplate="let shift of 'shift-template'"
                  [style.backgroundColor]="shift.color"
                  [class.blocked]="shift.isBlocked"
                >
                  <div class="showtime-preview" title="ID: {{ shift.id }}">
                    <div>
                      <strong>{{ shift.name }} ({{ shift.id }})</strong>
                      <pre style="display: none; font-size: 8px; text-align: left; margin: 0; padding: 0;">{{
                        shift | json
                      }}</pre>
                    </div>
                    <div class="dropdown-appointment-dates">
                      {{ (shift.startDate | date: 'shortTime') + ' - ' + (shift.endDate | date: 'shortTime') }}
                    </div>
                  </div>
                </div>

                <div *dxTemplate="let cellData of 'dataCellTemplate'" [class.closed-day-cell]="isClosed(cellData)">
                  <div class="day-cell">{{ cellData.text }}</div>
                </div>

                <div *dxTemplate="let shift of 'tooltip-template'">
                  <div class="shift-tooltip" (click)="stopPropagation($event)">
                    <div class="shift-info">
                      <h3>
                        <div class="color" [style.background-color]="shift.color"></div>
                        {{ shift.name }}
                      </h3>
                      <div class="badge badge-danger" *ngIf="shift.isBlocked">
                        {{ 'ResSettings.ReservationSetting.Blocked' | translate }}
                      </div>
                    </div>
                    <button class="btn btn-outline-secondary" (click)="editTimeTableEntry(shift)">
                      {{ 'ResSettings.ReservationSetting.Edit' | translate }}
                    </button>
                    <button
                      class="btn btn-outline-secondary"
                      *ngIf="!shift.isBlocked"
                      (click)="blockTimeTableEntry(shift)"
                    >
                      {{ 'ResSettings.ReservationSetting.Block' | translate }}
                    </button>
                    <button
                      class="btn btn-outline-secondary"
                      *ngIf="shift.isBlocked"
                      (click)="unblockTimeTableEntry(shift)"
                    >
                      {{ 'ResSettings.ReservationSetting.UnBlock' | translate }}
                    </button>
                    <button class="btn btn-outline-danger" (click)="deleteTimeTableEntry(shift)">
                      {{ 'ResSettings.ReservationSetting.Delete' | translate }}
                    </button>
                  </div>
                </div>
              </dx-scheduler>
              <dx-context-menu
                #shiftContextMenu
                [dataSource]="shiftContextMenuItems"
                target=".dx-scheduler-appointment"
                (onItemClick)="onContextMenuItemClick($event)"
                [width]="200"
              >
                <div *dxTemplate="let e of 'item'">
                  <div *ngIf="e.color">
                    <div class="item-badge" [ngStyle]="{ backgroundColor: e.color }"></div>
                  </div>
                  <div class="item-text">{{ e.text }}</div>
                </div>
              </dx-context-menu>
            </div>
          </mat-tab>

          <mat-tab label="{{ 'ResSettings.ReservationSetting.TextTemplate' | translate }}">
            <div class="element-box">
              <h5 class="form-header">{{ 'ResSettings.ReservationSetting.TextTemplate' | translate }}</h5>
              <div class="form-desc">
                {{ 'ResSettings.ReservationSetting.EditTextTemplate' | translate }}
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="">{{ 'ResSettings.ReservationSetting.ResConfirmation' | translate }}</label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      type="text"
                      name="alert"
                      rows="5"
                      [(ngModel)]="textPresets.acceptText"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label for="">{{ 'ResSettings.ReservationSetting.ResCancellation' | translate }}</label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      type="text"
                      name="alert"
                      rows="5"
                      [(ngModel)]="textPresets.declineText"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label for="">{{ 'ResSettings.ReservationSetting.ReservationCancel' | translate }}</label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      type="text"
                      name="alert"
                      rows="5"
                      [(ngModel)]="textPresets.cancelText"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label for="">{{ 'ResSettings.ReservationSetting.ReservationReminder' | translate }}</label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      type="text"
                      name="alert"
                      rows="5"
                      [(ngModel)]="textPresets.reminderText"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label for="">{{ 'ResSettings.ReservationSetting.InfoTextRes' | translate }}</label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      type="text"
                      name="alert"
                      rows="5"
                      [(ngModel)]="textPresets.infoText"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label for="">{{ 'ResSettings.ReservationSetting.NoMoreRes' | translate }}</label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      type="text"
                      name="alert"
                      rows="5"
                      [(ngModel)]="textPresets.noMoreReservationMsg"
                    ></textarea>
                  </div>
                </div>
                <div class="col-sm-6">
                  <h5 class="form-header">{{ 'ResSettings.ReservationSetting.UseWildCards' | translate }}</h5>
                  <p class="form-sub-title">{{ 'ResSettings.ReservationSetting.ReusePlaceholder' | translate }}</p>

                  <ul class="placeholder-list">
                    <li>
                      <mark>[{{ 'ResSettings.ReservationSetting.Date' | translate }}]</mark>
                      {{ 'ResSettings.ReservationSetting.DateOfTheRes' | translate }}
                    </li>
                    <li>
                      <mark>[{{ 'ResSettings.ReservationSetting.Time' | translate }}]</mark>
                      {{ 'ResSettings.ReservationSetting.TheTineOfRes' | translate }}
                    </li>
                    <li>
                      <mark>[{{ 'ResSettings.ReservationSetting.EndTime' | translate }}]</mark>
                      {{ 'ResSettings.ReservationSetting.EndTimeOfTheRes' | translate }}
                    </li>
                    <li>
                      <mark>[{{ 'ResSettings.ReservationSetting.Space' | translate }}]</mark>
                      {{ 'ResSettings.ReservationSetting.TheResRoom' | translate }}
                    </li>
                    <li>
                      <mark>[{{ 'ResSettings.ReservationSetting.LengthOfStay' | translate }}]</mark>
                      {{ 'ResSettings.ReservationSetting.LengthOfStayOfRes' | translate }}
                    </li>
                    <li>
                      <mark>[{{ 'ResSettings.ReservationSetting.FirstName' | translate }}]</mark>
                      {{ 'ResSettings.ReservationSetting.TheGuestFirstName' | translate }}
                    </li>
                    <li><mark>[name]</mark> {{ 'ResSettings.ReservationSetting.TheGuestName' | translate }}</li>
                    <li>
                      <mark>[{{ 'ResSettings.ReservationSetting.AdditionalText' | translate }}]</mark>
                      {{ 'ResSettings.ReservationSetting.AdditionalTextResTemplate' | translate }}
                    </li>
                    <p
                      *ngIf="textPresets.acceptText && !textPresets.acceptText.includes('[zusatztext]')"
                      class="show-alert-msg"
                    >
                      *Wird derzeit nicht angezeigt
                    </p>
                  </ul>
                  <br />
                  <div class="form-group">
                    <mat-slide-toggle
                      name="showStayTime"
                      (change)="saveSettings()"
                      [(ngModel)]="generalSettings.showStayTime"
                    >
                      {{ 'ResSettings.ReservationSetting.DwellTime' | translate }}
                      <div class="info">{{ 'ResSettings.ReservationSetting.ShowLengthEmail' | translate }}</div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" (click)="saveTextPresets()">
                {{ 'ResSettings.ReservationSetting.SaveTextTemplates' | translate }}
              </button>
            </div>
          </mat-tab>
          <mat-tab label="{{ 'ResSettings.ReservationSetting.Notification' | translate }}">
            <div class="element-box">
              <h5 class="form-header">{{ 'ResSettings.ReservationSetting.Notification' | translate }}</h5>
              <div class="form-desc">
                {{ 'ResSettings.ReservationSetting.SetUpChannelsRes' | translate }}
                <button class="btn btn-primary" (click)="addNotificationChannel()" style="margin-top:15px">
                  <i class="fa fa-plus-circle fa-fw"></i>
                  {{ 'ResSettings.ReservationSetting.CreateNewChannel' | translate }}
                </button>
              </div>

              <div class="table-responsive">
                <table class="table table-padded">
                  <thead>
                    <tr>
                      <th>{{ 'ResSettings.ReservationSetting.Channel' | translate }}</th>
                      <th>{{ 'ResSettings.ReservationSetting.Recipient' | translate }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let channel of channels; let i = index">
                      <td class="nowrap">
                        <i class="fas fa-at fa-fw" *ngIf="channel.type === 'email'"></i>
                        <i class="fas fa-mobile-alt fa-fw" *ngIf="channel.type === 'sms'"></i>
                        <span *ngIf="channel.type === 'email'">E-Mail</span>
                        <span *ngIf="channel.type === 'sms'">SMS</span>
                      </td>
                      <td>
                        <span>{{ channel.value }}</span>
                      </td>

                      <td style="width: 55px;">
                        <div class="shift-options" ngbDropdown>
                          <mat-icon svgIcon="settings" ngbDropdownToggle id="shiftDropdown"></mat-icon>
                          <div ngbDropdownMenu aria-labelledby="shiftDropdown">
                            <button ngbDropdownItem (click)="editNotificationChannel(channel, i)">
                              {{ 'ResSettings.ReservationSetting.Edit' | translate }}
                            </button>
                            <button ngbDropdownItem (click)="deleteNotificationChannel(channel.id)">
                              {{ 'ResSettings.ReservationSetting.Delete' | translate }}
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h5 class="form-header">{{ 'ResSettings.ReservationSetting.SMSSetting' | translate }}</h5>
              <div class="form-desc"></div>
              <form [formGroup]="smsSettingsFormGroup">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <mat-slide-toggle formControlName="reservationActive">
                        {{ 'ResSettings.ReservationSetting.AllowResSms' | translate }}
                        <div class="info">
                          {{ 'ResSettings.ReservationSetting.SMSTypeMessage' | translate }}
                        </div>
                        <div class="info">
                          {{ 'ResSettings.ReservationSetting.PricePerSMS' | translate }}
                        </div>
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <mat-slide-toggle formControlName="gateway">
                        {{ 'ResSettings.ReservationSetting.UsePremiumSMS' | translate }}
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="this.smsSettingsFormGroup.value.gateway">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for=""> {{ 'ResSettings.ReservationSetting.SenderNumber' | translate }}</label>
                      <input
                        class="form-control"
                        placeholder=""
                        type="text"
                        formControlName="senderId"
                        (change)="checkSenderIdValidation()"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for=""> {{ 'ResSettings.ReservationSetting.ConfirmationText' | translate }}</label>
                      <textarea
                        class="form-control"
                        placeholder=""
                        type="text"
                        formControlName="confirmationText"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="">{{ 'ResSettings.ReservationSetting.RequestText' | translate }} </label>
                      <textarea
                        class="form-control"
                        placeholder=""
                        type="text"
                        formControlName="requestText"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <button
                  class="btn btn-primary btn-block btn-lg"
                  (click)="saveSmsSettings()"
                  [promiseBtn]="editObservableSmsSettings"
                >
                  {{ 'ResSettings.ReservationSetting.SaveSetting' | translate }}
                </button>
              </form>
            </div>
          </mat-tab>
          <mat-tab label="{{ 'ResSettings.ReservationSetting.ReservationBook' | translate }}">
            <div class="element-box">
              <h5 class="form-header">{{ 'ResSettings.ReservationSetting.ReservationBook' | translate }}</h5>
              <div class="form-desc">{{ 'ResSettings.ReservationSetting.SettingReservationSystem' | translate }}</div>

              <legend style="margin-bottom: 10px;"><span>Tags</span></legend>
              <div class="section-desc">
                {{ 'ResSettings.ReservationSetting.CreateTagsToClearly' | translate }}
              </div>
              <div class="create-tag">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <app-color-picker [color]="tag.color" (event)="changeTagColor($event)"></app-color-picker>
                    </div>
                  </div>
                  <input
                    class="form-control"
                    placeholder="Neuen Tag erstellen..."
                    type="text"
                    [(ngModel)]="tag.label"
                  />
                </div>
                <button
                  class="btn btn-primary"
                  type="button"
                  (click)="addTag()"
                  [disabled]="!tag || tag.label.length === 0"
                >
                  <i class="fa fa-plus-circle fa-fw"></i> {{ 'ResSettings.ReservationSetting.AddTag' | translate }}
                </button>
              </div>
              <div class="tags">
                <div class="tag" [ngStyle]="{ 'background-color': tag.color }" *ngFor="let tag of tags">
                  {{ tag.label }}
                  <button class="btn btn-link" (click)="deleteTag(tag.id)">
                    <mat-icon svgIcon="delete"></mat-icon>
                  </button>
                </div>
              </div>

              <legend style="margin-bottom: 10px;margin-top:30px;">
                <span>{{ 'ResSettings.ResAddShift.Time' | translate }}</span>
              </legend>
              <div class="form-group row" *ngIf="reservationBook">
                <label class="col-form-label col-sm-4" for="">
                  {{ 'ResSettings.ReservationSetting.StartTimeInTimeLine' | translate }}</label
                >
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    [(ngModel)]="reservationBook.startTime"
                    (change)="saveReservationBookSettings()"
                  >
                    <option [value]="time.value" *ngFor="let time of timesArray">{{ time.time }} Uhr</option>
                  </select>
                </div>
              </div>
              <div class="form-group row" *ngIf="reservationBook">
                <label class="col-form-label col-sm-4" for="">
                  {{ 'ResSettings.ReservationSetting.EndTimeInTheTimeline' | translate }}</label
                >
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    [(ngModel)]="reservationBook.endTime"
                    (change)="saveReservationBookSettings()"
                  >
                    <option [value]="time.value" *ngFor="let time of timesArray">{{ time.time }} Uhr</option>
                  </select>
                </div>
              </div>
              <div class="form-group row" *ngIf="reservationBook">
                <label class="col-form-label col-sm-4" for="">
                  {{ 'ResSettings.ReservationSetting.TimeIntervalInTimeLine' | translate }}</label
                >
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    [(ngModel)]="reservationBook.timeInterval"
                    (change)="saveReservationBookSettings()"
                  >
                    <option value="15">15 {{ 'ResSettings.ReservationSetting.Minutes' | translate }}</option>
                    <option value="30">30 {{ 'ResSettings.ReservationSetting.Minutes' | translate }}</option>
                    <option value="60">60 {{ 'ResSettings.ReservationSetting.Minutes' | translate }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-4" for="">
                  {{ 'ResSettings.ReservationSetting.StandardLengthStayManual' | translate }}</label
                >
                <div class="col-sm-8">
                  <div class="staytime-slider">
                    <ng5-slider
                      [options]="stayTimeOptions"
                      [(value)]="reservationBook.defaultStaytime"
                      (userChange)="changeDefaultStaytime()"
                    >
                    </ng5-slider>
                  </div>
                </div>
              </div>

              <legend style="margin-bottom: 10px;margin-top:30px;">
                <span>{{ 'ResSettings.ReservationSetting.Other' | translate }}</span>
              </legend>
              <div class="form-group row">
                <div class="col-sm-12">
                  <div>
                    <mat-slide-toggle
                      name="requestStaff"
                      [(ngModel)]="reservationBook.requestStaff"
                      (change)="saveReservationBookSettings()"
                    >
                      {{ 'ResSettings.ReservationSetting.QueryStaffMakeRes' | translate }}
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.ManualResRequired' | translate }}
                        <a routerLink="/client/{{ activeClient?.id }}/administration/staff">{{
                          'ResSettings.ReservationSetting.ManageEmployee' | translate
                        }}</a>
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <div>
                    <mat-slide-toggle
                      name="confirmStatusChange"
                      [(ngModel)]="reservationBook.confirmStatusChange"
                      (change)="saveReservationBookSettings()"
                    >
                      {{ 'ResSettings.ReservationSetting.ConfirmStatusChange' | translate }}
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.DoesEveryStatusChange' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <div>
                    <mat-slide-toggle
                      name="arrivedStatus"
                      [(ngModel)]="reservationBook.arrivedStatus"
                      (change)="saveReservationBookSettings()"
                    >
                      {{ 'ResSettings.ReservationSetting.StatusArrived' | translate }}
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.ShouldStatusArrived' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <div>
                    <mat-slide-toggle
                      name="waitingStatus"
                      [(ngModel)]="reservationBook.waitingStatus"
                      (change)="saveReservationBookSettings()"
                    >
                      {{ 'ResSettings.ReservationSetting.WaitingList' | translate }}
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.ShouldWaitingList' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <div>
                    <mat-slide-toggle
                      name="highlightNew"
                      [(ngModel)]="reservationBook.highlightNew"
                      (change)="saveReservationBookSettings()"
                    >
                      {{ 'ResSettings.ReservationSetting.MarkNewRes' | translate }}
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.ShouldNewResMark' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <div>
                    <mat-slide-toggle
                      name="showWalkIns"
                      [(ngModel)]="reservationBook.showWalkIns"
                      (change)="saveReservationBookSettings()"
                    >
                      {{ 'ResSettings.ReservationSetting.ShowWalkList' | translate }}
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.ShouldWalkInDisplay' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <div>
                    <mat-slide-toggle
                      name="showOccupancy"
                      [(ngModel)]="reservationBook.showOccupancy"
                      (change)="saveReservationBookSettings()"
                    >
                      {{ 'ResSettings.ReservationSetting.ShowOccupancyGraph' | translate }}
                      <div class="info">
                        {{ 'ResSettings.ReservationSetting.ShowUtilization' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <div>
                    <mat-slide-toggle
                      name="showTablePlan"
                      [(ngModel)]="reservationBook.showTablePlan"
                      (change)="saveReservationBookSettings()"
                    >
                      {{ 'ResSettings.ReservationSetting.ViewTablePlan' | translate }}
                      <div class="info"></div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <div>
                    <mat-slide-toggle
                      name="showReservationId"
                      [(ngModel)]="reservationBook.showReservationId"
                      (change)="saveReservationBookSettings()"
                    >
                      {{ 'ResSettings.ReservationSetting.ReservationId' | translate }}
                      <div class="info"></div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <div>
                    <mat-slide-toggle
                      name="phoneNoRequired"
                      [(ngModel)]="reservationBook.phoneNoRequired"
                      (change)="saveReservationBookSettings()"
                    >
                      Telefonummer Pflicht
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <!-- <div class="form-group row">
                        <div class="col-sm-12">
                        <div>
                            <mat-slide-toggle
                            name="showTimeFilter"
                            [(ngModel)]="reservationBook.showTimeFilter"
                            (change)="saveReservationBookSettings()"
                            >
                            Zeitfilter
                            <div class="info">Lass dir nur Reservierungen für einen bestimmten Zeitbereich anzeigen</div>
                            </mat-slide-toggle>
                        </div>
                        </div>
                    </div> -->
              <div class="form-group row">
                <label class="col-form-label col-sm-4" for="">{{
                  'ResSettings.ReservationSetting.QueryShiftTemplate' | translate
                }}</label>
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    [(ngModel)]="reservationBook.includeShift"
                    (change)="saveReservationBookSettings()"
                  >
                    <option value="0">{{ 'ResSettings.ReservationSetting.No' | translate }}</option>
                    <option value="1">Optional</option>
                    <option value="2">{{ 'ResSettings.ReservationSetting.Duty' | translate }}</option>
                  </select>
                </div>
              </div>

              <div style="margin-top: 50px">
                <h5 class="form-header">{{ 'ResSettings.ReservationSetting.TimeFilter' | translate }}</h5>
                <div class="form-desc">
                  <button class="btn btn-primary" (click)="addResTimeFilter()" style="margin-top:15px">
                    <i class="fa fa-plus-circle fa-fw"></i>
                    {{ 'ResSettings.ReservationSetting.NewTimeRange' | translate }}
                  </button>
                </div>
                <div class="alert alert-info" *ngIf="resTimeFilter && !resTimeFilter.length">
                  {{ 'ResSettings.ReservationSetting.NoDataFound' | translate }}
                </div>
                <div class="table-responsive shifts" *ngIf="resTimeFilter && resTimeFilter.length">
                  <table class="table table-padded">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>{{ 'ResSettings.ReservationSetting.From' | translate }}</th>
                        <th>{{ 'ResSettings.ReservationSetting.To' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody class="table-row-not-expanded" *ngFor="let filter of resTimeFilter; let i = index">
                      <tr>
                        <td>
                          <span>{{ filter.name }}</span>
                        </td>
                        <td>
                          <span>{{ filter.from }} </span>
                        </td>
                        <td>
                          <span>{{ filter.to }} </span>
                        </td>
                        <td style="width: 55px;">
                          <div class="shift-options" ngbDropdown>
                            <mat-icon svgIcon="settings" ngbDropdownToggle id="resTimeFilterDropdown"> </mat-icon>
                            <div ngbDropdownMenu aria-labelledby="resTimeFilterDropdown">
                              <button ngbDropdownItem (click)="editResTimeFilter(filter, i)">
                                {{ 'ResSettings.ReservationSetting.Edit' | translate }}
                              </button>
                              <button ngbDropdownItem (click)="deleteResTimeFilter(filter.id)">
                                {{ 'ResSettings.ReservationSetting.Delete' | translate }}
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- Start status array -->
              <div style="margin-top: 50px">
                <h5 class="form-header">{{ 'ResSettings.ReservationSetting.Status' | translate }}</h5>
                <div class="form-desc">
                  <button class="btn btn-primary" (click)="addStatus()" style="margin-top:15px">
                    <i class="fa fa-plus-circle fa-fw"></i>
                    {{ 'ResSettings.ReservationSetting.AddNewStatus' | translate }}
                  </button>
                </div>
                <div class="alert alert-info" *ngIf="resStatusList && !resStatusList.length">
                  {{ 'ResSettings.ReservationSetting.NoDataFound' | translate }}
                </div>
                <div class="table-responsive shifts" *ngIf="resStatusList && resStatusList.length">
                  <table class="table table-padded">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Colour</th>
                        <th>Icon</th>
                      </tr>
                    </thead>
                    <tbody class="table-row-not-expanded" *ngFor="let status of resStatusList; let i = index">
                      <tr>
                        <td>
                          <span>{{ status.label }}</span>
                        </td>
                        <td>
                          <span style="pointer-events: none;"
                            ><color-picker-wrapper [(color)]="status.color"></color-picker-wrapper>
                          </span>
                        </td>
                        <td>
                          <i [class]="status.icon"></i>
                        </td>
                        <td style="width: 55px;">
                          <div class="shift-options" ngbDropdown>
                            <mat-icon
                              svgIcon="settings"
                              ngbDropdownToggle
                              id="resStatusDropdown"
                              *ngIf="status.showActiveButton === true"
                            >
                            </mat-icon>
                            <div
                              ngbDropdownMenu
                              aria-labelledby="resStatusDropdown"
                              *ngIf="status.showActiveButton === true"
                            >
                              <button ngbDropdownItem (click)="editResStatus(status, i)">
                                {{ 'ResSettings.ReservationSetting.Edit' | translate }}
                              </button>
                              <button ngbDropdownItem (click)="deleteResStatus(status.id)">
                                {{ 'ResSettings.ReservationSetting.Delete' | translate }}
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- End status array -->

              <div class="row">
                <div class="col-md-6">
                  <legend style="margin-bottom: 10px;margin-top:30px;">
                    <span>{{ 'ResSettings.ReservationSetting.ExportCurrentRes' | translate }}</span>
                  </legend>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <div>
                        <button (click)="downloadCsv()" class="btn btn-primary">CSV Download</button>
                        <angular2csv
                          [hidden]="true"
                          class="btn btn-primary"
                          [data]="csvReservations"
                          filename="export_reservations_{{ currentDate | date: 'yyyy_MM_dd' }}"
                          [options]="csvOptions"
                          #csvComponent
                        ></angular2csv>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <legend style="margin-bottom: 10px;margin-top:30px;">
                    <span>{{ 'ResSettings.ReservationSetting.ImportRes' | translate }}</span>
                  </legend>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <div>
                        <a routerLink="/client/{{ activeClient?.id }}/reservation/import" class="btn btn-primary">{{
                          'ResSettings.ReservationSetting.OpenImportSetting' | translate
                        }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import { filter, distinctUntilChanged, map, subscribeOn } from 'rxjs/operators';
import { AuthenticationService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: Breadcrumb[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    public translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event: any) => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.authService.getClient(this.authService.activeClientId).subscribe(client => {
      const breadcrumb: Breadcrumb = {
        label: client.name,
        url: '/client/' + client.id + '/dashboard'
      };
      const root: ActivatedRoute = this.route.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
      this.breadcrumbs = [breadcrumb, ...this.breadcrumbs];

      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
        // set breadcrumbs
        // const root: ActivatedRoute = this.route.root;
        this.breadcrumbs = this.getBreadcrumbs(root);
        this.breadcrumbs = [breadcrumb, ...this.breadcrumbs];
      });
    });
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    let children: ActivatedRoute[] = route.children;
    if (route.firstChild) {
      children = route.firstChild.children;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET || child.snapshot.url.length === 0) {
        continue;
      }

      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      url += `/${routeURL}`;

      // add breadcrumb
      let breadcrumb: Breadcrumb = {
        label: child.snapshot.data['transPath']
          ? this.translate.instant(child.snapshot.data['transPath'])
          : child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        url: url
      };
      breadcrumbs.push(breadcrumb);

      for (const c of child.children) {
        // get the route's URL segment
        const rUrl = c.snapshot.url.map(segment => segment.path).join('/');

        // append route URL to URL
        const u = url + `/${rUrl}`;
        // add breadcrumb
        breadcrumb = {
          label: c.snapshot.data['transPath']
            ? this.translate.instant(c.snapshot.data['transPath'])
            : c.snapshot.data[ROUTE_DATA_BREADCRUMB],
          url: u
        };
        breadcrumbs.push(breadcrumb);
      }

      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }
}

export interface Breadcrumb {
  label: string;
  url: string;
}

import { Component, OnInit } from '@angular/core';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';

import * as jspdf from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

import moment from 'moment';
import { AccountingService } from '@app/accounting/accounting.service';
import { VouchersService } from '../vouchers.service';
moment.locale('de');

@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.scss']
})
export class PayoutComponent implements OnInit {
  payoutData: any;
  showPayoutId: number;
  payout: {
    id: number;
    status: string;
    paidAt: any;
    createdAt: any;
    value: number;
  };
  orders: any[] = [];
  ordersCsv: any = {};
  downloadingCsv: boolean = false;
  creds: any = {};
  loading: boolean = false;

  pdfSums: any = {
    bar: 0,
    online: 0,
    commission: 0
  };
  now: any = new Date();
  csvOptions: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: ['ID', 'Datum', 'Uhrzeit', 'Betrag', 'Gebühr', 'Zahlart', 'Besteller', 'Typ', 'Kontostand'],
    showTitle: false,
    title: '',
    useBom: false,
    removeNewLines: true,
    keys: [
      'id',
      'dateFormatted',
      'timeFormatted',
      'subtotal',
      'commissionFee',
      'paymentMethodText',
      'besteller',
      'typeText',
      'balance'
    ]
  };
  billingCase: any = { iban: '', elv: 0 };

  constructor(
    private voucherService: VouchersService,
    private clientResolver: ClientResolverService,
    private credentialsService: CredentialsService,
    private accountingService: AccountingService
  ) {}

  ngOnInit() {
    this.getPayouts();
    this.creds = this.credentialsService.getCredentials();
    this.getBillingcase();

    // this.cronjobRequestPayout();
  }

  getBillingcase() {
    this.accountingService.getBillingcase().subscribe((data: any) => {
      this.billingCase = data || { iban: '', elv: 0 };
    });
  }

  getPayouts() {
    this.voucherService.getPayouts().subscribe((payoutData: any) => {
      this.payoutData = payoutData;
      if (payoutData.payouts) {
        payoutData.payouts.reverse();
      }
      // console.log('this.payoutData', this.payoutData);
    });
  }

  requestPayout() {
    this.voucherService.requestPayout().subscribe((payoutData: any) => {
      this.getPayouts();
    });
  }
  cronjobRequestPayout() {
    this.voucherService.requestPayout().subscribe((payoutData: any) => {
      this.getPayouts();
    });
  }

  returnPrice(price: any) {
    if (typeof price == 'string') {
      return parseFloat(price.replace(',', '.'));
    } else {
      return price || 0;
    }
  }

  downloadPdf(payout: any) {
    this.loading = true;
    this.pdfSums = {
      bar: 0,
      online: 0,
      commission: 0,
      delivery: 0,
      tip: 0
    };
    this.orders = [];
    this.payout = payout || { id: 0 };
    this.voucherService.getVoucherBalance(payout.id).subscribe((orders: any) => {
      orders.forEach((order: any) => {
        if (order.type !== 'payout') {
          let tempOrder: any[] = [];
          // 'ID', 'Datum', 'Betrag', 'Gebühr', 'Typ', 'Kontostand',

          tempOrder.push(order.id);
          tempOrder.push(moment(order.createdAt).format('DD.MM.YYYY') + ' ' + moment(order.createdAt).format('H:mm'));
          this.pdfSums.online += this.returnPrice(order.value);
          tempOrder.push(
            this.returnPrice(order.value)
              .toFixed(2)
              .replace('.', ',') + ' €'
          );
          this.pdfSums.commission += this.returnPrice(parseFloat(order.fee) - parseFloat(order.shippingFee));
          tempOrder.push(
            this.returnPrice(parseFloat(order.fee) - parseFloat(order.shippingFee))
              .toFixed(2)
              .replace('.', ',') + ' €'
          );
          tempOrder.push(
            order.type
              .replace('payment', 'Onlinezahlung')
              .replace('refund', 'Gutschrift')
              .replace('pool', 'Pool')
              .replace('payout', 'Auszahlung')
          );
          tempOrder.push(
            this.returnPrice(order.balance)
              .toFixed(2)
              .replace('.', ',') + ' €'
          );
          this.orders.push(tempOrder);
          //   //tempOrder.push(order.type.replace('delivery', 'Lieferung').replace('takeaway', 'Abholung'));
          //   /*
          //   tempOrder.push(
          //     order.status
          //       .replace('confirmed', 'Fertig')
          //       .replace('pending', 'Ausstehend')
          //       .replace('canceled', 'Storniert')
          //   );
          //   if (order.gastropayTransaction) {
          //     tempOrder.push(
          //       this.returnPrice(order.gastropayTransaction.balance)
          //         .toFixed(2)
          //         .replace('.', ',') + ' €'
          //     );
          //   } else {
          //     tempOrder.push('');
          //   }
          //   */
          //   // tempOrder.push(`${order.firstName} ${order.lastName}`);
          //   tempOrder.push(
          //     this.returnPrice(order.commissionFee)
          //       .toFixed(2)
          //       .replace('.', ',') + ' €'
          //   );

          //   if (order.status !== 'canceled' && order.status !== 'paying') {
          //     if (order.deliveryFee) {
          //       this.pdfSums.delivery += this.returnPrice(order.deliveryFee);
          //     }
          //     if (order.tip) {
          //       this.pdfSums.tip += this.returnPrice(order.tip);
          //     }
          //     if (!order.gastropayTransaction) {
          //       this.pdfSums.commission += this.returnPrice(order.commissionFee);
          //       if (order.paymentMethod == 'cash' || order.paymentMethod == 'Bar') {
          //         this.pdfSums.bar += this.returnPrice(order.subtotal) + this.returnPrice(order.deliveryFee);
          //       } else {
          //         this.pdfSums.online += this.returnPrice(order.subtotal) + this.returnPrice(order.deliveryFee);
          //       }
          //       this.orders.push(tempOrder);
          //     } else {
          //       if (order.gastropayTransaction.clientId == order.clientId) {
          //         this.pdfSums.commission += this.returnPrice(order.commissionFee);
          //         if (order.paymentMethod == 'cash' || order.paymentMethod == 'Bar') {
          //           this.pdfSums.bar += this.returnPrice(order.subtotal) + this.returnPrice(order.deliveryFee);
          //         } else {
          //           this.pdfSums.online += this.returnPrice(order.subtotal) + this.returnPrice(order.deliveryFee);
          //         }
          //         this.orders.push(tempOrder);
          //       }
          //     }
          //   }
        }
      });

      this.orders.push([
        '--------',
        '------------------------',
        '------------',
        '------------',
        '------------------------',
        '-------------'
      ]);
      // this.orders.push(['Summe', 'Barzahlung', this.pdfSums.bar.toFixed(2).replace('.', ',') + ' €', '', '', '', '']);
      this.orders.push([
        'Summe',
        'Onlinezahlung',
        this.pdfSums.online.toFixed(2).replace('.', ',') + ' €',
        '',
        '',
        '',
        ''
      ]);
      this.orders.push([
        'Summe',
        'Gebühren',
        this.pdfSums.commission.toFixed(2).replace('.', ',') + ' €',
        '',
        '',
        '',
        ''
      ]);
      // this.orders.push([
      //   'Summe',
      //   'Lieferung',
      //   this.pdfSums.delivery.toFixed(2).replace('.', ',') + ' €',
      //   '',
      //   '',
      //   '',
      //   ''
      // ]);
      // this.orders.push(['Summe', 'Trinkgeld', this.pdfSums.tip.toFixed(2).replace('.', ',') + ' €', '', '', '', '']);

      setTimeout(
        function(_this: any) {
          _this.generatePdf();
        },
        1,
        this
      );
    });
  }

  public generatePdf() {
    let jspdfClass: any = jspdf;
    let doc: jspdf = new jspdfClass('p', 'mm', 'a4'); // A4 size page of PDF

    let img = new Image();
    img.src = 'assets/img/receipt_bg.png';

    let img2 = new Image();
    img2.src = 'assets/img/receipt_bg_clear.png';

    doc.addImage(img, 'png', 0, 0, 210, 297);
    doc.internal.events.subscribe('addPage', function() {
      let pageSize = doc.internal.pageSize;
      doc.addImage(img2, 'png', 0, 0, pageSize.width, pageSize.height);
    });

    doc.setFontSize(14);
    let statusText = '';
    if (this.payout.status === 'pending') {
      statusText = 'Ausstehend';
    }
    if (this.payout.status === 'completed') {
      statusText = 'Ausbezahlt am ' + moment(this.payout.paidAt).format('DD.MM.YYYY');
    }

    doc.setFontSize(11);
    doc.text(
      'Wertgutschein-Auszahlung ' +
        this.payout.id +
        ' vom ' +
        moment(this.payout.createdAt).format('DD.MM.YYYY') +
        '  |  Betrag: ' +
        this.returnPrice(this.payout.value)
          .toFixed(2)
          .replace('.', ',') +
        ' €   |  ' +
        statusText,
      16,
      75
    );
    //console.log('this.clientResolver', this.orders, this.orders[0].createdAt, this.orders[this.orders.length-1].createdAt);
    doc.text('Leistungszeitraum: ' + this.orders[this.orders.length - 4][1] + ' - ' + this.orders[0][1], 16, 81);

    doc.text(this.clientResolver.client.name, 16, 43); // 25
    doc.text('', 16, 49); // 31
    doc.text(this.clientResolver.client.street, 16, 55); // 37
    doc.text(this.clientResolver.client.zipCode + ' ' + this.clientResolver.client.location, 16, 61); // 43
    doc.setFontSize(10);
    doc.setTextColor(100);

    let tableHeader: any[] = [['ID', 'Datum', 'Betrag', 'Gebühr', 'Typ', 'Kontostand']];

    (doc as any).autoTable({
      bodyStyles: {
        valign: 'top'
      },
      styles: {
        overflow: 'linebreak'
      },
      head: tableHeader,
      body: this.orders,
      footer: tableHeader,
      theme: 'plain',
      startY: 90,
      didDrawCell: (data: any) => {
        // console.log(data.column.index)
      }
    });

    doc.text(
      'Onlinezahlung: ' +
        this.pdfSums.online.toFixed(2).replace('.', ',') +
        ' €  |  Gebühren: ' +
        this.pdfSums.commission.toFixed(2).replace('.', ',') +
        ' €',
      16,
      290
    );
    this.loading = false;
    doc.save('payout_orders_list.pdf');
  }
}

import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() initialTime: number = 15 * 60; // Default to 15 minutes if no value is provided
  timeLeft: number = 0; // Initialize to 0
  timer: any;

  constructor() {}

  ngOnInit(): void {
    this.resetTimer(this.initialTime); // Use the initialTime provided on component initialization
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialTime']) {
      this.resetTimer(changes['initialTime'].currentValue); // Reset the timer whenever initialTime changes
    }
  }

  ngOnDestroy(): void {
    this.clearTimer(); // Clear the timer when the component is destroyed
  }

  private startTimer(): void {
    this.clearTimer(); // Clear any existing interval
    this.timer = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.clearTimer(); // Stop the timer when it reaches 0
      }
    }, 1000); // Update every second
  }

  private resetTimer(newTime: number): void {
    this.timeLeft = newTime; // Set timeLeft to the new initialTime
    this.startTimer(); // Restart the timer
  }

  private clearTimer(): void {
    if (this.timer) {
      clearInterval(this.timer); // Clear the existing interval
      this.timer = null;
    }
  }

  // Format the time as MM:SS for display
  get formattedTime(): string {
    const minutes = Math.floor(this.timeLeft / 60);
    const seconds = this.timeLeft % 60;
    return `${this.pad(minutes)}:${this.pad(seconds)}`;
  }

  private pad(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }
}

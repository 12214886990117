import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import format from 'date-fns/format';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { ReservationService } from '../reservation.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { addMinutes } from 'date-fns';
import { AuthenticationService } from '@app/core';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

@Component({
  selector: 'app-hotel-reservation',
  templateUrl: './hotel-reservation.component.html',
  styleUrls: ['./hotel-reservation.component.scss']
})
export class HotelReservationComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  reservationFormGroup: FormGroup;
  isLoadingAutocomplete: boolean = false;
  filteredGuests: any[] = [];
  restaurants: any = [];
  times: any[] = [];
  incompatibilities: any[] = [];
  areAllTablesFreeAtSelectedTime: boolean = true;
  clientId: number;
  minArrivalDate: Date;
  minDepartureDate: Date;
  // stayTimeIsNotSelected: boolean = true;
  // stayTimeErrorMsg = 'Bitte wählen Sie zuerst die Aufenthaltsdauer.';
  tags: any[];
  previousArrivalDate: Date;
  previousDepartureDate: Date;
  stayTimes = [
    { name: '30 Minuten', value: 30 },
    { name: '1 Stunde', value: 60 },
    { name: '1.5 Stunden', value: 90 },
    { name: '2 Stunden', value: 120 }
  ];
  constructor(
    private formBuilder: FormBuilder,
    private reservationService: ReservationService,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.initForm();

    this.subscriptions.push(
      this.authService.getActiveClient().subscribe(data => {
        this.clientId = data.id;
      })
    );

    this.subscriptions.push(
      this.reservationFormGroup
        .get('name')
        .valueChanges.pipe(
          debounceTime(300),
          tap(() => (this.isLoadingAutocomplete = true)),
          switchMap(value =>
            this.reservationService
              .searchGuest({ name: value })
              .pipe(finalize(() => (this.isLoadingAutocomplete = false)))
          )
        )
        .subscribe(guests => {
          this.filteredGuests = guests;
        })
    );

    this.subscriptions.push(
      this.reservationService.getRooms().subscribe(rooms => {
        this.restaurants = rooms.filter(function(room: any) {
          return room.isActive == 1;
        });
      })
    );

    this.subscriptions.push(
      this.reservationService.getIncompatibilities().subscribe(incompatibilities => {
        this.incompatibilities = incompatibilities.data;
      })
    );

    this.minArrivalDate = new Date();
    this.minDepartureDate = new Date();

    this.subscriptions.push(
      this.reservationService.getTags().subscribe((tags: any) => {
        this.tags = tags;
      })
    );
  }

  createNumValues(number: number): any[] {
    const items: any[] = [];
    for (let i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

  addNewReservationGroup(date: Date): FormGroup {
    return this.formBuilder.group({
      date: [date, Validators.required],
      shift: [null, Validators.required],
      shifts: [null, Validators.required],
      time: [null, Validators.required],
      listOfTimes: [null, Validators.required],
      listOfRestaurants: [null, Validators.required],
      restaurant: [null, Validators.required],
      table: [null, Validators.required],
      listOfTables: [[]],
      errorMessage: ['', Validators.maxLength(1)]
    });
  }

  addNewReservationFormAtIndex(date: Date, index: number) {
    (<FormArray>this.reservationForm).insert(index, this.addNewReservationGroup(date));
  }

  deleteReservationFromArray(index: number) {
    (<FormArray>this.reservationForm).removeAt(index);
  }

  addElementsToReservations(startDate: Date, endDate: Date, addBreakfast: boolean, index: number) {
    while (startDate.getTime() <= endDate.getTime()) {
      if (addBreakfast || startDate.getTime() === endDate.getTime()) {
        //for first and last day add one reservation
        this.addNewReservationFormAtIndex(new Date(startDate), index);
        addBreakfast = false;
        index++;
      } else {
        // for else days add two reservations for breakfast and diner
        this.addNewReservationFormAtIndex(new Date(startDate), index);
        this.addNewReservationFormAtIndex(new Date(startDate), index);
        index += 2;
      }
      startDate.setDate(startDate.getDate() + 1);
    }
  }

  checkIsSelectedTimeIntervalValid(): void {
    let reservations = this.reservationForm as FormArray;
    const data1 = this.reservationFormGroup.value.arrivalDate;
    const data2 = this.reservationFormGroup.value.departureDate;

    // if arriving or departure date are changed during booking process change. add or remove items from reservations array.
    if (reservations.value.length > 0) {
      const arrivalDate = new Date(this.reservationFormGroup.get('arrivalDate').value);
      const departureDate = new Date(this.reservationFormGroup.get('departureDate').value);

      // if new arriving date is bigger than previous
      // delete elements from beginning of the reservations array
      if (arrivalDate.getTime() > this.previousArrivalDate.getTime()) {
        for (let i = 0; i < reservations.value.length; i++) {
          const element = reservations.value[i];
          if (new Date(element.date).getTime() <= arrivalDate.getTime()) {
            this.deleteReservationFromArray(0);
            this.deleteReservationFromArray(1);
          }
        }
        // if new arriving date is smaller than previous
        // add new items at beginning of the reservations array
      } else if (arrivalDate.getTime() < this.previousArrivalDate.getTime()) {
        const ad = new Date(arrivalDate);
        const dd = new Date(this.previousArrivalDate);
        let addBreakfast = true;
        let index = 0;
        this.addElementsToReservations(ad, dd, addBreakfast, index);

        // if new departure date is smaller than previous one
        // delete elements from end of the reservations array
      } else if (departureDate.getTime() < this.previousDepartureDate.getTime()) {
        for (let i = 0; i < reservations.value.length; i++) {
          const element = reservations.value[i];
          if (new Date(element.date).getTime() >= departureDate.getTime()) {
            this.deleteReservationFromArray(reservations.value.length - 1);
            this.deleteReservationFromArray(reservations.value.length - 2);
          }
        }
        // if new departure date is bigger than previous one
        // add new element to reservation array
      } else if (departureDate.getTime() > this.previousDepartureDate.getTime()) {
        const ad = new Date(this.previousDepartureDate);
        const dd = new Date(departureDate);
        let addBreakfast = true;
        const index = reservations.value.length;
        this.addElementsToReservations(ad, dd, addBreakfast, index);
      }
      // if dates are selected for the first time populate reservations array
    } else {
      if (data1 && data2 && data2 > data1) {
        const ad = new Date(data1);
        const dd = new Date(data2);
        let addBreakfast = true;
        const index = reservations.value.length;
        this.addElementsToReservations(ad, dd, addBreakfast, index);
      }
    }
    for (let i = 0; i < this.reservationForm.value.length; i++) {
      const element = this.reservationForm.value[i];
      if (!element.shifts) {
        // add shifts to each reservation day
        const reservationDate = moment(element.date).format('YYYY-MM-DD');
        this.subscriptions.push(
          this.reservationService.getShiftsOfDate(this.clientId, reservationDate).subscribe(res => {
            this.updateFormGroupValue('reservations', { shifts: res.shifts }, i);
          })
        );
      }
    }

    this.previousArrivalDate = new Date(this.reservationFormGroup.value.arrivalDate);
    this.previousDepartureDate = new Date(this.reservationFormGroup.value.departureDate);
  }

  displayFnName(guest: any): string {
    if (guest) {
      this.reservationFormGroup.get('incompatibilities').setValue(JSON.parse(guest.intolerance));
      return guest.name;
    }
  }

  createReservation(): void {
    // if everything is ok create new reservations
    if (this.reservationFormGroup.valid) {
      this.processReservations();
    }

    // check if input form is invalid without reservations form
    if (
      !this.reservationFormGroup.controls.name.valid ||
      !this.reservationFormGroup.controls.roomNumber.valid ||
      !this.reservationFormGroup.controls.reservationNumber.valid ||
      !this.reservationFormGroup.controls.arrivalDate.valid ||
      !this.reservationFormGroup.controls.departureDate.valid ||
      !this.reservationFormGroup.controls.stayTime.valid
    ) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    // if all input fields are valid except list of reservations show this confirm dialog
    if (!this.reservationFormGroup.controls.reservations.valid) {
      const modalRef = this.modalService.open(ConfirmModalComponent);
      modalRef.componentInstance.title = 'Nicht alle Reservierungen wurden erfüllt';
      modalRef.componentInstance.message = `Es gibt noch Tage ohne Reservierung. Trotzdem speichern?`;
      modalRef.componentInstance.showInfo = false;
      modalRef.componentInstance.buttonText = 'Ja';
      modalRef.result.then(
        result => {
          if (result === 'ok') {
            this.processReservations();
          }
        },
        () => {}
      );
    }
  }

  onTimeShiftSelect(event: any, index: number) {
    if (this.reservationForm.value[index].restaurant) {
      this.reservationForm.controls[index].get('restaurant').patchValue(null, { emitEvent: false });
      this.updateFormGroupValue('reservations', { listOfRestaurants: [], errorMessage: '' }, index);
    }
    if (this.reservationForm.value[index].time) {
      this.reservationForm.controls[index].get('time').patchValue(null, { emitEvent: false });
      this.updateFormGroupValue('reservations', { listOfTimes: [] }, index);
    }
    if (this.reservationForm.value[index].table) {
      this.reservationForm.controls[index].get('table').patchValue(null, { emitEvent: false });
      this.updateFormGroupValue('reservations', { listOfTables: [] }, index);
    }

    const shift: any = this.reservationForm.value[index].shifts.find((shift: any) => {
      return shift.id == event;
    });

    const activeRestaurants = shift.rooms.filter((restaurant: any) => {
      return restaurant.isActive == 1;
    });

    this.updateFormGroupValue('reservations', { listOfRestaurants: activeRestaurants }, index);
  }

  onRestaurantSelect(selectedRestaurantId: number, index: number): void {
    if (this.reservationForm.value[index].time) {
      this.reservationForm.controls[index].get('time').patchValue(null, { emitEvent: false });
      this.updateFormGroupValue('reservations', { listOfTimes: [] }, index);
    }
    if (this.reservationForm.value[index].table) {
      this.reservationForm.controls[index].get('table').patchValue(null, { emitEvent: false });
      this.updateFormGroupValue('reservations', { listOfTables: [] }, index);
    }

    const listOfReservations = this.reservationForm.value;
    const selectedRestaurant = this.restaurants.find(function(restaurant: any) {
      return restaurant.id == selectedRestaurantId;
    });

    this.subscriptions.push(
      this.reservationService
        .checkAvailableTimes(
          this.clientId,
          this.reservationFormGroup.get('numberOfPersons').value,
          listOfReservations[index].date,
          null,
          listOfReservations[index].shift,
          this.reservationFormGroup.get('stayTime').value.value,
          listOfReservations[index].restaurant
        )
        .subscribe(res => {
          if (res.times.length === 0) {
            this.updateFormGroupValue('reservations', { errorMessage: 'Keine freien Uhrzeiten' }, index);
          }

          this.updateFormGroupValue('reservations', { listOfTimes: res.times }, index);
        })
    );
  }

  onTimeSelect(time: string, index: number) {
    const selectedRestaurantId = this.reservationForm.value[index].restaurant;
    const data = this.reservationFormGroup.value;
    const res = this.reservationForm.value[index];

    this.subscriptions.push(
      this.reservationService
        .checkFreeTables(res.date, time, data.numberOfPersons, data.stayTime.value, true, true)
        .subscribe(response => {
          const selectedRestaurant = response.find((restaurant: any) => {
            return restaurant.id == selectedRestaurantId;
          });
          this.fillTableArrayWithAwailableTables(selectedRestaurant, index);
        })
    );

    const listOfReservations = this.reservationForm.value;
    // set guest at same table
    for (let i = 0; i < listOfReservations.length; i++) {
      const element = listOfReservations[i];
      if (element.restaurant === listOfReservations[index].restaurant && element.table !== null) {
        this.updateFormGroupValue('reservations', { table: element.table }, index);
      }
    }
    this.updateFormGroupValue('reservations', { time }, index);
  }

  fillTableArrayWithAwailableTables(selectedRestaurant: any, index: number): void {
    const tables: any[] = [];
    for (let i = 0; i < selectedRestaurant.tables.length; i++) {
      const element = selectedRestaurant.tables[i];

      let disabled = false;
      let msg = 'Der Tisch ist frei';

      if (element.isFree === false) {
        disabled = true;
        msg = 'Der Tisch ist besetzt';
      } else if (parseInt(this.reservationFormGroup.get('numberOfPersons').value) > element.seats) {
        disabled = true;
        msg = `Max ${element.seats} Personen`;
      }
      tables.push({ table: element, disabled, msg });
    }

    this.updateFormGroupValue('reservations', { listOfTables: tables }, index);
  }

  onNumberOfPersonsChange() {
    for (let i = 0; i < this.reservationForm.value.length; i++) {
      const element = this.reservationForm.value[i];
      if (element.restaurant) {
        this.onRestaurantSelect(element.restaurant, i);
      }
    }
  }

  updateFormGroupValue(formGroupName: string, value: any, index: number): void {
    (<FormArray>this.reservationFormGroup.controls[formGroupName]).at(index).patchValue(value, { emitEvent: false });
  }

  processReservations(): void {
    const reservationFormData = this.reservationFormGroup.value;
    const reservationArray = reservationFormData.reservations;
    const reservations = [];
    for (let i = 0; i < reservationArray.length; i++) {
      const tempObject: any = {};
      const element = reservationArray[i];
      if (element.date && element.restaurant && element.table) {
        tempObject.reservedFor = format(element.date, 'YYYY-MM-DD') + ' ' + element.time + ':00:00';
        tempObject.room = element.restaurant;
        tempObject.table = element.table;
        reservations.push(tempObject);
      }
    }

    if (reservations.length === 0) {
      this.snackBar.open('Mindestens eine Reservierung muss abgeschlossen sein', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    const reservation = {
      name: reservationFormData.name,
      roomNumber: reservationFormData.roomNumber,
      reservationNumber: reservationFormData.reservationNumber,
      arrivalDate: reservationFormData.arrivalDate,
      departureDate: reservationFormData.departureDate,
      selectedTags: reservationFormData.selectedTags,
      numberOfPersons: reservationFormData.numberOfPersons,
      numberOfKids: reservationFormData.numberOfKids,
      numberOfHighChairs: reservationFormData.numberOfHighChairs,
      incompatibilities: reservationFormData.incompatibilities,
      notes: reservationFormData.notes,
      reservations: reservations,
      stayTime: reservationFormData.stayTime.value,
      createdAt: new Date()
    };

    this.subscriptions.push(
      this.reservationService.addMultipleReservations(reservation).subscribe(
        (res: any) => {
          if (res) {
            this.snackBar.open('Sie haben erfolgreich gebucht.', '', {
              duration: 3000,
              panelClass: ['snackbar-success']
            });
            this.initForm();
          }
        },
        err => {
          if (err.status === 422) {
            this.snackBar.open(err.error.msg, '', {
              duration: 3000,
              panelClass: ['snackbar-error']
            });
          }
        }
      )
    );
  }

  initForm(): void {
    this.reservationFormGroup = this.formBuilder.group({
      name: [null, Validators.required],
      roomNumber: [null, Validators.required],
      reservationNumber: ['', Validators.required],
      arrivalDate: [null, Validators.required],
      departureDate: [null, Validators.required],
      stayTime: new FormControl(null, Validators.required),
      selectedTags: [[]],
      numberOfPersons: [1, Validators.required],
      numberOfKids: [0, Validators.required],
      numberOfHighChairs: [0, Validators.required],
      incompatibilities: [null],
      notes: [null],
      reservations: this.formBuilder.array([])
    });
    setTimeout(() => {
      this.reservationFormGroup.controls['stayTime'].setValue(this.stayTimes[1]);
    }, 500);
  }

  get reservationForm() {
    return <FormArray>this.reservationFormGroup.get('reservations');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}

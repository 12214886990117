import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService, CredentialsService } from '@app/core';
import { environment } from '@env/environment';
import { map, tap } from 'rxjs/operators';
import { ClientResolverService } from '@app/core/client-resolver.service';
import format from 'date-fns/format';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GastroPayService {
  activeClient: any;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private credentialsService: CredentialsService,
    private clientResolver: ClientResolverService
  ) {}

  getOrders() {
    this.activeClient = this.clientResolver.client;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + this.activeClient.gastropay.token
    });
    return this.http
      .get<any>(environment.gastroPayUrl + `admin/selforders/?sort=id&order=desc&take=10&skip=0`, {
        headers: headers
      })
      .pipe(
        map((orders: any) => {
          return orders;
        })
      );
  }
  getAffiliates(memberId: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return (
      this.http
        // .get<any>(environment.serverUrl + `gastropay/affiliate/clients/${memberId}`, {
        .get<any>(environment.serverUrl + `gastropay/affiliate/clients/${memberId}`, {
          headers: headers
        })
        .pipe(
          map((clients: any) => {
            return clients;
          })
        )
    );
  }
  getRestApiKey(generateNew: boolean = false) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(
        environment.serverUrl +
          `gastropay/rest-api-key/` +
          this.authService.activeClientId +
          (generateNew ? '/create' : ''),
        {
          headers: headers
        }
      )
      .pipe(
        map((clients: any) => {
          return clients;
        })
      );
  }

  setOrderItemsStatus(orderId: number, orderItems: Array<any>, status: number) {
    this.activeClient = this.clientResolver.client;

    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + this.activeClient.gastropay.token
    });
    const data = {
      id: orderId,
      orderItems: orderItems
    };
    return this.http
      .put<any>(environment.gastroPayUrl + `admin/selforder/deliverystatus/`, data, {
        headers: headers
      })
      .pipe(
        map((orders: any) => {
          return orders;
        })
      );
  }

  getReport(from: Date, to: Date) {
    this.activeClient = this.clientResolver.client;

    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + this.activeClient.gastropay.token
    });

    return this.http
      .get<any>(
        environment.gastroPayUrl + `report/?from=${format(from, 'YYYY-MM-DD')}&until=${format(to, 'YYYY-MM-DD')}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((report: any) => {
          return report;
        })
      );
  }

  getChartData(from: Date, to: Date, type: string) {
    this.activeClient = this.clientResolver.client;

    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + this.activeClient.gastropay.token
    });

    return this.http
      .get<any>(
        environment.gastroPayUrl +
          `report/${type}/?from=${format(from, 'YYYY-MM-DD')}&until=${format(to, 'YYYY-MM-DD')}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((report: any) => {
          return report;
        })
      );
  }

  getSettings(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `gastropay/settings/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  saveSettings(data: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `gastropay/settings/`,
        { clientId: this.authService.activeClientId, ...data },
        {
          headers: headers
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  saveAnalytics(data: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `gastropay/analytics/`,
        { clientId: this.authService.activeClientId, ...data },
        {
          headers: headers
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // Get Gastrodigital orders
  getOwnOrdersByDate(dateString: string) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `gastropay/orders/` + this.authService.activeClientId + '/' + dateString, {
        headers: headers
      })
      .pipe(
        map((orders: any) => {
          return orders;
        })
      );
  }
  getOwnOrders(blank: boolean = false, dateString: string = '', allPending: boolean = false, status = '') {
    let ordersType = 'orders';
    let params = `${dateString}/${allPending}/${status}`;
    if (blank) {
      ordersType = 'blankOrders';
      params = ``;
    }
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `gastropay/${ordersType}/${this.authService.activeClientId}/${params}`, {
        headers: headers
      })
      .pipe(
        map((orders: any) => {
          return orders;
        })
      );
  }
  getPayoutOrders(blank: boolean = false, payoutId: any) {
    let ordersType = 'orders';
    if (blank) {
      ordersType = 'blankOrders';
    }
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `gastropay/${ordersType}/${this.authService.activeClientId}/${payoutId}`, {
        headers: headers
      })
      .pipe(
        map((orders: any) => {
          return orders;
        })
      );
  }

  getOwnCashOrders(clients: any[] = [], from: string = '0000-00-00', to: string = '3000-00-00', sorting: string = '') {
    let clientId = `${this.authService.activeClientId}`;
    if (clients.length > 0) {
      clients.forEach(row => {
        clientId = `${clientId},${row.clientId}`;
      });
      clientId = clientId.substr(1);
    }

    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `gastropay/ownCashOrders/${clientId}/${sorting}${from}/${sorting}${to}`, {
        headers: headers
      })
      .pipe(
        map((orders: any) => {
          return orders;
        })
      );
  }
  getOwnTransactionOrders(
    clients: any[] = [],
    from: string = '0000-00-00',
    to: string = '3000-00-00',
    sorting: string = ''
  ) {
    let clientId = `${this.authService.activeClientId}`;
    if (clients.length > 0) {
      clients.forEach(row => {
        clientId = `${clientId},${row.clientId}`;
      });
      clientId = clientId.substr(1);
    }

    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(
        environment.serverUrl + `gastropay/ownTransactionOrders/${clientId}/${sorting}${from}/${sorting}${to}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((orders: any) => {
          return orders;
        })
      );
  }

  getReservationsTransactions() {
    let clientId = this.authService.activeClientId;

    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/transactions/${clientId}`, {
        headers: headers
      })
      .pipe(
        map((stats: any) => {
          return stats;
        })
      );
  }

  getPayoutReservations(payoutId: any) {
    let clientId = this.authService.activeClientId;

    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/transactions/${clientId}/${payoutId}`, {
        headers: headers
      })
      .pipe(
        map((stats: any) => {
          return stats;
        })
      );
  }

  changeOrderStatus(orderId: number, status: string) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `gastropay/order/status`,
        {
          orderId: orderId,
          status: status
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((order: any) => {
          return order;
        })
      );
  }

  getDeliveryAreas() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `gastropay/delivery-areas/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((deliveryAreas: any) => {
          return deliveryAreas;
        })
      );
  }

  addDeliveryArea(deliveryArea: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `gastropay/delivery-areas`,
        { clientId: this.authService.activeClientId, ...deliveryArea },
        {
          headers: headers
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  saveDeliveryArea(deliveryArea: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .put<any>(
        environment.serverUrl + `gastropay/delivery-areas`,
        { clientId: this.authService.activeClientId, ...deliveryArea },
        {
          headers: headers
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  deleteDeliveryArea(areaId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `gastropay/delivery-areas/` + areaId, {
        headers: headers
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  setDeliveryTime(orderId: number, deliveryTime: number): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `gastropay/order/delivery-time`,
        { orderId: orderId, deliveryTime: deliveryTime },
        {
          headers: headers
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getTimes() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `gastropay/settings/times/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((times: any) => {
          return times;
        })
      );
  }

  addTime(time: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    time.clientId = this.authService.activeClientId;
    return this.http
      .post<any>(environment.serverUrl + `gastropay/settings/times`, time, {
        headers: headers
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  saveTime(time: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    time.clientId = this.authService.activeClientId;
    return this.http
      .put<any>(environment.serverUrl + `gastropay/settings/times`, time, {
        headers: headers
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  deleteTime(timeId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `gastropay/settings/times/` + timeId, {
        headers: headers
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getShortStats() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `gastropay/stats/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((stats: any) => {
          return stats;
        })
      );
  }

  createOrderlistPdf(data: any = { orders: [] }): Observable<Blob> {
    const headers = new HttpHeaders({
      Authorization: this.getAuthToken(),
      Accept: 'application/pdf'
    });

    return this.http
      .post(environment.serverUrl + `gastropay/createOrderlistPdf/`, data, { headers, responseType: 'blob' })
      .pipe(
        map(response => {
          if (response.type !== 'application/pdf') {
            throw new Error('Received non-PDF response');
          }
          return new Blob([response], { type: 'application/pdf' });
        })
      );
  }

  downloadOrderlistPdf(data: any = { orders: [] }): Promise<Blob> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post(environment.serverUrl + `gastropay/createOrderlistPdf/`, data, { headers, responseType: 'blob' })
      .toPromise();
  }

  createInvoicePdf(invoice: any = null) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http.post<any>(environment.serverUrl + `gastropay/createInvoicePdf/`, { invoice }, { headers }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getPayouts(type: string = '') {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `gastropay/payouts/` + this.authService.activeClientId + type, {
        headers: headers
      })
      .pipe(
        map((payouts: any) => {
          return payouts;
        })
      );
  }

  requestPayout(path: string = 'gastropay'): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + path + `/payout/`,
        { clientId: this.authService.activeClientId },
        {
          headers: headers
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  printBon(orderId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `gastropay/print-bon`,
        {
          orderId: orderId,
          clientId: this.authService.activeClientId
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getZipcodeLocations(zipcode: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `private/zipcode-locations/${zipcode}`, {
        headers: headers
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  getLocationDisctricts(location: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `private/location-districts/${location}`, {
        headers: headers
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  saveOrderTimeLimit(data: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `gastropay/save-order-time-limit/`,
        { clientId: this.authService.activeClientId, ...data },
        {
          headers: headers
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  addSpecialGastroTimes(entry: any) {
    return new Promise(resolve => {
      const cred = this.credentialsService.credentials;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      entry.clientId = this.authService.activeClientId;
      return this.http
        .post<any>(environment.serverUrl + `gastropay/add-special-gastro-times/`, entry, {
          headers: headers
        })
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch(error => {
          resolve(false);
        });
    });
  }

  getSpecialGastroTimes() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `gastropay/settings/special-gastro-times/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((times: any) => {
          return times;
        })
      );
  }
  editSpecialGastroTimes(entry: any) {
    return new Promise(resolve => {
      const cred = this.credentialsService.credentials;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      entry.clientId = this.authService.activeClientId;
      return this.http
        .put<any>(environment.serverUrl + `gastropay/settings/edit-special-gastro-times/`, entry, {
          headers: headers
        })
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch(error => {
          resolve(false);
        });
    });
  }
  deleteSpecialGastroTimes(timeId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `gastropay/settings/delete-special-gastro-times/` + timeId, {
        headers: headers
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getAuthToken(): string {
    const cred = this.credentialsService.credentials;
    return 'Bearer ' + cred.token;
  }
}

import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ReservationService } from '@app/reservation/reservation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, Subscriber } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-pattern',
  templateUrl: './add-pattern.component.html',
  styleUrls: ['./add-pattern.component.scss']
})
export class AddPatternComponent implements OnInit, OnDestroy {
  @Input() public pattern: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  name: string;
  image: string;
  isSubmited: boolean = false;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public reservationService: ReservationService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (this.pattern) {
      this.name = this.pattern.name;
      this.image = this.pattern.image;
    }
  }

  onChange(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.convertToBase64(file);
  }

  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe(data => {
      this.image = data;
    });
  }

  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      subscriber.next(fileReader.result);
    };
    fileReader.onerror = error => {
      subscriber.error(error);
      subscriber.complete();
    };
  }

  editPattern() {
    this.isSubmited = true;
    if (!this.name || this.name === '' || !this.image || this.image === '') {
      return;
    }
    this.pattern.name = this.name;
    this.pattern.image = this.image;
    this.reservationService
      .editPattern(this.pattern)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pattern: any) => {
        this.passEntry.emit(pattern);
      });
  }

  createPattern() {
    this.isSubmited = true;
    if (!this.name || this.name === '' || !this.image || this.image === '') {
      return;
    }
    this.reservationService
      .createPattern({ name: this.name, image: this.image })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pattern: any) => {
        this.passEntry.emit(pattern);
      });
  }

  getSanitizedSafeUrl(img: string) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${img})`);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-status',
  templateUrl: './add-status.component.html',
  styleUrls: ['./add-status.component.scss']
})
export class AddStatusComponent implements OnInit, OnDestroy {
  @Input() public editStatusData: any; // only if editing
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  resStatusFormGroup: FormGroup;
  private ngUnsubscribe: Subject<any> = new Subject();
  icons = [
    { name: 'Money', value: 'fas fa-money-bill-wave' },
    { name: 'Clock', value: 'fas fa-clock' },
    { name: 'Star', value: 'fas fa-star' },
    { name: 'Asterisk', value: 'fas fa-asterisk' },
    { name: 'Bolt', value: 'fas fa-bolt' },
    { name: 'Crosshairs', value: 'fas fa-crosshairs' },
    { name: 'Paper-plane', value: 'fas fa-paper-plane' }
    // Add more icons as needed
  ];

  constructor(public activeModal: NgbActiveModal, public formBuilder: FormBuilder, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.resStatusFormGroup = this.formBuilder.group({
      label: ['', Validators.required],
      color: ['#ff0000', Validators.required],
      value: [''],
      icon: ['', Validators.required]
    });

    if (this.editStatusData) {
      this.resStatusFormGroup.addControl('id', new FormControl(this.editStatusData.id, Validators.required));
      this.resStatusFormGroup.get('label').setValue(this.editStatusData.label);
      this.resStatusFormGroup.get('color').setValue(this.editStatusData.color);
      this.resStatusFormGroup.get('value').setValue(this.editStatusData.label);
      this.resStatusFormGroup.get('icon').setValue(this.editStatusData.icon);
    }
  }

  changeMainColor(e: any) {
    this.resStatusFormGroup.controls['color'].setValue(e);
  }

  add() {
    if (!this.resStatusFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben.', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.resStatusFormGroup.get('value').setValue(this.resStatusFormGroup.value.label);
    this.passEntry.emit(this.resStatusFormGroup.value);
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

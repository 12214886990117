import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReservationService } from '../reservation.service';
import { MatSnackBar } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddAlertComponent } from '../reservation-settings/add-alert/add-alert.component';
import { Alert } from '../reservation-settings/reservation-settings.model';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import 'rxjs/add/operator/takeUntil';

@Component({
  selector: 'app-reservation-alerts',
  templateUrl: './reservation-alerts.component.html',
  styleUrls: ['./reservation-alerts.component.scss']
})
export class ReservationAlertsComponent implements OnInit, OnDestroy {
  alerts: any;
  archiveAlerts: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  lang: boolean;

  constructor(
    private reservationService: ReservationService,
    private snackBar: MatSnackBar,
    private modalService: NgbModal,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.getAlerts();
    this.getArchiveAlert();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAlerts() {
    this.reservationService
      .getAlerts('active')
      .takeUntil(this.ngUnsubscribe)
      .subscribe((alerts: any) => {
        this.alerts = alerts;
      });
  }

  getArchiveAlert() {
    this.reservationService
      .getArchiveAlerts('archive')
      .takeUntil(this.ngUnsubscribe)
      .subscribe((alerts: any) => {
        this.archiveAlerts = alerts;
      });
  }

  addAlert() {
    const modalRef = this.modalService.open(AddAlertComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.reservationService
        .addAlert(receivedEntry)
        .takeUntil(this.ngUnsubscribe)
        .subscribe((response: any) => {
          this.alerts.push(receivedEntry);
          const snackBarRef = this.snackBar.open('Hinweis erfolgreich hinzugefügt', 'Ok', {
            duration: 3000
          });
          modalRef.close();
          this.ngOnInit();
        });
    });
  }

  editAlert(alert: Alert, index: number) {
    const modalRef = this.modalService.open(AddAlertComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editAlertData = { ...alert };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.reservationService
        .editAlert(receivedEntry)
        .takeUntil(this.ngUnsubscribe)
        .subscribe((response: any) => {
          this.alerts[index] = receivedEntry;
          const snackBarRef = this.snackBar.open('Hinweis erfolgreich geändert', 'Ok', {
            duration: 3000
          });
          modalRef.close();
          this.ngOnInit();
        });
      modalRef.close();
    });
  }

  deleteAlert(alertId: number, type: string) {
    this.reservationService
      .deleteAlert(alertId)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(() => {
        // Delete from array
        if (type == 'active') {
          this.alerts = this.alerts.filter((obj: any) => {
            return obj.id !== alertId;
          });
        } else {
          this.archiveAlerts = this.archiveAlerts.filter((obj: any) => {
            return obj.id !== alertId;
          });
        }

        const snackBarRef = this.snackBar.open('Hinweis erfolgreich gelöscht', 'Ok', {
          duration: 3000
        });
      });
  }
}

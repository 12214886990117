import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { VouchersService } from '../../vouchers.service';

@Component({
  selector: 'app-change-voucher-post-codes',
  templateUrl: './change-voucher-post-codes.component.html',
  styleUrls: ['./change-voucher-post-codes.component.scss']
})
export class ChangeVoucherPostCodes implements OnInit {
  @Input() order: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public transactions: any;
  public vouchers: any[] = [];
  voucherValue: null;
  codeArray: any[] = [];
  constructor(
    private snackBar: MatSnackBar,
    public activeModal: NgbActiveModal,
    private voucherService: VouchersService
  ) {}

  ngOnInit() {
    this.getTransactions();
    this.order.templates.map((item: any) => {
      this.codeArray[item.id] = '';
    });
  }

  save() {
    let isError = false;
    this.codeArray.map((item, key) => {
      if (key && (!item || !item.code)) {
        isError = true;
        this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
        return;
      }
    });
    if (!isError) {
      this.passEntry.emit(this.codeArray);
      this.activeModal.dismiss('');
    }
  }

  async getTransactions(voucherCode: string = '') {
    await this.voucherService
      .getVoucherTransactions(this.order.betriebId, voucherCode)
      .toPromise()
      .then((data: any) => {
        this.transactions = data;
        if (this.transactions.voucher) {
          // Reset voucher list
          this.vouchers = [];
          let voucherKeys: any[] = [];

          // Sum transaction values
          this.transactions.voucher.forEach((row: any) => {
            if (row) {
              if (!voucherKeys.includes(row.code)) {
                voucherKeys.push(row.code);
                this.vouchers.push({ id: row.id, code: row.code });
              }
            }
          });
          this.vouchers.sort(this.dynamicSort('code'));
          if (voucherCode && voucherCode != '') {
            this.transactions = this.transactions.voucher.filter(function(el: any) {
              if (el.code) {
                return el.code.includes(voucherCode);
              } else {
                return false;
              }
            });
          }
        }
      });
  }
  async filterVoucher(voucher: any, id: number) {
    // this.transactions = [];
    if (voucher === undefined || !voucher) {
      voucher = { code: '' };
    }
    if (voucher.code) {
      this.voucherValue = voucher.code;
      // this.getTransactions(this.voucherValue);
    }
    this.codeArray[id] = voucher;
  }

  async searchVoucher(voucher: any) {
    if (voucher && voucher.term.length >= 3) {
      this.voucherValue = voucher.term;
      this.getTransactions(voucher.term);
    } else if (!voucher) {
      this.getTransactions();
    }
  }

  dynamicSort(property: string) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function(a: any, b: any) {
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
}

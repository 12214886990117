import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { AuthenticationService } from './core';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  newReservation = this.socket.fromEvent<any>('newReservation');
  updateReservation = this.socket.fromEvent<any>('updateReservation');
  uploadFiles = this.socket.fromEvent<any>('filesUploaded');
  newOrder = this.socket.fromEvent<any>('newOrder');

  constructor(private socket: Socket, private authService: AuthenticationService) {
    socket.on('connect', () => {
      this.authService.getActiveClient().subscribe((clientData: any) => {
        this.joinClient(clientData.id);
      });
      console.log('connected to server');
    });
  }

  joinClient(clientId: number) {
    this.socket.emit('register', clientId);
  }
}

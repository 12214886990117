var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, ElementRef } from '@angular/core';
import { locale, loadMessages } from 'devextreme/localization';
import deMessages from 'devextreme/localization/messages/de.json';
import * as deLocale from 'date-fns/locale/de/index.js';
import { ReservationService } from '../reservation.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { DxSchedulerComponent } from 'devextreme-angular/ui/scheduler';
import { DxContextMenuComponent } from 'devextreme-angular/ui/context-menu';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { differenceInMinutes, subDays, addDays, isSameDay } from 'date-fns';
import { Page } from '../reservation.model';
import { AddReservationComponent } from '../reservations/add-reservation/add-reservation.component';
import { MatSnackBar, MatBottomSheet, MatBottomSheetRef } from '@angular/material';
import { AuthenticationService, Constants, CredentialsService, resStatus } from '@app/core';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import * as screenfull from 'screenfull';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NavService } from '@app/nav.service';
import 'rxjs/add/operator/takeUntil';
import moment from 'moment';
import { PendingReservationsComponent } from '../reservations/pending-reservations/pending-reservations.component';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
const jsPDF = require('jspdf');
require('jspdf-autotable');
moment.locale('de');
export class ReservationListByDateComponent {
    constructor(snackBar, reservationService, modalService, authService, 
    // private socketService: SocketService,
    deviceService, breakpointObserver, navService, bottomSheet, credentialsService, route) {
        this.snackBar = snackBar;
        this.reservationService = reservationService;
        this.modalService = modalService;
        this.authService = authService;
        this.deviceService = deviceService;
        this.breakpointObserver = breakpointObserver;
        this.navService = navService;
        this.bottomSheet = bottomSheet;
        this.credentialsService = credentialsService;
        this.route = route;
        this.range = new FormGroup({
            startDate: new FormControl(),
            endDate: new FormControl()
        });
        this.dateOptions = {
            locale: deLocale
        };
        this.todayDate = new Date();
        this.dataSource = {};
        this.tablesData = [];
        this.rooms = [];
        this.shifts = [];
        this.temp = [];
        this.currentDate = new Date();
        this.pendingCounter = 0;
        this.reservations = [];
        this.tags = [];
        this.allTags = [];
        this.intolerance = [];
        this.resAddOns = [];
        this.filteredReservations = [];
        this.resetTimeFilter = false;
        this.allStatus = resStatus;
        this.isFullscreen = false;
        this.view = 'list';
        this.page = new Page();
        this.editing = {};
        this.columns = [{ name: 'Uhrzeit' }, { name: 'Gast' }, { name: 'Personen' }, { name: 'Tisch' }, { name: 'Status' }];
        this.isDoubleClick = 0;
        this.prevCellData = null;
        this.resMenuOrderAlert = [];
        this.filter = {
            name: '',
            status: null,
            tags: [],
            roomId: null,
            shiftId: null
        };
        this.schedulerHeight = '';
        this.listHeight = 'initial';
        // timeFilter = [
        //   { name: 'Morgen', from: '07:00', to: '11:00' },
        //   { name: 'Mittag', from: '11:00', to: '16:00' },
        //   { name: 'Abend', from: '16:00', to: '24:00' }
        // ];
        this.timeFilter = [];
        this.selectedTimeFilter = [];
        this.tempRes = [];
        this.showExtraFilters = false;
        this.reservationContextMenuItems = [];
        // Edit mode(for mobile touch drag)
        this.editMode = false;
        // Fake fullscreen
        this.isFakeFullscreen = false;
        // Layout
        this.LAYOUT = {
            XXS: 450,
            XS: 768,
            SM: 992,
            MD: 1200,
            LG: 1600,
            XL: 1920,
            XXL: 2560
        };
        this.layout = this.LAYOUT.MD;
        this.ngUnsubscribe = new Subject();
        this.isArchive = false;
        this.reservationListActive = true;
        this.sortReservationList = [];
        this.resStatusList = [];
        loadMessages(deMessages);
        locale('de');
        this.reservationService
            .getReservationBookSettings()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((settings) => {
            this.bookSettings = settings;
        });
        this.resId = this.route.snapshot && this.route.snapshot.params ? this.route.snapshot.params.reservationId : '';
        this.page.pageNumber = 0;
        this.page.size = 20;
        // Create context menu
        this.cellContextMenuItems = [
            {
                text: 'Neue Reservierung',
                onItemClick: (e) => this.addReservation(false, this.contextMenuCellData.startDate, this.contextMenuCellData.endDate, this.contextMenuCellData.groups.tableId)
            },
            {
                text: 'Neuer Walk-In',
                onItemClick: () => this.addReservation(true, this.contextMenuCellData.startDate, this.contextMenuCellData.endDate, this.contextMenuCellData.groups.tableId, true)
            }
        ];
        /*
            (screenfull as Screenfull).on('change', () => {
            if ((screenfull as Screenfull).isFullscreen) {
                console.log('fullscreen');
                this.isFullscreen = true;
                // Set height of scheduler to fit window height and make inner table scrollable
                if (this.view === 'timeline') {
                setTimeout(() => {
                    // Set scheduler height
                    this.schedulerHeight =
                    this.fullscreenEl.nativeElement.offsetHeight -
                    (this.roomTabsGroup._elementRef.nativeElement.offsetHeight +
                        this.buttonBar.nativeElement.offsetHeight -
                        10);
                    this.scheduler.instance.repaint();
                }, 200);
                } else {
                // Set list height
                setTimeout(() => {
                    this.listHeight = window.outerHeight - this.buttonBar.nativeElement.offsetHeight + 'px';
                    this.table.recalculate();
                }, 200);
                }
            } else {
                this.isFullscreen = false;
                this.schedulerHeight = '';
                if (this.scheduler) {
                this.scheduler.instance.repaint();
                }
            }
            });
            */
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.resId) {
                this.currentDate = yield this.reservationService
                    .getDateofReservation(this.resId)
                    .toPromise()
                    .then((data) => {
                    console.log('data ===>', data);
                    this.currentDate = new Date(data.reservedFor);
                    return new Date(data.reservedFor);
                });
            }
            this.authService
                .getActiveClient()
                .takeUntil(this.ngUnsubscribe)
                .subscribe((clientData) => {
                this.activeClient = clientData;
                // (<any>window).ga(
                //   'set',
                //   'title',
                //   'Reservierungsbuch - ' + this.activeClient.name + ' ' + this.activeClient.location
                // );
                // this.socketService.joinClient(this.activeClient.id);
            });
            this.getResTimeFilter();
            this.getRooms();
            this.getShifts();
            this.getAllTags();
            this.getAlerts();
            this.reservationService.getIncompatibilities().subscribe(intolerance => {
                this.intolerance = intolerance.data;
            });
            this.reservationService.getReservationAddOnTemplates().subscribe(resAddOns => {
                this.resAddOns = resAddOns;
            });
            let endDate = moment().format('YYYY-MM-DD');
            let startDate = moment()
                .subtract(10, 'days')
                .format('YYYY-MM-DD');
            this.range.get('startDate').setValue(startDate);
            this.range.get('endDate').setValue(endDate);
            this.selectedStartDate = new Date(this.range.get('startDate').value);
            this.selectedEndDate = new Date(this.range.get('endDate').value);
            console.log('startDate ====>', this.selectedStartDate);
            console.log('endDate ====>', this.selectedEndDate);
            this.getAllReservations();
            document.addEventListener('fullscreenchange', e => {
                if (document && document['fullscreenElement']) {
                    this.isFullscreen = true;
                    // Set height of scheduler to fit window height and make inner table scrollable
                    if (this.view === 'timeline') {
                        setTimeout(() => {
                            // Set scheduler height
                            this.schedulerHeight =
                                window.innerHeight -
                                    (this.roomTabsGroup._elementRef.nativeElement.offsetHeight +
                                        this.buttonBar.nativeElement.offsetHeight -
                                        10);
                            setTimeout(() => {
                                this.scheduler.instance.repaint();
                            }, 100);
                        }, 200);
                    }
                    else {
                        // Set list height
                        setTimeout(() => {
                            this.listHeight = window.innerHeight + 'px';
                            // this.table.recalculate();
                        }, 200);
                    }
                }
                else {
                    this.isFullscreen = false;
                    this.schedulerHeight = '';
                    if (this.scheduler) {
                        this.scheduler.instance.repaint();
                    }
                }
            });
            // Get socket messages
            // this._reservationSub = this.socketService.newReservation.takeUntil(this.ngUnsubscribe).subscribe((res: any) => {
            //   // Check if reservation is from current day in view
            //   if (moment(this.currentDate).isSameOrAfter(res.reservedFor, 'day')) {
            //     this.snackBar.open('Neue Reservierung eingetroffen', '', {
            //       duration: 2000,
            //       panelClass: ['snackbar-info']
            //     });
            //     if (this.view === 'list') {
            //       this.getAllReservations();
            //     } else {
            //       this.dataSource.reload();
            //     }
            //   }
            // });
            // this._reservationUpdateSub = this.socketService.updateReservation
            //   .takeUntil(this.ngUnsubscribe)
            //   .subscribe(async (res: any) => {
            //     // Check if reservation is from current day in view
            //     if (moment(this.currentDate).isSameOrAfter(res.reservedFor, 'day')) {
            //       let snackMsg = '';
            //       if (res.guestData) {
            //         snackMsg = 'Reservierung von ' + res.guestData.name + ' wurde aktualisiert';
            //       } else {
            //         snackMsg = 'Walk-In um ' + moment(res.reservedFor).format('HH:mm') + ' Uhr wurde aktualisiert';
            //       }
            //       this.snackBar.open(snackMsg, '', {
            //         duration: 2000,
            //         panelClass: ['snackbar-info']
            //       });
            //       if (this.view === 'list') {
            //         if (!res.calledFunction || res.calledFunction != 'change-status') {
            //           this.getAllReservations();
            //         } else {
            //           await new Promise<void>(resolve => {
            //             this.setReservationListValues();
            //             resolve();
            //           });
            //         }
            //       } else {
            //         this.dataSource.reload();
            //       }
            //     }
            //   });
            // this._reservationFileUploadeSub = this.socketService.uploadFiles
            //   .takeUntil(this.ngUnsubscribe)
            //   .subscribe((res: any) => {
            //     // Check if reservation is from current day in view
            //     if (moment(this.currentDate).isSameOrAfter(res.reservedFor, 'day')) {
            //       if (this.view === 'list') {
            //         this.getAllReservations();
            //       } else {
            //         this.dataSource.reload();
            //       }
            //     }
            //   });
            // Setting up breakpoint mechanism
            const breakpoints = Object.keys(this.LAYOUT).map(k => this.LAYOUT[k]);
            breakpoints.forEach((maxWidth, index) => {
                const minWidth = index > 0 ? breakpoints[index - 1] : 0;
                this.breakpointObserver
                    .observe([`(min-width: ${minWidth}px) and (max-width: ${maxWidth - 1}px)`])
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe((state) => {
                    if (!state.matches) {
                        return;
                    }
                    this.layout = maxWidth;
                });
            });
            this.getAllStatus();
        });
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        // this._reservationSub.unsubscribe();
        // this._reservationFileUploadeSub.unsubscribe();
    }
    getAllStatus() {
        this.reservationService
            .getResStatus()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((resStatusList) => {
            this.resStatusList = resStatusList;
            this.resStatusList.forEach((item) => {
                item['type'] = 'single';
            });
            this.allStatus = this.allStatus.concat(this.resStatusList);
        });
    }
    getAlerts() {
        return this.reservationService
            .getAlerts('staff', moment(this.currentDate).format('YYYY-MM-DD'))
            .takeUntil(this.ngUnsubscribe)
            .subscribe((alerts) => {
            this.alerts = alerts;
        });
    }
    closeAlert(alert) {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }
    closeResMenuOrderAlert(alert) {
        this.resMenuOrderAlert.splice(this.alerts.indexOf(alert), 1);
    }
    changeView(val) {
        if (val === 'list') {
            if (this.listHeight === '') {
                // Set list height
                setTimeout(() => {
                    this.listHeight = window.innerHeight - this.buttonBar.nativeElement.offsetHeight + 'px';
                }, 100);
            }
            this.getAllReservations();
        }
        else if (val === 'timeline') {
            // Set height of scheduler to fit window height and make inner table scrollable
            if (this.schedulerHeight === '') {
                setTimeout(() => {
                    this.schedulerHeight =
                        window.innerHeight -
                            (this.roomTabsGroup._elementRef.nativeElement.offsetHeight +
                                this.buttonBar.nativeElement.offsetHeight -
                                10);
                    setTimeout(() => {
                        if (this.scheduler) {
                            this.scheduler.instance.repaint();
                        }
                    }, 100);
                }, 100);
            }
            this.tablesData = this.rooms[0] ? this.rooms[0].tables : [];
            this.selectedRoomId = this.rooms[0] ? this.rooms[0].id : null;
            this.dataSource = new DataSource({
                store: new CustomStore({
                    load: options => this.getTimelineReservations(options),
                    update: (key, values) => this.updateReservation(key, values)
                })
            });
            // this.dataSource.reload();
        }
        else if (val === 'tableplan') {
            this.dataSource = new DataSource({
                store: new CustomStore({
                    load: () => this.getAllReservations(),
                    update: (key, values) => this.updateReservation(key, values)
                })
            });
        }
    }
    getTimelineReservations(options) {
        options.roomId = this.selectedRoomId;
        return this.reservationService
            .getTimelineReservations(options)
            .toPromise()
            .then((data) => {
            return data;
        });
    }
    updateReservation(key, values) {
        if (key.tableId !== values.tableId) {
            // move reservation to other table
            values.startDate = key.startDate;
            values.reservedFor = key.reservedFor;
            values.endDate = key.endDate;
            const minutes = differenceInMinutes(values.endDate, values.startDate);
            values.stayTime = minutes;
            values.oldTableId = key.tableId;
            console.log(480, 'new table', key, values);
        }
        else {
            // move reservation on the same table
            // values.reservedFor = values.startDate;
            values.reservedFor = key.reservedFor;
            const minutes = differenceInMinutes(values.endDate, values.startDate);
            values.stayTime = minutes;
            values.oldTableId = key.tableId;
            console.log(480, 'same table', key, values);
        }
        return this.reservationService
            .editReservation(values)
            .toPromise()
            .then((data) => {
            return data;
        });
    }
    getRooms() {
        return this.reservationService
            .getRooms(false)
            .toPromise()
            .then((data) => {
            if (data) {
                this.rooms = data;
                this.tablesData = this.rooms && this.rooms.length ? this.rooms[0].tables : [];
                let allTables = [];
                this.rooms.map((item) => {
                    allTables.push(...item.tables);
                });
                let allRooms = Object.assign({}, this.rooms[0]);
                allRooms.id = 1000;
                allRooms.name = 'All';
                allRooms.tables = allTables;
                this.rooms.push(allRooms);
            }
        });
    }
    getShifts() {
        this.reservationService
            .getShifts()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((shifts) => {
            this.shifts = shifts;
        });
    }
    selectedTabChange(e) {
        return __awaiter(this, void 0, void 0, function* () {
            this.tablesData = this.rooms[e.index].tables;
            this.selectedRoomId = this.rooms[e.index].id;
            this.dataSource.reload();
            /*
            this.dataSource = new DataSource({
              store: new CustomStore({
                load: options => this.getTimelineReservations(options, this.rooms[e.index].id),
                update: (key, values) => this.updateReservation(key, values)
              })
            });
            */
        });
    }
    onInitialized(e) {
        setTimeout(() => {
            if (this.scheduler) {
                // this.scheduler.instance.repaint();
            }
            const scroll = e.component.getWorkSpaceScrollable();
            const scrollInstance = scroll.instance();
            scrollInstance.beginUpdate();
            // scrollInstance.option('useNative', true);
            scrollInstance.option('useNative', false);
            scrollInstance.endUpdate();
            const currentTimeHour = +moment(new Date()).format('HH') - 2;
            if (this.scheduler) {
                this.scheduler.instance.scrollToTime(currentTimeHour, 0);
            }
            // this.scheduler.instance.repaint();
        });
    }
    onContentReady(e) {
        const scroll = e.component.getWorkSpaceScrollable();
        const scrollInstance = scroll.instance();
        scrollInstance.beginUpdate();
        scrollInstance.option('useNative', false);
        scrollInstance.endUpdate();
    }
    onAppointmentRendered(e) {
        /*
        events.on(e.appointmentElement, 'dxdragstart', () => {
          console.log('dra');
        });
        */
        /*
        const transform = this.getTranslateXY(e.appointmentElement);
        e.appointmentElement.style.height = '70px';
        if (transform) {
          e.appointmentElement.
          style.transform = 'translate(' + transform.x + 'px,' + (transform.y - 25) + 'px)';
        }
        */
    }
    onAppointmentUpdating(e) {
        if (e.oldData.locked) {
            e.cancel = true;
            this.snackBar.open('Reservierung ist gesperrt', '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
        }
    }
    onCellContextMenu(e) {
        this.contextMenuCellData = e.cellData;
        // Get start and end time of all selected cells
        this.contextMenuCellData.startDate = this.scheduler.selectedCellData[0].startDate;
        this.contextMenuCellData.endDate = this.scheduler.selectedCellData[this.scheduler.selectedCellData.length - 1].endDate;
    }
    onAppointmentContextMenu(e) {
        this.contextMenuAppointmentData = e.appointmentData;
        this.contextMenuTargetedAppointmentData = e.targetedAppointmentData;
        this.onReservationContextMenuShowing(e);
    }
    // Called before the context menu for a reservation is opened
    // so build the menu based on the status
    onReservationContextMenuShowing(e) {
        const basicMenuItems = [
            {
                text: 'Bearbeiten',
                onItemClick: () => this.editReservation(this.contextMenuTargetedAppointmentData)
            }
        ];
        let contextMenuItems = [];
        let roomsContextMenu = [];
        if (e.appointmentData) {
            if (e.appointmentData.status === 'confirmed') {
                contextMenuItems = [
                    { text: 'Status ändern', beginGroup: true, disabled: true },
                    {
                        text: 'Angekommen',
                        color: '#ebb12b',
                        onItemClick: () => this.updateStatusTimeline('arrived', this.contextMenuTargetedAppointmentData.id)
                    },
                    {
                        text: 'Platziert',
                        color: '#1371fe',
                        onItemClick: () => this.updateStatusTimeline('placed', this.contextMenuTargetedAppointmentData.id)
                    },
                    {
                        text: 'Fertig',
                        color: '#495057',
                        onItemClick: () => this.updateStatusTimeline('finished', this.contextMenuTargetedAppointmentData.id)
                    },
                    {
                        text: 'No-Show',
                        color: '#912f5e',
                        onItemClick: () => this.updateStatusTimeline('noShow', this.contextMenuTargetedAppointmentData.id)
                    },
                    {
                        text: 'Storniert',
                        color: '#e65252',
                        onItemClick: () => this.updateStatusTimeline('canceled', this.contextMenuTargetedAppointmentData.id)
                    }
                ];
            }
            if (e.appointmentData.status === 'pending') {
                contextMenuItems = [
                    { text: 'Status ändern', beginGroup: true, disabled: true },
                    {
                        text: 'Bestätigt',
                        color: '#24b314',
                        onItemClick: () => this.updateStatusTimeline('confirmed', this.contextMenuTargetedAppointmentData.id)
                    },
                    {
                        text: 'Storniert',
                        color: '#e65252',
                        onItemClick: () => this.updateStatusTimeline('canceled', this.contextMenuTargetedAppointmentData.id)
                    }
                ];
            }
            if (e.appointmentData.status === 'placed') {
                contextMenuItems = [
                    { text: 'Status ändern', beginGroup: true, disabled: true },
                    {
                        text: 'Fertig',
                        color: '#495057',
                        onItemClick: () => this.updateStatusTimeline('finished', this.contextMenuTargetedAppointmentData.id)
                    }
                ];
            }
            if (e.appointmentData.paymentTransaction) {
                contextMenuItems.map((item, key) => {
                    if (item.text == 'Storniert') {
                        contextMenuItems.splice(key, 1);
                    }
                });
            }
            if (this.rooms.length > 1) {
                roomsContextMenu = [
                    {
                        text: 'Verschieben...',
                        beginGroup: true,
                        items: []
                    }
                ];
                for (let index = 0; index < this.rooms.length; index++) {
                    const room = this.rooms[index];
                    roomsContextMenu[0].items.push({
                        text: room.name,
                        items: this.roomTablesContextMenu(index, this.contextMenuTargetedAppointmentData)
                    });
                }
            }
        }
        // Merge arrays
        this.reservationMenu.instance.option('items', [...basicMenuItems, ...contextMenuItems, ...roomsContextMenu]);
    }
    roomTablesContextMenu(roomIndex, reservation) {
        const tablesContextMenu = [];
        for (let index = 0; index < this.rooms[roomIndex].tables.length; index++) {
            const table = this.rooms[roomIndex].tables[index];
            tablesContextMenu.push({
                text: table.name,
                onItemClick: () => {
                    const values = reservation;
                    if (values.tables && values.tables.length > 1) {
                        values.isTablePlan = values.isTablePlan.replace(values.tableId, table.id);
                        const replaceTableIndex = values.tables.findIndex((s) => s.id === values.tableId);
                        values.tables.splice(replaceTableIndex, 1);
                        values.tables.push(table);
                        values.tableId = table.id;
                    }
                    else {
                        values.tableId = table.id;
                        values.isTablePlan = table.id;
                        values.tables = [table];
                    }
                    return this.reservationService
                        .editReservation(values)
                        .toPromise()
                        .then((data) => {
                        return data;
                    });
                }
            });
        }
        return tablesContextMenu;
    }
    onContextMenuItemClick(e) {
        e.itemData.onItemClick(e.itemData);
    }
    getTranslateXY(obj) {
        const style = obj.style, transform = style.transform || style.webkitTransform;
        const match = transform.match(/translate\((-?\d+(?: \.\d*)?)px, (-?\d+(?:\.\d*)?)px\)/);
        if (!match) {
            return;
        }
        const transObj = {
            x: parseFloat(match[1]),
            y: parseFloat(match[2])
        };
        return transObj;
    }
    openFullscreen() {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        // if (isSafari) {
        this.fakeFullscreen();
        // } else {
        //   (screenfull as Screenfull).request(this.fullscreenEl.nativeElement);
        //   screen.orientation.lock('landscape');
        // }
        /*
        console.log('fs', this.fullscreenEl);
        this.fullscreenEl.nativeElement.requestFullscreen();
        */
    }
    openCreateBottomSheet() {
        const bottomSheetRef = this.bottomSheet.open(AddBottomSheetComponent);
        bottomSheetRef
            .afterDismissed()
            .takeUntil(this.ngUnsubscribe)
            .subscribe(data => {
            if (data && data === 'reservation') {
                this.addReservation();
            }
            else if (data && data === 'walkin') {
                this.addReservation(true);
            }
        });
    }
    closeFullscreen() {
        if (this.isFakeFullscreen) {
            this.isFullscreen = false;
            this.isFakeFullscreen = false;
            this.navService.showToolbar$.next(true);
            this.schedulerHeight = '';
            this.listHeight = 'auto';
            if (this.scheduler) {
                this.scheduler.instance.repaint();
            }
        }
        else {
            screenfull.exit();
        }
    }
    fakeFullscreen() {
        this.navService.appDrawer.close();
        this.navService.showToolbar$.next(false);
        this.isFakeFullscreen = true;
        this.isFullscreen = true;
        // Set height of scheduler to fit window height and make inner table scrollable
        if (this.view === 'timeline') {
            setTimeout(() => {
                // Set scheduler height
                this.schedulerHeight =
                    window.innerHeight -
                        (this.roomTabsGroup._elementRef.nativeElement.offsetHeight + this.buttonBar.nativeElement.offsetHeight - 10);
                setTimeout(() => {
                    this.scheduler.instance.repaint();
                }, 100);
            }, 50);
        }
        else {
            // Set list height
            setTimeout(() => {
                this.listHeight = window.innerHeight - this.buttonBar.nativeElement.offsetHeight - 15 + 'px';
                // this.table.recalculate();
            }, 50);
        }
    }
    translateStatus(status) {
        switch (status) {
            case 'confirmed':
                return 'Bestätigt';
                break;
            case 'canceled':
                return 'Storniert';
                break;
            case 'noShow':
                return 'No Show';
                break;
            case 'arrived':
                return 'Angekommen';
                break;
            case 'placed':
                return 'Platziert';
                break;
            case 'pending':
                return 'Ausstehend';
                break;
            case 'waiting':
                return 'Warteliste';
                break;
            case 'finished':
                return 'Fertig';
                break;
            case 'blocked':
                return 'Ausstehende Zahlung';
                break;
            default:
                break;
        }
    }
    isIntermediateTime(time) {
        const array = time.split(':');
        if (array[1] === '15' || array[1] === '30' || array[1] === '45') {
            return true;
        }
        return;
    }
    isIntermediateDate(date) {
        const dateString = new Date(date);
        if (dateString.getMinutes() === 0) {
            return;
        }
        return true;
    }
    onAppointmentClick(event) {
        event.cancel = true;
        let appendContainer = 'body';
        if (this.isFullscreen) {
            appendContainer = 'dx-scheduler';
        }
        const modalRef = this.modalService.open(AddReservationComponent, {
            size: 'lg',
            container: appendContainer
        });
        modalRef.componentInstance.reservation = Object.assign({}, event.appointmentData);
        modalRef.componentInstance.isWalkIn = !event.appointmentData.gastId ? true : false;
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            if (this.view === 'list') {
                this.getAllReservations();
            }
            else {
                this.dataSource.reload();
            }
            this.snackBar.open('Reservierung erfolgreich geändert', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
            });
            modalRef.close();
        });
    }
    onCellClick(event) {
        if (this.isDoubleClick === 1) {
            this.prevCellData = event.cellData;
        }
        this.isDoubleClick++;
        setTimeout(() => {
            if (this.isDoubleClick === 2) {
                if (event.cellData.startDate === this.prevCellData.startDate &&
                    event.cellData.endDate === this.prevCellData.endDate) {
                    this.addReservation(false, event.cellData.startDate, null, event.cellData.groups.tableId);
                }
            }
            else if (this.isDoubleClick === 1) {
                // Do nothing
            }
            this.isDoubleClick = 0;
            this.prevCellData = null;
        }, 300);
    }
    editReservation(reservation) {
        let appendContainer = 'body';
        if (this.isFullscreen) {
            appendContainer = '.fullscreen-element';
        }
        const modalRef = this.modalService.open(AddReservationComponent, {
            size: 'lg',
            container: appendContainer
        });
        modalRef.componentInstance.reservation = reservation;
        modalRef.componentInstance.isWalkIn = !reservation.gastId ? true : false;
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            if (this.view === 'list') {
                this.getAllReservations();
            }
            else {
                this.dataSource.reload();
            }
            this.snackBar.open('Reservierung erfolgreich geändert', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
            });
            modalRef.close();
        });
    }
    addReservation(walkin = false, startDate = null, endDate = null, tableId = null, instantSave = false) {
        if (instantSave && walkin) {
            // If instantSave is true, add the reservation without showing modal window
            const momentEndDate = moment(endDate);
            const duration = moment.duration(momentEndDate.diff(startDate));
            const reservation = {
                stayTime: duration.asMinutes(),
                guestCount: 2,
                reservedFor: startDate,
                time: moment(startDate).format('HH:mm'),
                status: 'placed',
                isTablePlan: tableId
            };
            this.reservationService
                .addReservation(reservation)
                .takeUntil(this.ngUnsubscribe)
                .subscribe(res => {
                this.dataSource.reload();
                this.snackBar.open('Walk-In erfolgreich erstellt', '', {
                    duration: 2000,
                    panelClass: ['snackbar-success']
                });
            });
        }
        else {
            let appendContainer = 'body';
            if (this.isFullscreen) {
                appendContainer = '.fullscreen-element';
            }
            const modalRef = this.modalService.open(AddReservationComponent, {
                size: 'lg',
                container: appendContainer
            });
            modalRef.componentInstance.isWalkIn = walkin;
            modalRef.componentInstance.defaultDate = walkin ? new Date() : this.currentDate;
            modalRef.componentInstance.defaultTable = tableId;
            modalRef.componentInstance.startDate = startDate;
            modalRef.componentInstance.endDate = endDate;
            modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
                if (this.view === 'list') {
                    this.getAllReservations();
                }
                else {
                    this.dataSource.reload();
                }
                this.snackBar.open('Reservierung erfolgreich erstellt', '', {
                    duration: 2000,
                    panelClass: ['snackbar-success']
                });
                modalRef.close();
            });
        }
    }
    getAllReservations() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loadingReservationList = true;
            this.tags = [];
            this.resetTimeFilter = false;
            yield this.reservationService
                .getAllReservationsBook(this.selectedStartDate, this.selectedEndDate, this.page.pageNumber, 24, '', false)
                .toPromise()
                .then((data) => __awaiter(this, void 0, void 0, function* () {
                if (data) {
                    console.log('data', data);
                    this.isArchive = data.isArchive;
                    this.editing = {}; // Reset editing because of new rows
                    this.temp = [...data.reservations];
                    this.tempRes = this.temp;
                    this.pendingCounter = data.pendingCounter;
                    this.reservations = data.reservations;
                    console.log('test2', this.currentDate, this.tempRes, this.pendingCounter, this.reservations);
                    yield new Promise(resolve => {
                        this.setReservationListValues();
                        resolve();
                    });
                    this.searchReservation();
                    this.loadingReservationList = false;
                }
            }));
            //  If not tags, add no tags option
            if (!this.tags.length) {
                this.tags = [
                    {
                        id: null,
                        label: 'Keine Tags für diesen Tag',
                        disabled: true
                    }
                ];
            }
        });
    }
    setReservationListValues() {
        let resAllMenuOrderTags = [];
        let resMenuOrderAlert = [];
        this.reservations.forEach((reservation) => {
            if (reservation.tags && Array.isArray(reservation.tags)) {
                reservation.tags = reservation.tags.filter((tag) => tag.type !== 'res-menu-order' && tag.type !== 'ticket');
                if (reservation.tags.length) {
                    reservation.tags.forEach((tag) => {
                        if (this.tags.findIndex((x) => x.id === tag.id) === -1) {
                            tag.selected = false;
                            this.tags = [...this.tags, tag];
                            // this.tags.push(tag);
                        }
                    });
                }
            }
            if (reservation &&
                reservation.guestData &&
                reservation.guestData.intolerance &&
                reservation.guestData.intolerance != 'null') {
                reservation.guestData.intolerance = this.getIntolerance(reservation.guestData.intolerance);
            }
            if (reservation && reservation.addOns) {
                reservation.addOns = this.getResAddOns(reservation.addOns);
            }
            let resMenuOrderTags = [];
            if (reservation.status != 'canceled' &&
                reservation.resMenuOrdersItem &&
                Object.keys(reservation.resMenuOrdersItem).length > 0) {
                let i = 1;
                Promise.all(reservation.resMenuOrdersItem.map((item) => {
                    resMenuOrderTags[item.resAnswers.answer] = {
                        count: ((resMenuOrderTags[item.resAnswers.answer] && resMenuOrderTags[item.resAnswers.answer]['count']) || 0) +
                            1,
                        color: item.resAnswers.color,
                        preOrderName: item.menuOrder && item.menuOrder.name ? item.menuOrder.name : ''
                    };
                    resAllMenuOrderTags[item.menuOrder.name + ':' + item.resAnswers.answer] =
                        (resAllMenuOrderTags[item.menuOrder.name + ':' + item.resAnswers.answer] || 0) + 1;
                }));
                Object.keys(resMenuOrderTags).map((item) => {
                    reservation.tags.push({
                        id: null,
                        label: `${resMenuOrderTags[item]['count']} * ${item}`,
                        color: resMenuOrderTags[item]['color'] ? resMenuOrderTags[item]['color'] : '#ffb6c1',
                        type: 'res-menu-order'
                    });
                });
            }
            if (reservation.ticketOrder && Object.keys(reservation.ticketOrder).length > 0) {
                const ticketCategories = {};
                reservation.ticketOrder.tickets.forEach((ticket) => {
                    const ticketName = ticket.ticket.name;
                    const subCategoryName = ticket.subCategory.name;
                    if (!ticketCategories[ticketName]) {
                        ticketCategories[ticketName] = {};
                    }
                    ticketCategories[ticketName][subCategoryName] = (ticketCategories[ticketName][subCategoryName] || 0) + 1;
                });
                const formattedTickets = Object.entries(ticketCategories).map(([ticketName, subCategoryCounts]) => {
                    const formattedCategories = Object.entries(subCategoryCounts)
                        .map(([name, count]) => `${count}*${name}`)
                        .join(', ');
                    return `${ticketName}: ${formattedCategories}`;
                });
                reservation.tags.push({
                    id: null,
                    label: formattedTickets,
                    color: '#f3d425',
                    textColor: '#454545',
                    type: 'ticket'
                });
            }
        });
        Object.keys(resAllMenuOrderTags).map((item) => {
            let preOrderName = item.split(':');
            if (!resMenuOrderAlert[preOrderName[0]]) {
                resMenuOrderAlert[preOrderName[0]] = [];
            }
            resMenuOrderAlert[preOrderName[0]].push(`${resAllMenuOrderTags[item]} * ${preOrderName[1]}`);
        });
        this.resMenuOrderAlert = [];
        if (resMenuOrderAlert && Object.keys(resMenuOrderAlert).length > 0) {
            Object.keys(resMenuOrderAlert).map((item) => {
                this.resMenuOrderAlert.push({ info: `${item} : ${resMenuOrderAlert[item].join(', ')}` });
            });
        }
        return true;
    }
    getIntolerance(intolerance) {
        const intolerances = JSON.parse(intolerance);
        let result = [];
        for (let i = 0; i < intolerances.length; i++) {
            const element = intolerances[i];
            const intoleranceRes = this.intolerance.find(el => {
                return element == el.id;
            });
            result.push(intoleranceRes.name);
        }
        return result;
    }
    getResAddOns(addOnsString) {
        const addOns = addOnsString.split(',');
        let result = [];
        for (let i = 0; i < addOns.length; i++) {
            const element = addOns[i];
            const addOnsRes = this.resAddOns.find(el => {
                return element == el.id;
            });
            if (addOnsRes && addOnsRes.title) {
                result.push(addOnsRes.title);
            }
        }
        return result;
    }
    prevDay() {
        this.currentDate = subDays(this.currentDate, 1);
        this.changeDate();
    }
    nextDay() {
        this.currentDate = addDays(this.currentDate, 1);
        this.changeDate();
    }
    today() {
        this.currentDate = new Date();
        this.changeDate();
        this.resetTimeFilter = true;
    }
    changeDate() {
        this.getAlerts();
        this.getAllReservations();
    }
    onReservationListChange(event) { }
    searchReservation(event) {
        // filter our data
        const filterKeys = Object.keys(this.filter);
        const temp = this.temp.filter((eachObj) => {
            return filterKeys.every(eachKey => {
                const that = this;
                if (!that.filter[eachKey] || !that.filter[eachKey].toString().length) {
                    return true; // passing an empty filter means that filter is ignored.
                }
                // Search for guest name
                if (eachKey === 'name') {
                    if (!eachObj.guestData) {
                        return false;
                    }
                    return ((eachObj.guestData[eachKey]
                        ? eachObj.guestData[eachKey].toLowerCase().includes(that.filter[eachKey].toLowerCase())
                        : false) ||
                        (eachObj.guestData['firstName']
                            ? eachObj.guestData['firstName'].toLowerCase().includes(that.filter[eachKey].toLowerCase())
                            : false) ||
                        (eachObj.guestData['company']
                            ? eachObj.guestData['company'].toLowerCase().includes(that.filter[eachKey].toLowerCase())
                            : false) ||
                        (eachObj.id ? eachObj.id.toString().includes(that.filter[eachKey]) : false));
                }
                // Search for room
                if (eachKey === 'roomId') {
                    if (!eachObj.tables || !eachObj.tables.length) {
                        if (eachObj.room && eachObj.room.length) {
                            let rooms = Array.isArray(eachObj.room) ? eachObj.room : [eachObj.room];
                            return rooms.find((x) => x.id === that.filter[eachKey]) ? true : false;
                        }
                        else {
                            return false;
                        }
                    }
                    return eachObj.tables.find((x) => x.roomId === that.filter[eachKey]) ? true : false;
                }
                // Search for tags
                if (eachKey === 'tags') {
                    if (!eachObj[eachKey].length) {
                        return false;
                    }
                    return eachObj[eachKey].some((tag) => {
                        return that.filter[eachKey].filter((e) => e.id === tag.id).length > 0;
                    });
                }
                // Search for status
                if (eachKey === 'status') {
                    if (that.filter[eachKey] === 'active') {
                        const statusArray = ['confirmed', 'arrived', 'placed'];
                        return statusArray.some((st) => {
                            return st.includes(eachObj[eachKey]);
                        });
                    }
                    return that.filter[eachKey].includes(eachObj[eachKey]);
                }
                // Search for shifts
                if (eachKey === 'shiftId') {
                    if (!eachObj[eachKey]) {
                        return false;
                    }
                    return eachObj[eachKey] === that.filter[eachKey] ? true : false;
                }
                // Other
                return that.filter[eachKey].includes(eachObj[eachKey]);
            });
        });
        // update the rows
        this.reservations = temp;
        this.tempRes = temp;
        this.filterReservationWithTime();
        // Whenever the filter changes, always go back to the first page
        // this.table.offset = 0;
    }
    getAllTags() {
        this.reservationService
            .getTags()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((tags) => {
            this.allTags = tags;
        });
    }
    updateValue(event, cell, rowIndex, reservationId) {
        this.editing[rowIndex + '-' + cell] = false;
        this.reservations[rowIndex][cell] = event.target.value;
        this.reservations = [...this.reservations];
        const index = this.reservations.findIndex((x) => x.id === reservationId);
        this.reservationService
            .editReservation(this.reservations[index])
            .takeUntil(this.ngUnsubscribe)
            .subscribe(() => { });
    }
    updateStatusTimeline(status, reservationId) {
        if (this.bookSettings.confirmStatusChange) {
            const modalRef = this.modalService.open(ConfirmModalComponent);
            modalRef.componentInstance.title = 'Status ändern';
            modalRef.componentInstance.message = `Sind Sie sicher dass Sie den Status auf "${this.translateStatus(status)}" setzen möchten?`;
            modalRef.componentInstance.showInfo = false;
            modalRef.componentInstance.buttonText = 'Ja';
            modalRef.result.then((result) => {
                if (result === 'ok') {
                    this.updateStatusTimelineHelper(status, reservationId);
                }
            }, () => { });
        }
        else {
            this.updateStatusTimelineHelper(status, reservationId);
        }
    }
    updateStatusTimelineHelper(status, reservationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const index = yield this.dataSource._items.findIndex((x) => x.id === reservationId);
            const reservData = Object.assign({}, this.dataSource._items[index]);
            reservData.status = status;
            if (reservData.guestData) {
                reservData.guestData.guestInform = true;
            }
            reservData.guestCount = reservData.peopleCount;
            this.reservationService
                .editReservation(reservData)
                .takeUntil(this.ngUnsubscribe)
                .subscribe(() => {
                this.dataSource.reload();
                this.snackBar.open('Status wurde erfolgreich geändert', '', {
                    duration: 2000,
                    panelClass: ['snackbar-success']
                });
            }, err => {
                this.snackBar.open(`Status konnte nicht geändert werden (${err.error.msg})`, '', {
                    duration: 2000,
                    panelClass: ['snackbar-error']
                });
            });
        });
    }
    isToday() {
        return isSameDay(new Date(), this.currentDate);
    }
    reservationsFiltered(e) {
        return __awaiter(this, void 0, void 0, function* () {
            this.filteredReservations = e;
        });
    }
    downloadPdf() {
        const doc = new jsPDF('p', 'mm', 'a4');
        console.log(this.reservations, this.filteredReservations);
        // const reservations = this.table.bodyComponent.temp ? this.table.bodyComponent.temp : this.reservations;
        // const reservations = this.reservations;
        let reservations = [];
        this.reservations.map((item) => {
            if (this.filteredReservations.includes(item.id)) {
                reservations.push(item);
            }
        });
        const reservationsArray = JSON.parse(JSON.stringify(reservations)); // clone ([...reservations] not working?)
        const parsedReservations = reservationsArray
            .filter((res) => res.status !== 'canceled')
            .map((res) => {
            res.reservedFor = moment(res.reservedFor).format('HH:mm') + ' - ' + moment(res.endDate).format('HH:mm'); // + ' Uhr';
            if (res.guestData && typeof res.guestData === 'object') {
                res.guestName = res.guestData.company
                    ? res.guestData.company + ' (' + res.guestData.name + ')'
                    : res.guestData.name;
                res.guestName += !res.guestData.company && res.guestData.firstName ? ', ' + res.guestData.firstName : '';
            }
            else {
                res.guestName = 'Walk-In';
            }
            if (res.notes) {
                res.guestName = res.guestName + '\nAdmin-Hinweis:' + res.notes;
            }
            if (res.msg) {
                res.guestName = res.guestName + '\nGast-Hinweis:' + res.msg;
            }
            const tables = [];
            res.tables.forEach((tableData) => {
                tables.push(tableData.name);
            });
            // res.tablesString = tables.join(',');
            res.tablesString = '';
            let i = 0;
            tables.forEach((t) => {
                i++;
                res.tablesString += t + ',';
                if (i === 4) {
                    res.tablesString += '\n';
                    i = 0;
                }
            });
            if (!res.tablesString || res.tablesString == '') {
                if (res.room) {
                    if (res.room.name) {
                        res.tablesString = res.room.name;
                    }
                }
            }
            res.peopleCount = res.peopleCount + ' ';
            if (res.highChairs && res.children) {
                res.peopleCount = res.peopleCount + ' (K: ' + res.children + ', HS: ' + res.highChairs + ')';
            }
            else {
                if (res.children) {
                    res.peopleCount += '(K: ' + res.children + ')';
                }
                if (res.highChairs) {
                    res.peopleCount += '(HS: ' + res.highChairs + ')';
                }
            }
            const tags = [];
            res.tags.forEach((tagData) => {
                tags.push(tagData.label);
            });
            res.tagsString = tags.join(',');
            //res.status = this.translateStatus(res.status);
            console.log(res);
            return res;
        });
        const width = doc.internal.pageSize.getWidth();
        const printDate = 'Druckdatum : ' + moment().format('DD.MM.YYYY');
        const txtWidth = doc.getTextWidth(printDate);
        doc.text(width - 15 - txtWidth, 10, printDate);
        doc.text(15, 10, 'Reservierungen für den ' + moment(this.currentDate).format('DD.MM.YYYY'));
        doc.autoTable({
            bodyStyles: {
                valign: 'top'
            },
            styles: {
                overflow: 'linebreak'
            },
            headStyles: { fillColor: [0, 0, 0] },
            body: parsedReservations,
            columns: [
                { header: 'Uhrzeit', dataKey: 'reservedFor' },
                { header: 'Name', dataKey: 'guestName' },
                { header: 'Gäste', dataKey: 'peopleCount' },
                { header: 'Tisch', dataKey: 'tablesString' },
                { header: 'Tags', dataKey: 'tagsString' }
                // { header: 'Status', dataKey: 'status' }
            ]
        });
        // Save the PDF
        doc.save(`Reservierungen_${moment(this.currentDate).format('YYYY-MM-DD')}.pdf`);
    }
    sortTablesComparator(propA, propB, rowA, rowB) {
        // do something with rowA and rowB objects.
        if (rowA.tables[0] && rowB.tables[0]) {
            if (rowA.tables[0].name < rowB.tables[0].name) {
                return -1;
            }
            if (rowA.tables[0].name > rowB.tables[0].name) {
                return 1;
            }
        }
        return 0;
    }
    toggleEditMode() {
        this.editMode = !this.editMode;
        if (!this.editMode) {
            this.snackBar.open('Bearbeitungsmodus beendet.', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
            });
            if (this.scheduler) {
                this.scheduler.instance.repaint();
            }
        }
        else {
            this.snackBar.open('Bearbeitungsmodus gestartet. Sie können nun Reservierungen verschieben.', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
            });
        }
    }
    onRowActivate(event) {
        if (event.type === 'click' && event.column.prop !== 'status') {
            this.editReservation(event.row);
        }
    }
    openAllPending() {
        if (!this.isArchive) {
            let appendContainer = 'body';
            if (this.isFullscreen) {
                appendContainer = '.fullscreen-element';
            }
            const modalRef = this.modalService.open(PendingReservationsComponent, {
                size: 'lg',
                container: appendContainer
            });
        }
    }
    fsChanged(event) {
        this.reservationService.fsSubject.next(event || 14);
    }
    getResTimeFilter() {
        this.reservationService
            .getResTimeFilter()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((resTimeFilter) => {
            if (resTimeFilter && resTimeFilter.length > 0) {
                this.timeFilter = resTimeFilter;
            }
        });
    }
    checkSelectedFilter(name) {
        if (this.selectedTimeFilter.includes(name)) {
            return true;
        }
        return false;
    }
    filterReservationWithTime(name = '', repaint = false) {
        let timeFilterValue = {};
        if (name) {
            if (this.selectedTimeFilter.includes(name)) {
                const indexToDelete = this.selectedTimeFilter.indexOf(name);
                if (indexToDelete !== -1) {
                    this.selectedTimeFilter.splice(indexToDelete, 1);
                }
            }
            else {
                this.selectedTimeFilter = [name];
            }
        }
        // let temp = this.tempRes && this.tempRes.length > 0 ? this.tempRes : this.temp;
        let temp = this.tempRes;
        this.reservations = temp;
        let data = [];
        if (this.bookSettings) {
            this.bookSettings.startTime = this.bookSettings.startTimeOriginal;
            this.bookSettings.endTime = this.bookSettings.endTimeOriginal;
        }
        if (this.selectedTimeFilter && this.selectedTimeFilter.length > 0) {
            for (let item of this.selectedTimeFilter) {
                let filterValue = this.timeFilter.find((el) => {
                    return item == el.name;
                });
                if (filterValue) {
                    timeFilterValue.from = filterValue.from;
                    timeFilterValue.to = filterValue.to;
                    if (timeFilterValue.from && timeFilterValue.to) {
                        if (this.bookSettings && Object.keys(this.bookSettings).length > 0) {
                            this.bookSettings.startTime = parseInt(timeFilterValue.from.split(':')[0], 10);
                            this.bookSettings.endTime =
                                parseInt(timeFilterValue.to.split(':')[0], 10) < this.bookSettings.startTime
                                    ? 24
                                    : parseInt(timeFilterValue.to.split(':')[0], 10);
                        }
                        // if (this.resetTimeFilter) {
                        //   timeFilterValue.from = '';
                        //   timeFilterValue.to = '';
                        // }
                        // this.resetTimeFilter = false;
                        const startDate = new Date(this.currentDate);
                        const endDate = new Date(this.currentDate);
                        if (timeFilterValue.from && timeFilterValue.from.length > 0) {
                            const [hours, minutes] = timeFilterValue.from.split(':');
                            startDate.setHours(parseInt(hours, 0));
                            startDate.setMinutes(parseInt(minutes, 0));
                            startDate.setSeconds(0);
                        }
                        else {
                            startDate.setHours(0, 0, 0, 0);
                        }
                        if (timeFilterValue.to && timeFilterValue.to.length > 0) {
                            const [hours, minutes] = timeFilterValue.to.split(':');
                            endDate.setHours(parseInt(hours, 0));
                            endDate.setMinutes(parseInt(minutes, 0));
                            endDate.setSeconds(0);
                        }
                        else {
                            endDate.setHours(0, 0, 0, 0);
                        }
                        temp.map((item) => {
                            if (timeFilterValue.to >= timeFilterValue.from) {
                                if (new Date(item.reservedFor) >= startDate && new Date(item.reservedFor) <= endDate) {
                                    data.push(item);
                                }
                            }
                            else {
                                if (new Date(item.reservedFor) >= startDate && new Date(item.reservedFor) <= addDays(endDate, 1)) {
                                    data.push(item);
                                }
                            }
                        });
                    }
                }
            }
            this.reservations = data;
        }
        this.reservations = this.setStatusInfo(this.view, this.reservations);
        if (this.view === 'timeline' && repaint) {
            setTimeout(() => {
                this.scheduler.instance.repaint();
            }, 100);
        }
    }
    viewExtraFilters() {
        this.showExtraFilters = !this.showExtraFilters;
    }
    getTransactionByYear() {
        console.log(this.range.get('startDate').value);
        console.log(this.range.get('endDate').value);
        this.selectedStartDate = new Date(this.range.get('startDate').value);
        this.selectedEndDate = new Date(this.range.get('endDate').value);
        this.getAllReservations();
    }
    setStatusInfo(view, reservations) {
        reservations = reservations.map((item) => {
            let statusInfo;
            if (Constants.fixedStatusMap[item.status]) {
                if (view == 'timeline') {
                    statusInfo = [];
                }
                else {
                    statusInfo = Constants.fixedStatusMap[item.status];
                }
            }
            else {
                let customStatus = this.resStatusList.find((status) => status.value === item.status);
                if (customStatus) {
                    statusInfo = customStatus;
                }
                else {
                    statusInfo = Object.assign({}, Constants.defaultStatus, { label: item.status, value: item.status });
                }
            }
            return Object.assign({}, item, { statusInfo });
        });
        return reservations;
    }
}
export class AddBottomSheetComponent {
    constructor(bottomSheetRef) {
        this.bottomSheetRef = bottomSheetRef;
    }
    addReservation() {
        this.bottomSheetRef.dismiss('reservation');
    }
    addWalkin() {
        this.bottomSheetRef.dismiss('walkin');
    }
}

import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { LoaderComponent } from './loader/loader.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { NumberSpinnerComponent } from '@app/shared/number-spinner/number-spinner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormSubmitDirective } from './control-error/form-submit.directive';
import { ControlErrorContainerDirective } from './control-error/control-error-container.directive';
import { ControlErrorComponent } from './control-error/control-error.component';
import { ControlErrorsDirective } from './control-error/control-errors.directive';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TruncateTextPipe } from './truncate/truncate.pipe';
import { FilePickerDirective } from './file-picker.directive';
import { RangesFooterComponent } from './ranges-footer/ranges-footer.component';
import { ColorPickerWrapperComponent } from './color-picker-wrapper/color-picker-wrapper.component';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SafePipe } from './safe.pipe';
import { Nl2brPipe } from '@app/nl2br.pipe';
import { ToFixPipe } from '@app/to-fix.pipe';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { StripHtmlPipe } from '@app/striphtml.pipe';
import { EncodeBase64Pipe } from './encode.pipe';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RangesFooterNewComponent } from './ranges-footer-new/ranges-footer-new.component';
import { URLFixPipe } from './url-fix';
import { SpecialCharactersPipe } from './special-characters.pipe';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ColorPickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    LoaderComponent,
    BreadcrumbComponent,
    NumberSpinnerComponent,
    ControlErrorsDirective,
    ControlErrorComponent,
    ControlErrorContainerDirective,
    FormSubmitDirective,
    ConfirmModalComponent,
    TruncateTextPipe,
    SafePipe,
    Nl2brPipe,
    StripHtmlPipe,
    ToFixPipe,
    RangesFooterComponent,
    FilePickerDirective,
    ColorPickerComponent,
    ColorPickerWrapperComponent,
    EncodeBase64Pipe,
    URLFixPipe,
    SpecialCharactersPipe,
    RangesFooterNewComponent
  ],
  exports: [
    LoaderComponent,
    BreadcrumbComponent,
    NumberSpinnerComponent,
    ControlErrorsDirective,
    ControlErrorComponent,
    ControlErrorContainerDirective,
    FormSubmitDirective,
    ConfirmModalComponent,
    TruncateTextPipe,
    SafePipe,
    Nl2brPipe,
    StripHtmlPipe,
    ToFixPipe,
    RangesFooterComponent,
    FilePickerDirective,
    ColorPickerComponent,
    ColorPickerWrapperComponent,
    NgxPermissionsModule,
    EncodeBase64Pipe,
    URLFixPipe,
    SpecialCharactersPipe,
    RangesFooterNewComponent
  ]
})
export class SharedModule {}

import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ReviewsService } from './reviews.service';
import { MatSnackBar } from '@angular/material';
import { ReportReviewComponent } from './report-review/report-review.component';
import { SetReplyComponent } from './set-reply/set-reply.component';
import { EditCommentComponent } from './edit-comment/edit-comment.component';
import moment from 'moment';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { Nl2brPipe } from '@app/nl2br.pipe';
// import { MatAccordion } from '@angular/material/expansion';
// import {nl2br} from 'nl2br';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReviewsComponent implements OnInit {
  @ViewChild('myTable', { static: false }) table: any;

  loader: boolean;
  reviewData: any;
  rate: number;
  comment: string;
  memberInfo: any;
  betriebInfo: any;

  // expanded: boolean;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private reviewService: ReviewsService, private modalService: NgbModal, private snackBar: MatSnackBar) {
    this.loader = true;
    this.reviewData = [];
    this.comment = '';
    // this.expanded = false;
  }

  ngOnInit() {
    this.loadData();
  }

  getNestedComments(currentComment: any, parentComment: any, commentList: any, commentLikeData: any) {
    const straightComments = commentList.filter((comment: any) => comment.parentIds == currentComment.id);
    if (straightComments && straightComments.length) {
      for (let k = 0; k < straightComments.length; k++) {
        const commentData = { ...straightComments[k] };
        const matchInfo = commentLikeData.filter((object: any) => commentData.id === object.thingId);
        if (matchInfo && matchInfo.length) {
          straightComments[k]['commentLikeData'] = matchInfo[0];
        }
        parentComment['comments'].push(straightComments[k]);
        this.getNestedComments(straightComments[k], parentComment, commentList, commentLikeData);
      }
    }
  }

  buildCommentTree(commentList: any, commentLikeData: any, commentId: any, parentComment: any, step: string) {
    const nextLevel = {
      parent: 'child',
      child: 'grandChild',
      grandChild: 'grandChild'
    };

    let straightComments: any;
    if (!commentId) {
      straightComments = commentList.filter((comment: any) => !comment.parentIds);
    } else {
      straightComments = commentList.filter((comment: any) => comment.parentIds == commentId);
    }

    if (step === 'grandChild') {
      if (!parentComment['comments']) {
        parentComment['comments'] = [];
      }
      for (let k = 0; k < straightComments.length; k++) {
        const commentData = { ...straightComments[k] };
        const matchInfo = commentLikeData.filter((object: any) => commentData.id === object.thingId);
        if (matchInfo && matchInfo.length) {
          straightComments[k]['commentLikeData'] = matchInfo[0];
        }
        parentComment['comments'].push(straightComments[k]);
        this.getNestedComments(straightComments[k], parentComment, commentList, commentLikeData);
      }
    } else if (straightComments && straightComments.length) {
      for (let j = 0; j < straightComments.length; j++) {
        const commentData = { ...straightComments[j] };
        const matchInfo = commentLikeData.filter((object: any) => commentData.id === object.thingId);
        if (matchInfo && matchInfo.length) {
          straightComments[j]['commentLikeData'] = matchInfo[0];
        }
        straightComments[j]['comments'] = [
          ...this.buildCommentTree(
            commentList,
            commentLikeData,
            commentData['id'],
            straightComments[j],
            nextLevel[step]
          )
        ];
      }
    }
    return step === 'grandChild' ? parentComment['comments'] : straightComments;
  }

  reArrangeData(reviewInfo: any, likeData: any, wellWrittenData: any, commentLikeData: any, reviewComments: any) {
    const reviewData = [...reviewInfo];

    for (let i = 0; i < reviewData.length; i++) {
      const review = { ...reviewData[i] };

      if (likeData && likeData.length) {
        const matchInfo = likeData.filter((object: any) => review.id === object.thingId);
        if (matchInfo && matchInfo.length) {
          review['likeInfo'] = matchInfo[0];
        }
      }
      if (wellWrittenData && wellWrittenData.length) {
        const matchInfo = wellWrittenData.filter((object: any) => review.id === object.reviewId);
        if (matchInfo && matchInfo.length) {
          review['well-written-info'] = matchInfo[0];
        }
      }
      if (reviewComments && reviewComments.length) {
        const matchInfo = reviewComments.filter((object: any) => review.id === object.itemId);
        if (matchInfo && matchInfo.length) {
          const commentTree = this.buildCommentTree(matchInfo, commentLikeData, null, null, 'parent');
          console.log('CommentTree => ', commentTree);
          review['comments'] = [...commentTree];
        }
      }
      reviewData[i] = review;
    }
    this.reviewData = reviewData;
    this.loader = false;
  }

  async loadData() {
    const { id } = JSON.parse(localStorage.getItem('credentials'));

    const [reviewData, likeData, wellWrittenData, commentLikeData, memberInfo] = await Promise.all([
      this.reviewService.getReviews().toPromise(),
      this.reviewService.getLikes(id, 'reviews').toPromise(),
      this.reviewService.getWellWrittenData(id).toPromise(),
      this.reviewService.getLikes(id, 'comment').toPromise(),
      this.reviewService.getMemberInfo(id).toPromise()
    ]);

    this.memberInfo = memberInfo;

    if (reviewData) {
      const reviewInfo = [...reviewData.reviewList];
      const reviewIds = [...reviewData.reviewIds];
      this.betriebInfo = { ...reviewData.clientInfo };

      for (let i = 0; i < reviewInfo.length; i++) {
        const postedDate = `${moment(reviewInfo[i].postedAt).format('DD.MM.YYYY')}`;
        const regex = /\{Foto_(\d+)\}/g;
        let { comment } = reviewInfo[i];
        const photoStrings = comment.match(regex);
        if (photoStrings && photoStrings.length) {
          for (let i = 0; i < photoStrings.length; i++) {
            let elem = photoStrings[i];
            elem = elem.replace('{', '');
            elem = elem.replace('}', '');
            elem = elem.replace('Foto_', '');
            photoStrings[i] = parseInt(elem);
          }
          const photos = await this.reviewService
            .getPhotos({ photoIds: JSON.stringify(photoStrings), memberId: id })
            .toPromise();

          if (photos && photos.length) {
            for (let p = 0; p < photos.length; p++) {
              const fotoHTML = `<a class="review-img" href="http://cdn.gastroguide.de/${photos[p].photoFile}" target="_blank" alt="${photos[p].description}">
                                  <img src="http://cdn.gastroguide.de/${photos[p].photoFile}"/>
                                  <i>${photos[p].description}</i>
                                </a>`;
              const fotoId = `{Foto_${photos[p].id}}`;
              comment = comment.replace(fotoId, `${fotoHTML}`);
            }
          }
        }
        comment = comment.replaceAll('[', '<');
        comment = comment.replaceAll(']', '>');

        // const parser = new DOMParser();
        // const doc = parser.parseFromString(comment, 'text/html');
        // comment = doc.body.innerHTML

        reviewInfo[i].comment = comment;
        reviewInfo[i].postedDate = postedDate;
      }

      let reviewComments;
      if (reviewIds && reviewIds.length) {
        this.reviewService.getComments({ itemId: JSON.stringify(reviewIds) }).subscribe((response: any) => {
          reviewComments = response;
          this.reArrangeData(reviewInfo, likeData, wellWrittenData, commentLikeData, reviewComments);
        });
      } else {
        this.reArrangeData(reviewInfo, likeData, wellWrittenData, commentLikeData, null);
      }
    }
  }

  toggleExpandRow(row: any) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  likeUnlikeReview(action: string, review: any) {
    const { id } = JSON.parse(localStorage.getItem('credentials'));
    review['likeLoader'] = true;
    this.reviewService.likeUnlikeReview(id, review.id, action).subscribe((response: any) => {
      if (response) {
        switch (action) {
          case 'like':
            review['likeInfo'] = response;
            delete review['likeLoader'];
            review['likesCntr'] += 1;
            break;
          case 'unlike':
            delete review['likeInfo'];
            delete review['likeLoader'];
            review['likesCntr'] -= 1;
            break;
        }
      }
    });
  }

  likeUnlikeWellWritten(action: string, review: any) {
    const { id } = JSON.parse(localStorage.getItem('credentials'));
    review['wellWrittenLoader'] = true;
    this.reviewService.likeUnlikeWellWritten(id, review.id, action).subscribe((response: any) => {
      if (response) {
        switch (action) {
          case 'well-wriiten-like':
            review['well-written-info'] = response;
            delete review['wellWrittenLoader'];
            review['wellWrittenCntr'] += 1;
            break;
          case 'well-wriiten-unlike':
            delete review['well-written-info'];
            delete review['wellWrittenLoader'];
            review['wellWrittenCntr'] -= 1;
            break;
        }
      }
    });
  }

  reportReview(row: any) {
    const modalRef = this.modalService.open(ReportReviewComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.reviewContent = { ...row };
    modalRef.componentInstance.submitReviewResponse.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      const snackBarRef = this.snackBar.open('Successfully Added Report', 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
      modalRef.close();
    });
  }

  postComment(row: any) {
    const comment = this.comment;
    const { id } = JSON.parse(localStorage.getItem('credentials'));
    const reviewId = row.id;

    const data = {
      comment,
      reviewId,
      memberId: id
    };

    this.reviewService.postComment(data).subscribe((response: any) => {
      if (response) {
        const snackBarRef = this.snackBar.open('Successfully Added Comment', 'Ok', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        this.comment = '';
        response['memberDataInfo'] = this.memberInfo;
        response['comments'] = [];
        if (row.comments) {
          row.comments.push(response);
        } else {
          row['comments'] = [response];
        }
      }
    });
  }

  likeUnlikeComment(action: string, review: any, comment: any) {
    const { id } = JSON.parse(localStorage.getItem('credentials'));
    comment['likeLoader'] = true;
    this.reviewService.likeUnlikeComment(id, review.id, comment.id, action).subscribe((response: any) => {
      if (response) {
        switch (action) {
          case 'like':
            comment['commentLikeData'] = response;
            delete comment['likeLoader'];
            comment['likes'] += 1;
            break;
          case 'unlike':
            delete comment['commentLikeData'];
            delete comment['likeLoader'];
            comment['likes'] -= 1;
            break;
        }
      }
    });
  }

  setReply(review: any, comment: any, mapComment?: any) {
    const modalRef = this.modalService.open(SetReplyComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.commentContent = { review, comment };
    modalRef.componentInstance.submitReplyResponse.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      const snackBarRef = this.snackBar.open('Successfully Added Report', 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
      receivedEntry['memberDataInfo'] = this.memberInfo;
      if (mapComment) {
        mapComment.comments.push(receivedEntry);
      } else if (comment.comments) {
        comment.comments.push(receivedEntry);
      } else {
        comment['comments'] = [...receivedEntry];
      }
      modalRef.close();
    });
  }

  removeComment(comment: any, parentData: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Delete Comment';
    modalRef.componentInstance.message = `Do you want to delete comment`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Delete';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.reviewService.removeComment(comment.id, this.memberInfo.id).subscribe(
            (event: any) => {
              parentData['comments'] = parentData['comments'].filter(
                (eachComment: any) => eachComment.id !== comment.id
              );
              this.snackBar.open('Ereignis erfolgreich gelöscht', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            },
            (err: any) => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  editComment(comment: any, index: number, parentData: any) {
    const modalRef = this.modalService.open(EditCommentComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.commentContent = { comment };
    modalRef.componentInstance.commentResponse.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      const snackBarRef = this.snackBar.open('Comment Updated Successfully', 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
      parentData['comments'][index]['comment'] = receivedEntry.comment;
      modalRef.close();
    });
  }
}

<div class="register-box-w">
  <div class="logo-w">
    <a href="index.html"><img alt="" class="img-fluid" src="./assets/logo.png"/></a>
    <app-lang-selector-backoffice></app-lang-selector-backoffice>
  </div>
  <h4 class="auth-header">
    {{ 'Register.Title' | translate }}
  </h4>
  <form (ngSubmit)="register()" [formGroup]="registerForm" novalidate>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <!-- <i class="far fa-building"></i> -->
          <label for="Firmenname">{{ 'Register.CompanyName' | translate }}</label>
          <input
            class="form-control"
            formControlName="companyName"
            placeholder="{{ 'Register.CompanyName' | translate }}"
            type="text"
          />
          <small
            [hidden]="registerForm.controls.companyName.valid || registerForm.controls.companyName.untouched"
            class="text-danger"
            translate
          >
            {{ 'Register.CompanyError' | translate }}
          </small>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <!--i class="far fa-user"></i -->
          <label for="{{ 'Register.ContactPer' | translate }}">{{ 'Register.ContactPer' | translate }}</label>
          <input
            class="form-control"
            formControlName="name"
            placeholder="{{ 'Register.ContactPer' | translate }}"
            type="text"
          />
          <small
            [hidden]="registerForm.controls.name.valid || registerForm.controls.name.untouched"
            class="text-danger"
            translate
          >
            {{ 'Register.CompanyError' | translate }}
          </small>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <!--i class="fas fa-road"></i-->
          <label for="{{ 'Common.Country' | translate }}">{{ 'Common.Country' | translate }}</label>
          <!-- <input
            class="form-control"
            formControlName="country"
            placeholder="{{ 'Common.Country' | translate }}"
            type="text"
          /> -->
          <ng-select
            formControlName="country"
            [items]="countries"
            bindLabel="name"
            bindValue="id"
            placeholder="{{ 'Common.Country' | translate }}"
            (change)="selectCountriesChange()"
          >
          </ng-select>
          <small
            [hidden]="registerForm.controls.country.valid || registerForm.controls.country.untouched"
            class="text-danger"
            translate
            >{{ 'Register.CountryError' | translate }}</small
          >
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <!--i class="fas fa-road"></i-->
          <label for="{{ 'Common.Street' | translate }}">{{ 'Common.Street' | translate }}</label>
          <input
            class="form-control"
            formControlName="street"
            placeholder="{{ 'Common.Street' | translate }}"
            type="text"
          />
          <small
            [hidden]="registerForm.controls.street.valid || registerForm.controls.street.untouched"
            class="text-danger"
            translate
            >{{ 'Register.StreetError' | translate }}</small
          >
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <!--i class="fas fa-globe-europe"></i-->
          <label for="{{ 'Common.Postcode' | translate }}">{{ 'Common.Postcode' | translate }}</label>
          <input
            class="form-control"
            formControlName="zipCode"
            placeholder="{{ 'Common.Postcode' | translate }}"
            (keypress)="checkForTyping()"
            (keyup)="fetchLocation()"
            type="text"
            [matAutocomplete]="auto"
            (blur)="InputControl($event)"
          />
          <small
            [hidden]="registerForm.controls.zipCode.valid || registerForm.controls.zipCode.untouched"
            class="text-danger"
            *ngIf="registerForm.value.country !== 1"
            translate
          >
            {{ 'Register.PostcodeError' | translate }}
          </small>
          <small
            [hidden]="registerForm.controls.zipCode.valid || registerForm.controls.zipCode.untouched"
            class="text-danger"
            *ngIf="registerForm.value.country === 1"
            translate
          >
            {{ 'Register.PostcodeSuggestionError' | translate }}
          </small>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let location of locationArray"
              (click)="selectZipCode(location)"
              [value]="location.name"
            >
              {{ location.zips }}&nbsp;{{ location.name }}
            </mat-option>
          </mat-autocomplete>
          <!-- <div *ngIf="isList">
            <div
              *ngIf="locationArray && locationArray.length"
              style="background-color: #fff; overflow: scroll; position: absolute; z-index: 5; height: 250px; width:100%"
            >
              <ul style="list-style-type: none;">
                <li
                  *ngFor="let location of locationArray"
                  (click)="selectZipCode(location)"
                  style="cursor: pointer; padding-left: 0;"
                >
                  {{ location.zips }}&nbsp;{{ location.name }}
                </li>
              </ul>
            </div>
          </div> -->
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <!-- <i class="far fa-building"></i> -->

          <!--i class="fas fa-location-arrow"></i-->
          <label for="{{ 'Common.Place' | translate }}">{{ 'Common.Place' | translate }}</label>
          <input
            class="form-control"
            formControlName="place"
            placeholder="{{ 'Common.Place' | translate }}"
            type="text"
          />
          <small
            [hidden]="registerForm.controls.place.valid || registerForm.controls.place.untouched"
            class="text-danger"
            translate
          >
            {{ 'Register.PlaceError' | translate }}
          </small>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <!--i class="fas fa-phone-alt"></i-->
          <label for="{{ 'Common.PhoneNo' | translate }}">{{ 'Common.PhoneNo' | translate }}</label>
          <input
            class="form-control"
            formControlName="telefon"
            placeholder="{{ 'Common.PhoneNo' | translate }}"
            type="text"
          />
          <small
            [hidden]="registerForm.controls.telefon.valid || registerForm.controls.telefon.untouched"
            class="text-danger"
            translate
          >
            {{ 'Register.PhoneError' | translate }}
          </small>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label style="font-size: 0.7rem;" for="{{ 'Common.HowDidYouFindUs' | translate }}">{{
            'Common.HowDidYouFindUs' | translate
          }}</label>
          <input
            class="form-control"
            formControlName="cameFrom"
            placeholder="{{ 'Common.HowDidYouFindUs' | translate }}"
            type="text"
          />
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <!--i class="far fa-envelope"></i-->
          <label for="{{ 'Common.eMail' | translate }}">{{ 'Common.eMail' | translate }}</label>
          <input
            class="form-control"
            formControlName="email"
            placeholder="{{ 'Common.eMail' | translate }}"
            type="text"
          />
          <small
            [hidden]="registerForm.controls.email.valid || registerForm.controls.email.untouched"
            class="text-danger"
            translate
          >
            {{ 'Register.EmailError' | translate }}
          </small>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <!--i class="fas fa-lock"></i-->
          <label for="{{ 'Common.Password' | translate }}">{{ 'Common.Password' | translate }}</label>
          <input
            class="form-control"
            formControlName="password"
            placeholder="{{ 'Common.Password' | translate }}"
            type="password"
          />
          <small
            [hidden]="registerForm.controls.password.valid || registerForm.controls.password.untouched"
            class="text-danger"
            translate
          >
            {{ 'Register.PasswordError' | translate }}
          </small>
        </div>
      </div>
      <div class="col-sm-12 col-md-12">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input form-check-input"
            id="customCheck1"
            formControlName="termsAndCond"
          />
          <label class="custom-control-label" for="customCheck1"
            >{{ 'Register.Read' | translate }}
            <a target="_blank" href="https://www.gastroguide.de/footer/agb">{{ 'Register.Terms' | translate }}</a>
            {{ 'Register.Read2' | translate }}
            <a target="_blank" href="https://www.gastroguide.de/footer/datenschutzerklaerung">{{
              'Register.Data' | translate
            }}</a>
            {{ 'Register.Read3' | translate }}</label
          >
          <!--label class="form-check-label">
            <input class="form-check-input" type="checkbox" formControlName="termsAndCond" />Ich habe die
            <a target="_blank" href="https://www.gastroguide.de/footer/agb">AGB</a> und die
            <a target="_blank" href="https://www.gastroguide.de/footer/datenschutzerklaerung">Datenschutzerklärung</a>
            gelesen und bin damit einverstanden.
          </label-->
        </div>
      </div>
    </div>
    <!-- <div class="form-group">
        <i class="far fa-phone"></i>
        <label for="Nutzername">Nutzername</label>
        <input
          class="form-control"
          formControlName="username"
          placeholder="Nutzername"
          type="text"
        />
        <small
          [hidden]="registerForm.controls.username.valid || registerForm.controls.username.untouched"
          class="text-danger"
          translate
        > Nutzername ist ungültig. </small>
      </div> -->

    <div class="text-danger" *ngIf="message">{{ 'Register.T&CError' | translate }}</div>

    <!-- <div class="form-group">
      <re-captcha
        (resolved)="resolved($event)"
        siteKey="6LdJg8UpAAAAAKIdsT4SKFyDMY8vSPoo2t6__bKU"
        formControlName="recaptcha"
      ></re-captcha>
    </div> -->

    <div class="buttons-w">
      <button [disabled]="disableRegisterBtn" class="btn btn-primary" type="submit">Registrieren</button>
    </div>

    <!-- <p style="margin-top: 20px">
        <small> Haben Sie Ihr <a routerLink="/login/forgot-password">Passwort vergessen?</a></small>
      </p> -->
    <!-- <input type="submit" value="Register"/> -->
    <p style="margin-top: 20px">
      {{ 'Register.Already' | translate }} <a routerLink="/login">{{ 'Register.LoginHere' | translate }}</a>
    </p>
  </form>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MomentModule } from 'ngx-moment';
import { FilterPipeModule } from '@app/shared/filter/filter.module';
import {
  MatTabsModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatProgressSpinnerModule,
  MatSlideToggleModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { DateFnsModule } from 'ngx-date-fns';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ExportComponent } from './export.component';
import { Ng5SliderModule } from 'ng5-slider';
import { Angular2CsvModule } from 'angular2-csv';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ExportComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    NgScrollbarModule,
    NgxDatatableModule,
    MatProgressSpinnerModule,
    MomentModule,
    FilterPipeModule,
    MatTabsModule,
    Ng5SliderModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDatepickerModule,
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fas fa-spinner fa-spin button-loading"></span>'
    }),
    DateFnsModule.forRoot(),
    Angular2CsvModule,
    RouterModule
  ],
  entryComponents: []
})
export class ExportModule {}

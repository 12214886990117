import { Component, OnInit, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material';

import { ReservationService } from '../reservation.service';
import { AuthenticationService, resStatus } from '@app/core';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/takeUntil';
import { Subscription, Subject } from 'rxjs';

import moment from 'moment';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
moment.locale('de');

@Component({
  selector: 'app-reservations-statistics',
  templateUrl: './reservations-statistics.component.html',
  styleUrls: ['./reservations-statistics.component.scss']
})
export class ReservationsStatisticsComponent implements OnInit, OnDestroy {
  range = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl()
  });
  minDate: any;
  maxDate: any;
  resStatusList: any[] = [];
  allStatus: any[] = resStatus;
  // allStatus: any = [
  //   {
  //     label: 'Aktive Reservierungen',
  //     value: 'active',
  //     icon: '',
  //     type: 'group',
  //     description: 'Angekommen, Platziert & Bestätigt'
  //   },
  //   {
  //     label: 'Bestätigt',
  //     value: 'confirmed',
  //     icon: 'fas fa-check-circle',
  //     type: 'single'
  //   },
  //   {
  //     label: 'Storniert',
  //     value: 'canceled',
  //     icon: 'fas fa-ban fa-fw',
  //     type: 'single'
  //   },
  //   {
  //     label: 'No-Show',
  //     value: 'noShow',
  //     icon: 'fas fa-eye-slash fa-fw',
  //     type: 'single'
  //   },
  //   {
  //     label: 'Angekommen',
  //     value: 'arrived',
  //     icon: 'fas fa-store-alt fa-fw',
  //     type: 'single'
  //   },
  //   {
  //     label: 'Platziert',
  //     value: 'placed',
  //     icon: 'fas fa-chair fa-fw',
  //     type: 'single'
  //   },
  //   {
  //     label: 'Ausstehend',
  //     value: 'pending',
  //     icon: 'fas fa-minus-circle fa-fw',
  //     type: 'single'
  //   },
  //   {
  //     label: 'Fertig',
  //     value: 'finished',
  //     icon: 'fas fa-check-double fa-fw',
  //     type: 'single'
  //   },
  //   {
  //     label: 'Warteliste',
  //     value: 'waiting',
  //     icon: 'fas fa-hourglass-half fa-fw',
  //     type: 'single'
  //   }
  // ];
  filterDiv = {
    isYear: false,
    isDateFilter: false
  };

  // Sockets
  // private _reservationSub: Subscription;
  // private _reservationUpdateSub: Subscription;

  private ngUnsubscribe: Subject<any> = new Subject();

  public reservations: any[] = [];
  public pageNumber: number = 0;
  public loading: boolean = true;
  private currentDate: Date = new Date();
  public optionYear: any[] = [];

  public startDate: any = new Date();
  public endDate: any = new Date();
  public hours: number = 24 * 30;

  public showChartValue: string = 'reservation';
  public showChartType: string = 'next30days';
  public showChartLabel: any = {
    next30days: 'Online Reservierungen der nächsten 30 Tage',
    last30days: 'Online Reservierungen der letzten 30 Tage',
    thisMonth: 'akt. Monat',
    lastMonth: 'letzter Monat',
    last24months: 'Online Reservierungen der letzten 24 Monate'
  };
  public selectOptions: any[] = [];

  public showChart = false;

  public barChartOptions: ChartOptions = {
    responsive: true
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend: boolean = true;
  public barChartPlugins: any[] = [];

  public barChartData: ChartDataSets[] = [
    {
      data: [],
      label: ''
    }
  ];

  public barChartTagsData: ChartDataSets[] = [
    {
      data: [],
      label: ''
    }
  ];

  public barChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: '#1ad1ff'
    },
    // {
    //   borderColor: 'black',
    //   backgroundColor: 'rgba(255, 165, 0, 0.7)' //orange
    // },
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0, 0, 0, 0.5)' //black
    },
    {
      borderColor: 'black',
      backgroundColor: 'rgb(157,216,102)' //light green
    },
    {
      borderColor: 'black',
      backgroundColor: '#00b7e6'
    },
    {
      borderColor: 'black',
      backgroundColor: '#008fb3'
    }
  ];

  public chartValueArray = [
    {
      value: 'reservation',
      name: 'Anz. der Reservierungen'
    },
    {
      value: 'people',
      name: 'Anz. der Gäste'
    },
    {
      value: 'visitWeekday',
      name: 'Besucherzahl pro Wochentag'
    },
    {
      value: 'visitHour',
      name: 'Anzahl der Besucher pro Stunde'
    },
    {
      value: 'tags',
      name: 'Tags'
    }
  ];
  public barChartColorsTag: any[] = [];

  public onlineAggr: any = 0; // online
  public appAggr: any = 0; // app
  public manuellAggr: any = 0; // manuell
  public walkinsAggr: any = 0; // walkins
  public _totalCount: any = 0;
  public _onlineCount = 0; // online
  public _appCount = 0; // app
  public _manuellCount = 0; // manuell
  public _walkinsCount = 0; // walkins
  public totalCountOfPeople: number; // Total Number of guest
  public totalCountOfTags: number = 0; // Total number of tags
  public totalAssistantAiCount = 0;
  public totalMitGoogleCount = 0;
  public totalOtherReferrerCount = 0;

  public perAssistantAiCount: any = 0;
  public perMitGoogleCount: any = 0;
  public perOtherReferrerCount: any = 0;
  showButtonDiv: boolean = false;
  public showType: string = 'daily';
  public dateValue: string = 'none';
  tags: Array<any> = [];
  showTagsSelector: boolean = false;
  showTagsValue: any[] = [];
  selectedTags: any[] = [];
  sortSelectedTags: any[] = [];
  countMap = new Map<string, Map<string, { count: number; color: string }>>();
  labelCounts: { [label: string]: { count: number; createdAt: string; label: string } } = {};
  public barChartLabelsTags: Label[] = [];
  showTagsChart: boolean = false;
  isArchive: boolean = false;
  listOfTagsWithCount: { label: string; totalCount: number; percentage: string }[] = [];
  showBarCharts: boolean = false;

  constructor(private snackBar: MatSnackBar, private reservationService: ReservationService) {}

  ngOnInit() {
    this.setDates(this.showChartType);
    this.generateYearsFromOrigin();
    this.getTags();
    this.getAllStatus();
  }

  ngOnDestroy() {
    // this.ngUnsubscribe.next();
    // this.ngUnsubscribe.complete();
  }

  getAllStatus() {
    this.reservationService
      .getResStatus()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resStatusList: any) => {
        this.resStatusList = resStatusList;
        this.resStatusList.forEach((item: any) => {
          item['type'] = 'single';
        });
        this.allStatus = this.allStatus.concat(this.resStatusList);
      });
  }

  translateStatus(status: string) {
    switch (status) {
      case 'confirmed':
        return 'Bestätigt';
        break;
      case 'canceled':
        return 'Storniert';
        break;
      case 'noShow':
        return 'No Show';
        break;
      case 'arrived':
        return 'Angekommen';
        break;
      case 'placed':
        return 'Platziert';
        break;
      case 'pending':
        return 'Ausstehend';
        break;
      case 'waiting':
        return 'Warteliste';
        break;
      case 'finished':
        return 'Fertig';
        break;
      case 'blocked':
        return 'Ausstehende Zahlung';
        break;
      default:
        break;
    }
  }
  public generateYearsFromOrigin() {
    var max = new Date().getFullYear() + 1,
      min = new Date();

    min.setFullYear(2019);

    for (var i = min.getFullYear(); i <= max; i++) {
      this.optionYear.push(i);
    }
    this.optionYear.reverse();
  }

  public setDates(type: string) {
    this.totalCountOfPeople = 0;
    this.totalCountOfTags = 0;
    this.listOfTagsWithCount = [];
    this.showChartType = type;
    if (this.dateValue === 'none' || this.dateValue === '') {
      this.filterDiv.isYear = false;
      this.filterDiv.isDateFilter = false;
    } else {
      this.filterDiv.isYear = true;
      this.filterDiv.isDateFilter = true;
    }
    if (type == 'next30days') {
      this.range.reset();
      this.range.markAsPristine();
      this.showButtonDiv = false;
      this.dateValue = 'none';
      this.startDate = moment();
      this.endDate = moment(this.startDate).add(30, 'days');
      this.hours = 24 * 30;
    }
    if (type == 'last30days') {
      this.range.reset();
      this.range.markAsPristine();
      this.showButtonDiv = false;
      this.dateValue = 'none';
      this.startDate = moment().subtract(30, 'days');
      this.endDate = moment();
      this.hours = 24 * 30;
    }
    if (type == 'lastMonth') {
      this.range.reset();
      this.range.markAsPristine();
      this.showButtonDiv = false;
      this.dateValue = 'none';
      const date = moment().subtract(1, 'month');
      this.startDate = date.startOf('month');
      this.endDate = moment(this.startDate).endOf('month');
    }
    if (type == 'thisMonth') {
      this.range.reset();
      this.range.markAsPristine();
      this.showButtonDiv = false;
      this.dateValue = 'none';
      const date = moment();
      this.startDate = date.startOf('month');
      this.endDate = moment(this.startDate).endOf('month');
    }
    if (type == 'last24months') {
      this.startDate = this.range.value.startDate;
      this.endDate = this.range.value.endDate;
      this.showButtonDiv = true;
    }
    if (this.showChartValue === 'tags') {
      this.showBarCharts = true;
      this.showTagsSelector = true;
      // this.showTagsChart = true;
      if (type !== 'last24months') {
        this.range.reset();
        this.range.markAsPristine();
      } else {
        this.barChartTagsData = [
          {
            data: [],
            label: ''
          }
        ];
      }
      if (this.selectedTags.length > 0) {
        this.loading = true;
        this.getAllReservations(true);
      } else {
        this.barChartTagsData = [
          {
            data: [],
            label: ''
          }
        ];
      }
    } else {
      this.showBarCharts = false;
      this.showTagsSelector = false;
      this.showTagsChart = false;
      this.getAllReservations();
      this.loading = true;
    }
  }

  async getAllReservations(selectedTags: boolean = false) {
    if (this.startDate.isSame(this.endDate)) {
      // Add 24 hours to the end date
      this.endDate.add(24, 'hours');
    }
    await this.reservationService
      .getAllReservations(this.startDate, this.endDate, 0, this.hours, '/statistics')
      .toPromise()
      .then((data: any) => {
        this.isArchive = data.isArchive;
        this.reservations = data.reservations || [];
        if (this.reservations.length == 0) {
          this.showBarCharts = true;
        }
        if (this.showChartValue === 'tags' && this.selectedTags.length > 0 && selectedTags === true) {
          this.getSelectedTags(this.selectedTags);
        } else {
          this.showChart = true;
          // if (!this.reservations.length) {
          //   if (this.showChartType == 'next30days') {
          //     this.setDates('last30days');
          //   } else if (this.showChartType == 'last30days') {
          //     // this.setDates('last24months');
          //   }
          //   // return false;
          // }

          this.barChartLabels = [];
          let tempBarChartData7 = {}; // online
          let tempBarChartData = {}; // phone-assistant
          // let tempBarChartData2 = {}; // app
          let tempBarChartData3 = {}; // manuell
          let tempBarChartData4 = {}; // walkins
          let tempBarChartData5 = {}; // Google
          let tempBarChartData6 = {}; // Website
          this.clear();
          console.log('this.reservations', this.reservations);
          this.reservations.forEach((reservation: any) => {
            let addValue = 1;
            let key = '';
            let d = Date.parse(reservation.startDate);
            let dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
            let [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d);
            if (this.showChartValue == 'reservation') {
              addValue = 1;
              this.showChartLabel[this.showChartType].replace('Gäste', 'Reservierungen');
              if (
                this.showChartType == 'next30days' ||
                this.showChartType == 'last30days' ||
                this.showChartType == 'thisMonth' ||
                this.showChartType == 'lastMonth'
              ) {
                key = `${da}.${mo}.${ye}`;
                // key = moment(reservation.startDate).format('DD') + '.' + moment(reservation.startDate).format('MM') + '.' + moment(reservation.startDate).format('YYYY');
                //addValue = reservation.peopleCount;
              } else if (this.showChartType == 'last24months') {
                if (this.showType === 'daily') {
                  key = `${da}.${mo}.${ye}`;
                } else if (this.showType === 'monthly') {
                  key = moment(reservation.startDate).format('MM') + '.' + moment(reservation.startDate).format('YYYY');
                }
              }
            } else if (this.showChartValue == 'visitWeekday') {
              addValue = 1;
              // this.showChartLabel[this.showChartType].replace('Gäste', 'Reservierungen');
              key = moment(reservation.startDate).format('dddd');
            } else if (this.showChartValue == 'visitHour') {
              addValue = 1;
              // this.showChartLabel[this.showChartType].replace('Gäste', 'Reservierungen');
              key = moment(reservation.startDate).format('hA');
            } else {
              this.totalCountOfPeople += reservation.peopleCount;
              this.totalCountOfPeople =
                this.totalCountOfPeople === null || isNaN(this.totalCountOfPeople) ? 0 : this.totalCountOfPeople;
              addValue = reservation.peopleCount;
              this.showChartLabel[this.showChartType].replace('Reservierungen', 'Gäste');
              key =
                moment(reservation.startDate).format('DD') +
                '.' +
                moment(reservation.startDate).format('MM') +
                '.' +
                moment(reservation.startDate).format('YYYY');
              if (this.showChartType == 'last24months') {
                if (this.showType === 'daily') {
                  key = `${da}.${mo}.${ye}`;
                } else if (this.showType === 'monthly') {
                  key = moment(reservation.startDate).format('MM') + '.' + moment(reservation.startDate).format('YYYY');
                }
                //addValue = reservation.peopleCount;
              }
            }

            if (!this.barChartLabels.includes(key)) {
              this.barChartLabels.push(key);
            }

            //console.log('reservation.log[0].entries.length', reservation.log[0].entries.length, reservation.id);
            if (reservation.source == 'online') {
              this._onlineCount += 1;
              if (tempBarChartData7[key]) {
                tempBarChartData7[key] = tempBarChartData7[key] + addValue;
              } else {
                tempBarChartData7[key] = addValue;
              }
              if (!tempBarChartData[key]) {
                tempBarChartData[key] = 0;
              }
              if (!tempBarChartData3[key]) {
                tempBarChartData3[key] = 0;
              }
              if (!tempBarChartData4[key]) {
                tempBarChartData4[key] = 0;
              }
              if (!tempBarChartData5[key]) {
                tempBarChartData5[key] = 0;
              }
              if (!tempBarChartData6[key]) {
                tempBarChartData6[key] = 0;
              }
            }

            if (reservation.referrer !== null && reservation.referrer !== '') {
              if (reservation.referrer === 'assistent.ai') {
                this.totalAssistantAiCount += 1;
                if (tempBarChartData[key]) {
                  tempBarChartData[key] = tempBarChartData[key] + addValue;
                } else {
                  tempBarChartData[key] = addValue;
                }
                // if (!tempBarChartData2[key]) {
                //   tempBarChartData2[key] = 0;
                // }
                if (!tempBarChartData3[key]) {
                  tempBarChartData3[key] = 0;
                }
                if (!tempBarChartData4[key]) {
                  tempBarChartData4[key] = 0;
                }
                if (!tempBarChartData5[key]) {
                  tempBarChartData5[key] = 0;
                }
                if (!tempBarChartData6[key]) {
                  tempBarChartData6[key] = 0;
                }
                if (!tempBarChartData7[key]) {
                  tempBarChartData7[key] = 0;
                }
              } else if (reservation.referrer === 'Reservierung mit Google') {
                this.totalMitGoogleCount += 1;
                if (tempBarChartData5[key]) {
                  tempBarChartData5[key] = tempBarChartData5[key] + addValue;
                } else {
                  tempBarChartData5[key] = addValue;
                }
                if (!tempBarChartData[key]) {
                  tempBarChartData[key] = 0;
                }
                // if (!tempBarChartData2[key]) {
                //   tempBarChartData2[key] = 0;
                // }
                if (!tempBarChartData3[key]) {
                  tempBarChartData3[key] = 0;
                }
                if (!tempBarChartData4[key]) {
                  tempBarChartData4[key] = 0;
                }
                if (!tempBarChartData6[key]) {
                  tempBarChartData6[key] = 0;
                }
                if (!tempBarChartData7[key]) {
                  tempBarChartData7[key] = 0;
                }
              } else {
                this.totalOtherReferrerCount += 1;
                if (tempBarChartData6[key]) {
                  tempBarChartData6[key] = tempBarChartData6[key] + addValue;
                } else {
                  tempBarChartData6[key] = addValue;
                }
                if (!tempBarChartData[key]) {
                  tempBarChartData[key] = 0;
                }
                // if (!tempBarChartData2[key]) {
                //   tempBarChartData2[key] = 0;
                // }
                if (!tempBarChartData3[key]) {
                  tempBarChartData3[key] = 0;
                }
                if (!tempBarChartData4[key]) {
                  tempBarChartData4[key] = 0;
                }
                if (!tempBarChartData5[key]) {
                  tempBarChartData5[key] = 0;
                }
                if (!tempBarChartData7[key]) {
                  tempBarChartData7[key] = 0;
                }
              }
            }

            // if (reservation.source == 'app') {
            //   this._appCount += 1;
            //   if (tempBarChartData2[key]) {
            //     tempBarChartData2[key] = tempBarChartData2[key] + addValue;
            //   } else {
            //     tempBarChartData2[key] = addValue;
            //   }
            //   if (!tempBarChartData[key]) {
            //     tempBarChartData[key] = 0;
            //   }
            //   if (!tempBarChartData3[key]) {
            //     tempBarChartData3[key] = 0;
            //   }
            //   if (!tempBarChartData4[key]) {
            //     tempBarChartData4[key] = 0;
            //   }
            //   if (!tempBarChartData5[key]) {
            //     tempBarChartData5[key] = 0;
            //   }
            //   if (!tempBarChartData6[key]) {
            //     tempBarChartData6[key] = 0;
            //   }
            // }
            if (reservation.source == 'manual') {
              this._manuellCount += 1;
              if (tempBarChartData3[key]) {
                tempBarChartData3[key] = tempBarChartData3[key] + addValue;
              } else {
                tempBarChartData3[key] = addValue;
              }
              if (!tempBarChartData[key]) {
                tempBarChartData[key] = 0;
              }
              // if (!tempBarChartData2[key]) {
              //   tempBarChartData2[key] = 0;
              // }
              if (!tempBarChartData4[key]) {
                tempBarChartData4[key] = 0;
              }
              if (!tempBarChartData5[key]) {
                tempBarChartData5[key] = 0;
              }
              if (!tempBarChartData6[key]) {
                tempBarChartData6[key] = 0;
              }
            }
            if (reservation.source == 'walkin' || !reservation.source) {
              this._walkinsCount += 1;
              if (tempBarChartData4[key]) {
                tempBarChartData4[key] = tempBarChartData4[key] + addValue;
              } else {
                tempBarChartData4[key] = addValue;
              }
              if (!tempBarChartData[key]) {
                tempBarChartData[key] = 0;
              }
              // if (!tempBarChartData2[key]) {
              //   tempBarChartData2[key] = 0;
              // }
              if (!tempBarChartData3[key]) {
                tempBarChartData3[key] = 0;
              }
              if (!tempBarChartData5[key]) {
                tempBarChartData5[key] = 0;
              }
              if (!tempBarChartData6[key]) {
                tempBarChartData6[key] = 0;
              }
            }
          }, this);
          if (this.showChartValue == 'visitHour') {
            this.barChartLabels.sort((a, b) => this.convertTo24Hour(a).localeCompare(this.convertTo24Hour(b)));

            Object.keys(tempBarChartData)
              .sort((a, b) => this.convertTo24Hour(a).localeCompare(this.convertTo24Hour(b)))
              .forEach((key, index) => {
                const value = tempBarChartData[key];
                delete tempBarChartData[key];
                tempBarChartData[key] = value;
              });

            // Object.keys(tempBarChartData2)
            //   .sort((a, b) => this.convertTo24Hour(a).localeCompare(this.convertTo24Hour(b)))
            //   .forEach((key, index) => {
            //     const value = tempBarChartData2[key];
            //     delete tempBarChartData2[key];
            //     tempBarChartData2[key] = value;
            //   });

            Object.keys(tempBarChartData3)
              .sort((a, b) => this.convertTo24Hour(a).localeCompare(this.convertTo24Hour(b)))
              .forEach((key, index) => {
                const value = tempBarChartData3[key];
                delete tempBarChartData3[key];
                tempBarChartData3[key] = value;
              });

            Object.keys(tempBarChartData4)
              .sort((a, b) => this.convertTo24Hour(a).localeCompare(this.convertTo24Hour(b)))
              .forEach((key, index) => {
                const value = tempBarChartData4[key];
                delete tempBarChartData4[key];
                tempBarChartData4[key] = value;
              });

            Object.keys(tempBarChartData5)
              .sort((a, b) => this.convertTo24Hour(a).localeCompare(this.convertTo24Hour(b)))
              .forEach((key, index) => {
                const value = tempBarChartData5[key];
                delete tempBarChartData5[key];
                tempBarChartData5[key] = value;
              });

            Object.keys(tempBarChartData6)
              .sort((a, b) => this.convertTo24Hour(a).localeCompare(this.convertTo24Hour(b)))
              .forEach((key, index) => {
                const value = tempBarChartData6[key];
                delete tempBarChartData6[key];
                tempBarChartData6[key] = value;
              });

            //   debugger
            //   this.barChartLabels = this.barChartLabels.sort()

            //   tempBarChartData = Object.keys(tempBarChartData).sort().reduce(
            //     (obj, key) => {
            //       obj[key] = tempBarChartData[key];
            //       return obj;
            //     },
            //     {}
            //   );

            //   tempBarChartData2 = Object.keys(tempBarChartData2).sort().reduce(
            //     (obj, key) => {
            //       obj[key] = tempBarChartData2[key];
            //       return obj;
            //     },
            //     {}
            //   );

            //   tempBarChartData3 = Object.keys(tempBarChartData3).sort().reduce(
            //     (obj, key) => {
            //       obj[key] = tempBarChartData3[key];
            //       return obj;
            //     },
            //     {}
            //   );

            //   tempBarChartData4 = Object.keys(tempBarChartData4).sort().reduce(
            //     (obj, key) => {
            //       obj[key] = tempBarChartData4[key];
            //       return obj;
            //     },
            //     {}
            //   );
          } else if (this.showChartValue == 'reservation' || this.showChartValue == 'people') {
            let label: any = [];
            label = this.barChartLabels;
            this.barChartLabels = label.sort((a: any, b: any) => {
              const [dayA, monthA] = a.split('.').map(Number);
              const [dayB, monthB] = b.split('.').map(Number);

              if (monthA === monthB) {
                return dayA - dayB;
              } else {
                return monthA - monthB;
              }
            });
            tempBarChartData = this.sortData(tempBarChartData);
            // tempBarChartData2 = this.sortData(tempBarChartData2);
            tempBarChartData3 = this.sortData(tempBarChartData3);
            tempBarChartData4 = this.sortData(tempBarChartData4);
            tempBarChartData5 = this.sortData(tempBarChartData5);
            tempBarChartData6 = this.sortData(tempBarChartData6);
          }
          this._totalCount =
            this._manuellCount +
            this.totalAssistantAiCount +
            this.totalMitGoogleCount +
            this.totalOtherReferrerCount +
            this._walkinsCount;
          this._totalCount =
            this._totalCount === null || this._totalCount === '' || isNaN(this._totalCount) ? 0 : this._totalCount;
          let tempData = [];
          tempData = Object.values(tempBarChartData);
          tempData.forEach((element: number) => {
            element = Math.round(element);
          });
          this.onlineAggr = ((this._onlineCount / this._totalCount) * 100).toFixed(1);
          this.onlineAggr =
            this.onlineAggr === null || this.onlineAggr === '' || isNaN(this.onlineAggr) ? 0 : this.onlineAggr;
          this.perAssistantAiCount = ((this.totalAssistantAiCount / this._onlineCount) * 100).toFixed(1);
          this.perAssistantAiCount =
            this.perAssistantAiCount === null || this.perAssistantAiCount === '' || isNaN(this.perAssistantAiCount)
              ? 0
              : this.perAssistantAiCount;

          // let tempData2 = [];
          // tempData2 = Object.values(tempBarChartData2);
          // tempData2.forEach((element: number) => {
          //   element = Math.round(element);
          // });
          // this.appAggr = ((this._appCount / this.onlineAggr) * 100).toFixed(2);
          let tempData3 = [];
          tempData3 = Object.values(tempBarChartData3);
          tempData3.forEach((element: number) => {
            element = Math.round(element);
          });
          this.manuellAggr = ((this._manuellCount / this._totalCount) * 100).toFixed(1);
          this.manuellAggr =
            this.manuellAggr === null || this.manuellAggr === '' || isNaN(this.manuellAggr) ? 0 : this.manuellAggr;
          let tempData4 = [];
          tempData4 = Object.values(tempBarChartData4);
          tempData4.forEach((element: number) => {
            element = Math.round(element);
          });
          this.walkinsAggr = ((this._walkinsCount / this._totalCount) * 100).toFixed(1);
          this.walkinsAggr =
            this.walkinsAggr === null || this.walkinsAggr === '' || isNaN(this.walkinsAggr) ? 0 : this.walkinsAggr;
          let tempData5 = [];
          tempData5 = Object.values(tempBarChartData5);
          tempData5.forEach((element: number) => {
            element = Math.round(element);
          });
          this.perMitGoogleCount = ((this.totalMitGoogleCount / this._onlineCount) * 100).toFixed(1);
          this.perMitGoogleCount =
            this.perMitGoogleCount === null || this.perMitGoogleCount === '' || isNaN(this.perMitGoogleCount)
              ? 0
              : this.perMitGoogleCount;

          let tempData6 = [];
          tempData6 = Object.values(tempBarChartData6);
          tempData6.forEach((element: number) => {
            element = Math.round(element);
          });
          this.perOtherReferrerCount = ((this.totalOtherReferrerCount / this._onlineCount) * 100).toFixed(1);
          this.perOtherReferrerCount =
            this.perOtherReferrerCount === null ||
            this.perOtherReferrerCount === '' ||
            isNaN(this.perOtherReferrerCount)
              ? 0
              : this.perOtherReferrerCount;

          let replaceWord = 'Reservierungen';
          let replaceWith = 'Gäste';
          if (this.showChartValue == 'reservation') {
            replaceWith = 'Reservierungen';
            replaceWord = 'Gäste';
          }

          this.barChartData = [
            {
              data: tempData, // Phone-Assistan
              label: 'Phone-Assistant',
              stack: '1'
            },
            // {
            //   data: tempData2, // app
            //   label: 'APP ' + replaceWith,
            //   stack: '1'
            // },
            {
              data: tempData3, // manuell
              label: 'Manuelle ' + replaceWith,
              stack: '1'
            },
            {
              data: tempData4, // walkin
              label: 'WalkIns',
              stack: '1'
            },
            {
              data: tempData5, // Google
              label: 'Google',
              stack: '1'
            },
            {
              data: tempData6, // Website
              label: 'Website',
              stack: '1'
            }
          ];
          // console.log(this.barChartLabels, tempBarChartData, Object.values(tempBarChartData), this.barChartData);

          this.showChart = true;
          this.loading = false;
        }
      })
      .catch(err => {
        this.reservations = [];
        this.loading = false;
      });
  }

  filterYearBy(value: any) {
    this.dateValue = value;
    this.range.reset();
    this.filterDiv.isDateFilter = true;
    const currentDate = new Date().getFullYear();
    if (value == currentDate) {
      this.minDate = '';
      this.maxDate = '';
    } else {
      const yearStart = moment(`${value}-01-01`);
      const yearEnd = moment(`${value}-12-31`);
      this.minDate = yearStart;
      this.maxDate = yearEnd;
    }
    // this.minDate = moment().set({ year: value, month: 0, date: 1, hour: 0, minute: 0, second: 1 });
    // this.maxDate = moment().set({ year: value, month: 12, date: 31, hour: 23, minute: 59, second: 0 });
    this.range.controls.startDate.setValidators([
      Validators.required,
      (control: AbstractControl) => Validators.min(this.minDate)(control),
      (control: AbstractControl) => Validators.max(this.maxDate)(control)
    ]);
    this.range.controls.endDate.setValidators([
      Validators.required,
      (control: AbstractControl) => Validators.min(this.minDate)(control),
      (control: AbstractControl) => Validators.max(this.maxDate)(control)
    ]);
  }

  typeOfGraph(value: any) {
    this.showType = value;
    // this.loading = true;
    // if (this.showChartValue === 'tags' && this.selectedTags.length > 0) {
    //   this.getAllReservations(true);
    // } else {
    //   this.getAllReservations();
    // }
  }

  sortObject(obj: any) {
    return Object.keys(obj)
      .sort()
      .reduce((a, v) => {
        a[v] = obj[v];
        return a;
      }, {});
  }

  getTransactionByYear() {
    this.startDate = this.range.value.startDate;
    this.endDate = this.range.value.endDate;
    this.loading = true;
    this.hours = 24 * 30;
    if (this.showChartValue === 'tags' && this.selectedTags.length > 0) {
      this.getAllReservations(true);
    } else {
      this.getAllReservations();
    }
  }
  clear() {
    this.onlineAggr = 0; // online
    this.appAggr = 0; // app
    this.manuellAggr = 0; // manuell
    this.walkinsAggr = 0; // walkins
    this._totalCount = 0;
    this._onlineCount = 0; // online
    this.totalCountOfPeople = 0;
    this.totalCountOfTags = 0; // Total Tags
    this.listOfTagsWithCount = [];
    this.totalAssistantAiCount = 0; // phone-assistant
    this.totalMitGoogleCount = 0; // Google
    this.totalOtherReferrerCount = 0; // website
    this._appCount = 0; // app
    this._manuellCount = 0; // manuell
    this._walkinsCount = 0; // walkins
  }
  convertTo24Hour(time: any) {
    // const [hour, period] = time.split(/(?<=\d)(?=AM|PM)/);
    let hour;
    let period;
    if (time.includes('AM')) {
      hour = time.replace('AM', '');
      period = 'AM';
    } else {
      hour = time.replace('PM', '');
      period = 'PM';
    }
    let hour24 = parseInt(hour);

    if (period === 'PM' && hour24 !== 12) {
      hour24 += 12;
    } else if (period === 'AM' && hour24 === 12) {
      hour24 = 0;
    }

    return hour24.toString().padStart(2, '0') + ':00';
  }

  sortData(data: any) {
    const sortedKeys = Object.keys(data).sort((key1, key2) => {
      const [day1, month1] = key1.split('.').map(parseFloat);
      const [day2, month2] = key2.split('.').map(parseFloat);
      if (month1 === month2) {
        return day1 - day2;
      }
      return month1 - month2;
    });

    const sortedData = {};
    sortedKeys.forEach(key => {
      sortedData[key] = data[key];
      delete data[key];
    });

    Object.assign(data, sortedData);
    return data;
  }

  getTags() {
    this.reservationService
      .getTags()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((tags: any) => {
        this.tags = tags;
      });
  }

  getSelectedTags(selectedTags: any) {
    this.showChart = true;
    this.showTagsChart = true;
    this.loading = true;
    this.selectedTags = selectedTags;
    let newTagsArray: any[] = [];
    selectedTags.forEach((selectTag: any) => {
      const label = selectTag.label;
      if (this.isArchive === false) {
        this.reservations.filter((obj2: any) => {
          obj2.tags.map((tag: any) => {
            if (tag.tag.label === label) {
              newTagsArray.push({ ...tag, startDate: obj2.startDate });
            }
          });
        });
      } else {
        this.reservations.filter((obj2: any) => {
          if (obj2.tags !== null) {
            obj2.tags.map((tag: any) => {
              if (tag.label === label) {
                newTagsArray.push({ tag: tag, startDate: obj2.startDate });
              }
            });
          }
        });
      }
    });
    this.sortSelectedTags = newTagsArray;
    this.countMap.clear();
    this.sortSelectedTags = [];
    newTagsArray.forEach(reservation => {
      // const createdAt = reservation.tag.createdAt;
      const createdAt = reservation.startDate;
      const label = reservation.tag.label;
      const color = reservation.tag.color;

      if (this.countMap.has(label)) {
        if (this.countMap.get(label).has(createdAt)) {
          const count = this.countMap.get(label).get(createdAt).count + 1;
          this.countMap.get(label).set(createdAt, { count: count, color: color });
        } else {
          this.countMap.get(label).set(createdAt, { count: 1, color: color });
        }
      } else {
        const innerMap: Map<string, { count: number; color: string }> = new Map();
        innerMap.set(createdAt, { count: 1, color: color });
        this.countMap.set(label, innerMap);
      }
    });

    this.countMap.forEach((innerMap, label) => {
      innerMap.forEach((data, createdAt) => {
        const count = data.count;
        const color = data.color;
        if (
          this.showChartType == 'next30days' ||
          this.showChartType == 'last30days' ||
          this.showChartType == 'thisMonth' ||
          this.showChartType == 'lastMonth'
        ) {
          this.sortSelectedTags.push({
            label: label,
            createdAt: moment(createdAt).format('DD.MM.YYYY'),
            totalCount: count,
            color: color
          });
        } else if (this.showChartType == 'last24months') {
          if (this.showType === 'daily') {
            this.sortSelectedTags.push({
              label: label,
              createdAt: moment(createdAt).format('DD.MM.YYYY'),
              totalCount: count,
              color: color
            });
          } else if (this.showType === 'monthly') {
            const key = moment(createdAt).format('MM') + '.' + moment(createdAt).format('YYYY');
            this.sortSelectedTags.push({ label: label, createdAt: key, totalCount: count, color: color });
          }
        }
      });
    });
    this.totalCountOfTags = this.sortSelectedTags.reduce((acc, obj) => acc + obj.totalCount, 0);
    this.totalCountOfTags = this.totalCountOfTags === null || isNaN(this.totalCountOfTags) ? 0 : this.totalCountOfTags;

    this.sortSelectedTags.forEach(item => {
      const parts = item.createdAt.split('.');
      if (this.showType === 'monthly') {
        item.createdAt = new Date(parts[1], parts[0] - 1);
      } else {
        item.createdAt = new Date(parts[2], parts[1] - 1, parts[0]);
      }
    });
    this.sortSelectedTags.sort((a, b) => a.createdAt - b.createdAt);

    this.sortSelectedTags.forEach(item => {
      let formattedDate = '';
      if (this.showType === 'monthly') {
        formattedDate = `${item.createdAt.getMonth() + 1}.${item.createdAt.getFullYear()}`;
      } else {
        formattedDate = `${item.createdAt.getDate()}.${item.createdAt.getMonth() + 1}.${item.createdAt.getFullYear()}`;
      }
      item.createdAt = formattedDate;
    });

    this.calculateCountPerTags();
    const labelCountsPerDate = {};
    for (const entry of this.sortSelectedTags) {
      const date = entry.createdAt.split(' ')[0]; // Assuming we only care about the date, not the time
      if (!labelCountsPerDate[date]) {
        labelCountsPerDate[date] = {};
      }
      const labelCounts = labelCountsPerDate[date];
      labelCounts[entry.label] = (labelCounts[entry.label] || 0) + entry.totalCount;
    }

    const labels = [...new Set(this.sortSelectedTags.map(item => item.label))]; // Unique labels
    const datasets = labels.map(label => ({
      label,
      data: [],
      stack: '1'
    }));

    for (const date of Object.keys(labelCountsPerDate)) {
      for (const dataset of datasets) {
        dataset.data.push(labelCountsPerDate[date][dataset.label] || 0);
      }
    }
    this.showTagsChart = true;
    // Set header and bar chart data, color or tags.
    const uniqueColorsMap = {};
    // Iterate over the data array to populate the uniqueColorsMap
    this.sortSelectedTags.forEach(item => {
      if (!uniqueColorsMap[item.label]) {
        uniqueColorsMap[item.label] = item.color;
      }
    });

    // Convert the uniqueColorsMap into an array of objects
    const uniqueColorsArray = Object.keys(uniqueColorsMap).map(label => ({
      label,
      color: uniqueColorsMap[label]
    }));

    this.barChartColorsTag = uniqueColorsArray.map(item => ({
      backgroundColor: item.color,
      borderColor: 'black'
    }));
    this.barChartTagsData = datasets;
    this.barChartLabelsTags = Object.keys(labelCountsPerDate);
    this.loading = false;
  }

  calculateCountPerTags() {
    const labelMap: { [key: string]: number } = {};

    this.sortSelectedTags.forEach(item => {
      if (labelMap[item.label]) {
        labelMap[item.label]++;
      } else {
        labelMap[item.label] = 1;
      }
    });

    this.listOfTagsWithCount = Object.keys(labelMap).map(label => ({
      label: label,
      totalCount: labelMap[label],
      percentage: ((labelMap[label] / this.totalCountOfTags) * 100).toFixed(1)
    }));
  }
}

import { Component, Input, OnChanges, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Reservation } from '@app/reservation/reservation.model';
import { ReservationService } from '@app/reservation/reservation.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface IncomingResData {
  guestName: string;
  time: string;
  isReservedAtSelectedTime: boolean;
  tableId: string;
  reservationId: number;
  popoverData?: {
    status: {};
    start: null;
    end: null;
    stayTime: null;
    numberOfPersons: null;
  };
}

@Component({
  selector: 'app-incoming-reservations',
  templateUrl: './incoming-reservations.component.html',
  styleUrls: ['./incoming-reservations.component.scss']
})
export class IncomingReservationsComponent implements OnChanges, OnInit, OnDestroy {
  @Input() reservations: Reservation[];
  @Input() table: any;
  @Input() selectedTime: number;
  @Input() sizeFactor: number;
  @Input() statusArray: any[];
  @Input() zoomSizeFactor: number;
  @Output() onReservationSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReservationDraged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReservationDragEnd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReservationClick: EventEmitter<any> = new EventEmitter<any>();
  isDraging = false;

  listOfTableReservations: IncomingResData[];

  items: any;
  contextMenuStatusItems: any = [];

  showEventOptions: {
    name: '';
    stopPropagation: true;
    delay: 200;
  };
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private translateService: TranslateService,
    private reservationService: ReservationService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.listOfTableReservations = [];
    let currentlySelectedReservationInsideTable: any;
    this.reservations.forEach((reservation: any) => {
      for (let i = 0; i < reservation.tables.length; i++) {
        const table = reservation.tables[i];
        if (table.id === this.table.id) {
          // const guestNamePeces = reservation.guestData.name.split(' ');
          const guestName = reservation.guestData ? reservation.guestData.name : 'Walk-In';
          const time = reservation.startDate;
          const isReservedAtSelectedTime = this.checkIfTableIsReserved(reservation);
          const tableId = table.id;
          const reservationId = reservation.id;
          const status = this.statusArray.find((s: any) => {
            return reservation.status === s.value;
          });
          const start = reservation.startDate;
          const end = reservation.endDate;
          const stayTime = reservation.stayTime;
          const numberOfPersons = reservation.peopleCount;
          const popoverData = { status, start, end, stayTime, numberOfPersons };
          this.listOfTableReservations.push({
            guestName,
            time,
            isReservedAtSelectedTime,
            tableId,
            reservationId,
            popoverData
          });
          if (isReservedAtSelectedTime) {
            currentlySelectedReservationInsideTable = reservation;
          }
        }
      }
    });
    setTimeout(() => {
      // this.onReservationSelected.emit(this.listOfTableReservations);
      this.onReservationSelected.emit(currentlySelectedReservationInsideTable);
    });
  }

  getTimeInMinutes(date: Date): number {
    let hours = date.getHours();
    if (hours === 0) {
      hours = 24;
    }
    const mintes = date.getMinutes();
    return hours * 60 + mintes;
  }

  formatTime(n: any) {
    // tslint:disable-next-line:no-bitwise
    return `0${(n / 60) ^ 0}`.slice(-2) + ':' + ('0' + (n % 60)).slice(-2);
  }

  checkIfTableIsReserved(reservation: any): boolean {
    const reservationStart = this.getTimeInMinutes(new Date(reservation.startDate));
    const reservationEnd = this.getTimeInMinutes(new Date(reservation.endDate));
    if (this.selectedTime >= reservationStart && reservationEnd > this.selectedTime) {
      return true;
    }
    return false;
  }

  onDragStart(data: any) {
    this.isDraging = true;
    const reservationIdAndTableId = {
      reservationId: data.reservationId,
      tableId: data.tableId,
      start: data.popoverData.start,
      end: data.popoverData.end,
      peopleCount: data.popoverData.numberOfPersons
    };
    this.onReservationDraged.emit(reservationIdAndTableId);
  }

  onDragEnd() {
    this.onReservationDragEnd.emit(true);
    this.isDraging = false;
  }

  refreshStatusOfMenuItems(tableReservationData: any, i: number) {
    const reservation = this.reservations.find(res => {
      return res.id === tableReservationData.reservationId;
    });

    if (reservation) {
      this.contextMenuStatusItems = this.statusArray;
      for (let i = 0; i < this.contextMenuStatusItems.length; i++) {
        const status = this.contextMenuStatusItems[i];
        if (status.value === reservation.status) {
          status.isActive = true;
        } else {
          status.isActive = false;
        }
      }
    }
  }

  statusItemClick(event: any, tableReservationData: any) {
    const selectedStatus = event.itemData.value;

    const reservation = this.reservations.find(res => {
      return res.id === tableReservationData.reservationId;
    });

    reservation.status = selectedStatus;
    this.reservationService
      .editReservation(reservation)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.snackBar.open(this.translateService.instant('tablePlan.reservationStatusChanged'), '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
  }

  onContextMenu($event: MouseEvent) {
    $event.stopPropagation();
    return true;
  }

  handleReservationClick(reservation: any) {
    this.onReservationClick.emit(reservation);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { InitPermissionsService } from '@app/core/init-permissions.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { NewCompanyService } from '../new-company/new-company.service';
import { TranslateService } from '@ngx-translate/core';
var CryptoJS = require('crypto-js');

@Component({
  selector: 'app-select-client',
  templateUrl: './select-client.component.html',
  styleUrls: ['./select-client.component.scss']
})
export class SelectClientComponent implements OnInit, OnDestroy {
  clients: any;
  isUserBlocked: boolean = false;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private initPermissionService: InitPermissionsService,
    private permissionsService: NgxPermissionsService,
    private newCompanyService: NewCompanyService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.checkUserStatus();
    this.getClients();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getClients() {
    this.authService
      .getClients(true)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        res => {
          this.clients = res.clients;
        },
        error => {}
      );
  }

  checkUserStatus() {
    this.authService
      .getUserStatus()
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        res => {
          this.isUserBlocked = res ? true : false;
        },
        error => {}
      );
  }

  openClientDashboard(clientId: number, client: any = null) {
    let { email, id } = JSON.parse(localStorage.getItem('credentials'));
    let key = 'TZCUeUbiUDqdTTrdeVlqxtZ6ieSfVLxmPtKYzNH4'; // live
    id = `${id}`;
    let signedString = CryptoJS.HmacSHA256(id, key);
    let resultSign = CryptoJS.enc.Hex.stringify(signedString);

    this.authService.getUserData().subscribe(
      (user: any) => {
        console.log(71, 'selecting', user, email, id, resultSign, client);
        let intercomUser = {
          app_id: window.location.href.includes('kunden.gastro.digital') ? 'u57ft9ay' : 'u57ft9ay',
          email: email,
          user_id: id,
          user_hash: resultSign,
          name: user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : 'Besucher',

          company: {
            id: client.id,
            name: client.name || 'Firma'
          }
        };
        (<any>window).Intercom('boot', intercomUser);
        console.log(85, 'select intercom', intercomUser, user);
      },
      error => {
        console.error(91, error);
      }
    );

    this.router.navigate(['/client', clientId, 'dashboard']);
    this.getPermissions(clientId);
  }

  getPermissions(clientId: number) {
    this.initPermissionService.load(clientId).then(permissions => this.permissionsService.addPermission(permissions));
  }

  addNewClient() {
    const { id } = JSON.parse(localStorage.getItem('credentials'));
    this.newCompanyService.getDraftData({ userId: id }).subscribe((response: any) => {
      if (response && response.length) {
        this.router.navigate(['/select-from-draft']);
      } else {
        this.router.navigate(['new-client']);
      }
    });
  }
}

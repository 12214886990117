import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ReservationService } from '@app/reservation/reservation.service';
import { Subscription, Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DatatableComponent } from '@swimlane/ngx-datatable';

import moment from 'moment';
moment.locale('de');

@Component({
  selector: 'app-reservations-history',
  templateUrl: './reservation-history.component.html',
  styleUrls: ['./reservation-history.component.scss']
})
export class ReservationHistoryComponent implements OnInit {
  @Input() public guestData: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  // Layout
  readonly LAYOUT = {
    XXS: 450,
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1600,
    XL: 1920,
    XXL: 2560
  };
  layout: number = this.LAYOUT.MD;

  loadingReservationList = true;
  reservations: any = [];
  allReservationsCopy: any = [];

  loading: boolean = true;

  tableLimit: number = 50;
  tablePages: any[] = [];
  tableOffset: number = 1;
  filterBy: string = '';
  filterId: number = 0;

  dates: any = {
    from: '',
    to: ''
  };

  private _ordersSub: Subscription;
  private ngUnsubscribe: Subject<any> = new Subject();

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(private reservationService: ReservationService, public activeModal: NgbActiveModal) {}

  changeTableOffset(num: number = 0) {
    this.loading = true;
    this.table.offset = num;

    setTimeout(
      function(_this: any) {
        _this.loading = false;
      },
      3000,
      this
    );
  }

  ngOnInit() {
    this.getReservationHistory(this.guestData.id);
  }

  getReservationHistory(guestId: any) {
    this.reservationService.guestReservationHistory(guestId).subscribe(reservations => {
      this.reservations = reservations;
      this.setTablePages();
      this.loadingReservationList = false;
    });
  }

  setTablePages() {
    this.tablePages = [];
    for (let index = 1; index <= this.reservations.length / this.tableLimit + 1; index++) {
      this.tablePages.push(index);
    }
    this.loading = false;
  }

  onSort(event: any) {
    this.loadingReservationList = true;
    // event was triggered, start sort sequence
    this.loading = true;
    // emulate a server request with a timeout
    setTimeout(() => {
      const rows = [...this.reservations];
      const sort = event.sorts[0];

      if (event.column.prop === 'tables') {
        rows.sort((a, b) => {
          if (!a.tables.length) {
            return 0;
          }
          if (!b.tables.length) {
            return -1;
          }
          return a.tables[0].name.localeCompare(b.tables[0].name) * (sort.dir === 'desc' ? -1 : 1);
        });
      } else {
        rows.sort((a, b) => {
          let aValue = a[sort.prop]
            ? a[sort.prop]
            : a[sort.prop.split('.')[0]]
            ? a[sort.prop.split('.')[0]][sort.prop.split('.')[1]]
            : '';
          let bValue = b[sort.prop]
            ? b[sort.prop]
            : b[sort.prop.split('.')[0]]
            ? b[sort.prop.split('.')[0]][sort.prop.split('.')[1]]
            : '';
          return String(aValue).localeCompare(String(bValue)) * (sort.dir === 'desc' ? -1 : 1);
        });
      }

      this.reservations = rows;
      this.loadingReservationList = false;
    }, 500);
  }

  closeReservationHistory() {
    this.passEntry.emit();
  }
}

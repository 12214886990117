import { Component, OnInit, ViewChild } from '@angular/core';
import { EventsService } from '../events.service';
import moment from 'moment';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { ChangeEmailAddressComponent } from './change-email-address/change-email-address.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { MoveTicketDatesComponent } from '../move-ticket-dates/move-ticket-dates.component';
import { Subject } from 'rxjs';
import { ChangeBuyerAddressComponent } from '@app/shared/change-buyer-address/change-buyer-address.component';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  @ViewChild('myTable', { static: false }) table: any;
  private ngUnsubscribe: Subject<any> = new Subject();

  ticketOrders: any = [];
  allOrdersCopy: any = [];
  ticketOrdersMobile: any = [];

  loading: boolean = true;
  expanded: any = {};
  tableLimit: number = 50;
  tablePages: any[] = [];
  tableOffset: number = 1;
  filterBy: string = '';
  filterId: number = 0;
  myControl = new FormControl();

  dates: any = {
    from: '',
    to: ''
  };

  lowValue: number = 0;
  highValue: number = 50;
  pageSize: number = 50;
  amountFilter: string = '0';
  allEvents: any = [];
  filter: any = {
    eventId: null,
    eventDate: null,
    showAll: false
  };

  filterStats: any = {
    orders: {
      count: 0,
      total: 0
    },
    cancelations: {
      count: 0,
      total: 0
    }
  };

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(private eventService: EventsService, private modalService: NgbModal, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.getTransactions(true);
    this.getAllEvents();
  }

  getTransactions(initialStart: boolean = false) {
    this.filterStats.orders.total = 0;
    this.filterStats.orders.count = 0;
    this.filterStats.cancelations.total = 0;
    this.filterStats.cancelations.count = 0;
    this.loading = true;
    if (initialStart) {
      this.dates.from = moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD');
      this.dates.to = moment().format('YYYY-MM-DD');
    }
    this.eventService
      .getTicketOrders(this.dates.from, this.dates.to, this.amountFilter, this.filter)
      .subscribe(orders => {
        orders.map((item: any, key: any) => {
          // orders[key].eventName =
          //   item.tickets.length > 0 && item.tickets[0].dateInfo && item.tickets[0].dateInfo.event
          //     ? item.tickets[0].dateInfo.event.name
          //     : '';
          orders[key].eventName =
            item.tickets.length > 0 &&
            item.tickets[0] &&
            item.tickets[0].eventDetails &&
            item.tickets[0].eventDetails.name
              ? item.tickets[0].eventDetails.name
              : '';
          orders[key].eventDate =
            item.tickets.length > 0 && item.tickets[0].dateInfo
              ? moment(item.tickets[0].dateInfo.startsAtDay + ' ' + item.tickets[0].dateInfo.startsAtHour).format(
                  'DD.MM.YYYY HH:mm'
                )
              : item.eventDate
              ? moment(item.eventDate).format('DD.MM.YYYY HH:mm')
              : '';

          if (item.status === 'canceled') {
            this.filterStats.cancelations.total += item.subtotal;
            this.filterStats.cancelations.count += 1;
          } else {
            this.filterStats.orders.total += item.subtotal;
            this.filterStats.orders.count += 1;
          }
          return orders;
        });
        this.ticketOrders = orders;
        if (this.filterBy && this.filterBy != '') {
          this.allOrdersCopy = [];
          this.filterOrdersBy('client', this.filterBy);
        }
        this.setTablePages();

        this.loading = false;
      });
  }

  setTablePages() {
    this.tablePages = [];
    for (let index = 1; index <= this.ticketOrders.length / this.tableLimit + 1; index++) {
      this.tablePages.push(index);
    }
    const paginateEvent = {
      previousPageIndex: 0,
      pageSize: this.pageSize,
      pageIndex: 0,
      length: this.ticketOrders.length
    };
    this.getPaginatorData(paginateEvent);
    this.loading = false;
  }

  filterOrdersBy(filterBy: string = '', filterVal: string = '') {
    switch (filterBy) {
      case 'from':
        this.dates.from = filterVal;

        this.allOrdersCopy = [...this.ticketOrders];
        this.ticketOrders = this.allOrdersCopy.filter(function(order: any) {
          if (
            (order.createdAt >= this.dates.from || this.dates.from == '') &&
            (order.createdAt <= this.dates.to || this.dates.to == '')
          ) {
            return order;
          }
        }, this);
        this.ticketOrders = [...this.ticketOrders];
        this.setTablePages();
        //this.prepareChartData();
        break;

      case 'to':
        this.dates.to = filterVal;
        this.allOrdersCopy = [...this.ticketOrders];
        this.ticketOrders = this.allOrdersCopy.filter(function(order: any) {
          if (
            (order.createdAt >= this.dates.from || this.dates.from == '') &&
            (order.createdAt <= this.dates.to || this.dates.to == '')
          ) {
            return order;
          }
        }, this);
        this.ticketOrders = [...this.ticketOrders];
        this.setTablePages();
        //this.prepareChartData();
        break;

      case 'client':
        filterVal = `${filterVal}`;
        this.filterBy = filterVal;
        if (this.allOrdersCopy.length) {
          this.ticketOrders = this.allOrdersCopy;
        }
        this.allOrdersCopy = [...this.ticketOrders];
        this.ticketOrders = this.allOrdersCopy.filter(function(order: any) {
          if (order && order.firstName.toLowerCase().includes(filterVal.toLowerCase())) {
            return order;
          } else if (order && order.lastName.toLowerCase().includes(filterVal.toLowerCase())) {
            return order;
          } else if (order && order.client.name.toLowerCase().includes(filterVal.toLowerCase())) {
            return order;
          }
        }, this);
        this.ticketOrders = [...this.ticketOrders];
        this.setTablePages();
        // this.table.offset = 0;
        break;

      default:
        this.filterBy = '';
        this.ticketOrders = [...this.allOrdersCopy];
        this.setTablePages();
        this.allOrdersCopy = [];
        break;
    }
  }

  setDates(type: string) {
    if (type == 'today') {
      this.dates.from = moment().format('YYYY-MM-DD');
      this.dates.to = moment().format('YYYY-MM-DD');
    }
    if (type == 'yesterday') {
      this.dates.from = moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
      this.dates.to = moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
    }
    if (type == 'thisWeek') {
      const date = moment();
      this.dates.from = date.startOf('week').format('YYYY-MM-DD');
      this.dates.to = moment(this.dates.from)
        .endOf('week')
        .format('YYYY-MM-DD');
    }
    if (type == 'lastSeven') {
      this.dates.from = moment()
        .subtract(6, 'days')
        .format('YYYY-MM-DD');
      this.dates.to = moment().format('YYYY-MM-DD');
    }
    if (type == 'lastWeek') {
      const date = moment().subtract(1, 'week');
      this.dates.from = date.startOf('week').format('YYYY-MM-DD');
      this.dates.to = moment(this.dates.from)
        .endOf('week')
        .format('YYYY-MM-DD');
    }
    if (type == 'lastMonth') {
      const date = moment().subtract(1, 'month');
      this.dates.from = date.startOf('month').format('YYYY-MM-DD');
      this.dates.to = moment(this.dates.from)
        .endOf('month')
        .format('YYYY-MM-DD');
    }
    if (type == 'thisMonth') {
      const date = moment();
      this.dates.from = date.startOf('month').format('YYYY-MM-DD');
      this.dates.to = moment(this.dates.from)
        .endOf('month')
        .format('YYYY-MM-DD');
    }
    this.getTransactions(false);
  }

  toggleExpandRow(row: any) {
    this.table.rowDetail.toggleExpandRow(row);
  }
  // used to build an array of orders for mobile relevant at any given time
  public getPaginatorData(event: PageEvent): PageEvent {
    this.pageSize = event.pageSize;
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    this.ticketOrdersMobile = this.ticketOrders.slice(this.lowValue, this.highValue);
    return event;
  }

  sendTicketPdf(order: any) {
    const modalRef = this.modalService.open(ChangeEmailAddressComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.email = order.email;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      order.userEmail = receivedEntry.email;
      this.eventService.sendTicketPdf(order).subscribe(
        (result: any) => {
          this.snackBar.open('Ticket wurde erfolgreich gesendet an ' + order.userEmail + '!', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        (err: any) => {
          console.error(55, err);
          this.snackBar.open('Fehler beim Senden von E-Mails.', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
    });
  }
  cancelTicket(orderId: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Ticket stornieren';
    modalRef.componentInstance.message = `Sind Sie sicher dass Sie die ticket stornieren möchten?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.eventService.cancelOrder(orderId).subscribe(
            () => {
              this.snackBar.open('Status wurde erfolgreich geändert', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
              this.getTransactions();
            },
            err => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }
  showAddress(event: any, order: any) {
    event.target.parentElement.parentElement.blur();
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Details';
    const addressDetails =
      '<div>' +
      (order.email || '') +
      '<br>' +
      (order.firstName + ' ' + order.lastName || '') +
      '<br>' +
      (order.street || '') +
      '<br>' +
      (order.zipcode || '') +
      '<br>' +
      (order.city || '') +
      '<br> Phone no:- ' +
      (order.phone || '') +
      '</div>';
    modalRef.componentInstance.message = addressDetails;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
        }
      },
      () => {}
    );
  }

  filterAmountStatus(filterVal: string = '') {
    this.amountFilter = filterVal;
    this.getTransactions(false);
  }

  prepareTicketPdf(code: any) {
    this.eventService.prepareTicketPdf(code).subscribe(
      (res: any) => {
        const blobdata = new Blob([new Uint8Array(res.data)], { type: 'application/pdf' });
        var fileURL = window.URL.createObjectURL(blobdata);
        window.open(fileURL);
      },
      err => {
        const errMsg = err && err.error.msg ? err.error.msg : 'Something went wrong.';
        this.snackBar.open(errMsg, '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
      }
    );
  }
  moveTickets(data: any) {
    const modalRef = this.modalService.open(MoveTicketDatesComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.ticketInfo = data;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      const snackBarRef = this.snackBar.open('Ticket moved successfully.', 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
      this.getTransactions(false);
      modalRef.close();
    });
  }

  getAllEvents() {
    this.eventService.getEvents().subscribe((events: any) => {
      this.allEvents = events;
    });
  }

  clearDate(event: any) {
    event.stopPropagation();
    this.filter.eventDate = null;
    this.getTransactions(false);
  }

  sendReceipt(order: any) {
    console.log(order);
    const modalRef = this.modalService.open(ChangeBuyerAddressComponent, { windowClass: 'onboarding-modal' });

    order.name = order.firstName + ' ' + order.lastName;
    order.zipCode = order.zipcode;
    order.company = order.companyName;
    modalRef.componentInstance.order = order;

    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      const name = receivedEntry.name.split(' ');
      order.firstName = name[0];
      order.lastName = name.slice(1).join(' ');
      order.street = receivedEntry.street;
      order.zipcode = receivedEntry.zipCode;
      order.city = receivedEntry.city;
      order.companyName = receivedEntry.company;
      order.email = receivedEntry.email;
      this.eventService.sendTicketReceipt(order).subscribe(
        (result: any) => {
          const index = this.ticketOrders.findIndex((x: any) => x.id === order.id);
          if (this.ticketOrders[index]) {
            this.ticketOrders[index] = order;
          }
          this.ticketOrders = [...this.ticketOrders];
          this.snackBar.open('Quittung erfolgreich gesendet.', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        (err: any) => {
          console.error(55, err);
          this.snackBar.open('Something went wrong.', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
    });
  }

  downloadTicketList(type: any) {
    this.eventService
      .getTicketOrders(this.dates.from, this.dates.to, this.amountFilter, this.filter)
      .subscribe(orders => {});
  }

  downloadTicketPdf(row: any) {
    this.eventService
      .getTicketsForPayment(row.paymentId, row.tickets && row.tickets[0].dateId ? row.tickets[0].dateId : '')
      .subscribe(
        (res: any) => {
          const file = res.tickets[0].fileData;
          console.log(file);
          const blobdata = new Blob([new Uint8Array(file.data)], { type: 'application/pdf' });
          var fileURL = window.URL.createObjectURL(blobdata);
          window.open(fileURL);
        },
        err => {
          const errMsg = err && err.error.msg ? err.error.msg : 'Something went wrong.';
          this.snackBar.open(errMsg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }

  isWithin60Days(createdAt: string): boolean {
    const purchaseDate = new Date(createdAt);
    const currentDate = new Date();
    const diffInDays = (currentDate.getTime() - purchaseDate.getTime()) / (1000 * 60 * 60 * 24);
    return diffInDays <= 60;
  }
}

<div class="all-container header-main" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="toolbar" #toolbarEl [hidden]="!showToolbar">
    <button mat-icon-button class="btn hamburgerMenu" (click)="toggleSidebar()">
      <mat-icon svgIcon="menu"></mat-icon>
    </button>

    <div class="top-logo">
      <picture>
        <source media="(max-width:767px)" srcset="assets/logo-icon.png" />
        <img src="assets/logo.png" />
      </picture>
    </div>

    <div class="top-menu-controls top-menu-buttons">
      <div class="element-search autosuggest-search-activator">
        <input placeholder="Tippen um zu suchen..." type="text" (click)="openSearch()" readonly #searchInput />
      </div>
      <ng-template #popTitle></ng-template>
      <ng-template #popContent>
        <i class="text-muted" *ngIf="!newsArticles || !newsArticles.length">Derzeit sind keine Neuigkeiten vorhanden</i>
        <div *ngFor="let news of newsArticles">
          <a href="{{ news.link }}" target="_blank">
            <h5>{{ news.title.rendered | striphtml }}</h5>
            <div class="text-muted" style="padding-bottom: 5px">{{ news.date | dfnsFormat: 'dd. DD MMM YYYY' }}</div>

            <p style="color: initial;">{{ news.content.rendered | striphtml | truncatetext: 90 }}</p>
          </a>
        </div>

        <a
          class="btn btn-block btn-primary"
          href="https://blog.gastro.digital/blog/"
          target="_blank"
          style="margin-top: 10px"
        >
          Alle Neuigkeiten ansehen
        </a>
      </ng-template>

      <button
        class="btn"
        matTooltip="Neuigkeiten entdecken"
        placement="bottom"
        [ngbPopover]="popContent"
        [popoverTitle]="popTitle"
      >
        <mat-icon svgIcon="fire"></mat-icon>
      </button>
      <button class="btn" (click)="goToHelp()" matTooltip="Zu unserem Hilfeportal">
        <mat-icon svgIcon="help"></mat-icon>
      </button>
      <div ngbDropdown class="d-inline-block settings-dropdown">
        <button class="btn" ngbDropdownToggle>
          <mat-icon svgIcon="settings"></mat-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <div class="dropdown-item">
            GER
            <mat-slide-toggle
              name="switchLang"
              (change)="changeLang($event)"
              [(ngModel)]="lang"
              style="color: pointer;"
            >
            </mat-slide-toggle>
            EN
          </div>
          <div class="dropdown-item theme-options" *ngIf="isAccess">
            <span>Light Theme</span>
            <mat-slide-toggle (change)="switchTheme($event)">Dark Theme</mat-slide-toggle>
          </div>
          <button ngbDropdownItem (click)="addNewClient()">Neuen Betrieb hinzufügen</button>
          <button ngbDropdownItem (click)="changePassword()">Change Password</button>
          <button ngbDropdownItem (click)="logout()">Logout ({{ userData?.email }})</button>
        </div>
      </div>
    </div>
  </mat-toolbar>

  <div class="search-with-suggestions-w over-search-field" [hidden]="!showSearchbox" #searchBox>
    <div class="search-with-suggestions-modal">
      <div class="element-search">
        <input
          class="search-suggest-input"
          placeholder="Tippen um zu suchen..."
          type="text"
          (keyup)="searchTerm$.next($event.target.value)"
          #searchBoxInput
        />
        <div class="close-search-suggestions" (click)="closeSearch()"><i class="fas fa-times fa-fw"></i></div>
      </div>
      <div
        class="search-suggestions-empty"
        *ngIf="!searchSuggestions || !searchSuggestions.term || searchSuggestions.term.length === 0"
      >
        <p>
          Geben Sie ein Stichwort ein nach dem Sie suchen möchten
        </p>
      </div>
      <div class="search-suggestions-loading" *ngIf="isLoadingSearch">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
      <ng-template [ngIf]="searchSuggestions && searchSuggestions.term">
        <div class="search-suggestions-group">
          <div class="ssg-header">
            <div class="ssg-icon">
              <div class="icon icon-reservation"></div>
            </div>
            <div class="ssg-name">Reservierungen</div>
            <div class="ssg-info">{{ searchSuggestions?.results?.reservations?.length }} Insg.</div>
          </div>
          <div class="ssg-content">
            <div class="ssg-nothing-found" *ngIf="!searchSuggestions.results.reservations.length">
              <div class="icon-w"><i class="os-icon os-icon-eye-off"></i></div>
              <span>Keine Reservierungen gefunden. Versuchen Sie einen anderen Suchbegriff...</span>
            </div>

            <div
              class="ssg-items ssg-items-boxed"
              *ngFor="let res of searchSuggestions.results.reservations | slice: 0:3"
            >
              <span class="ssg-item" (click)="openReservation(res.id)">
                <div class="item-name" [innerHtml]="res.guestData.name | highlight: searchSuggestions.term"></div>
                <div class="item-description">
                  {{ res.reservedFor | dfnsFormat: 'DD.MM.YYYY HH:mm' }} &#8226; {{ res.peopleCount }} Personen
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="search-suggestions-group">
          <div class="ssg-header">
            <div class="ssg-icon">
              <div class="icon icon-jobs"></div>
            </div>
            <div class="ssg-name">Gäste</div>
            <div class="ssg-info">{{ searchSuggestions?.results?.guests?.length }} Insg.</div>
          </div>
          <div class="ssg-content">
            <div class="ssg-nothing-found" *ngIf="!searchSuggestions.results?.guests?.length">
              <div class="icon-w"><i class="os-icon os-icon-eye-off"></i></div>
              <span>Keine Gäste gefunden. Versuchen Sie einen anderen Suchbegriff...</span>
            </div>
            <div class="ssg-items ssg-items-list">
              <span
                class="ssg-item"
                (click)="openGuest(guest.id)"
                *ngFor="let guest of searchSuggestions.results.guests | slice: 0:3"
              >
                <div class="item-media" style="background-image: url(./assets/img/no-image.svg)"></div>
                <div class="item-name" [innerHtml]="guest.name | highlight: searchSuggestions.term"></div>
              </span>
            </div>
          </div>
        </div>
        <div class="search-suggestions-group">
          <div class="ssg-header">
            <div class="ssg-icon">
              <div class="icon icon-credit-card"></div>
            </div>
            <div class="ssg-name">Gutscheine</div>
            <div class="ssg-info">0 Insg.</div>
          </div>
          <div class="ssg-content">
            <div class="ssg-nothing-found" *ngIf="!searchSuggestions.vouchers?.length">
              <div class="icon-w"><i class="os-icon os-icon-eye-off"></i></div>
              <span>Keine Gutscheine gefunden. Versuchen Sie einen anderen Suchbegriff...</span>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0" autosize>
    <mat-sidenav
      #snav
      [mode]="!showSidebar ? 'over' : 'side'"
      [fixedInViewport]="!mobileQuery.matches"
      [fixedTopGap]="fixedTopGap"
      [opened]="showSidebar"
    >
      <div
        class="selected-client-box os-dropdown-trigger os-dropdown-position-center"
        [class.over]="showClientDropdown"
      >
        <div class="selected-client">
          <div class="logo" (click)="logoOptions()">
            <img alt="" src="https://cdn.gastroguide.de/{{ activeClient?.logo }}" *ngIf="activeClient?.logo" />
            <img alt="" src="./assets/img/no-image.svg" *ngIf="!activeClient?.logo" />
          </div>
          <div class="client-info-wrap" (click)="toggleClientDropdown()">
            <div class="client-name">
              {{ activeClient?.name }}
            </div>
            <div class="client-address">
              {{ activeClient?.zipCode }} {{ activeClient?.location ? activeClient?.location : activeClient?.cityName }}
            </div>
          </div>
          <div class="select-client-arrow" (click)="toggleClientDropdown()">
            <div class="fas fa-angle-down"></div>
          </div>
        </div>
        <div class="os-dropdown light message-list">
          <ng-scrollbar [trackX]="false" [shown]="'hover'" [compact]="true" thumbClass="custom-scrollbar">
            <ul>
              <li *ngFor="let client of userClients">
                <a href="{{ router.url.replace(activeClient?.id, client?.id) }}">
                  <div class="message-content">
                    <h6 class="message-from">{{ client.name }}</h6>
                    <h6 class="message-title">
                      {{ client.zipCode }} {{ client.location ? client.location : client.cityName }}
                    </h6>
                  </div>
                </a>
              </li>
            </ul>
          </ng-scrollbar>
        </div>
      </div>

      <ng-scrollbar
        [trackX]="false"
        [shown]="'hover'"
        [compact]="true"
        [style.height]="calculateScrollHeight()"
        thumbClass="custom-scrollbar"
      >
        <div class="menu">
          <mat-nav-list>
            <div *ngFor="let group of navGroups">
              <ng-template [ngIf]="!group.hidden">
                <div class="nav-group-name" *ngIf="group.groupName">
                  <span>{{ group.groupName }}</span>
                </div>
                <app-menu-list-item *ngFor="let item of group.navItems" [item]="item"></app-menu-list-item>
              </ng-template>
            </div>
          </mat-nav-list>
          <div class="version"><i class="icon icon-documents"></i>Version 1.13.0.0</div>
        </div>
      </ng-scrollbar>
    </mat-sidenav>

    <ng-scrollbar [trackX]="false" [shown]="'hover'" [compact]="true" #scrollbarRef class="router-outlet-scrollbar">
      <router-outlet></router-outlet>
    </ng-scrollbar>
  </mat-sidenav-container>
</div>

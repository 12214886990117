import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { ReviewsComponent } from './reviews.component';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/reviews',
      data: {
        breadcrumb: 'Reviews'
      },
      resolve: {
        someKey: ClientResolverService
      },
      children: [
        {
          path: '',
          component: ReviewsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Reviews',
            breadcrumb: 'Reviews',
            permissions: {
              only: ['admin'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReviewsRoutingModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReservationTableSettingsComponent } from './reservation-table-settings.component';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import {
  MatSlideToggleModule,
  MatCheckboxModule,
  MatIconModule,
  MatTabsModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatRadioModule,
  MatGridListModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddTableComponent } from './add-table/add-table.component';
import { AddRoomComponent } from './add-room/add-room.component';
import { Ng5SliderModule } from 'ng5-slider';
import { DxSchedulerModule } from 'devextreme-angular/ui/scheduler';
import { DxTemplateModule } from 'devextreme-angular/core/template';
import { DxFormModule } from 'devextreme-angular';
import { DateFnsModule } from 'ngx-date-fns';
import { MomentModule } from 'ngx-moment';
import { ReservationRoutingModule } from '../reservation-routing.module';
import { ControlErrorComponent } from '@app/shared/control-error/control-error.component';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MatSelectModule } from '@angular/material/select';
import { ColorPickerModule } from 'ngx-color-picker';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Angular2CsvModule } from 'angular2-csv';
// import { TablePlanComponent } from '../reservation-settings/table-plan/table-plan.component';
import { MatListModule } from '@angular/material';
// import { IncomingReservationsComponent } from '../reservation-settings/table-plan/incoming-reservations/incoming-reservations.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatSidenavModule } from '@angular/material/sidenav';
// import { AddPatternComponent } from '../reservation-settings/table-plan/add-pattern/add-pattern.component';
// import { AddDecorationComponent } from '../reservation-settings/table-plan/add-decoration/add-decoration.component';
// import { GridComponent } from '../reservation-settings/table-plan/grid/grid.component';
import { MatInputModule } from '@angular/material/input';
import { SatDatepickerModule } from 'saturn-datepicker';
import { CheckboxRendererComponent } from './renderers/checkbox-renderer.component';
import { NoEditorComponent } from './renderers/no-editor.component';
import { ToggleRendererComponent } from './renderers/toggle-renderer.component';
import { PhotoRendererComponent } from './renderers/photo-renderer.component';
import { NgSelectRendererComponent } from './renderers/ng-select-renderer.component';
import { TagsRendererComponent } from './renderers/tags-renderer.component';
import { AgGridModule } from 'ag-grid-angular';
import { ReservationSettingsModule } from '../reservation-settings/reservation-settings.module';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    ReservationRoutingModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    DragDropModule,
    MatIconModule,
    MatTabsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatRadioModule,
    MatGridListModule,
    MatSelectModule,
    ColorPickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    DxSchedulerModule,
    DxTemplateModule,
    DxFormModule,
    DxContextMenuModule,
    NgxMaskModule.forRoot(options),
    MomentModule,
    FlexLayoutModule,
    MatListModule,
    MatProgressSpinnerModule,
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fas fa-spinner fa-spin button-loading"></span>'
    }),
    DateFnsModule.forRoot(),
    Angular2CsvModule,
    MatAutocompleteModule,
    NgScrollbarModule,
    MatSidenavModule,
    MatInputModule,
    SatDatepickerModule,
    ReservationSettingsModule,
    AgGridModule.withComponents([])
  ],
  declarations: [
    ReservationTableSettingsComponent,
    CheckboxRendererComponent,
    NoEditorComponent,
    ToggleRendererComponent,
    PhotoRendererComponent,
    NgSelectRendererComponent,
    TagsRendererComponent,
    AddTableComponent,
    AddRoomComponent
    // TablePlanComponent,
    // IncomingReservationsComponent,
    // AddPatternComponent,
    // AddDecorationComponent,
    // GridComponent
  ],
  entryComponents: [
    ToggleRendererComponent,
    PhotoRendererComponent,
    CheckboxRendererComponent,
    NoEditorComponent,
    NgSelectRendererComponent,
    TagsRendererComponent,
    ControlErrorComponent,
    AddTableComponent,
    AddRoomComponent
  ],
  exports: [
    // TablePlanComponent
  ]
})
export class ReservationTableSettingsModule {}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReviewsService } from '../reviews.service';

@Component({
  selector: 'app-set-reply',
  templateUrl: './set-reply.component.html',
  styleUrls: ['./set-reply.component.scss']
})
export class SetReplyComponent implements OnInit {
  @Input() public commentContent: any;
  @Output() submitReplyResponse: EventEmitter<any> = new EventEmitter();

  commentData: any;
  reviewData: any;
  reply: string;

  constructor(public activeModal: NgbActiveModal, private reviewService: ReviewsService) {}

  ngOnInit() {
    if (this.commentContent) {
      this.commentData = this.commentContent.comment;
      this.reviewData = this.commentContent.review;
    }
  }

  submitReply() {
    const replyText = this.reply;
    if (replyText) {
      const { id } = JSON.parse(localStorage.getItem('credentials'));

      const data = {
        comment: replyText,
        reviewId: this.reviewData.id,
        memberId: id,
        parentId: this.commentData.id
      };

      this.reviewService.postComment(data).subscribe(
        (response: any) => {
          this.submitReplyResponse.emit(response);
        },
        err => {
          console.log('Error => ', err);
        }
      );
    }
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewChecked,
  ViewEncapsulation
} from '@angular/core';
import { MatSnackBar, PageEvent } from '@angular/material';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { GuestComponent } from '../guest/guest.component';
import { ReservationService } from '../reservation.service';
import { AddGuestComponent } from './add-guest/add-guest.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { ReservationHistoryComponent } from '../reservations/reservation-history/reservation-history.component';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-my-guests',
  templateUrl: './my-guests.component.html',
  styleUrls: ['./my-guests.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyGuestsComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('searchAddress', { static: true }) searchAddress: ElementRef;
  @ViewChild('searchMinVisits', { static: true }) searchMinVisits: ElementRef;
  @ViewChild('searchMaxVisits', { static: true }) searchMaxVisits: ElementRef;
  @ViewChild('myTable', { static: false }) table: any;
  @ViewChild('DownloadCSV', { static: false }) DownloadCSV: any;
  myGuestsArray: any[];
  filteredGuests: any[];
  tableLimit: number = 100;
  subscriptions: Subscription[] = [];
  selectedForm: string;
  today: any = new Date();
  rows: any[] = [];
  expanded: any = {};
  searchForm: '';
  searchGuest: '';

  // langArray: any;
  // lightLogo = 'assets/logo.png';
  // darkLogo = 'assets/logo_farbig-W.png';

  csvOptions: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: [
      'ID',
      'Name',
      'Vorname',
      'Anrede',
      'E-Mail',
      'Telefon',
      'Straße',
      'Ort',
      'PLZ',
      'Geburstag',
      'Letzter_Besuch',
      'Besuche',
      'No-Show',
      'Hinweise',
      'Unverträglichkeiten'
    ],
    showTitle: true,
    title: '',
    useBom: false,
    removeNewLines: true,
    keys: [
      'id',
      'name',
      'firstName',
      'form',
      'email',
      'phone',
      'address',
      'place',
      'zip',
      'birthdate',
      'weddingDay',
      'lastVisit',
      'visits',
      'noShow',
      'notes',
      'intolerance'
    ]
  };
  lowValue: number = 0;
  highValue: number = 50;
  pageSize: number = 50;
  filterGuestList: any[];
  page: any = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0
  };
  filteredArray: any[] = [];
  mobileWindow = false;
  loading: boolean = true;
  orderSort = [{ prop: 'lastVisit', dir: 'desc' }];
  private ngUnsubscribe: Subject<any> = new Subject();
  constructor(
    private reservationService: ReservationService,
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    public translate: TranslateService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 50;
    this.mobileWindow = window.innerWidth <= 768 ? true : false;
    // const browserLang = 'de';
    // console.log('this is test' + translate.getBrowserLang());
    // translate.use(browserLang.match(/de|en/) ? browserLang : 'de');
  }

  ngOnInit() {
    this.getGuestData(this.orderSort);
    // this.langArray = ['de', 'en'];
  }

  getGuestData(sort: any) {
    this.loading = true;
    const searchFilter = {
      input: this.searchInput.nativeElement.value,
      address: this.searchAddress.nativeElement.value,
      form: this.searchForm,
      guest: this.searchGuest,
      minVisit: this.searchMinVisits.nativeElement.value,
      maxVisit: this.searchMaxVisits.nativeElement.value
    };
    this.myGuestsArray = this.filteredArray = this.filteredGuests = this.filterGuestList = [];
    this.subscriptions.push(
      this.reservationService.getClientGuests(sort, searchFilter).subscribe(response => {
        // this.myGuestsArray = response;
        // this.filteredGuests = response;
        // this.filterGuestList = response;
        this.myGuestsArray = response;
        this.filteredArray = response;
        this.filterGuestList = response;
        this.setPage({ offset: 0 });
        const paginateEvent = {
          previousPageIndex: 0,
          pageSize: this.pageSize,
          pageIndex: 0,
          length: this.filterGuestList.length
        };
        this.getPaginatorData(paginateEvent);
      })
    );
  }
  setPage(pageInfo: any) {
    this.page.pageNumber = pageInfo.offset;
    this.getResults(this.page).subscribe(pagedData => {
      this.page = pagedData.page;
      this.filteredGuests = pagedData.data;
      this.loading = false;
    });
  }

  getResults(page: any): Observable<any> {
    return of(this.filteredArray)
      .pipe(map(d => this.getPagedData(page)))
      .pipe(delay(1000 * Math.random()));
  }

  private getPagedData(page: any) {
    const pagedData: any = [];
    pagedData.data = [];
    page.totalElements = this.filteredArray.length;
    page.totalPages = page.totalElements / page.size;
    const start = page.pageNumber * page.size;
    const end = Math.min(start + page.size, page.totalElements);
    for (let i = start; i < end; i++) {
      const jsonObj = this.filteredArray[i];
      pagedData.data.push(jsonObj);
    }
    pagedData.page = page;
    return pagedData;
  }

  onSort(event: any) {
    this.orderSort = [event.sorts[0]];
    this.getGuestData(this.orderSort);
  }

  toggleExpandRow(row: any) {
    console.log('Toggled Expand Row!', row);
    console.log(this.table);
    this.table.rowDetail.toggleExpandRow(row);
  }
  onDetailToggle(event: any) {}
  // switchTheme(event: any) {
  //   const theme = event.checked ? 'dark-theme' : 'light-theme';
  //   const html = document.querySelector('html');
  //   const logo = document.querySelector('.top-logo picture img').attributes;
  //   // console.log(logo.src.nodeValue);
  //   html.dataset.theme = theme;
  //   logo['src'].nodeValue = event.checked ? this.darkLogo : this.lightLogo;
  // }

  ngAfterViewInit() {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap(text => {
          if (
            !this.searchInput.nativeElement.value ||
            (this.searchInput.nativeElement.value && this.searchInput.nativeElement.value.length >= 3)
          ) {
            this.filterGuestArray();
          }
        })
      )
      .subscribe();

    fromEvent(this.searchAddress.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap(text => {
          if (
            !this.searchAddress.nativeElement.value ||
            (this.searchAddress.nativeElement.value && this.searchAddress.nativeElement.value.length >= 3)
          ) {
            this.filterGuestArray();
          }
        })
      )
      .subscribe();

    fromEvent(this.searchMinVisits.nativeElement, 'change')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap(text => {
          this.filterGuestArray();
        })
      )
      .subscribe();

    fromEvent(this.searchMaxVisits.nativeElement, 'change')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap(text => {
          this.filterGuestArray();
        })
      )
      .subscribe();
  }

  ngAfterViewChecked() {
    this.translate.get('GuestPage.CSVDownload').subscribe((text: string) => {
      this.DownloadCSV.label_btn = text;
    });
  }

  createGuest() {
    const modalRef = this.modalService.open(AddGuestComponent, { windowClass: 'onboarding-modal' });
    this.subscriptions.push(
      modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
        this.myGuestsArray = [...this.myGuestsArray, receivedEntry];
        this.filterGuestArray();
        this.snackBar.open('Gast erfolgreich hinzugefügt', 'Ok', {
          duration: 3000
        });
      })
    );
  }

  editGuest(guest: any) {
    const modalRef = this.modalService.open(AddGuestComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editGuest = guest;
    this.subscriptions.push(
      modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
        // If user was deleted from edit form
        if (receivedEntry.deleted) {
          this.myGuestsArray.splice(
            this.myGuestsArray.findIndex(i => {
              return i.id === +receivedEntry.guestId;
            }),
            1
          );

          // If user data was updated
        } else {
          this.myGuestsArray = this.myGuestsArray.map(g => {
            if (receivedEntry.id === g.id) {
              return receivedEntry;
            }
            return g;
          });
        }
        this.filterGuestArray();
        this.snackBar.open('Gast erfolgreich hinzugefügt', 'Ok', {
          duration: 3000
        });
      })
    );
  }

  deleteGuest(guest: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Gast löschen';
    modalRef.componentInstance.message = `Sind Sie sicher, dass Sie den Gast ${guest.name} löschen wollen?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.reservationService.deleteGuest(guest.id).subscribe((response: any) => {
            this.myGuestsArray.splice(
              this.myGuestsArray.findIndex(i => {
                return i.id === +response.guestId;
              }),
              1
            );

            this.filterGuestArray();
            this.snackBar.open('Gast erfolgreich gelöscht', 'Ok', {
              duration: 3000
            });
          });
        }
      },
      () => {}
    );
  }

  filterGuestArray(event?: any) {
    this.getGuestData(this.orderSort);
    // this.selectedForm = event ? event : null;
    // const filteredArrayWithNameSearch = this.filterArrayWithSearchValue(this.myGuestsArray);
    // const filteredArrayWithAddressSearch = this.filterArrayWithAddressSearchValue(filteredArrayWithNameSearch);
    // const filteredArrayByNumberOfWisits = this.filterArrayByNumberOfVisits(filteredArrayWithAddressSearch);
    // this.filteredGuests = this.filterByForm(this.selectedForm, filteredArrayByNumberOfWisits);
    // this.filterGuestList = this.filterByForm(this.selectedForm, filteredArrayByNumberOfWisits);
    // this.filteredArray = this.filterByForm(this.selectedForm, filteredArrayByNumberOfWisits);
    // this.setPage({ offset: 0 });
    // const paginateEvent = {
    //   previousPageIndex: 0,
    //   pageSize: this.pageSize,
    //   pageIndex: 0,
    //   length: this.filterGuestList.length
    // };
    // this.getPaginatorData(paginateEvent);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
  private filterByForm(form: string, array: any[]) {
    return array.filter((guest: any) => {
      if (form) {
        if (form === 'Alle Anrede') {
          return guest.form;
        }

        if (form === 'Keine Anrede') {
          return !guest.form;
        }

        return guest.form === form;
      } else {
        return guest;
      }
    });
  }

  private filterArrayWithSearchValue(array: any[]) {
    return array.filter((guest: any) => {
      return (
        (guest.name ? guest.name.toLowerCase().includes(this.searchInput.nativeElement.value.toLowerCase()) : false) ||
        (guest.email
          ? guest.email.toLowerCase().includes(this.searchInput.nativeElement.value.toLowerCase())
          : false) ||
        (guest.phone ? guest.phone.toLowerCase().includes(this.searchInput.nativeElement.value.toLowerCase()) : false)
      );
    });
  }

  private filterArrayWithAddressSearchValue(array: any[]) {
    return array.filter((guest: any) => {
      if (this.searchAddress.nativeElement.value) {
        if (guest.address && guest.zip && guest.place) {
          return (
            guest.address.toLowerCase().includes(this.searchAddress.nativeElement.value.toLowerCase()) ||
            guest.zip.includes(this.searchAddress.nativeElement.value) ||
            guest.place.toLowerCase().includes(this.searchAddress.nativeElement.value.toLowerCase())
          );
        }

        if (guest.address && guest.zip) {
          return (
            guest.address.toLowerCase().includes(this.searchAddress.nativeElement.value.toLowerCase()) ||
            guest.zip.includes(this.searchAddress.nativeElement.value)
          );
        }

        if (guest.address && guest.place) {
          return (
            guest.address.toLowerCase().includes(this.searchAddress.nativeElement.value.toLowerCase()) ||
            guest.place.toLowerCase().includes(this.searchAddress.nativeElement.value.toLowerCase())
          );
        }

        if (guest.zip && guest.place) {
          return (
            guest.zip().includes(this.searchAddress.nativeElement.value) ||
            guest.place.toLowerCase().includes(this.searchAddress.nativeElement.value.toLowerCase())
          );
        }

        if (guest.zip) {
          return guest.zip().includes(this.searchAddress.nativeElement.value);
        }

        if (guest.place) {
          return guest.place.toLowerCase().includes(this.searchAddress.nativeElement.value.toLowerCase());
        }
      } else {
        return guest;
      }
    });
  }

  private filterArrayByNumberOfVisits(array: any[]) {
    return array.filter((guest: any) => {
      if (this.searchMaxVisits.nativeElement.value && this.searchMinVisits.nativeElement.value) {
        return (
          this.searchMinVisits.nativeElement.value <= guest.visits &&
          guest.visits <= this.searchMaxVisits.nativeElement.value
        );
      }

      if (this.searchMaxVisits.nativeElement.value) {
        return guest.visits <= this.searchMaxVisits.nativeElement.value;
      }

      if (this.searchMinVisits.nativeElement.value) {
        return guest.visits >= this.searchMinVisits.nativeElement.value;
      }
      return guest;
    });
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.pageSize = event.pageSize;
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    this.filterGuestList = this.filteredArray.slice(this.lowValue, this.highValue);
    return event;
  }

  getGuestReservationHistory(guestData: any) {
    guestData.clientName = guestData && guestData['client.name'] ? guestData['client.name'] : '';
    let appendContainer = 'body';
    const modalRef = this.modalService.open(ReservationHistoryComponent, {
      size: 'lg',
      container: appendContainer
    });

    modalRef.componentInstance.guestData = guestData;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      modalRef.close();
    });
  }
}

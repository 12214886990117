import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule, MatProgressSpinnerModule } from '@angular/material';
import { IConfig } from 'ngx-mask';
import { SMSServiceConfirmationComponent } from './sms-service-confirmation.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  imports: [MatSlideToggleModule, FormsModule, CommonModule, MatProgressSpinnerModule],
  declarations: [SMSServiceConfirmationComponent],
  entryComponents: [],
  exports: []
})
export class SMSServiceConfirmationModule {}

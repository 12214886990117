import { Component, OnInit } from '@angular/core';
// import { AdminService } from '../../admin.service';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { Subscription, Subject } from 'rxjs';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import moment from 'moment';
moment.locale('de');

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  loading: boolean = true;
  orders: any = [];
  allOrdersCopy: any = [];
  canceledList: any = {};
  canceledListArray: any[] = [];
  clientList: any = {};
  filterClient: number = 0;

  showChartType: string = 'orders-daily';
  showChartLabel: any = {
    'orders-monthly': 'Bestellungen (pro Monat)',
    'orders-weekly': 'Bestellungen (pro Kalenderwoche)',
    'orders-daily': 'Bestellungen (pro Tag)',
    'orders-hourly': 'Bestellungen (pro Stunde)',

    'amount-monthly': 'Bestellvolumen in € (pro Monat)',
    'amount-weekly': 'Bestellvolumen in € (pro Kalenderwoche)',
    'amount-daily': 'Bestellvolumen in € (pro Tag)',
    'amount-hourly': 'Bestellvolumen in € (pro Stunde)'
  };
  selectOptions: any[] = [];

  showChart = false;

  barChartOptions: ChartOptions = {
    responsive: true
  };
  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins: any[] = [];

  barChartData: ChartDataSets[] = [
    {
      data: [],
      label: ''
    }
  ];

  barChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0, 150, 136, 0.5)'
    },
    {
      borderColor: 'black',
      backgroundColor: 'rgba(230, 82, 82, 0.5)'
    }
  ];

  dates: any = {
    from: '',
    to: ''
  };
  minDate: any;

  pushedPayouts: any[] = [];
  pushedOrders: any[] = [];

  private _ordersSub: Subscription;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private gastropayService: GastroPayService) {}

  ngOnInit() {
    this.minDate = moment()
      // .subtract(2, 'years')
      .format('YYYY-MM-01');
    this.getOrders(true);
    this.selectOptions = this.getObjFromArray(this.showChartLabel);
  }

  getObjFromArray(obj: any) {
    var arr: any[] = [];
    var keys = Object.keys(obj);
    keys.forEach(key => {
      arr.push({ value: key, label: this.showChartLabel[key] });
    });
    //console.log('getObjFromArray', arr);
    return arr;
  }

  getWeekNumber(dateString: any) {
    var d: any = new Date(Date.parse(dateString));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);

    return weekNo + '-' + d.getUTCFullYear();
    return weekNo + ' ' + `${d.getUTCFullYear()}`.substring(2);
  }

  getOrders(initialStart: boolean = false) {
    this.loading = true;
    this.pushedOrders = [];
    this.orders = [];
    if (initialStart) {
      this.dates.to = moment().format('YYYY-MM-DD');
      this.dates.from = moment()
        .subtract(6, 'days')
        .format('YYYY-MM-DD');
    }
    this.getOwnCashOrders(initialStart);
    //
  }

  pushOrder(order: any, balance: number = 0) {
    if (!this.pushedOrders.includes(order.id)) {
      if (!order.paymentId) {
        order.paymentId = '';
      }
      if (!order.zipCode) {
        order.zipCode = '';
      }

      if (order.gastropayTransaction !== undefined && order.gastropayTransaction !== null) {
        order.balance = order.gastropayTransaction.balance;
      } else {
        order.balance = balance;
      }
      if (order.firstName || order.lastName) {
        order.person = order.firstName + ' ' + order.lastName;
      } else {
        order.person = '';
      }
      order.paymentMethodText = '';
      if (order.paymentMethod === 'cash') {
        order.paymentMethodText = 'Bar';
      }
      if (order.paymentMethod === 'gastropay') {
        order.paymentMethodText = 'Gastropay';
      }
      if (order.paymentMethod === 'online' && order.paymentTransaction) {
        order.paymentMethodText = order.paymentTransaction.paymentMethod;
      }
      if (order.paymentMethodText === 'paypal') {
        order.paymentMethodText = 'PayPal';
      }
      if (order.paymentMethodText === 'creditcard') {
        order.paymentMethodText = 'Kreditkarte';
      }

      order.typeText = '';
      if (order.type === 'delivery') {
        order.typeText = 'Lieferung';
      }
      if (order.type === 'takeaway') {
        order.typeText = 'Abholung';
      }

      order.statusText = '';
      if (order.type === 'pending') {
        order.statusText = 'Ausstehend';
      }
      if (order.type === 'confirmed') {
        order.statusText = 'Fertig';
      }
      if (order.type === 'canceled') {
        order.statusText = 'Storniert';
      }

      // let date = moment(order.createdAt);
      // const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
      // const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);

      order.dateFormatted = moment(order.createdAt).format('DD.MM.YYYY');
      order.timeFormatted = moment(order.createdAt).format('H:mm');
      order.desiredFormatted =
        moment(order.desiredTime).format('DD.MM.YYYY') + ' ' + moment(order.desiredTime).format('H:mm');

      this.orders.push(order);
      this.pushedOrders.push(order.id);
    }
  }

  getOwnCashOrders(initialStart: boolean = false) {
    this.gastropayService
      .getOwnCashOrders([], this.dates.from, this.dates.to)
      //.getOwnOrders(true) //blank ones!
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        res.forEach((order: any) => {
          //if (order.paymentMethod === 'cash') {
          if (!order.desiredTime) {
            order.desiredTime = order.createdAt;
          }
          this.pushOrder(order);
          //}
        });
        console.log('getOwnCashOrders: this.allOrders', this.orders);
        this.getOwnTransactionOrders(initialStart);
      });
  }

  getOwnTransactionOrders(initialStart: boolean = false) {
    console.log('start getOwnTransactionOrders');

    this.gastropayService
      .getOwnTransactionOrders([], this.dates.from, this.dates.to)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        console.log('ende getOwnTransactionOrders', res);

        res.orders.forEach((order: any) => {
          if (order.paymentTransaction) {
            if (order.paymentTransaction.gastropayOrders) {
              order.paymentTransaction.gastropayOrders.paymentTransaction = { ...order.paymentTransaction };
              if (!order.paymentTransaction.gastropayOrders.desiredTime) {
                order.paymentTransaction.gastropayOrders.desiredTime =
                  order.paymentTransaction.gastropayOrders.createdAt;
              }
              delete order.paymentTransaction.gastropayOrders.paymentTransaction.gastropayOrders;
            }
            if (order.paymentTransaction.gastropayOrders && order.type != 'refund') {
              this.pushOrder(order.paymentTransaction.gastropayOrders, order.balance);
            } else {
              if (order.type == 'refund') {
                this.pushOrder(res.refundOrders[order.foreignId], order.balance);
              } else {
                if (order.type == 'payout') {
                }
              }
            }
          }
        });
        console.log('getOwnTransactionOrders: this.allOrders', this.orders);
        //this.orders = this.orders.reverse();
        this.orders.sort(function(b: any, a: any) {
          return b.id - a.id;
        });
        this.loading = false;
        this.prepareChartData();
      });
  }

  prepareChartData() {
    this.barChartLabels = [];
    var tempBarChartData = {};
    var tempBarChartData2 = {};

    if (this.showChartType == 'amount-hourly' || this.showChartType == 'orders-hourly') {
      let i = 0;
      for (i = 0; i < 24; i++) {
        tempBarChartData[i] = 0;
        tempBarChartData2[i] = 0;
        this.barChartLabels.push(i.toFixed(0));
      }
      //console.log('00 tempBarChartData,tempBarChartData2', tempBarChartData, tempBarChartData2, this.barChartLabels);
    }
    this.canceledList = {};
    this.clientList = {};
    this.canceledListArray = [];
    this.orders.forEach((order: any) => {
      var d = Date.parse(order.createdAt);
      var dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
      var [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d);
      var kw = this.getWeekNumber(order.createdAt);
      var hour = moment(order.createdAt).format('H');
      //console.log(hour);

      var addValue: any = 1;
      if (this.showChartType == 'orders-monthly') {
        var key = `${mo}.${ye}`;
      }
      if (this.showChartType == 'orders-weekly') {
        var key = `KW${kw}.${ye}`;
      }
      if (this.showChartType == 'orders-daily') {
        var key = `${da}.${mo}.${ye}`;
      }
      if (this.showChartType == 'orders-hourly') {
        var key = hour;
      }

      if (this.showChartType == 'amount-monthly') {
        var key = `${mo}.${ye}`;
        addValue = this.returnPrice(order.subtotal);
      }
      if (this.showChartType == 'amount-weekly') {
        var key = `KW${kw}.${ye}`;
        addValue = this.returnPrice(order.subtotal);
      }
      if (this.showChartType == 'amount-daily') {
        var key = `${da}.${mo}.${ye}
                                                                                                                                                  `;
        addValue = this.returnPrice(order.subtotal);
      }
      if (this.showChartType == 'amount-hourly') {
        var key = hour;
        addValue = this.returnPrice(order.subtotal);
      }

      if (!this.barChartLabels.includes(key)) {
        this.barChartLabels.push(key);
      }

      if (this.barChartLabels.includes(key)) {
        if (this.clientList[order.clientId]) {
          this.clientList[order.clientId]++;
        } else {
          this.clientList[order.clientId] = 1;
        }

        if (order.status == 'canceled') {
          if (this.canceledList[order.clientId]) {
            this.canceledList[order.clientId]++;
          } else {
            //this.canceledListArray.push(order.clientId);
            this.canceledList[order.clientId] = 1;
          }
          if (tempBarChartData2[key]) {
            tempBarChartData2[key] = tempBarChartData2[key] + addValue;
          } else {
            tempBarChartData2[key] = addValue;
          }
          if (!tempBarChartData[key]) {
            tempBarChartData[key] = 0;
          }
        } else {
          if (tempBarChartData[key]) {
            tempBarChartData[key] = tempBarChartData[key] + addValue;
          } else {
            tempBarChartData[key] = addValue;
          }

          if (!tempBarChartData2[key]) {
            tempBarChartData2[key] = 0;
          }
        }
      } /* else  {
        this.barChartLabels.push(key);
        if (order.status == 'canceled') {
          if (this.canceledList[order.clientId]) {
            this.canceledList[order.clientId]++;
          } else {
            //this.canceledListArray.push(order.clientId);
            this.canceledList[order.clientId] = 1;
          }
          if (tempBarChartData2[key]) {
            tempBarChartData2[key] = tempBarChartData2[key] + addValue;
          } else {
            tempBarChartData2[key] = addValue;
          }
        } else {
          if (tempBarChartData[key]) {
            tempBarChartData[key] = tempBarChartData[key] + addValue;
          } else {
            tempBarChartData[key] = addValue;
          }
        }
      } */

      //console.log(`${da}-${mo}-${ye}`);
    }, this);

    let list: any = {};
    Object.keys(this.canceledList).forEach((row: any) => {
      list[row] = (100 / this.clientList[row]) * this.canceledList[row];
    });
    //console.log('list', list);
    this.canceledListArray = Object.keys(list)
      .sort(function(a, b) {
        return list[a] - list[b];
      })
      .reverse();

    //console.log('XX tempBarChartData,tempBarChartData2', tempBarChartData,tempBarChartData2);

    var tempData = [];
    tempData = Object.values(tempBarChartData);
    //console.log('XX tempData', tempData, tempBarChartData);
    tempData.forEach((element: number) => {
      element = Math.round(element);
    });
    var tempData2 = [];
    tempData2 = Object.values(tempBarChartData2);
    tempData2.forEach((element: number) => {
      element = Math.round(element);
    });

    this.barChartData = [
      {
        data: tempData,
        label: this.showChartLabel[this.showChartType]
        // stack: '1'
      },
      {
        data: tempData2,
        label: this.showChartLabel[this.showChartType]
          .replace('Bestellungen', 'Stornierungen')
          .replace('Bestellvolumen', 'Storniervolumen')
        // stack: '0'
      }
    ];

    //console.log(this.barChartLabels, tempBarChartData, Object.values(tempBarChartData), this.barChartData);

    this.showChart = true;
    this.loading = false;
  }

  filterDates(filterBy: string, event: any) {
    let filterDate = moment(event.value._d).format('YYYY-MM-DD');

    if (filterDate) {
      this.filterOrdersBy(filterBy, filterDate);
    }
  }

  newFilterBy(filterBy: string = '', filterVal: string = '') {
    if (this.allOrdersCopy.length) {
      this.orders = [...this.allOrdersCopy];
      this.allOrdersCopy = [];
    }
    this.filterOrdersBy(filterBy, filterVal);
  }

  getArrayFromObject(obj: any = {}) {
    return Object.keys(obj);
  }

  filterOrdersBy(filterBy: string = '', filterVal: string = '') {
    switch (filterBy) {
      case 'from':
        this.dates.from = filterVal;
        this.getOrders(false);

        break;

      case 'to':
        this.dates.to = filterVal;
        this.getOrders(false);

        break;

      case 'clientId':
        if (filterVal == '') {
          this.filterClient = 0;
        } else {
          this.filterClient = parseInt(filterVal);
        }
        this.allOrdersCopy = [...this.orders];
        this.orders = this.allOrdersCopy.filter(function(order: any) {
          if (
            this.filterClient == 0 ||
            (order.clientId === this.filterClient &&
              (order.createdAt >= this.dates.from || this.dates.from == '') &&
              (order.createdAt <= this.dates.to || this.dates.to == ''))
          ) {
            return order;
          }
        }, this);
        this.orders = [...this.orders];
        this.prepareChartData();
        break;

      default:
        this.orders = [...this.allOrdersCopy];
        this.allOrdersCopy = [];
        break;
    }
  }

  setDates(type: string) {
    if (type == 'today') {
      this.filterDates('from', { value: { _d: new Date() } });
      this.filterDates('to', { value: { _d: new Date() } });
    }
    if (type == 'yesterday') {
      let yesterday: Date = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      this.filterDates('from', { value: { _d: yesterday } });
      this.filterDates('to', { value: { _d: yesterday } });
    }
    if (type == 'thisWeek') {
      const date = new Date();
      this.filterDates('from', { value: { _d: this.getMonday(date) } });

      const date2 = new Date();
      this.filterDates('to', { value: { _d: this.getSunday(date2) } });
    }
    if (type == 'lastSeven') {
      let date = new Date();
      date.setDate(date.getDate() - 6);
      this.filterDates('from', { value: { _d: date } });
      date.setDate(date.getDate() + 6);
      this.filterDates('to', { value: { _d: date } });
    }
    if (type == 'lastWeek') {
      let date = new Date();
      date.setDate(date.getDate() - 7);
      this.filterDates('from', { value: { _d: this.getMonday(date) } });
      this.filterDates('to', { value: { _d: this.getSunday(date) } });
    }
    if (type == 'lastMonth') {
      let date = new Date();
      let startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      let endDate = new Date(date.getFullYear(), date.getMonth(), 1);
      endDate.setDate(endDate.getDate() - 1);

      this.filterDates('from', { value: { _d: startDate } });
      this.filterDates('to', { value: { _d: endDate } });
      console.log(this.dates);
    }
    if (type == 'thisMonth') {
      let date = new Date();
      let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      endDate.setDate(endDate.getDate() - 1);

      this.filterDates('from', { value: { _d: startDate } });
      this.filterDates('to', { value: { _d: endDate } });
    }

    if (type == 'thisYear') {
      let startDate = new Date(moment().format('YYYY-01-01'));
      let endDate = new Date(moment().format('YYYY-12-31'));

      this.filterDates('from', { value: { _d: startDate } });
      this.filterDates('to', { value: { _d: endDate } });
      console.log(this.dates);
    }
  }

  getMonday(d: any) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  getSunday(d: any) {
    d = new Date(d);
    const s = moment(d).day(7);
    return s;
  }

  returnPrice(price: any) {
    if (typeof price == 'string') {
      return parseFloat(price.replace(',', '.'));
    } else {
      return price || 0;
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract, AuthenticationGuard } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { VouchersPoolComponent } from './vouchers-pool/vouchers-pool.component';
import { VouchersLogComponent } from './vouchers-log/vouchers-log.component';
import { VouchersSettingsComponent } from './vouchers-settings/vouchers-settings.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { VouchersListComponent } from './vouchers-list/vouchers-list.component';
import { VouchersOrdersComponent } from './vouchers-orders/vouchers-orders.component';
import { VoucherStatisticsComponent } from './voucher-statistics/voucher-statistics.component';
import { VouchersApiComponent } from './vouchers-api/vouchers-api.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/vouchers',
      data: {
        breadcrumb: 'Wertgutscheine',
        transPath: 'ShellMenu.GuestMarketing.Vouchers.Title'
      },
      resolve: {
        someKey: ClientResolverService
      },
      children: [
        {
          path: '',
          redirectTo: 'pool',
          pathMatch: 'full'
        },
        {
          path: 'pool',
          component: VouchersPoolComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Poolabrechnung',
            breadcrumb: 'Poolabrechnung',
            transPath: 'ShellMenu.GuestMarketing.Vouchers.PoolBilling',
            permissions: {
              only: ['admin', 'manageVoucherPool'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'transactions',
          component: VouchersLogComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Transaktionen',
            breadcrumb: 'Transaktionen',
            transPath: 'ShellMenu.GuestMarketing.Vouchers.Transactions',
            permissions: {
              only: ['admin', 'viewVoucherTransactions'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'list',
          component: VouchersListComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Wertgutscheine Übersicht',
            breadcrumb: 'Übersicht',
            transPath: 'ShellMenu.GuestMarketing.Vouchers.Overview',
            permissions: {
              only: [
                'admin',
                'viewVoucherTransactions',
                'manageVoucherPool',
                'manageVoucherSettings',
                'manageVoucherOrders'
              ],
              redirectTo: '403'
            }
          },
          children: [
            {
              path: ':code',
              component: VouchersListComponent,
              canActivate: [NgxPermissionsGuard],
              data: {
                title: 'Wertgutscheine Übersicht',
                breadcrumb: 'Übersicht',
                transPath: 'ShellMenu.GuestMarketing.Vouchers.Overview',
                permissions: {
                  only: [
                    'admin',
                    'viewVoucherTransactions',
                    'manageVoucherPool',
                    'manageVoucherSettings',
                    'manageVoucherOrders'
                  ],
                  redirectTo: '403'
                }
              }
            }
          ]
        },
        {
          path: 'settings',
          component: VouchersSettingsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Einstellungen',
            breadcrumb: 'Einstellungen',
            transPath: 'ShellMenu.GuestMarketing.Vouchers.Settings',
            permissions: {
              only: ['admin', 'manageVoucherSettings'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'orders',
          component: VouchersOrdersComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Bestellungen',
            breadcrumb: 'Bestellungen',
            permissions: {
              only: ['admin', 'manageVoucherOrders'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'statistics',
          component: VoucherStatisticsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: extract('Statistiken'),
            breadcrumb: 'Statistiken',
            transPath: 'ShellMenu.GuestMarketing.Vouchers.Statistics',
            permissions: {
              only: ['admin', 'viewVoucherTransactions'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        }
      ]
    }
  ]),
  {
    path: 'voucher-api-check',
    component: VouchersApiComponent,
    data: {
      title: 'Voucher API Check'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class VouchersRoutingModule {}

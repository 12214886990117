/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import 'hammerjs';

import { LicenseManager } from '@ag-grid-enterprise/core';
LicenseManager.setLicenseKey(
  'CompanyName=mes.mo GmbH,LicensedApplication=GastroDigital,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-011541,ExpiryDate=2_November_2021_[v2]_MTYzNTgxMTIwMDAwMA==70d96546e88e1bf7b42625520a2c639c'
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, { preserveWhitespaces: true })
  .catch(err => console.log(err));

import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ReservationService } from '@app/reservation/reservation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscriber } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-decoration',
  templateUrl: './add-decoration.component.html',
  styleUrls: ['./add-decoration.component.scss']
})
export class AddDecorationComponent implements OnInit, OnDestroy {
  @Input() public decoration: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  name: string;
  type: string;
  image: SafeResourceUrl;
  decorationTypes: any[] = [
    { name: this.translateService.instant('tablePlan.plant'), value: 'plant' },
    { name: this.translateService.instant('tablePlan.decoration'), value: 'decoration' },
    { name: this.translateService.instant('tablePlan.furniture'), value: 'furniture' },
    { name: this.translateService.instant('tablePlan.highChairs'), value: 'chair' }
  ];

  isSubmited: boolean = false;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public reservationService: ReservationService,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (this.decoration) {
      this.name = this.decoration.name;
      this.image = this.decoration.image;
      this.type = this.decoration.type;
    }
  }

  onChange(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.convertToBase64(file);
  }

  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe(data => {
      this.image = data;
    });
  }

  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      subscriber.next(fileReader.result);
    };
    fileReader.onerror = error => {
      subscriber.error(error);
      subscriber.complete();
    };
  }

  editDecoration() {
    this.isSubmited = true;
    if (!this.name || this.name === '' || !this.image || this.image === '' || !this.type || this.type === '') {
      return;
    }
    this.decoration.name = this.name;
    this.decoration.image = this.image;
    this.decoration.type = this.type;
    this.reservationService
      .editDecoration(this.decoration)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pattern: any) => {
        this.passEntry.emit(pattern);
      });
  }

  createDecoration() {
    this.isSubmited = true;
    if (!this.name || this.name === '' || !this.image || this.image === '' || !this.type || this.type === '') {
      return;
    }
    this.reservationService
      .createDecoration({ name: this.name, type: this.type, image: this.image })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pattern: any) => {
        this.passEntry.emit(pattern);
      });
  }

  getSanitizedSafeUrl(img: string) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${img})`);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReviewsService } from '../reviews.service';

@Component({
  selector: 'app-report-review',
  templateUrl: './report-review.component.html',
  styleUrls: ['./report-review.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportReviewComponent implements OnInit {
  @Input() public reviewContent: any;
  @Output() submitReviewResponse: EventEmitter<any> = new EventEmitter();

  review: any;
  report: string;

  constructor(public activeModal: NgbActiveModal, private reviewsService: ReviewsService) {}

  ngOnInit() {
    if (this.reviewContent) {
      this.review = this.reviewContent;
    }
  }

  submitReport() {
    const reportText = this.report;
    const { id } = JSON.parse(localStorage.getItem('credentials'));
    const data = {
      msg: reportText,
      memberId: id,
      reviewId: this.reviewContent.id
    };
    this.reviewsService.submitReportOnReview(data).subscribe(
      (response: any) => {
        this.submitReviewResponse.emit(response);
      },
      err => {
        console.log('Error => ', err);
      }
    );
  }
}

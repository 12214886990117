import {
  Directive,
  Optional,
  Inject,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  Host,
  OnDestroy,
  OnInit
} from '@angular/core';
import { NgControl, ControlContainer } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FORM_ERRORS } from './form-errors';
import { ControlErrorComponent } from './control-error.component';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { FormSubmitDirective } from './form-submit.directive';
import { merge, EMPTY, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { I18nService } from '@app/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formControl], [formControlName]'
})
export class ControlErrorsDirective implements OnInit, OnDestroy {
  ref: ComponentRef<ControlErrorComponent>;
  container: ViewContainerRef;
  submit$: Observable<Event>;
  @Input() customErrors = {};

  constructor(
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    @Optional() controlErrorContainer: ControlErrorContainerDirective,
    @Inject(FORM_ERRORS) private errors: any,
    @Optional() @Host() private form: FormSubmitDirective,
    private controlDir: NgControl,
    public translateService: TranslateService,
    private i18n: I18nService
  ) {
    // Setup translations
    this.i18n.init(environment.defaultLanguage, environment.supportedLanguages);
    this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  ngOnInit() {
    merge(this.submit$, this.control.valueChanges)
      .pipe(untilDestroyed(this))
      .subscribe(v => {
        const controlErrors = this.control.errors;
        if (controlErrors) {
          const firstKey = Object.keys(controlErrors)[0];
          const getError = this.errors[firstKey];
          const text = this.customErrors[firstKey] || (getError && getError(controlErrors[firstKey]));
          // this.setError(text);
          if (text.includes('minlengthError') || text.includes('maxlengthError')) {
            let errorText = text.split(' ');
            this.setError(
              this.translateService.instant(
                this.translateService.instant(`common.${errorText[0]}`, { length: errorText[1] })
              )
            );
          } else {
            this.setError(this.translateService.instant(`common.${text}`));
          }
        } else if (this.ref) {
          this.setError(null);
        }
      });
  }

  get control() {
    return this.controlDir.control;
  }

  setError(text: string) {
    console.log('err', text);
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
      this.ref = this.container.createComponent(factory);
    }

    this.ref.instance.text = text;
  }

  ngOnDestroy() {}
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReviewsService } from '../reviews.service';

@Component({
  selector: 'app-edit-comment',
  templateUrl: './edit-comment.component.html',
  styleUrls: ['./edit-comment.component.scss']
})
export class EditCommentComponent implements OnInit {
  @Input() public commentContent: any;
  @Output() commentResponse: EventEmitter<any> = new EventEmitter();

  commentData: any;
  comment: any;

  constructor(public activeModal: NgbActiveModal, private reviewService: ReviewsService) {}

  ngOnInit() {
    if (this.commentContent) {
      this.commentData = this.commentContent.comment;
      this.comment = this.commentData.comment;
    }
  }

  updateComment() {
    const commentText = this.comment;
    if (commentText) {
      const { id } = JSON.parse(localStorage.getItem('credentials'));

      const data = {
        comment: commentText,
        id: this.commentData.id,
        memberId: id
      };

      this.reviewService.updateComment(data).subscribe(
        (response: any) => {
          this.commentResponse.emit(response);
        },
        err => {
          console.log('Error => ', err);
        }
      );
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'number-spinner',
  template: `
    <span class="input-group">
      <div class="input-group-btn">
        <button type="button" class="btn btn-primary decrease" (click)="decreaseValue()">
          <i class="fas fa-minus fa-fw"></i>
        </button>
      </div>
      <input
        [formControl]="numberPicker"
        class="form-control"
        type="number"
        min="{{ min }}"
        max="{{ max }}"
        (blur)="changeValue()"
      />
      <div class="input-group-btn">
        <button type="button" class="btn btn-primary increase" (click)="increaseValue()">
          <i class="fas fa-plus fa-fw"></i>
        </button>
      </div>
    </span>
  `,
  styles: [
    `
      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='number'] {
        text-align: center;
      }
      .btn {
        display: flex;
        align-items: center;
        padding: 0.375rem 0.75rem;
        margin-bottom: 0;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        text-align: center;
        white-space: nowrap;
        background-color: #e9ecef;
        border: 2px solid #dde2ec;
        border-radius: 4px;
      }
      .btn.decrease {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      }
      .btn.increase {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
      }
    `
  ]
})
export class NumberSpinnerComponent implements OnInit, OnChanges {
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() precision: number;
  @Input() defaultValue: number;
  @Input() inputDisabled: boolean;
  @Input() readonly: boolean;
  @Output() whenChange: EventEmitter<number> = new EventEmitter();

  numberPicker: FormControl;

  constructor() {}

  ngOnInit() {
    if (this.inputDisabled == null) {
      this.inputDisabled = false;
    }
    if (this.readonly == null) {
      this.readonly = false;
    }
    if (this.min == null) {
      this.min = 0;
    }
    if (this.max == null) {
      this.max = 100;
    }
    if (this.precision == null) {
      this.precision = 1;
    }
    if (this.step == null) {
      this.step = 1;
    }

    let value = this.min;
    if (this.defaultValue) {
      value = this.defaultValue;
    }
    this.numberPicker = new FormControl({ value: value, disabled: this.inputDisabled || this.readonly });

    this.numberPicker.valueChanges.subscribe(() => {
      this.whenChange.emit(this.numberPicker.value);
    });
  }

  ngOnChanges() {
    if (this.defaultValue && this.numberPicker) {
      this.numberPicker.setValue(this.defaultValue);
    }

    if (this.numberPicker) {
      if (this.readonly) {
        this.numberPicker.disable();
      } else {
        this.numberPicker.enable();
      }
    }
  }

  public getValue(): number {
    return this.numberPicker.value;
  }

  increaseValue(): void {
    let currentValue = this.numberPicker.value;
    if (currentValue < this.max) {
      currentValue = currentValue + this.step;
      if (this.precision != null && Number.isInteger(this.precision)) {
        currentValue = this.round(currentValue, this.precision);
      } else {
        currentValue = currentValue.toFixed(2);
      }
      this.numberPicker.setValue(currentValue);
    }
  }

  decreaseValue(): void {
    let currentValue = this.numberPicker.value;
    if (currentValue > this.min) {
      currentValue = currentValue - this.step;
      if (this.precision != null && Number.isInteger(this.precision)) {
        currentValue = this.round(currentValue, this.precision);
      } else {
        currentValue = currentValue.toFixed(2);
      }
      this.numberPicker.setValue(currentValue);
    }
  }

  changeValue() {
    if (this.numberPicker.value < this.min) {
      this.numberPicker.setValue(this.min);
    } else if (this.numberPicker.value > this.max) {
      this.numberPicker.setValue(this.max);
    } else {
      this.numberPicker.setValue(this.numberPicker.value);
    }
  }

  private round(value: number, precision: number): number {
    const multiplier: number = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private activeTheme = new BehaviorSubject<string>('light-theme');
  activeTheme$ = this.activeTheme.asObservable();

  constructor() {}

  setActiveTheme(theme: string) {
    this.activeTheme.next(theme);
  }

  getActiveTheme(): string {
    return this.activeTheme.getValue();
  }
}

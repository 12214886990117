import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'urlFix' })
export class URLFixPipe implements PipeTransform {
  transform(value: string): string {
    // Replace + with %2B
    value = value.replace(/\+/g, '%2B');
    // Replace space with +
    value = value.replace(/ /g, '+');
    return value;
  }
}

import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Room, RoomTable } from '../reservation-settings.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservationService } from '@app/reservation/reservation.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/takeUntil';
import { CredentialsService } from '@app/core';

@Component({
  selector: 'app-add-table',
  templateUrl: './add-table.component.html',
  styleUrls: ['./add-table.component.scss']
})
export class AddTableComponent implements OnInit, OnDestroy {
  @Input() public room: Room;
  @Input() public isCombined = false;
  @Input() public editTableData: any; // only if editing
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  tableFormGroup: FormGroup;
  error: string;
  addObservable: any;
  editObservable: any;
  showGpaySettings = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private reservationService: ReservationService,
    public formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public credentialsService: CredentialsService
  ) {}

  ngOnInit() {
    this.tableFormGroup = this.formBuilder.group({
      roomId: [this.room.id],
      name: ['', Validators.required],
      isActive: [true],
      selfOrdering: [false],
      instantPayment: [false],
      bringService: [true],
      isCombined: [false],
      seats: [1, Validators.required],
      minOccupancy: [1, Validators.required],
      combinedTables: ['']
    });

    this.tableFormGroup
      .get('isCombined')
      .valueChanges.takeUntil(this.ngUnsubscribe)
      .subscribe(isCombined => {
        if (isCombined) {
          this.tableFormGroup.get('combinedTables').setValidators([Validators.required, Validators.minLength(2)]);
        } else {
          this.tableFormGroup.get('combinedTables').clearValidators();
          this.tableFormGroup.get('combinedTables').updateValueAndValidity();
        }
      });

    if (this.isCombined) {
      this.tableFormGroup.get('isCombined').setValue(true);
    }

    if (this.editTableData) {
      this.tableFormGroup.addControl('id', new FormControl(this.editTableData.id, Validators.required));
      this.tableFormGroup.get('name').setValue(this.editTableData.name);
      this.tableFormGroup.get('minOccupancy').setValue(this.editTableData.minOccupancy);
      this.tableFormGroup.get('seats').setValue(this.editTableData.seats);
      this.tableFormGroup.get('isActive').setValue(this.editTableData.isActive);
      this.tableFormGroup.get('selfOrdering').setValue(this.editTableData.selfOrdering);
      this.tableFormGroup.get('instantPayment').setValue(this.editTableData.instantPayment);
      this.tableFormGroup.get('bringService').setValue(this.editTableData.bringService);
      this.tableFormGroup.get('isCombined').setValue(this.editTableData.isCombined);
      this.tableFormGroup.get('combinedTables').setValue(this.editTableData.combinedTables);
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  addTable() {
    if (!this.tableFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    const addData = this.tableFormGroup.value;
    if (addData.combinedTables.length) {
      addData.combinedTables = addData.combinedTables.map((table: any) => table.id);
    }
    this.error = null;
    this.addObservable = this.reservationService
      .addTable(addData)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          console.log('err', err);
          this.error = err.error.msg;
        }
      );
  }

  saveTable() {
    if (!this.tableFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    const editData = this.tableFormGroup.value;
    if (editData.combinedTables) {
      editData.combinedTables = editData.combinedTables.map((table: any) => table.id);
    }
    this.error = null;
    this.editObservable = this.reservationService
      .editTable(this.tableFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          console.log('err', err);
          this.error = err.error.msg;
        }
      );
  }

  seatsAmountChanged(event: any) {
    this.tableFormGroup.patchValue({ seats: event });
    // this.tableFormGroup.value.seats = event;
  }

  minOccupancyChanged(event: any) {
    this.tableFormGroup.patchValue({ minOccupancy: event });
    // this.tableFormGroup.value.minOccupancy = event;
  }

  changeCombined(event: any) {
    if (!event.checked) {
      this.tableFormGroup.get('combinedTables').patchValue([]);
    }
  }

  toggleGpaySettings() {
    this.showGpaySettings = !this.showGpaySettings;
  }
}

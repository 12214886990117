import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReservationService } from '../reservation.service';
import { MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/takeUntil';

@Component({
  selector: 'app-reservation-demo',
  templateUrl: './reservation-demo.component.html',
  styleUrls: ['./reservation-demo.component.scss']
})
export class ReservationDemoComponent implements OnInit, OnDestroy {
  alerts: any;
  archiveAlerts: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  lang: boolean;
  disableBtn: boolean;

  constructor(
    private reservationService: ReservationService,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {}

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  defaultResSystem() {
    this.disableBtn = true;
    this.reservationService
      .defaultResSystem()
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        () => {
          this.disableBtn = false;
          this.snackBar.open('Data added successfully.', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.activatedRoute.params.subscribe(async (parameter: any) => {
            const { clientId } = parameter;
            this.router.navigate([`/client/${clientId}/reservation/book`]).then(() => {
              window.location.reload();
            });
          });
        },
        err => {
          // this.disableBtn = false;
          this.snackBar.open(`Something went wrong! Please contact administrator.`, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }
}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservationService } from '../reservation.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss']
})
export class GuestComponent implements OnInit {
  @Input() public guestId: number;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  guestFormGroup: FormGroup;
  loadingReservationList = true;
  reservations: any;
  guestData: any;
  savingPromise: Promise<any>;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    public reservationService: ReservationService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.reservationService.getGuest(this.guestId).subscribe((guestData: any) => {
      this.guestData = guestData;
      this.guestFormGroup = this.formBuilder.group({
        name: [guestData.name, Validators.required],
        email: [guestData.email],
        phone: [guestData.phone],
        birthdate: [guestData.birthdate],
        notes: [guestData.notes]
      });
    });

    this.getGuestReservations();
  }

  async getGuestReservations() {
    this.loadingReservationList = true;
    await this.reservationService
      .getGuestReservations(this.guestId)
      .toPromise()
      .then((data: any) => {
        this.loadingReservationList = false;
        this.reservations = data;
      });
  }

  save() {
    const passData = this.guestFormGroup.value;
    passData.id = this.guestData.id;
    passData.birthdate = passData.birthdate.format('YYYY-MM-DD');
    this.savingPromise = this.reservationService
      .saveGuest(passData)
      .toPromise()
      .then((res: any) => {
        if (res) {
          this.snackBar.open('Gast-Daten gespeichert.', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        } else {
          this.snackBar.open('Gast-Daten konnten nicht gespeichert werden:' + res.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      });
  }
}

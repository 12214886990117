import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { ReservationService } from '@app/reservation/reservation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-res-menu-orders',
  templateUrl: './add-res-menu-orders.component.html',
  styleUrls: ['./add-res-menu-orders.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddResMenuOrders implements OnInit, OnDestroy {
  @Input() public editResMenuOrders: any; // only if editing
  @Input() public resMenuOrders: any;
  @Input() public clientData: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  resMenuOrdersFormGroup: FormGroup;
  addObservable: any;
  editObservable: any;
  specialResMenuOrders: FormArray;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private reservationService: ReservationService,
    private snackBar: MatSnackBar,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.resMenuOrdersFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      totalAmount: [1, Validators.required],
      specialResMenuOrders: this.formBuilder.array([])
    });

    if (this.editResMenuOrders) {
      this.resMenuOrdersFormGroup.addControl('id', new FormControl(this.editResMenuOrders.id, Validators.required));
      this.resMenuOrdersFormGroup.get('name').setValue(this.editResMenuOrders.name);
      this.resMenuOrdersFormGroup.get('totalAmount').setValue(this.editResMenuOrders.totalAmount);
      // Special Res Menu Orders
      const specialResMenuOrders: any = [];
      if (this.editResMenuOrders.specialResMenuOrders) {
        this.editResMenuOrders.specialResMenuOrders.forEach((st: any) => {
          specialResMenuOrders.push(this.formBuilder.group(st));
        });
        this.resMenuOrdersFormGroup.setControl('specialResMenuOrders', this.formBuilder.array(specialResMenuOrders));
      }
    }
  }

  get formData() {
    return <FormArray>this.resMenuOrdersFormGroup.get('specialResMenuOrders');
  }
  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  add() {
    if (!this.resMenuOrdersFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.addObservable = this.reservationService
      .addResMenuOrders(this.resMenuOrdersFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.snackBar.open(err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
          return;
        }
      );
  }

  save() {
    if (!this.resMenuOrdersFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.editObservable = this.reservationService
      .editResMenuOrders(this.resMenuOrdersFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.snackBar.open(err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
          return;
        }
      );
  }
  addNewUnit() {
    this.specialResMenuOrders = this.resMenuOrdersFormGroup.get('specialResMenuOrders') as FormArray;
    this.specialResMenuOrders.push(this.createUnit());
  }

  createUnit(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      date: ['', Validators.required],
      totalAmount: [1, Validators.required],
      createdAt: ['']
    });
  }

  removeUnit(index: number) {
    this.specialResMenuOrders = this.resMenuOrdersFormGroup.get('specialResMenuOrders') as FormArray;
    this.specialResMenuOrders.removeAt(index);
  }
}

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit, AfterViewInit {
  @ViewChild('canvasGrid', { static: false }) gridCanvas: ElementRef<HTMLCanvasElement>;
  gridContext: CanvasRenderingContext2D;
  roomWidth: number;
  roomHeight: number;
  @Input() room: any;

  constructor() {}
  ngAfterViewInit(): void {
    this.gridContext = this.gridCanvas.nativeElement.getContext('2d');
    this.roomWidth = this.room.width * 100 * 2;
    this.roomHeight = this.room.length * 100 * 2;
    this.gridContext.canvas.width = this.roomWidth;
    this.gridContext.canvas.height = this.roomHeight;
    this.drawGrid();
  }

  ngOnInit() {}

  drawGrid(): void {
    let positionX = 0;
    for (let i = 0; i < this.roomWidth; i++) {
      if (i === 0 || i % 10 === 0) {
        this.drawGridThickLine(positionX, this.roomHeight, 'X');
      } else {
        this.drawGridThinLine(positionX, this.roomHeight, 'X');
      }
      positionX += 10;
    }

    let positionY = 0;
    for (let i = 0; i < this.roomHeight; i++) {
      if (i === 0 || i % 10 === 0) {
        this.drawGridThickLine(positionY, this.roomWidth, 'Y');
      } else {
        this.drawGridThinLine(positionY, this.roomWidth, 'Y');
      }
      positionY += 10;
    }
  }

  drawGridThinLine(step: number, sideOfTheRoom: number, axis: string): void {
    this.gridContext.strokeStyle = 'gray';
    this.gridContext.lineWidth = 0.5;
    this.gridContext.beginPath();
    if (axis === 'X') {
      this.gridContext.moveTo(step, 0);
      this.gridContext.lineTo(step, sideOfTheRoom);
    } else {
      this.gridContext.moveTo(0, step);
      this.gridContext.lineTo(sideOfTheRoom, step);
    }
    this.gridContext.stroke();
    this.gridContext.closePath();
  }

  drawGridThickLine(step: number, sideOfTheRoom: number, axis: string): void {
    this.gridContext.strokeStyle = 'black';
    this.gridContext.lineWidth = 0.5;
    this.gridContext.beginPath();
    if (axis === 'X') {
      this.gridContext.moveTo(step, 0);
      this.gridContext.lineTo(step, sideOfTheRoom);
    } else {
      this.gridContext.moveTo(0, step);
      this.gridContext.lineTo(sideOfTheRoom, step);
    }
    this.gridContext.stroke();
    this.gridContext.closePath();
  }
}

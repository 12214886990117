import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservationService } from '@app/reservation/reservation.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-copy-shift-layer',
  templateUrl: './copy-shift-layer.component.html',
  styleUrls: ['./copy-shift-layer.component.scss']
})
export class CopyShiftLayerComponent implements OnInit {
  @Input() shifts: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  copyExistingShiftFormGroup: FormGroup;
  copyShift: boolean = false;
  createShift: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public activeModal: NgbActiveModal,
    private reservationService: ReservationService,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event: any) => {
      this.translate.setDefaultLang(event.lang);
    });
  }

  ngOnInit() {
    this.copyExistingShiftFormGroup = this.formBuilder.group({
      shiftId: ['']
    });
  }

  submit() {
    if (!this.copyExistingShiftFormGroup.valid || (!this.copyShift && !this.createShift)) {
      this.snackBar.open(this.translate.instant('Common.FormInvalid'), '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    if (this.copyShift) {
      if (this.copyExistingShiftFormGroup.value.shiftId) {
        this.reservationService
          .copyExistingShift(this.copyExistingShiftFormGroup.value.shiftId)
          .subscribe((result: any) => {
            this.passEntry.emit(result);
            this.activeModal.dismiss('');
          });
      } else {
        this.snackBar.open(this.translate.instant('ResSettings.ReservationSetting.PleaseSelectShift'), '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
        return;
      }
    } else {
      this.passEntry.emit('createNew');
      this.activeModal.dismiss('');
    }
  }
  copyOrCreateShift(value: any) {
    if (value == 'copy') {
      this.copyShift = true;
      this.createShift = false;
    } else {
      this.createShift = true;
      this.copyShift = false;
    }
    this.cdr.detectChanges();
  }
}

import { Injectable } from '@angular/core';
import {
  Timeplan,
  Notification,
  Alert,
  Room,
  RoomTable,
  Shift,
  Tag,
  Question,
  PaymentTemplate
} from './reservation-settings/reservation-settings.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { of, Observable, Subject } from 'rxjs';
import { AuthenticationService, CredentialsService } from '@app/core';
import { environment } from '@env/environment';
import { Reservation } from './reservation.model';
import { Point } from 'electron';
import moment, { Moment } from 'moment';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {
  fsSubject = new Subject();
  private cancelSubject = new Subject<void>();
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private credentialsService: CredentialsService
  ) {}

  getSettings() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/settings/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((settings: any) => {
          return settings;
        })
      );
  }

  // For Reservation widget
  getSettingsOfClient(clientId: number) {
    return this.http.get<any>(environment.serverUrl + `public/reservation/settings/` + clientId).pipe(
      map((settings: any) => {
        return settings;
      })
    );
  }

  getReservationBookSettings() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/book-settings/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((settings: any) => {
          return settings;
        })
      );
  }

  saveSettings(settings: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    if (!settings.clientId) {
      settings.clientId = this.authService.activeClientId;
    }
    return this.http
      .post<any>(environment.serverUrl + `reservation/settings/`, settings, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  saveReservationBookSettings(settings: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    if (!settings.clientId) {
      settings.clientId = this.authService.activeClientId;
    }
    return this.http
      .post<any>(environment.serverUrl + `reservation/book-settings/`, settings, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getAppointments() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/timetable/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((timetable: Timeplan[]) => {
          return timetable;
        })
      );
  }

  addTimeTableEntry(entry: any) {
    return new Promise(resolve => {
      const cred = this.credentialsService.credentials;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      entry.shiftId = entry.shift;
      entry.clientId = this.authService.activeClientId;
      return this.http
        .post<any>(environment.serverUrl + `reservation/timetable/`, entry, {
          headers: headers
        })
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch(error => {
          resolve(false);
        });
    });
  }

  editTimeTableEntry(entry: any) {
    return new Promise(resolve => {
      const cred = this.credentialsService.credentials;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      entry.shiftId = entry.shift;
      entry.clientId = this.authService.activeClientId;
      return this.http
        .put<any>(environment.serverUrl + `reservation/timetable/`, entry, {
          headers: headers
        })
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch(error => {
          resolve(false);
        });
    });
  }

  unblockTimeTableEntry(entry: any) {
    return new Promise(resolve => {
      const cred = this.credentialsService.credentials;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      entry.shiftId = entry.shift;
      entry.clientId = this.authService.activeClientId;
      return this.http
        .post<any>(environment.serverUrl + `reservation/timetable/unblock`, entry, {
          headers: headers
        })
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch(error => {
          resolve(false);
        });
    });
  }

  deleteTimeTableEntry(entryId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/timetable/` + entryId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  getNotificationChannels() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/notifications/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((channels: any) => {
          return channels;
        })
      );
  }

  addNotificationChannel(channel: Notification) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    channel.clientId = this.authService.activeClientId;
    return this.http
      .post<any>(environment.serverUrl + `reservation/notifications/`, channel, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  editNotificationChannel(channel: Notification) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    channel.clientId = this.authService.activeClientId;
    return this.http
      .put<any>(environment.serverUrl + `reservation/notifications/`, channel, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  deleteNotificationChannel(channelId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/notifications/` + channelId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  getAlerts(type: string = 'all', date: any = '') {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(
        environment.serverUrl + `reservation/alerts/` + this.authService.activeClientId + '/' + type + '/' + date,
        {
          headers: headers
        }
      )
      .pipe(
        map((channels: any) => {
          return channels;
        })
      );
  }
  getArchiveAlerts(type: string = 'all', date: any = '') {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(
        environment.serverUrl + `reservation/alerts/` + this.authService.activeClientId + '/' + type + '/' + date,
        {
          headers: headers
        }
      )
      .pipe(
        map((channels: any) => {
          return channels;
        })
      );
  }

  addAlert(alert: Alert) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    alert.clientId = this.authService.activeClientId;
    const formData: any = new FormData();
    for (const key in alert) {
      if (key === 'fileUrl') {
        formData.append('file', alert[key]);
      } else {
        formData.append(key, alert[key]);
      }
    }
    return this.http
      .post<any>(environment.serverUrl + `reservation/alerts/`, formData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  editAlert(alert: Alert) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    alert.clientId = this.authService.activeClientId;
    const formData: any = new FormData();
    for (const key in alert) {
      if (key === 'fileUrl') {
        formData.append('file', alert[key]);
      } else {
        formData.append(key, alert[key]);
      }
    }
    return this.http
      .put<any>(environment.serverUrl + `reservation/alerts/`, formData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  deleteAlert(alertId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/alerts/` + alertId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  getRooms(getCombinedTables: boolean = true) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(
        environment.serverUrl +
          `reservation/rooms/` +
          this.authService.activeClientId +
          '/' +
          (getCombinedTables ? '1' : '0'),
        {
          headers: headers
        }
      )
      .pipe(
        map((channels: any) => {
          return channels;
        })
      );
  }

  getTables(roomId: number = null) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/tables/` + this.authService.activeClientId + '/' + roomId, {
        headers: headers
      })
      .pipe(
        map((tables: any) => {
          return tables;
        })
      );
  }

  getAllTablesForActiveClient() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/all-tables/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((tables: any) => {
          return tables;
        })
      );
  }

  addRoom(room: Room) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    room.clientId = this.authService.activeClientId;
    return this.http
      .post<any>(environment.serverUrl + `reservation/rooms/`, room, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  editRoom(room: Room) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    room.clientId = this.authService.activeClientId;
    return this.http
      .put<any>(environment.serverUrl + `reservation/rooms/`, room, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteRoom(roomId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/rooms/` + roomId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addTable(table: RoomTable) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    table.clientId = this.authService.activeClientId;
    return this.http
      .post<any>(environment.serverUrl + `reservation/rooms/tables/`, table, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  editTable(table: RoomTable) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    table.clientId = this.authService.activeClientId;
    return this.http
      .put<any>(environment.serverUrl + `reservation/rooms/tables/`, table, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteTable(tableId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/rooms/tables/` + tableId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  setRoomOrder(items: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `reservation/rooms/order/`,
        {
          items
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  setTableOrder(tableId: number, roomId: number, previousIndex: number, currentIndex: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `reservation/rooms/tables/order/`,
        {
          id: tableId,
          roomId: roomId,
          previousIndex: previousIndex,
          currentIndex: currentIndex
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  saveTableOrder(items: any, roomId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `reservation/rooms/tables/order/all`,
        {
          items,
          roomId
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  getShifts() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/shifts/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((shifts: any) => {
          return shifts;
        })
      );
  }

  getShiftsOfDate(clientId: number, date: string, lang = 'de') {
    const headers = new HttpHeaders({
      lang
    });
    return this.http
      .get<any>(environment.serverUrl + `public/reservation/shifts-of-date/` + clientId + '/' + date, {
        headers: headers
      })
      .pipe(
        map((shifts: any) => {
          return shifts;
        })
      );
  }

  addShift(shift: Shift) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    shift.clientId = this.authService.activeClientId;
    return this.http
      .post<any>(environment.serverUrl + `reservation/shifts/`, shift, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  editShift(shift: Shift) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    shift.clientId = this.authService.activeClientId;
    return this.http
      .put<any>(environment.serverUrl + `reservation/shifts/`, shift, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteShift(shiftId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/shifts/` + shiftId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  addTag(tag: Tag) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    tag.clientId = this.authService.activeClientId;
    return this.http
      .post<any>(environment.serverUrl + `reservation/tags/`, tag, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  deleteTag(tagId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/tags/` + tagId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  getTags() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/tags/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((tags: any) => {
          return tags;
        })
      );
  }

  startReservation(clientId: number, guestCount: Number, selectedDate: string, selectedShift: Number, lang = 'de') {
    const headers = new HttpHeaders({
      lang
    });
    return this.http
      .post<any>(
        environment.serverUrl + `public/reservation/start-reservation/`,
        {
          clientId: clientId,
          guestCount: guestCount,
          date: selectedDate,
          shiftId: selectedShift
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  checkAvailableTimes(
    clientId: number,
    guestCount: Number,
    date: string,
    time: string,
    shift: Number,
    stayTime: number,
    roomId: number,
    resId: string = '',
    additionalRes: boolean = false
  ) {
    return this.http
      .post<any>(environment.serverUrl + `public/reservation/check-available-times/`, {
        clientId: clientId,
        guestCount: guestCount,
        date: date,
        time: time,
        shiftId: shift,
        stayTime: stayTime,
        roomId: roomId,
        resId: resId,
        additionalRes: additionalRes
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  processReservation(reservation: any, lang = 'de') {
    const headers = new HttpHeaders({
      lang,
      'x-reservation-token': localStorage.getItem('reservationToken')
    });
    return this.http
      .post<any>(environment.serverUrl + `public/reservation/add/`, reservation, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getTimelineReservations(params: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `reservation/timeline/` + this.authService.activeClientId, params, {
        headers: headers
      })
      .pipe(
        map((reservationsData: any) => {
          return reservationsData;
        })
      );
  }

  getReservation(reservationId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/` + reservationId, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getReservationByHash(reservationHash: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `public/reservation/hash/` + reservationHash, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getAllReservationsBook(
    startDate: any,
    endDate: any,
    page: number = 0,
    endDateAddition: number = 24,
    type = '',
    isEndCal: boolean = true
  ) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    const apiUrl = environment.serverUrl + `reservation/all/` + this.authService.activeClientId + type;

    // Emit a value into the cancelSubject to cancel the previous request
    this.cancelSubject.next();

    return this.http
      .post<any>(
        apiUrl,
        {
          startDate: format(startDate, 'YYYY-MM-DD') + ' 00:00:00',
          endDate: format(endDate, 'YYYY-MM-DD') + ' 23:59:59',
          page,
          endDateAddition,
          isEndCal
        },
        {
          headers: headers
        }
      )
      .pipe(
        debounceTime(500),
        switchMap((reservationsData: any) => {
          return of(reservationsData);
        }),
        takeUntil(this.cancelSubject) // Cancels the previous request
      );
  }

  getAllReservations(startDate: any, endDate: any, page: number = 0, endDateAddition: number = 24, type = '') {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `reservation/all/` + this.authService.activeClientId + type,
        {
          startDate,
          endDate,
          page,
          endDateAddition
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((reservationsData: any) => {
          return reservationsData;
        })
      );
  }

  getGuestReservations(guestId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/guest-reservations/` + guestId, {
        headers: headers
      })
      .pipe(
        map((reservationsData: any) => {
          return reservationsData;
        })
      );
  }

  getPendingReservations() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/pending/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((reservationsData: any) => {
          return reservationsData;
        })
      );
  }

  editReservation(reservation: Reservation) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    reservation.clientId = this.authService.activeClientId;
    const formData = this.objectToFormData(reservation);

    return this.http
      .put<any>(environment.serverUrl + `reservation/`, formData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  objectToFormData(model: any, form: FormData = null, namespace: string = ''): FormData {
    const formData = form || new FormData();
    let formKey: string;

    for (const propertyName in model) {
      if (!model.hasOwnProperty(propertyName)) {
        continue;
      }
      formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      if (model[propertyName] instanceof Date) {
        formData.append(formKey, model[propertyName].toISOString());
      } else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element: any, index: number) => {
          const tempFormKey = `${formKey}[${index}]`;
          this.objectToFormData(element, formData, tempFormKey);
        });
      } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
        this.objectToFormData(model[propertyName], formData, formKey);
      } else {
        let itemKey = model[propertyName];

        if (typeof itemKey === 'boolean' && itemKey === true) {
          itemKey = 1;
        }
        if (typeof itemKey === 'boolean' && itemKey === false) {
          itemKey = 0;
        }
        if (itemKey !== undefined) {
          if (itemKey instanceof File) {
            formData.append('files', itemKey);
          } else {
            formData.append(formKey, itemKey);
          }
        }
      }
    }
    return formData;
  }

  addReservation(reservation: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    reservation.clientId = this.authService.activeClientId;

    const formData = this.objectToFormData(reservation);

    return this.http
      .post<any>(environment.serverUrl + `reservation/`, formData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  checkFreeTables(
    date: any,
    time: string,
    guestCount: number,
    stayTime: any,
    includeUnavailable: boolean = false,
    includeLockedRooms: boolean = false,
    id: any = ''
  ) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `reservation/tables/free/`,
        {
          clientId: this.authService.activeClientId,
          guestCount: guestCount,
          date: date,
          time: time,
          stayTime: stayTime,
          includeUnavailable,
          includeLockedRooms,
          id
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  searchGuest(guest: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    guest.clientId = this.authService.activeClientId;
    return this.http
      .post<any>(
        environment.serverUrl + `reservation/guest/search/`,
        { searchData: guest },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getClientGuests(sort: any, searchFilter: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    const clientId = this.authService.activeClientId;
    return this.http
      .post<any>(
        environment.serverUrl + `reservation/my-guests/${clientId}`,
        {
          order: sort[0],
          searchFilter: searchFilter
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  createGuest(guest: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    guest.clientId = this.authService.activeClientId;
    return this.http
      .post<any>(environment.serverUrl + `reservation/guest/new`, guest, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteGuest(guestId: number): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .delete<any>(environment.serverUrl + `reservation/guest/` + guestId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  checkUserToken(token: string) {
    return this.http.get<any>(environment.serverUrl + `public/check-reservation-token/` + token).pipe(
      map((reservation: any) => {
        return reservation;
      })
    );
  }

  /* For User */
  cancelReservation(reservationId: number) {
    return this.http
      .post<any>(environment.serverUrl + `public/cancel-reservation/`, { reservationId })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getQuestions() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/questions/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((questions: any) => {
          return questions;
        })
      );
  }

  addQuestion(question: Question) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    question.clientId = this.authService.activeClientId;
    return this.http
      .post<any>(environment.serverUrl + `reservation/questions/`, question, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  editQuestion(question: Question) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    question.clientId = this.authService.activeClientId;
    return this.http
      .put<any>(environment.serverUrl + `reservation/questions/`, question, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteQuestion(questionId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/questions/` + questionId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getWidgetStyle() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/widget-style/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((style: any) => {
          return style;
        })
      );
  }

  getWidgetStylePublic(clientId: number) {
    return this.http.get<any>(environment.serverUrl + `public/widget-style/` + clientId).pipe(
      map((style: any) => {
        return style;
      })
    );
  }

  saveWidgetStyle(styles: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    if (!styles.clientId) {
      styles.clientId = this.authService.activeClientId;
    }
    return this.http
      .put<any>(environment.serverUrl + `reservation/save-widget-style/`, styles, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getGuest(guestId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/guest/` + guestId, {
        headers: headers
      })
      .pipe(
        map((guestData: any) => {
          return guestData;
        })
      );
  }

  saveGuest(guestData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .put<any>(environment.serverUrl + `reservation/guest/`, guestData, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getPaymentTemplates() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/payment-templates/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((questions: any) => {
          return questions;
        })
      );
  }

  addPaymentTemplate(paymentTemplate: PaymentTemplate) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    paymentTemplate.clientId = this.authService.activeClientId;
    return this.http
      .post<any>(environment.serverUrl + `reservation/payment-templates/`, paymentTemplate, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  editPaymentTemplate(paymentTemplate: PaymentTemplate) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    paymentTemplate.clientId = this.authService.activeClientId;
    return this.http
      .put<any>(environment.serverUrl + `reservation/payment-templates/`, paymentTemplate, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deletePaymentTemplate(templateId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/payment-templates/` + templateId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getTotalNumberOfTablesAndChairs(autoPlace: number): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(
        environment.serverUrl + `reservation/get-occupation-per-hour/${this.authService.activeClientId}/${autoPlace}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getAllDecorations(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/room-decorations`, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getRestaurantDecorations(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/restaurant-decorations/${this.authService.activeClientId}`, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  addNewDecorationInRoom(decoration: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `reservation/restaurant-decorations`, { decoration }, { headers: headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  editDecorationInRoom(decoration: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .put<any>(environment.serverUrl + `reservation/restaurant-decorations`, decoration, { headers: headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteDecorationFromRoom(decorationId: number): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/restaurant-decorations/${decorationId}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getRestaurantFloorPatterns() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/restaurant-floor-patterns`, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getRestaurantFloorSettings() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/restaurant-floor-patterns/${this.authService.activeClientId}`, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateRestaurantFloorSettings(floorSettings: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .put<any>(environment.serverUrl + `reservation/restaurant-floor-patterns`, floorSettings, { headers: headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  editPattern(pattern: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .put<any>(environment.serverUrl + `reservation/edit-floor-pattern`, pattern, { headers: headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  createPattern(pattern: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `reservation/create-floor-pattern`, pattern, { headers: headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deletePattern(id: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/delete-floor-pattern/` + id, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  editDecoration(decoration: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .put<any>(environment.serverUrl + `reservation/edit-decoration`, decoration, { headers: headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  createDecoration(decoration: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `reservation/create-decoration`, decoration, { headers: headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteDecoration(id: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/delete-decoration/` + id, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  getIncompatibilities(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/incompatibilities/${this.authService.activeClientId}`, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  addMultipleReservations(reservation: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `reservation/hotel-reservations/${this.authService.activeClientId}`,
        { reservation },
        { headers: headers }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getSpecialHotelReservation(reservation: number, room: number): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(
        environment.serverUrl +
          `reservation/special-hotel-reservation/${reservation}/${room}/${this.authService.activeClientId}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  setTablePosition(tableId: number, position: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `public/set-table-position/${this.authService.activeClientId}/${tableId}`,
        { position },
        { headers: headers }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  isActiveClientTablePlanUser(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get(environment.serverUrl + `reservation/table-plan-client/${this.authService.activeClientId}`, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  isActiveMemberSuperAdmin(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get(environment.serverUrl + `private/users/super-admin/${cred.id}`, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  deleteReservationFile(id: number, staffId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/delete-reservation-file/${id}/${staffId}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  /* New reservation series format*/
  addTimeTableSeries(entry: any) {
    return new Promise(resolve => {
      const cred = this.credentialsService.credentials;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      entry.clientId = this.authService.activeClientId;
      return this.http
        .post<any>(environment.serverUrl + `reservation/add-timetable-series/`, entry, {
          headers: headers
        })
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch(error => {
          resolve(false);
        });
    });
  }

  getTimeTableCurrentWeek(startDate: any, endDate: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `reservation/timetable-series/` + this.authService.activeClientId,
        { startDate, endDate },
        {
          headers: headers
        }
      )
      .pipe(
        map((timetable: Timeplan[]) => {
          return timetable;
        })
      );
  }
  editTimeTableSeries(entry: any) {
    return new Promise(resolve => {
      const cred = this.credentialsService.credentials;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      entry.clientId = this.authService.activeClientId;
      return this.http
        .put<any>(environment.serverUrl + `reservation/edit-timetable-series/`, entry, {
          headers: headers
        })
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch(error => {
          resolve(false);
        });
    });
  }
  deleteTimeTableSeries(id: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/delete-timetable-series/` + id, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }
  blockTimeTableSeries(entry: any) {
    return new Promise(resolve => {
      const cred = this.credentialsService.credentials;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      entry.clientId = this.authService.activeClientId;
      return this.http
        .post<any>(environment.serverUrl + `reservation/block-timetable-series/`, entry, {
          headers: headers
        })
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch(error => {
          resolve(false);
        });
    });
  }
  unBlockTimeTableSeries(entry: any) {
    return new Promise(resolve => {
      const cred = this.credentialsService.credentials;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      entry.shiftId = entry.shift;
      entry.clientId = this.authService.activeClientId;
      return this.http
        .post<any>(environment.serverUrl + `reservation/unblock-timetable-series`, entry, {
          headers: headers
        })
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch(error => {
          resolve(false);
        });
    });
  }
  blockTimeTableSeriesEdit(entry: any) {
    return new Promise(resolve => {
      const cred = this.credentialsService.credentials;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      entry.clientId = this.authService.activeClientId;
      return this.http
        .post<any>(environment.serverUrl + `reservation/edit-block-timetable-series/`, entry, {
          headers: headers
        })
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch(error => {
          resolve(false);
        });
    });
  }
  getReservationLogs(from: any = null, to: any = null) {
    if (from === null) {
      from = moment()
        .subtract(7, 'days')
        .format();
    }
    if (to === null) {
      to = moment().format();
    }
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `reservation/logs/` + this.authService.activeClientId,
        {
          startDate: from,
          endDate: to
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((stats: any) => {
          return stats;
        })
      );
  }
  getRecurrenceLogs(from: any = null, to: any = null) {
    if (from === null) {
      from = moment()
        .subtract(7, 'days')
        .format();
    }
    if (to === null) {
      to = moment().format();
    }
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `reservation/recurrence-logs/` + this.authService.activeClientId,
        {
          startDate: from,
          endDate: to
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((stats: any) => {
          return stats;
        })
      );
  }
  getDateofReservation(reservationId: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/date/` + reservationId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getupdateGoogleReseration(status: string, reservation: any) {
    const reservationID = reservation['id'];
    return this.http.get<any>(environment.serverUrl + `public/updateGoogleReservation/${reservationID}/${status}`).pipe(
      map((reservationsData: any) => {
        return reservationsData;
      })
    );
  }
  getAllTables() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation//all-tables-settings/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((tables: any) => {
          return tables;
        })
      );
  }

  reorderTables(tableArray: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `reservation/table/reorder`,
        { clientId: this.authService.activeClientId, tables: tableArray },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getReservationAddOnTemplates() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/reservation-addon-template/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((questions: any) => {
          return questions;
        })
      );
  }

  addReservationAddOnTemplate(reservationAddOnTemplates: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!reservationAddOnTemplates.clientId) {
      reservationAddOnTemplates.clientId = this.authService.activeClientId;
    }
    const formData: any = new FormData();
    for (const key in reservationAddOnTemplates) {
      if (key == 'allPrices') {
        formData.append(key, JSON.stringify(reservationAddOnTemplates[key]));
      } else {
        formData.append(key, reservationAddOnTemplates[key]);
      }
    }
    return this.http
      .post<any>(environment.serverUrl + `reservation/reservation-addon-template`, formData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  editReservationAddOnTemplate(reservationAddOnTemplates: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!reservationAddOnTemplates.clientId) {
      reservationAddOnTemplates.clientId = this.authService.activeClientId;
    }
    const formData: any = new FormData();
    for (const key in reservationAddOnTemplates) {
      if (key == 'allPrices') {
        formData.append(key, JSON.stringify(reservationAddOnTemplates[key]));
      } else {
        formData.append(key, reservationAddOnTemplates[key]);
      }
    }
    return this.http
      .put<any>(environment.serverUrl + `reservation/reservation-addon-template`, formData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteReservationAddOnTemplate(addOnId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/reservation-addon-template/` + addOnId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  // For Reservation addons
  getClientReservationAddOnTemplates(clientId: number, selectedShift: number) {
    return this.http
      .get<any>(environment.serverUrl + `public/reservation-addon-template/` + clientId + '/' + selectedShift, {})
      .pipe(
        map((questions: any) => {
          return questions;
        })
      );
  }
  getGeoCountries() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `public/getCountries`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getSmsSettings() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/sms-settings/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((questions: any) => {
          return questions;
        })
      );
  }

  saveSmsSettings(smsSettings: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!smsSettings.clientId) {
      smsSettings.clientId = this.authService.activeClientId;
    }
    return this.http
      .put<any>(environment.serverUrl + `client/sms-settings`, smsSettings, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  guestReservationHistory(guestId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/guest-reservations-history/` + guestId, {
        headers: headers
      })
      .pipe(
        map((reservationsData: any) => {
          return reservationsData;
        })
      );
  }
  getSmsSettingsOfClient(clientId: number) {
    return this.http.get<any>(environment.serverUrl + `public/reservation/sms-settings/` + clientId).pipe(
      map((settings: any) => {
        return settings;
      })
    );
  }

  getSMSConfirmationSettings() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/sms-confirmation-settings/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveSMSConfirmationSettings(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    if (!data.clientId) {
      data.clientId = this.authService.activeClientId;
    }
    return this.http
      .post<any>(environment.serverUrl + `reservation/sms-confirmation-settings`, data, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  getResMenuOrder(foreignId: number, date: any) {
    return this.http
      .post<any>(environment.serverUrl + `public/reservation/res-menu-orders/`, { foreignId, date })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  defaultResSystem() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.secondaryServerUrl + `reservation/default-res-system/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  checkOnlineSlotAvailability(slotAvailabilityData: any, lang = 'de') {
    const headers = new HttpHeaders({
      lang
    });
    return this.http
      .post<any>(environment.serverUrl + `public/reservation/check-online-slot-availability/`, slotAvailabilityData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  saveShiftSettings(settings: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .put<any>(environment.serverUrl + `reservation/save-shift-settings`, settings, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getAllResMenuOrders() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/all-res-menu-orders/` + this.authService.activeClientId, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  addResMenuOrders(resMenuOrders: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!resMenuOrders.clientId) {
      resMenuOrders.clientId = this.authService.activeClientId;
    }
    return this.http
      .post<any>(environment.serverUrl + `reservation/add-res-menu-orders`, resMenuOrders, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  editResMenuOrders(resMenuOrders: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!resMenuOrders.clientId) {
      resMenuOrders.clientId = this.authService.activeClientId;
    }
    return this.http
      .put<any>(environment.serverUrl + `reservation/edit-res-menu-orders`, resMenuOrders, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteResMenuOrders(foreignId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/delete-res-menu-orders/` + foreignId, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addResTimeFilter(entry: any) {
    return new Promise(resolve => {
      const cred = this.credentialsService.credentials;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      entry.clientId = this.authService.activeClientId;
      return this.http
        .post<any>(environment.serverUrl + `reservation/add-res-time-filter/`, entry, {
          headers: headers
        })
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch(error => {
          resolve(false);
        });
    });
  }

  getResTimeFilter() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/res-time-filter/` + this.authService.activeClientId, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  editResTimeFilter(entry: any) {
    return new Promise(resolve => {
      const cred = this.credentialsService.credentials;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      entry.clientId = this.authService.activeClientId;
      return this.http
        .put<any>(environment.serverUrl + `reservation/edit-res-time-filter/`, entry, {
          headers: headers,
          reportProgress: true
        })
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch(error => {
          resolve(false);
        });
    });
  }

  deleteResTimeFilter(id: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/delete-res-time-filter/` + id, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateGuestData(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(environment.serverUrl + `reservation/update-guest-data/${this.authService.activeClientId}`, data, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getPhoneAssistantSettings() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `public/phone-assistant-settings-client/` + this.authService.activeClientId, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  // reservation/guest/new
  getShiftEventDetails(id: any, date: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `public/res-event-details/${id}/${date}`, { headers: headers })
      .pipe(
        map((events: any) => {
          return events;
        })
      );
  }

  // getPhoneAssistantSettings() {
  //   const cred = this.credentialsService.credentials;
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     Authorization: 'Bearer ' + cred.token
  //   });
  //   return this.http
  //     .get<any>(environment.serverUrl + `public/phone-assistant-settings/` + this.authService.activeClientId, {
  //       headers: headers,
  //       reportProgress: true
  //     })
  //     .pipe(
  //       map((response: any) => {
  //         return response;
  //       })
  //     );
  // }

  getTicketsForPayment(paymentId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `public/getTicketsOnPayment/paymentId/${paymentId}/date/`, {
        headers: headers
      })
      .pipe(
        map((events: any) => {
          return events;
        })
      );
  }

  createPhoneAssistant(phoneAssistant: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `public/phone-assistant-settings`, phoneAssistant, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  // reservation/guest/new
  getShiftAvailableDate(clientId: number, shiftId: number, lang = 'de') {
    const headers = new HttpHeaders({
      lang
    });
    return this.http
      .get<any>(environment.serverUrl + `public/reservation/shifts-available-dates/` + clientId + '/' + shiftId + '/', {
        headers: headers
      })
      .pipe(
        map((shifts: any) => {
          return shifts;
        })
      );
  }

  getTicketSettings(clientId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `public/tickets/settings/` + clientId, {
        headers: headers
      })
      .pipe(
        map((stats: any) => {
          return stats;
        })
      );
  }

  checkVoucher(clientId: number, code: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `public/voucher/check/${clientId}/${code}`, {
        headers: headers
      })
      .pipe(
        map((areas: any) => {
          return areas;
        })
      );
  }

  getPaymentDetails(ticketOrderId: number, type: any) {
    return this.http.get<any>(environment.serverUrl + `public/get-payment-details/${ticketOrderId}/${type}`).pipe(
      map((payment: any) => {
        return payment;
      })
    );
  }

  getOnlineShifts(clientId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `public/reservation/shifts/${clientId}`, {
        headers: headers
      })
      .pipe(
        map((shifts: any) => {
          return shifts;
        })
      );
  }

  copyExistingShift(shiftId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/copy-existing-shift/` + shiftId, {
        headers: headers
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  // For Google Translation logs
  saveGoogleTranslationLogs(logs: any, lang = 'de') {
    const headers = new HttpHeaders({
      lang
    });
    return this.http
      .post<any>(environment.serverUrl + `public/google-translate-logs`, logs, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addAndUpdateStatus(statusObj: any) {
    return new Promise(resolve => {
      const cred = this.credentialsService.credentials;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      statusObj.clientId = this.authService.activeClientId;
      return this.http
        .post<any>(environment.serverUrl + `reservation/add-res-status/`, statusObj, {
          headers: headers
        })
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch(error => {
          resolve(false);
        });
    });
  }

  getResStatus() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/res-status/` + this.authService.activeClientId, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveVersionSettings(settings: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `reservation/save-version-settings/${this.authService.activeClientId}`,
        settings,
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteResStatus(id: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `reservation/delete-res-status/` + id, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getPackageSettings(clientId: number) {
    return this.http.get<any>(environment.serverUrl + `public/client/package-settings/` + clientId).pipe(
      map((settings: any) => {
        return settings;
      })
    );
  }

  generateReservationToken(clientId: any, token: any) {
    const headers = new HttpHeaders({});
    return this.http
      .post<any>(
        environment.serverUrl + `public/user/check-data/`,
        {
          clientId,
          token
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  sendPaymentLinkViaEmail(baseURL: any, reservationId: any) {
    const headers = new HttpHeaders({});
    return this.http
      .post<any>(
        environment.serverUrl + `public/reservation/send-payment-link-email`,
        {
          baseURL,
          reservationId
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReviewsRoutingModule } from './reviews-routing.module';
import { ReviewsComponent } from './reviews.component';
import { SharedModule } from '@app/shared';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { MatProgressBarModule, MatExpansionModule } from '@angular/material';
import { ReportReviewComponent } from './report-review/report-review.component';
import { SetReplyComponent } from './set-reply/set-reply.component';
import { EditCommentComponent } from './edit-comment/edit-comment.component';

@NgModule({
  declarations: [ReviewsComponent, ReportReviewComponent, SetReplyComponent, EditCommentComponent],
  imports: [
    CommonModule,
    ReviewsRoutingModule,
    NgxDatatableModule,
    SharedModule,
    NgbRatingModule,
    MatProgressBarModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule
  ],
  entryComponents: [ReportReviewComponent, SetReplyComponent, EditCommentComponent]
})
export class ReviewsModule {}

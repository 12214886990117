<breadcrumb></breadcrumb>
<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Wertgutscheine - Bestellungen
          <small *ngIf="filterBy"><br />Filter: <a class="link" (click)="filterOrdersBy()">aufheben</a></small>
        </h6>

        <div class="element-box mat-wrapper">
          <div class="row">
            <div class="col-md-3">
              <section>
                <div class="form-group">
                  <label for="">Datum von</label>
                  <div class="input-group">
                    <div class="input-group-prepend" (click)="dateFromPicker.open()">
                      <div class="input-group-text">
                        <i class="fas fa-calendar-alt"></i>
                      </div>
                    </div>
                    <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                    <input
                      class="form-control"
                      #dateFromInput
                      placeholder=""
                      [(ngModel)]="dates.from"
                      type="text"
                      name="date"
                      [matDatepicker]="dateFromPicker"
                      (focus)="dateFromPicker.open()"
                      (dateChange)="getTransactions(false)"
                    />
                  </div>
                </div>
              </section>
            </div>

            <div class="col-md-3">
              <section>
                <div class="form-group">
                  <label for="">Datum bis</label>
                  <div class="input-group">
                    <div class="input-group-prepend" (click)="dateFromPicker.open()">
                      <div class="input-group-text">
                        <i class="fas fa-calendar-alt"></i>
                      </div>
                    </div>
                    <mat-datepicker #dateToPicker (closed)="dateToInput.blur()"></mat-datepicker>
                    <input
                      class="form-control"
                      #dateToInput
                      placeholder=""
                      [(ngModel)]="dates.to"
                      type="text"
                      name="date"
                      [matDatepicker]="dateToPicker"
                      (focus)="dateToPicker.open()"
                      (dateChange)="getTransactions(false)"
                    />
                  </div>
                </div>
              </section>
            </div>

            <div class="col-md-3" *ngIf="tablePages.length > 1">
              <section>
                <div class="form-group">
                  <label for="">Zeige Seite</label>
                  <div class="input-group">
                    <mat-select [(ngModel)]="tableOffset" (selectionChange)="changeTableOffset(tableOffset - 1)">
                      <mat-option *ngFor="let option of tablePages" [value]="option">Seite {{ option }}</mat-option>
                    </mat-select>
                  </div>
                </div>
              </section>
            </div>

            <div class="col-md-3">
              <div class="form-group row_">
                <form class="example-form">
                  <label style="margin-bottom: -4px; display: block;">Suche:</label>
                  <mat-form-field class="example-full-width" style="width: 100%;">
                    <input
                      type="text"
                      matInput
                      [formControl]="myControl"
                      [(ngModel)]="filterBy"
                      placeholder="Käufer"
                      style="width: auto;"
                      (keyup)="filterOrdersBy('client', $event.target.value)"
                    />
                  </mat-form-field>
                </form>
              </div>
            </div>

            <div class="col-md-12">
              <section>
                <div class="form-group actions">
                  <label for="">Aktionen</label><br />
                  <button class="btn btn-warning" *ngIf="filterBy" (click)="filterOrdersBy()">
                    <i class="fas fa-times"></i>&nbsp;Suche:&nbsp;{{ filterBy }}
                  </button>
                  <button class="btn btn-light" (click)="setDates('today')">heute</button>
                  <button class="btn btn-light" (click)="setDates('yesterday')">gestern</button>
                  <button class="btn btn-light" (click)="setDates('lastSeven')">letzten 7 Tage</button>
                  <button class="btn btn-light" (click)="setDates('thisWeek')">akt. Woche</button>
                  <button class="btn btn-light" (click)="setDates('lastWeek')">letzte Woche</button>
                  <button class="btn btn-light" (click)="setDates('thisMonth')">akt. Monat</button>
                  <button class="btn btn-light" (click)="setDates('lastMonth')">letzter Monat</button>
                  <button
                    class="btn"
                    [ngClass]="{
                      'post-voucher-active': isPostalVouchersClicked,
                      'btn-light': !isPostalVouchersClicked
                    }"
                    (click)="getPostalVouchers('post')"
                  >
                    Postgutscheine
                  </button>
                  <!-- <small *ngIf="filterBy"><br />Filter: <a class="link" (click)="filterOrdersBy()">aufheben</a></small> -->
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="col-md-12" style="padding: 0px;" *ngIf="finalOrders.length">
          <a class="element-box el-tablo" style="padding: 1.65rem 0.3rem" href="javascript:return;">
            <div class="row">
              <div class="col-sm-3 text-center">
                <div class="label">
                  Umsatz (brutto)
                </div>
                <div class="value">
                  {{ filterStats?.orders.total | currency: 'EUR' }}
                </div>
              </div>
              <div class="col-sm-3 text-center">
                <div class="label">
                  Anz.
                </div>
                <div class="value">
                  {{ filterStats?.orders.count }}
                </div>
              </div>
              <div class="col-sm-3 text-center">
                <div class="label">
                  Stornos (brutto)
                </div>
                <div class="value">
                  {{ filterStats?.cancelations.total | currency: 'EUR' }}
                </div>
              </div>
              <div class="col-sm-3 text-center">
                <div class="label" style="word-break: break-all;">
                  Stornos
                </div>
                <div class="value">
                  {{ filterStats?.cancelations.count }}
                </div>
              </div>
            </div>

            <!-- <div class="value">
              <div class="label" style="padding-top: 19px;">Zeitraum</div>
              <small>{{ dates.from | date: 'd.M.yy' }} - {{ dates.to | date: 'd.M.yy' }}</small>
            </div>
    
            <div class="row" style="margin-top: 15px;">
              <div class="el-tablo smaller col-sm-6">
                <div class="label">Bar</div>
                <div class="value">{{ filterStats?.orders.cash | currency: 'EUR' }}</div>
              </div>
              <div class="el-tablo smaller col-sm-6">
                <div class="label">Online</div>
                <div class="value">
                  {{ filterStats?.orders.online + filterStats?.orders.gastropay | currency: 'EUR' }}
                </div>
              </div>
            </div>
    
            <div class="row" style="margin-top: 15px;">
              <div class="el-tablo smaller col-sm-6">
                <div class="label">Trinkg.</div>
                <div class="value">{{ filterStats?.orders.tip | currency: 'EUR' }}</div>
              </div>
              <div class="el-tablo smaller col-sm-6">
                <div class="label">Gebühren</div>
                <div class="value">{{ filterStats?.orders.commission | currency: 'EUR' }}</div>
              </div>
            </div> -->
          </a>
        </div>

        <div class="element-box datatable-res-transactions">
          <ngx-datatable
            class="material"
            [rows]="orders"
            [columnMode]="'force'"
            headerHeight="50"
            footerHeight="50"
            rowHeight="auto"
            [limit]="tableLimit"
            [sorts]="[{ prop: 'id', dir: 'desc' }]"
            [cssClasses]="{
              pagerLeftArrow: 'fas fa-backward',
              pagerRightArrow: 'fas fa-forward',
              pagerPrevious: 'fas fa-step-backward',
              pagerNext: 'fas fa-step-forward'
            }"
          >
            <ngx-datatable-column [sortable]="true" name="ID" prop="id">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{ row.id }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [sortable]="true" name="Datum" prop="orderDate">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="date-label" style="white-space: nowrap;"
                  >{{ row.orderDate | amDateFormat: 'DD.MM.YYYY' }} -
                  {{ row.orderDate | amDateFormat: 'HH:mm' }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column [sortable]="true" name="Zahlart" prop="paymentProvider">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <img
                  style="max-height: 28px"
                  src="https://www.gastroguide.de/imgs/business/payment/{{ row.paymentProvider }}_small.png"
                />
              </ng-template>
            </ngx-datatable-column> -->

            <ngx-datatable-column [sortable]="true" name="Versand" prop="shippingType">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span> {{ row.shippingType }} </span>
                <ng-container *ngIf="row.shippingType == 'email'">
                  <span *ngFor="let template of row.templates">
                    <!-- <a
                      *ngIf="template.templateFileName"
                      href="https://www.gastroguide.de/restaurantgutscheine/show-pdf?file={{
                        template.templateFileName | encodeBase64
                      }}"
                      target="_blank"
                      class="pdf-files"
                      ><i class="fas fa-file-pdf"></i
                    ></a> -->
                    <span *ngIf="template.templateFileName" class="pdf-files" (click)="prepareVoucherPdf(template.id)">
                      <!-- Show loader if loadingMap[template.id] is true -->
                      <span *ngIf="loadingMap[template.id]; else pdfIcon">
                        <span class="loader"></span>
                      </span>
                      <!-- Show the PDF icon if not loading -->
                      <ng-template #pdfIcon>
                        <i class="fas fa-file-pdf"></i>
                      </ng-template>
                    </span>
                  </span>
                </ng-container>
                <ng-container *ngIf="row.shippingType == 'post' && row.shippedDate">
                  <span class="date-label">
                    ({{ row.shippedDate | amDateFormat: 'DD.MM.YYYY' }} - {{ row.shippedDate | amDateFormat: 'HH:mm' }})
                  </span>
                </ng-container>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [sortable]="true" name="Betrag" prop="total">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="price-label" style="white-space: nowrap;">{{ row.total | number: '1.2' }} €</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [sortable]="true" name="Käufer" prop="name">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span
                  ><a href="mailto:{{ row.email }}">{{ row.name }}</a></span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [sortable]="true" name="" prop="details">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <i (click)="showAddress($event, row)" ngbTooltip="Details" class="fa fa-info-circle details-icon"></i>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [sortable]="false" name="Aktionen">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.status === 2" class="text-danger">storniert</span>
                <div *ngIf="row.status !== 2" class="action-btn">
                  <button
                    (click)="voucherService.downloadReceipt(row)"
                    class="btn btn-small btn-light"
                    ngbTooltip="Quittung herunterladen"
                  >
                    <i class="fas fa-file-download"></i>
                    PDF</button
                  ><button
                    (click)="voucherService.sendReceipt(row)"
                    class="btn btn-small btn-primary"
                    ngbTooltip="Quittung senden"
                  >
                    <i class="fas fa-envelope-open-text"></i>
                  </button>
                  <button
                    *ngIf="row.totalTemplate > 0"
                    (click)="sendVoucherPdf(row)"
                    class="btn btn-small btn-primary"
                    ngbTooltip="Gutscheine versenden"
                  >
                    <i class="fas fa-envelope-open"></i>
                  </button>
                  <button
                    *ngIf="row.shippingType == 'post' && !row.shippedDate"
                    (click)="setShippingDate(row)"
                    class="btn btn-small btn-primary"
                    ngbTooltip="Versendet"
                  >
                    <i class="fa fa-paper-plane"></i>
                  </button>
                  <button
                    (click)="cancelVoucher(row)"
                    class="btn btn-small btn-danger"
                    ngbTooltip="Stornieren"
                    [disabled]="row.status === 2"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>

          <div class="grid">
            <mat-paginator
              [length]="orders.length"
              pageSize="{{ pageSize }}"
              (page)="getPaginatorData($event)"
              [pageSizeOptions]="[10, 20, 50]"
              showFirstLastButtons="false"
            >
            </mat-paginator>
            <div class="row">
              <ng-container *ngFor="let row of ordersMobile; let i = index">
                <div
                  class="col-sm-6 col-md-6"
                  *ngIf="i < table.offset * tableLimit + tableLimit && i >= table.offset * tableLimit"
                >
                  <div class="card" id="card-{{ row.id }}">
                    <div class="card-body">
                      <h5 class="card-title">
                        <span class="price-label_">{{ row.total | number: '1.2' }} €</span>
                        <!-- <span class="badge float-right" style="font-weight: normal;">
                          <img
                            style="max-height: 28px"
                            src="https://www.gastroguide.de/imgs/business/payment/{{ row.paymentProvider }}_small.png"
                        /></span> -->
                        <br />
                        <small
                          >{{ row.orderDate | amDateFormat: 'DD.MM.YYYY' }} -
                          {{ row.orderDate | amDateFormat: 'HH:mm' }}</small
                        >
                      </h5>
                      <p class="card-text" style="font-size: 14px;">
                        <span>
                          <i class="fas fa-shipping-fast" style="color: #009688;"></i> {{ row.shippingType }}
                          <ng-container *ngIf="row.shippingType == 'email'">
                            <span *ngFor="let template of row.templates">
                              <!-- <a
                                *ngIf="template.templateFileName"
                                href="https://www.gastroguide.de/restaurantgutscheine/show-pdf?file={{
                                  template.templateFileName | encodeBase64
                                }}"
                                target="_blank"
                                class="pdf-files"
                                ><i class="fas fa-file-pdf"></i
                              ></a> -->
                              <span
                                *ngIf="template.templateFileName"
                                class="pdf-files"
                                (click)="prepareVoucherPdf(template.id)"
                              >
                                <!-- Show loader if loadingMap[template.id] is true -->
                                <span *ngIf="loadingMap[template.id]; else pdfIcon">
                                  <span class="loader"></span>
                                </span>
                                <!-- Show the PDF icon if not loading -->
                                <ng-template #pdfIcon>
                                  <i class="fas fa-file-pdf"></i>
                                </ng-template>
                              </span>
                            </span>
                          </ng-container>
                          <ng-container *ngIf="row.shippingType == 'post' && row.shippedDate">
                            <span class="date-label">
                              ({{ row.shippedDate | amDateFormat: 'DD.MM.YYYY' }} -
                              {{ row.shippedDate | amDateFormat: 'HH:mm' }})
                            </span>
                          </ng-container>
                          <br />
                          <br />
                          <a href="mailto:{{ row.email }}">{{ row.name }}</a>
                          <ng-container>
                            <i
                              (click)="showAddress($event, row)"
                              ngbTooltip="Details"
                              class="fa fa-info-circle details-icon"
                            ></i>
                          </ng-container> </span
                        ><br /><br />
                        <span *ngIf="row.status === 2" class="text-danger">storniert</span>
                        <span *ngIf="row.status !== 2" class="action-btn">
                          <button
                            (click)="voucherService.downloadReceipt(row)"
                            class="btn btn-small btn-light"
                            ngbTooltip="Quittung herunterladen"
                          >
                            <i class="fas fa-file-download"></i>
                            PDF</button
                          ><button
                            (click)="voucherService.sendReceipt(row)"
                            class="btn btn-small btn-primary"
                            ngbTooltip="Quittung senden"
                          >
                            <i class="fas fa-envelope-open-text"></i>
                          </button>
                          <button
                            *ngIf="row.totalTemplate > 0"
                            (click)="sendVoucherPdf(row)"
                            class="btn btn-small btn-primary"
                            ngbTooltip="Gutscheine versenden"
                          >
                            <i class="fas fa-envelope-open"></i>
                          </button>
                          <button
                            *ngIf="row.shippingType == 'post' && !row.shippedDate"
                            (click)="setShippingDate(row)"
                            class="btn btn-small btn-primary"
                            ngbTooltip="Versendet"
                          >
                            <i class="fa fa-paper-plane"></i>
                          </button>
                          <button
                            (click)="cancelVoucher(row)"
                            class="btn btn-small btn-danger"
                            ngbTooltip="Stornieren"
                            [disabled]="row.status === 2"
                          >
                            <i class="fa fa-times"></i>
                          </button>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { ReservationsListComponent } from '@app/reservation/reservations-list/reservations-list.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { MomentModule } from 'ngx-moment';
import { DateFnsModule } from 'ngx-date-fns';
import { MatTooltipModule, MatChipsModule, MatProgressSpinnerModule, MatSlideToggleModule } from '@angular/material';
import { CheckinsLabelPipe } from '@app/checkins-label.pipe';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxDatatableModule,
    NgbModule,
    SharedModule,
    MatChipsModule,
    MomentModule,
    DateFnsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule
  ],
  declarations: [ReservationsListComponent, CheckinsLabelPipe],
  exports: [ReservationsListComponent, CheckinsLabelPipe]
})
export class ReservationsListModule {}

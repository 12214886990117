import { ChangeDetectorRef, Component } from '@angular/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SatCalendar, SatCalendarFooter, SatDatepicker } from 'saturn-datepicker';
import { DateAdapter } from 'saturn-datepicker';

@Component({
  templateUrl: './ranges-footer.component.html'
})
export class RangesFooterComponent<Date> implements SatCalendarFooter<Date> {
  public ranges: Array<{ key: string; label: string }> = [
    { key: 'last7days', label: 'Letzten 7 Tage' },
    { key: 'thisMonth', label: 'Dieser Monat' },
    { key: 'lastMonth', label: 'Letzter Monat' },
    { key: 'thisYear', label: 'Dieses Jahr' }
  ];
  private destroyed = new Subject<void>();

  constructor(
    private calendar: SatCalendar<Date>,
    private datePicker: SatDatepicker<Date>,
    private dateAdapter: DateAdapter<Date>,
    cdr: ChangeDetectorRef
  ) {
    calendar.stateChanges.pipe(takeUntil(this.destroyed)).subscribe(() => cdr.markForCheck());
  }

  setRange(range: string) {
    const today = moment();
    switch (range) {
      case 'today':
        this.calendar.beginDate = this.dateAdapter.deserialize(new Date());
        this.calendar.endDate = this.dateAdapter.deserialize(new Date());
        this.calendar.activeDate = this.calendar.beginDate;
        break;
      case 'thisWeek':
        this.calendar.beginDate = this.dateAdapter.deserialize(
          moment()
            .weekday(0)
            .toDate()
        );
        this.calendar.endDate = this.dateAdapter.deserialize(
          moment()
            .weekday(6)
            .toDate()
        );
        break;
      case 'last7days':
        const sevenDaysAgo = moment().subtract(7, 'days');
        this.calendar.beginDate = this.dateAdapter.deserialize(sevenDaysAgo.toDate());
        this.calendar.endDate = this.dateAdapter.deserialize(today.toDate());
        break;
      case 'thisMonth':
        this.calendar.beginDate = this.dateAdapter.deserialize(
          moment()
            .startOf('month')
            .toDate()
        );
        this.calendar.endDate = this.dateAdapter.deserialize(
          moment()
            .endOf('month')
            .toDate()
        );
        break;
      case 'lastMonth':
        this.calendar.beginDate = this.dateAdapter.deserialize(
          moment()
            .subtract(1, 'months')
            .startOf('month')
            .toDate()
        );
        this.calendar.endDate = this.dateAdapter.deserialize(
          moment()
            .subtract(1, 'months')
            .endOf('month')
            .toDate()
        );
        break;
      case 'thisYear':
        this.calendar.beginDate = this.dateAdapter.deserialize(
          moment()
            .startOf('year')
            .toDate()
        );
        this.calendar.endDate = this.dateAdapter.deserialize(
          moment()
            .endOf('year')
            .toDate()
        );
        break;
    }
    this.calendar.activeDate = this.calendar.beginDate;
    this.calendar.beginDateSelectedChange.emit(this.calendar.beginDate);
    this.calendar.dateRangesChange.emit({ begin: this.calendar.beginDate, end: this.calendar.endDate });
    this.datePicker.close();
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lang-selector-backoffice',
  templateUrl: './lang-selector-backoffice.component.html',
  styleUrls: ['./lang-selector-backoffice.component.scss']
})
export class LangSelectorBackofficeComponent implements OnInit {
  lang: boolean = false; // false for 'de', true for 'en'

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    const browserLang = localStorage.getItem('browserLang') || 'de';
    this.lang = browserLang === 'de' ? false : true;
    this.useLang(browserLang);
    localStorage.setItem('browserLang', browserLang);
  }

  changeLang(event: any) {
    const browserLang = event.checked ? 'en' : 'de';
    this.useLang(browserLang);
    localStorage.setItem('browserLang', browserLang);
  }

  useLang(lang: any) {
    if (lang == 'de' && false) {
      this.translate.use('de-DE');
    } else {
      this.translate.use(lang);
    }
  }
}

import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({
  name: 'encodeURI'
})
export class EncodeURIPipe implements PipeTransform {
  transform(input: any) {
    if (typeof input !== 'string') {
      return input;
    }

    return encodeURI(input);
  }
}

@NgModule({
  declarations: [EncodeURIPipe],
  exports: [EncodeURIPipe]
})
export class NgEncodeURIPipeModule {}

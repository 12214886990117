import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract, AuthenticationGuard } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { ReservationOverviewComponent } from './reservation-overview/reservation-overview.component';
import { ReservationImportComponent } from './reservation-import/reservation-import.component';
import { ReservationSettingsComponent } from './reservation-settings/reservation-settings.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { ReservationUserPageComponent } from './reservation-user-page/reservation-user-page.component';
import { ReservationIntegrationComponent } from './reservation-integration/reservation-integration.component';
import { ReservationAlertsComponent } from './alerts/reservation-alerts.component';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { PendingReservationsComponent } from './reservations/pending-reservations/pending-reservations.component';
import { GuestComponent } from './guest/guest.component';
import { HotelReservationComponent } from './hotel-reservation/hotel-reservation.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ReservationsStatisticsComponent } from './reservations-statistics/reservations-statistics.component';
import { ExportComponent } from './export/export.component';
import { MyGuestsComponent } from './my-guests/my-guests.component';
import { ReservationLogsComponent } from './reservation-logs/reservation-logs.component';
import { ReservationTableSettingsComponent } from './reservation-table-settings/reservation-table-settings.component';
import { SMSServiceConfirmationComponent } from './sms-service-confirmation/sms-service-confirmation.component';
import { ReservationDemoComponent } from './reservation-demo/reservation-demo.component';
import { PhoneAssistantComponent } from './phone-assistant/phone-assistant.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/reservation',
      data: {
        breadcrumb: 'Reservierungs-System'
      },
      resolve: {
        someKey: ClientResolverService
      },
      children: [
        {
          path: '',
          redirectTo: 'overview',
          pathMatch: 'full'
        },
        {
          path: 'import',
          component: ReservationImportComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Importieren',
            breadcrumb: 'Importieren',
            permissions: {
              only: ['admin', 'addReservation'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'overview',
          component: ReservationOverviewComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Übersicht',
            breadcrumb: 'Übersicht',
            permissions: {
              only: ['admin', 'listReservations'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'settings',
          component: ReservationSettingsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Einstellungen',
            breadcrumb: 'Einstellungen',
            permissions: {
              only: ['admin', 'manageReservationSettings'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'table-settings',
          component: ReservationTableSettingsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Räume und Tische',
            breadcrumb: 'Räume und Tische',
            permissions: {
              only: ['admin', 'manageReservationSettings'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'book/:reservationId',
          component: ReservationsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Reservierungsbuch',
            breadcrumb: 'Reservierungsbuch',
            permissions: {
              only: ['admin', 'listReservations'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'book',
          component: ReservationsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Reservierungsbuch',
            breadcrumb: 'Reservierungsbuch',
            permissions: {
              only: ['admin', 'listReservations'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'transactions',
          component: ExportComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Anzahlungen',
            breadcrumb: 'Anzahlungen',
            permissions: {
              only: ['admin', 'listReservations'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'statistics',
          component: ReservationsStatisticsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Statistiken',
            breadcrumb: 'Statistiken',
            permissions: {
              only: ['admin', 'listReservations'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'alerts',
          component: ReservationAlertsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Hinweise',
            breadcrumb: 'Hinweise',
            permissions: {
              only: ['admin', 'manageReservationAlerts'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'integration',
          component: ReservationIntegrationComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Integration',
            breadcrumb: 'Integration',
            permissions: {
              only: ['admin', 'viewWidgets'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'pending',
          component: PendingReservationsComponent,
          data: {
            title: 'Ausstehende Reservierungen',
            breadcrumb: 'Ausstehende Reservierungen'
          }
        },
        {
          path: 'guest/:guestId',
          loadChildren: () => import('./guest/guest.module').then(m => m.GuestModule),
          data: {
            title: extract('Gast-Daten'),
            breadcrumb: 'Gast-Daten'
          }
        },
        {
          path: 'hotel',
          component: HotelReservationComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Hotelreservierungen',
            breadcrumb: 'Hotelreservierungen',
            permissions: {
              only: ['admin', 'createHotelReservation'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'my-guests',
          component: MyGuestsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Meine Gäste',
            breadcrumb: 'Meine Gäste',
            permissions: {
              only: ['admin', 'manageGuests', 'addEditGuests'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'logs',
          component: ReservationLogsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Logfiles',
            breadcrumb: 'Logfiles',
            permissions: {
              only: ['admin', 'manageReservationLogs'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'sms-service-confirmation',
          component: SMSServiceConfirmationComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'SMS Service Confirmation',
            breadcrumb: 'SMS Service Confirmation',
            permissions: {
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'demo',
          component: ReservationDemoComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Reservierung',
            breadcrumb: 'Reservierung',
            permissions: {
              only: ['admin', 'listReservations'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'phone-assistant',
          component: PhoneAssistantComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Telefonassistent',
            breadcrumb: 'Telefonassistent',
            permissions: {
              only: ['admin'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        }
      ]
    }
  ]),
  {
    path: 'user/reservation/:token',
    component: ReservationUserPageComponent,
    data: {
      title: 'Deine Reservierung'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ReservationRoutingModule {}

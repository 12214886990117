/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./timer.component";
var styles_TimerComponent = [i0.styles];
var RenderType_TimerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimerComponent, data: {} });
export { RenderType_TimerComponent as RenderType_TimerComponent };
export function View_TimerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "timer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("widget.reservation.timeLeftForPayment")); var currVal_1 = _co.formattedTime; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_TimerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timer", [], null, null, null, View_TimerComponent_0, RenderType_TimerComponent)), i1.ɵdid(1, 770048, null, 0, i3.TimerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimerComponentNgFactory = i1.ɵccf("app-timer", i3.TimerComponent, View_TimerComponent_Host_0, { initialTime: "initialTime" }, {}, []);
export { TimerComponentNgFactory as TimerComponentNgFactory };

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotelReservationComponent } from './hotel-reservation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule, MatDatepickerModule, MatAutocompleteModule } from '@angular/material';

import { SharedModule } from '@app/shared';
import { NgSelectModule } from '@ng-select/ng-select';
import { GeneratePdfReservationComponent } from './generate-pdf-reservation/generate-pdf-reservation.component';

@NgModule({
  declarations: [HotelReservationComponent, GeneratePdfReservationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    SharedModule,
    NgSelectModule,
    MatAutocompleteModule
  ]
})
export class HotelReservationModule {}

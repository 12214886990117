import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { PaymentRequest } from './payment.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  createPayment(payment: PaymentRequest) {
    return this.http.post<any>(environment.serverUrl + `public/payment-request/`, payment).pipe(
      map((response: any) => {
        console.log('Payment Response => ', response);
        return response;
      })
    );
  }
}

import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { ReservationService } from '@app/reservation/reservation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-payment-template',
  templateUrl: './add-payment-template.component.html',
  styleUrls: ['./add-payment-template.component.scss']
})
export class AddPaymentTemplateComponent implements OnInit, OnDestroy {
  @Input() public editPaymentTemplateData: any; // only if editing
  @Input() public clientData: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  paymentTemplateFormGroup: FormGroup;
  error: string;
  addObservable: any;
  editObservable: any;
  deleteObservable: any;
  paymentCancelTimes: any = [];
  defaultInfoText: string;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private reservationService: ReservationService,
    private snackBar: MatSnackBar,
    public formBuilder: FormBuilder
  ) {
    this.paymentCancelTimes = Array.from(Array.from(Array(Math.ceil((73 - 1) / 1)).keys()), x => 1 + x * 1);
  }

  ngOnInit() {
    this.paymentTemplateFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      minGuests: [1, Validators.required],
      priceOption: ['guest', Validators.required],
      price: [0, Validators.required],
      cancelTime: [1, Validators.required],
      infoText: ['']
    });
    this.paymentInfoTemplate('guest');
    if (this.editPaymentTemplateData) {
      this.paymentTemplateFormGroup.addControl(
        'id',
        new FormControl(this.editPaymentTemplateData.id, Validators.required)
      );
      this.paymentTemplateFormGroup.get('name').setValue(this.editPaymentTemplateData.name);
      this.paymentTemplateFormGroup.get('minGuests').setValue(this.editPaymentTemplateData.minGuests);
      this.paymentTemplateFormGroup.get('priceOption').setValue(this.editPaymentTemplateData.priceOption);
      this.paymentTemplateFormGroup.get('price').setValue(this.editPaymentTemplateData.price);
      this.paymentTemplateFormGroup.get('cancelTime').setValue(this.editPaymentTemplateData.cancelTime);
      this.paymentTemplateFormGroup.get('infoText').setValue(this.editPaymentTemplateData.infoText);
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  minGuestsChanged(event: any) {
    this.paymentTemplateFormGroup.get('minGuests').setValue(event);
  }

  add() {
    if (!this.paymentTemplateFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    this.addObservable = this.reservationService
      .addPaymentTemplate(this.paymentTemplateFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }

  save() {
    if (!this.paymentTemplateFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    this.editObservable = this.reservationService
      .editPaymentTemplate(this.paymentTemplateFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          console.log('err', err);
          this.error = err.error.msg;
        }
      );
  }

  delete() {
    this.deleteObservable = this.reservationService
      .deletePaymentTemplate(this.paymentTemplateFormGroup.value.id)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((response: any) => {
        const snackBarRef = this.snackBar.open('Anzahlungsvorlage erfolgreich gelöscht', '', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        this.passEntry.emit('delete');
      });
  }

  addVariable(variable: string) {}

  paymentInfoTemplate(event: any) {
    let isPerPerson = event == 'guest' ? '(pro Person)' : '';
    if (this.clientData.pronominalForm && this.clientData.pronominalForm == 'sie') {
      this.defaultInfoText = `Diese Reservierung benötigt eine Anzahlung von [betrag] EUR ${isPerPerson} um bestätigt zu werden. Nach dem Abschluss der Reservierung haben Sie die Möglichkeit diese per Kreditkarte, Paypal oder SofortÜberweisung zu bezahlen.`;
    } else {
      this.defaultInfoText = `Diese Reservierung benötigt eine Anzahlung von [betrag] EUR ${isPerPerson} um bestätigt zu werden. Nach dem Abschluss der Reservierung hast du die Möglichkeit diese per Kreditkarte, Paypal oder SofortÜberweisung zu bezahlen.`;
    }
    this.paymentTemplateFormGroup.get('infoText').setValue(this.defaultInfoText);
  }
}

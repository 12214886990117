import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  @Input() public title: string;
  @Input() public message: string;
  @Input() public showInfo = true;
  @Input() public buttonText: String = 'Ja, löschen';
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public modal: NgbActiveModal, public translate: TranslateService) {}

  ngOnInit() {}
}

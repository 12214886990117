import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService, CredentialsService } from '@app/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private credentialsService: CredentialsService
  ) {}

  getReviews() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/${this.authService.activeClientId}/reviews`, {
        headers: headers
      })
      .pipe(
        map((event: any) => {
          return event;
        })
      );
  }

  getComments(params: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/${this.authService.activeClientId}/comments`, {
        headers: headers,
        params: params
      })
      .pipe(
        map((event: any) => {
          return event;
        })
      );
  }

  likeUnlikeReview(memberId: number, reviewId: number, action: string) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    const data = { memberId, reviewId };

    return this.http
      .post<any>(environment.serverUrl + `client/${this.authService.activeClientId}/review/${action}`, data, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getLikes(memberId: number, thing: string) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    const params: any = { memberId, thing };

    return this.http
      .get<any>(environment.serverUrl + `client/${this.authService.activeClientId}/likes`, {
        headers: headers,
        params: params
      })
      .pipe(
        map((likes: any) => {
          return likes;
        })
      );
  }

  likeUnlikeWellWritten(memberId: number, reviewId: number, action: string) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    const data = { memberId, reviewId };

    return this.http
      .post<any>(
        environment.serverUrl + `client/${this.authService.activeClientId}/review-well-written/${action}`,
        data,
        {
          headers: headers,
          reportProgress: true
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getWellWrittenData(memberId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    const params: any = { memberId };

    return this.http
      .get<any>(environment.serverUrl + `client/${this.authService.activeClientId}/well-written`, {
        headers: headers,
        params: params
      })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  submitReportOnReview(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(environment.serverUrl + `client/${this.authService.activeClientId}/setReportOnReview`, data, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  postComment(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(environment.serverUrl + `client/${this.authService.activeClientId}/comment`, data, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getPhotos(params: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/${this.authService.activeClientId}/reviewPhotos`, {
        headers: headers,
        params: params
      })
      .pipe(
        map((event: any) => {
          return event;
        })
      );
  }

  likeUnlikeComment(memberId: number, reviewId: number, commentId: number, action: string) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    const data = { memberId, reviewId, commentId };

    return this.http
      .post<any>(environment.serverUrl + `client/${this.authService.activeClientId}/comment/${action}`, data, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getMemberInfo(id: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `private/user/${id}`, {
        headers: headers
      })
      .pipe(
        map((event: any) => {
          return event;
        })
      );
  }

  removeComment(commentId: number, memberId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    const data = {
      commentId,
      memberId
    };

    return this.http
      .put<any>(environment.serverUrl + `client/${this.authService.activeClientId}/comment/delete`, data, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateComment(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .put<any>(environment.serverUrl + `client/${this.authService.activeClientId}/comment/update`, data, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}

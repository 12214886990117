import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType, PluginServiceRegistrationOptions } from 'chart.js';
import moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { Subject, range } from 'rxjs';
import { VouchersService } from '../vouchers.service';
moment.locale('de');

@Component({
  selector: 'app-voucher-statistics',
  templateUrl: './voucher-statistics.component.html',
  styleUrls: ['./voucher-statistics.component.scss']
})
export class VoucherStatisticsComponent implements OnInit {
  public hours: number = 24 * 30;

  public showChartValue: string = 'reservation';
  public showChartType: string = 'last30days';
  public loading: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();
  vouchers: any[] = [];
  vouchersForDoughnut: any[] = [];
  vouchersValidity: any[] = [];
  tab: any;

  public barChartOptions: ChartOptions = {
    responsive: true
  };

  public barChartOptions2: ChartOptions = {
    responsive: true
  };

  public barChartData2: ChartDataSets[] = [
    {
      data: [],
      label: ''
    }
  ];
  public barChartType2 = 'horizontalBar';
  public barChartLabels2: Label[] = [];
  public barChartColors2: Color[] = [];

  public barChartLabels: Label[] = [];
  public doughnutChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public doughnutChartType: ChartType = 'doughnut';
  public barChartLegend: boolean = true;
  public barChartLegend2: boolean = false;
  public barChartPlugins: any[] = [];
  public barChartData: ChartDataSets[] = [
    {
      data: [],
      label: ''
    }
  ];

  public doughnutChartData: ChartDataSets[] = [
    {
      data: [],
      label: ''
    }
  ];

  public doughnutChartOptions: ChartOptions = {
    responsive: true
  };

  public barChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0, 150, 136, 0.7)' //primary green
    },
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255, 165, 0, 0.7)' //orange
    },
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0, 0, 0, 0.5)' //black
    },
    {
      borderColor: 'black',
      backgroundColor: 'rgb(157,216,102)' //light green
    }
  ];
  showChart: boolean = false;
  showDoughnutChart: boolean = false;
  dates: any = {
    from: '',
    to: ''
  };
  doughnutChartColors: Color[];
  doughnutChartPlugins: PluginServiceRegistrationOptions = {};
  filterStats: any = {
    orders: {
      count: 0,
      total: 0
    },
    cancelations: {
      count: 0,
      total: 0
    }
  };
  finalOrders: any = [];

  constructor(private voucherService: VouchersService) {}

  ngOnInit() {
    this.setDates(this.showChartType);
  }

  public setDates(type: string) {
    this.showChart = false;
    this.showDoughnutChart = false;
    this.barChartData = [];
    this.vouchers = [];
    this.vouchersForDoughnut = [];
    this.showChartType = type;

    if (type == 'next30days') {
      this.dates.from = moment();
      this.dates.to = moment(this.dates.from).add(30, 'days');
      this.hours = 24 * 30;
    }
    if (type == 'last30days') {
      this.dates.from = moment().subtract(30, 'days');
      this.dates.to = moment();
      this.hours = 24 * 30;
    }
    if (type == 'last13months') {
      this.dates.to = moment().endOf('month');
      this.dates.from = this.dates.to
        .clone()
        .subtract(12, 'months')
        .startOf('month');
      this.hours = 24 * 30;
    }
    if (type == 'last25months') {
      this.dates.to = moment().endOf('month');
      // this.dates.from = moment().subtract(25, 'months');
      this.dates.from = this.dates.to
        .clone()
        .subtract(24, 'months')
        .startOf('month');
      this.hours = 24 * 30;
    }
    this.getVoucherStat(true);
    this.getVoucherStatDoughnut(true);
    this.getVouchersOrder();
  }

  async getVoucherStat(type: boolean) {
    this.loading = true;
    if (!type) {
      this.showChartType = 'CustomYear';
    }
    await this.voucherService
      .getVoucherStatistics(this.dates.from, this.dates.to)
      .toPromise()
      .then((data: any) => {
        this.vouchers = data;
        this.barChartLabels = [];
        let tempBarChartData = {};
        if (this.vouchers && this.vouchers.length > 0) {
          this.vouchers.forEach(voucher => {
            let addValue = 0;
            if (voucher.transValue) {
              addValue = voucher.transValue;
            } else {
              addValue = 0;
            }

            let key = '';

            if (this.showChartType == 'last13months') {
              key =
                moment(voucher.createdAt).format('DD') +
                '.' +
                moment(voucher.createdAt).format('MM') +
                '.' +
                moment(voucher.createdAt).format('YYYY');
            } else if (this.showChartType == 'last30days') {
              key =
                moment(voucher.createdAt).format('DD') +
                '.' +
                moment(voucher.createdAt).format('MM') +
                '.' +
                moment(voucher.createdAt).format('YYYY');
            } else if (this.showChartType == 'last25months' || this.showChartType == 'CustomYear') {
              key = moment(voucher.createdAt).format('MM') + '.' + moment(voucher.createdAt).format('YYYY');
            }

            if (voucher.transType && voucher.transType === 'create' && voucher.transReferrer === 'gg') {
              if (!this.barChartLabels.includes(key)) {
                this.barChartLabels.push(key);
              }
              if (tempBarChartData[key]) {
                tempBarChartData[key] = tempBarChartData[key] + addValue;
              } else {
                tempBarChartData[key] = addValue;
              }
            }
          }, this);

          let tempData = [];
          tempData = Object.values(tempBarChartData);
          tempData.forEach((element: number) => {
            element = Math.round(element);
          });

          tempData = tempData.map((num: any) => parseFloat(num.toFixed(2)));

          this.barChartData = [
            {
              data: tempData, // Online
              label: 'Online',
              stack: '1'
            }
            // {
            //   data: tempData2, // POS
            //   label: 'POS',
            //   stack: '1'
            // }
          ];

          console.log('this.barChartData[0].data.length ===>', this.barChartData[0].data.length);

          console.log(this.barChartData);

          if (this.barChartData[0].data.length > 0) {
            this.showChart = true;
            this.loading = false;
          } else {
            this.showChart = false;
            this.loading = false;
          }
        } else {
          this.vouchers = [];
          this.barChartData = [];
          this.loading = false;
          this.showChart = false;
        }
      })
      .catch(err => {
        this.loading = false;
        this.showChart = false;
        this.barChartData = [];
        this.vouchers = [];
      });
  }

  async getVoucherStatDoughnut(type: boolean) {
    this.loading = true;
    if (!type) {
      this.showChartType = 'CustomYear';
    }
    await this.voucherService
      .getVoucherStatDoughnut(this.dates.from, this.dates.to)
      .toPromise()
      .then((data: any) => {
        this.vouchersForDoughnut = data;

        this.vouchersForDoughnut.forEach((item: any) => {
          item['color'] = this.getRandomColor();
        });
        const filteredData = this.vouchersForDoughnut.filter(
          item => item.boughtAt && item.status === 1 && item.type === 'gg'
        );
        this.createHorizontalBarGraph(filteredData);

        const groupedData = filteredData.reduce((acc, item) => {
          const value = item.value;
          let range: string;

          if (value < 10) {
            range = '0-09';
          } else if (value <= 25) {
            range = '10-25';
          } else if (value <= 50) {
            range = '26-50';
          } else if (value <= 100) {
            range = '51-100';
          } else {
            range = '> 100';
          }

          if (!acc[range]) {
            acc[range] = { value: value, count: 1, color: item.color };
          } else {
            acc[range].count += 1;
          }
          return acc;
        }, {});

        // Sort the ranges to ensure they are from low to high
        const sortedRanges = Object.keys(groupedData).sort((a, b) => {
          const getValue = (range: string) => {
            if (range === '100+') return 100;
            const [min, max] = range.split('-').map(Number);
            return (min + max) / 2;
          };
          return getValue(a) - getValue(b);
        });
        const values = Object.keys(sortedRanges).map(key => parseFloat(key));

        const counts = Object.values(sortedRanges).map(range => groupedData[range].count);
        // const colors = Object.values(sortedRanges).map(range => groupedData[range].color);

        this.doughnutChartLabels = sortedRanges.map(value => `${value}`);

        // Set chart data
        this.doughnutChartData = [
          {
            data: counts
          }
        ];

        // Set chart colors
        this.doughnutChartColors = [
          {
            backgroundColor: ['#00c3eb', '#008eb3', '#01ae7d', '#fdd11b', '#f2215a']
          }
        ];

        this.doughnutChartOptions = {
          responsive: true,
          tooltips: {
            callbacks: {
              label: (tooltipItem: any, data: any) => {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const count = dataset.data[tooltipItem.index];
                const value = values[tooltipItem.index];
                const range = sortedRanges[tooltipItem.index];
                const total = dataset.data.reduce((acc: any, curr: any) => acc + curr, 0);
                const percentage = ((count / total) * 100).toFixed(2) + '%';
                return `Gutschein Statistiken : ${range} EUR ( ${percentage} )`;
              }
            }
          }
        };

        if (this.doughnutChartData[0].data.length > 0) {
          this.showDoughnutChart = true;
          this.loading = false;
        } else {
          this.showDoughnutChart = false;
          this.loading = false;
        }
      })
      .catch(err => {
        this.loading = false;
        this.showDoughnutChart = false;
        this.doughnutChartData = [];
        this.vouchersForDoughnut = [];
      });
  }

  getVoucherOnclick() {
    this.getVoucherStat(false);
    this.getVoucherStatDoughnut(false);
  }

  getChartColors(filteredData: any): Color[] {
    return [
      {
        backgroundColor: filteredData.map((item: any) => item.color)
      }
    ];
  }

  createHorizontalBarGraph(vouchersForDoughnut: any) {
    this.barChartLabels2 = [];
    const groupedData = vouchersForDoughnut.reduce((acc: any, item: any) => {
      const value = parseFloat(item.value);
      if (acc[value]) {
        acc[value].count++;
      } else {
        acc[value] = { count: 1, color: item.color };
      }
      return acc;
    }, {});

    this.barChartLabels2 = Object.keys(groupedData)
      .map(Number)
      .map(value => value.toString());
    this.barChartColors2 = [{ backgroundColor: Object.values(groupedData).map((item: any) => item.color) }];
    this.barChartData2 = [{ data: Object.values(groupedData).map((item: any) => item.count) }];

    let setAutoSkip = false;
    if (this.barChartLabels2.length > 25) {
      setAutoSkip = true;
    }

    if (this.barChartLabels2) {
      this.barChartOptions2 = {
        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                stepSize: 1
              }
            }
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                stepSize: 1,
                autoSkip: setAutoSkip
              }
            }
          ]
        }
      };
    }
  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  async getVoucherValidity() {
    this.loading = true;
    await this.voucherService
      .getVoucherValidity()
      .toPromise()
      .then((data: any) => {
        this.loading = false;
        this.vouchersValidity = data;
      })
      .catch(err => {
        console.log('err', err);
        this.vouchersValidity = [];
      });
  }
  selectedTabChange(e: any) {
    if (e.index === 1) {
      this.getVoucherValidity();
    } else {
      this.ngOnInit();
    }
  }

  getVouchersOrder() {
    this.voucherService.getVoucherOrders(this.dates.from, this.dates.to).subscribe(data => {
      const result = data;
      let finalResult: any[] = [];
      let i = 0;
      this.filterStats.orders.total = 0;
      this.filterStats.orders.count = 0;
      this.filterStats.cancelations.total = 0;
      this.filterStats.cancelations.count = 0;
      result.map((item: any, key: any) => {
        let isPresent = false;
        if (finalResult) {
          isPresent = finalResult.some(function(el: any) {
            return el.id === item.id;
          });
        }
        if (!isPresent) {
          const templates: any[] = [];
          let j = 0;
          let totalTemplate = 0;
          result.map(function(data: any, key1: any) {
            if (data.vouchers && data.vouchers.orderId == item.id) {
              if (data.vouchers.templateFileName) {
                totalTemplate++;
              }
              templates[j] = data.vouchers;
              j++;
            }
          });
          if (result[key] !== undefined && templates) {
            result[key].templates = templates;
            result[key].totalTemplate = totalTemplate;
            finalResult[i] = result[key];
            i++;
          }
        }
      });

      for (let item of finalResult) {
        if (item.status === 2) {
          // this.filterStats.cancelations.total += item.total / item.quantity;
          this.filterStats.cancelations.total += parseFloat(item.total);
          this.filterStats.cancelations.count += 1;
        } else if (item.status === 0) {
          // this.filterStats.orders.total += item.total / item.quantity;
          this.filterStats.orders.total += parseFloat(item.total);
          this.filterStats.orders.count += 1;
        }
      }
      this.finalOrders = finalResult;
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import {
  MatIconModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatNativeDateModule
} from '@angular/material';
import { DateFnsModule } from 'ngx-date-fns';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MomentModule } from 'ngx-moment';
import { VouchersRoutingModule } from '../vouchers-routing.module';
import { VouchersPoolComponent } from './vouchers-pool.component';
import { FormsModule } from '@angular/forms';
import { SatDatepickerModule } from 'saturn-datepicker';
import { RangesFooterComponent } from '@app/shared/ranges-footer/ranges-footer.component';
import { RangesFooterNewComponent } from '@app/shared/ranges-footer-new/ranges-footer-new.component';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    VouchersRoutingModule,
    MatIconModule,
    NgScrollbarModule,
    NgxDatatableModule,
    MatTooltipModule,
    MomentModule,
    MatDatepickerModule,
    FormsModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    SatDatepickerModule,
    DateFnsModule.forRoot()
  ],
  declarations: [VouchersPoolComponent],
  entryComponents: [RangesFooterComponent, RangesFooterNewComponent]
})
export class VouchersPoolModule {}

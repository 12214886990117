import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { NavService } from '@app/nav.service';
import { CheckboxRendererComponent } from './renderers/checkbox-renderer.component';
import { LicenseManager } from 'ag-grid-enterprise';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { Subject } from 'rxjs';
import { NoEditorComponent } from './renderers/no-editor.component';
import { ToggleRendererComponent } from './renderers/toggle-renderer.component';
import { PhotoRendererComponent } from './renderers/photo-renderer.component';
import { NgSelectRendererComponent } from './renderers/ng-select-renderer.component';
import { TagsRendererComponent } from './renderers/tags-renderer.component';
import { ClientSideRowModelModule } from '@ag-grid-enterprise/all-modules';
import { ReservationService } from '../reservation.service';
import { CredentialsService } from '@app/core';
// import { AddTableComponent } from '../reservation-settings/add-table/add-table.component';
import { AddTableComponent } from './add-table/add-table.component';
import { AddRoomComponent } from './add-room/add-room.component';
import { TablePlanComponent } from '../reservation-settings/table-plan/table-plan.component';
import { Room, RoomTable, ReservationBookSettings } from '../reservation-settings/reservation-settings.model';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

declare var AG_GRID_LOCALE_DE: any;

@Component({
  selector: 'app-reservation-table-settings',
  templateUrl: './reservation-table-settings.component.html',
  styleUrls: ['./reservation-table-settings.component.scss']
})
export class ReservationTableSettingsComponent implements OnInit {
  activeClient: any;
  @ViewChild('contentBoxEl', { static: false }) contentBoxEl: ElementRef;
  @ViewChild(TablePlanComponent, { static: false }) tablePlan: TablePlanComponent;
  itemIndex = 0;
  items: any;
  frameworkComponents = {
    toggleRenderer: ToggleRendererComponent,
    checkboxRenderer: CheckboxRendererComponent,
    photoRenderer: PhotoRendererComponent,
    noEditor: NoEditorComponent,
    ngSelectEditor: NgSelectRendererComponent,
    tagsRenderer: TagsRendererComponent
  };
  modules: any[] = [ClientSideRowModelModule, RangeSelectionModule];
  gridLocale = AG_GRID_LOCALE_DE;
  gridOptions: any = {};
  sideBar: any;
  uploadQueue: any = [];
  rangeSelectionMode = false;
  roomList: any = [];
  tableList: any = [];
  filteredRooms: any = [];
  alert: string =
    'Auf dieser Seite können Sie durch die Sortierung der Tische die Priorisierung für die Online-Reservierung vornehmen. Die Tabelle hat die gleiche Funktionalität wie Excel (kopieren, einfügen, löschen) mit rechter und linker Maustaste (nicht am Handy oder Tablet möglich)';
  reservationBook: ReservationBookSettings = {
    timeInterval: 15,
    defaultStaytime: 60,
    requestStaff: false,
    confirmStatusChange: false,
    arrivedStatus: true,
    waitingStatus: false,
    showStatus: false,
    showWalkIns: true,
    startTime: 10,
    endTime: 24,
    showOccupancy: false,
    showTablePlan: false,
    includeShift: 0
  };
  tab: any;
  rooms: any;
  columnDefs: any = [];
  defaultColDef = {
    lockPosition: true,
    suppressMenu: true,
    enablePivot: false,
    sortable: true,
    comparator: this.columnSorting
  };
  rowSelection = 'multiple';
  combinationTables = 0;
  private gridApi: any;
  private gridColumnApi: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  isVisible: boolean = true;

  constructor(
    private snackBar: MatSnackBar,
    private nav: NavService,
    private clientResolver: ClientResolverService,
    private modalService: NgbModal,
    private reservationService: ReservationService,
    public credentialsService: CredentialsService,
    private activatedRoute: ActivatedRoute,
    private route: Router
  ) {
    this.activeClient = this.clientResolver.client;
    LicenseManager.setLicenseKey('your-license-key'); // We need this line to trigger enterprise mode

    this.sideBar = {
      position: 'left',
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true
          }
        }
      ]
    };
  }

  ngOnInit() {
    // Get query params for tab change
    this.activatedRoute.queryParams.takeUntil(this.ngUnsubscribe).subscribe(params => {
      if (params.tab) {
        this.tab = params.tab;
        this.route.navigate([], {
          queryParams: {
            tab: null
          },
          queryParamsHandling: 'preserve'
        });
      }
    });
    this.getSettings();
    this.getfilteredRooms();
    this.getRooms();
    this.getReservationBookSettings();
    // this.nav.closeNav();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridOptions.getRowNodeId = function(data: any) {
      return data.index;
    };
    this.getTables();
  }

  getRooms() {
    this.reservationService
      .getRooms()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((rooms: any) => {
        this.rooms = rooms;
      });
  }

  getfilteredRooms() {
    this.reservationService
      .getRooms(false)
      .toPromise()
      .then((filteredRooms: any) => {
        this.filteredRooms = filteredRooms;
        this.roomList = filteredRooms.map((room: any) => {
          return room.name;
        });
      });
  }
  getTables() {
    this.itemIndex = 0;
    this.items = [];
    this.tableList = [];
    this.reservationService
      .getAllTables()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((tables: any) => {
        this.items = tables.map((i: any) => {
          this.tableList.push(i.name);
          i = this.createTableArray(i, this.itemIndex);
          this.itemIndex += 1;
          return i;
        });
        this.tableList.sort();
        this.tableList.unshift(' ');
        console.log('allTables', this.itemIndex, this.items);

        for (let index = 0; index < 20; index++) {
          this.addEmptyRow();
        }
        this.columnDefs = [
          {
            rowDrag: true,
            rowDragText: (params: any) => {
              return (params.rowNode.data.id ? params.rowNode.data.id + '. ' : '') + params.rowNode.data.name;
            },
            field: 'isActive',
            headerName: 'Active',
            cellRenderer: 'toggleRenderer',
            width: 100,
            headerTooltip: 'Tisch ist für online Reservierung frei gegeben',
            lockPosition: true,
            floatingFilter: true,
            editable: true,
            cellEditor: 'noEditor',
            sortable: false
          },
          {
            field: 'sortTable',
            headerName: 'Priority',
            width: 70,
            headerTooltip: 'Priority',
            editable: false,
            cellStyle: { textAlign: 'center' }
          },
          {
            field: 'name',
            headerName: 'Name',
            editable: true,
            minWidth: 200,
            headerTooltip: 'Name',
            filter: 'agTextColumnFilter',
            floatingFilter: true
          },
          {
            field: 'roomName',
            headerName: 'Raum',
            cellEditor: 'agSelectCellEditor',
            editable: true,
            width: 200,
            headerTooltip: 'Raum',
            cellEditorParams: {
              values: this.roomList
            },
            sortable: false,
            filter: 'agSetColumnFilter',
            floatingFilter: true,
            filterParams: {
              suppressMiniFilter: true
            }
          },
          {
            field: 'pairedTable',
            headerName: 'Tischpaar',
            cellEditor: 'agSelectCellEditor',
            editable: true,
            width: 200,
            headerTooltip: 'Tischpaar',
            cellEditorParams: {
              values: this.tableList
            },
            sortable: false,
            filter: 'agSetColumnFilter',
            floatingFilter: true,
            filterParams: {
              suppressMiniFilter: true
            }
          },
          {
            field: 'seats',
            headerName: 'Plätze',
            width: 70,
            headerTooltip: 'Plätze',
            editable: true,
            cellStyle: { textAlign: 'center' }
          },
          {
            field: 'minOccupancy',
            headerName: 'Minimum',
            width: 70,
            headerTooltip: 'Minimum',
            editable: true,
            cellStyle: { textAlign: 'center' }
          }
        ];
        if (
          this.credentialsService &&
          this.credentialsService.credentials &&
          this.credentialsService.credentials.role === 'admin'
        ) {
          const extraGastroField = [
            {
              field: 'POSName',
              headerName: 'POS-Name',
              editable: true,
              minWidth: 100,
              headerTooltip: 'POS-Name',
              filter: 'agTextColumnFilter',
              floatingFilter: true
            },
            {
              field: 'selfOrdering',
              headerName: 'Self Ordering',
              cellRenderer: 'checkboxRenderer',
              width: 80,
              headerTooltip: 'Self Ordering',
              editable: true,
              cellStyle: { textAlign: 'center' },
              cellEditor: 'noEditor'
            },
            {
              field: 'instantPayment',
              headerName: 'Instant Payment',
              cellRenderer: 'checkboxRenderer',
              width: 80,
              headerTooltip: 'Der Gast muss an diesem Tisch direkt online bezahlen',
              editable: true,
              cellStyle: { textAlign: 'center' },
              cellEditor: 'noEditor'
            },
            {
              field: 'bringService',
              headerName: 'Service',
              cellRenderer: 'checkboxRenderer',
              width: 80,
              headerTooltip: 'Service',
              editable: true,
              cellStyle: { textAlign: 'center' },
              cellEditor: 'noEditor'
            }
          ];
          this.columnDefs = this.columnDefs.concat(extraGastroField);
          this.gridApi.setColumnDefs(this.columnDefs);
        }
      });
  }

  addEmptyRow() {
    const defaultObj: any = {
      roomId: null,
      pairedTableId: null,
      name: null,
      isActive: 1,
      POSName: null,
      selfOrdering: 0,
      instantPayment: 0,
      bringService: 1,
      isCombined: 0,
      seats: 1,
      minOccupancy: 1,
      combinedTables: '',
      sortTable: '',

      id: null,
      clientId: this.activeClient.id,
      sort: 0,
      room: {},

      createdAt: new Date(),
      roomName: '',
      pairedTable: '',
      updatedAt: null,
      index: this.itemIndex
    };
    this.items.push(defaultObj);
    this.itemIndex += 1;
    const res = this.gridApi.applyTransaction({
      add: [defaultObj]
    });
  }

  onCellValueChanged(e: any) {
    const itemData = { ...e.data };
    console.log('value1', itemData.roomId, itemData);
    // If no name is provided, do not submit because name is needed
    if (!itemData.name || !itemData.seats || !itemData.minOccupancy) {
      return;
    }
    if (itemData.roomName) {
      const roomId = this.filteredRooms.find((room: any) => room.name === itemData.roomName);
      itemData.roomId = roomId ? roomId.id : itemData.roomId;
      console.log('value2', itemData.roomId, itemData);
    }
    if (itemData.pairedTable) {
      const pairedTableId = this.items.find((item: any) => item.name === itemData.pairedTable);
      itemData.pairedTableId = pairedTableId ? pairedTableId.id : null;
    }
    console.log('value3', itemData);
    if (!itemData.id || this.uploadQueue.length) {
      // If id is null (new item), remove the id property from the object
      delete itemData.id;

      // If its coming from a paste or a range selection fill, work with a queue because if not multiple items will be fd
      // because agGrid runs this even for each cell
      if (this.rangeSelectionMode || e.source === 'paste') {
        // Add to upload queue
        const queueObject = {
          index: e.rowIndex,
          data: itemData
        };
        const findIndex = this.uploadQueue.findIndex((uq: any) => uq.index === e.rowIndex);
        if (findIndex > -1) {
          this.uploadQueue[findIndex] = queueObject;
        } else {
          this.uploadQueue.push(queueObject);
        }
        return;
      }
    }
    this.gridApi.showLoadingOverlay();
    if (!itemData.id) {
      itemData.sortTable = this.calculateRows().length + 1;
      this.reservationService.addTable(itemData).subscribe((itemRes: any) => {
        // If is a new row (there was no id), add new empty row
        if (!itemData.id) {
          this.addEmptyRow();
        }
        const index = this.items.findIndex((i: any) => i.name === itemData.name);
        if (index > -1) {
          this.items[index].id = itemRes.id;
        }
        itemRes = this.createTableArray(itemRes, index);
        this.gridApi.applyTransaction({ update: [itemRes] });
        this.gridApi.refreshCells({ rowNodes: [itemRes] });
        const snackBarRef = this.snackBar.open('Tisch erfolgreich gespeichert', 'Ok', {
          duration: 3000
        });
      });
    } else {
      this.reservationService.editTable(itemData).subscribe((itemRes: any) => {
        const index = this.items.findIndex((i: any) => i.index === itemData.index);
        if (index > -1) {
          this.items[index].id = itemRes.id;
        }
        itemRes = this.createTableArray(itemRes, index);
        this.gridApi.applyTransaction({ update: [itemRes] });
        this.gridApi.refreshCells({ rowNodes: [itemRes] });
        const snackBarRef = this.snackBar.open('Tisch erfolgreich gespeichert', 'Ok', {
          duration: 3000
        });
      });
    }
  }

  createTableArray(itemRes: any, index: any) {
    itemRes.index = index;
    itemRes.roomName = itemRes.room && itemRes.room.name ? itemRes.room.name : '';
    itemRes.pairedTable = itemRes.pairedTable && itemRes.pairedTable.name ? itemRes.pairedTable.name : '';
    delete itemRes.position;
    delete itemRes.dimensions;
    delete itemRes.type;
    return itemRes;
  }
  onRangeSelectionChanged(e: any) {
    // If range selection started set rangeSelectionMode mode on
    if (e.started && !e.finished) {
      this.rangeSelectionMode = true;
    }

    // If range selection ended, start uploading tables
    if (!e.started && e.finished && this.uploadQueue.length) {
      // Upload rows from queue
      this.processQueue();
      this.rangeSelectionMode = false;
    }
  }

  onPasteEnd(e: any) {
    console.log(e);
    // Upload queue now
    this.processQueue();
  }

  processQueue() {
    this.uploadQueue.forEach((row: any) => {
      row.data.sortTable = row.index + 1;
      row.data.name = row.data.name + '.1';
      this.reservationService.addTable(row.data).subscribe((itemRes: any) => {
        // Remove from Queue
        const queueIndex = this.uploadQueue.findIndex((up: any) => (this.uploadQueue.index = row.index));
        this.uploadQueue.splice(queueIndex, 1);

        // Apply grid transaction to update row ids
        this.items[row.index].id = itemRes.id;

        // Get row node
        let rowNode = this.gridApi.getDisplayedRowAtIndex(row.index);
        rowNode.data.id = itemRes.id;

        rowNode.data = this.createTableArray(itemRes, queueIndex);

        this.gridApi.applyTransaction({ update: [rowNode.data] });
        this.addEmptyRow();
      });
    });
  }

  getContextMenuItems = (params: any) => {
    const result = [
      {
        icon: '<i class="fas fa-info"></i>',
        name: 'Tischinfos',
        action: () => {
          if (!params || !params.node || !params.node.data || !params.node.data.roomId) {
            this.snackBar.open('Unbekannter Fehler', '', {
              duration: 2000,
              panelClass: ['snackbar-error']
            });
            return;
          }
          this.openItem(params.node.data);
        }
      },
      'separator',
      'export',
      'copy',
      'separator',
      {
        icon: '<i class="far fa-trash-alt"></i>',
        name: 'Ausgewählte Tisch löschen',
        action: () => {
          this.deleteItems(this.gridApi.getCellRanges()[0]);
        },
        cssClasses: ['text-danger']
      }
    ];
    return result;
    // tslint:disable-next-line:semicolon
  };

  openItem(table: any) {
    const modalRef = this.modalService.open(AddTableComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.room = { ...table.room };
    modalRef.componentInstance.editTableData = { ...table };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      // Find menu in array to update it
      const index = this.items.findIndex((m: any) => m.id === receivedEntry.id);
      this.items[index] = receivedEntry;
      receivedEntry = this.createTableArray(receivedEntry, index);
      this.gridApi.applyTransaction({ update: [receivedEntry] });
      const snackBarRef = this.snackBar.open('Tisch erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  deleteItems(ranges: any) {
    const start =
      ranges.startRow.rowIndex <= ranges.endRow.rowIndex ? ranges.startRow.rowIndex : ranges.endRow.rowIndex;
    const end = ranges.endRow.rowIndex >= ranges.startRow.rowIndex ? ranges.endRow.rowIndex : ranges.startRow.rowIndex;

    const rowsToDelete: any = [];
    for (let index = start; index <= end; index++) {
      const row = this.gridApi.getDisplayedRowAtIndex(index);
      if (row && row.data) {
        rowsToDelete.push(row.data);
      }
    }

    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Tisch löschen';
    if (end - start > 0) {
      modalRef.componentInstance.message = `Sind Sie sicher dass Sie <b>${rowsToDelete.length} Tisch</b> löschen möchten? Diese Aktion ist unwiederruflich.`;
    } else {
      modalRef.componentInstance.message = `Sind Sie sicher dass Sie den Tisch <b>${rowsToDelete[0].name}</b> löschen möchten? Diese Aktion ist unwiederruflich.`;
    }
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          // Remove from grid
          this.gridApi.applyTransaction({ remove: rowsToDelete });

          rowsToDelete.forEach((row: any) => {
            // Find in items array and remove from there as well
            const itemIndex = this.items.findIndex((item: any) => (item.id = row.id));
            if (itemIndex > -1) {
              this.items.splice(itemIndex, 1);
            }

            this.reservationService.deleteTable(row.id).subscribe(
              () => {
                this.reorderTable();
                this.snackBar.open('Tisch wurde erfolgreich gelöscht', '', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              },
              (err: any) => {
                this.snackBar.open(err && err.error ? err.error.msg : 'Unbekannter Fehler', '', {
                  duration: 2000,
                  panelClass: ['snackbar-error']
                });
              }
            );
          });
        }
      },
      () => {}
    );
  }

  onRowDragEnd(e: any) {
    this.reorderTable();
  }
  calculateRows() {
    const rowData: any = [];
    this.gridApi.forEachNode((node: any) => {
      if (node.data.id) {
        rowData.push(node.data);
      }
    });
    return rowData;
  }
  reorderTable() {
    this.gridApi.showLoadingOverlay();
    let rowData = this.calculateRows();
    console.log(rowData);
    this.reservationService.reorderTables(rowData).subscribe((res: any) => {
      this.getTables();
    });
  }
  columnSorting(a: any, b: any, nodeA: any, nodeB: any) {
    if (nodeA && nodeA.data && !nodeA.data.id) {
      // If no id is present, ignore the row because its empty
      return 0;
    }
    return a > b ? 1 : -1;
  }

  selectedTabChange(e: any) {
    if (e.index === 2 && this.tablePlan) {
      this.tablePlan.getRooms();
      this.tablePlan.getAllTables();
    } else {
      this.ngOnInit();
      this.getTables();
    }
  }

  addRoom() {
    const modalRef = this.modalService.open(AddRoomComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      receivedEntry.tables = [];
      this.rooms.push(receivedEntry);
      const snackBarRef = this.snackBar.open('Raum erfolgreich hinzugefügt', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  editRoom(room: Room, index: number) {
    const modalRef = this.modalService.open(AddRoomComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editRoomData = { ...room };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      if (receivedEntry && receivedEntry !== 'delete') {
        this.rooms[index] = receivedEntry;
        const snackBarRef = this.snackBar.open('Raum erfolgreich gespeichert', 'Ok', {
          duration: 3000
        });
        modalRef.close();
      } else if (receivedEntry === 'delete') {
        this.rooms = this.rooms.filter((obj: any) => {
          return obj.id !== room.id;
        });
        modalRef.close();
      }
    });
  }

  addTable(room: Room) {
    const modalRef = this.modalService.open(AddTableComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.room = { ...room };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      room.tables.push(receivedEntry);
      modalRef.close();
    });
  }

  addCombination(room: Room) {
    const modalRef = this.modalService.open(AddTableComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.room = { ...room };
    modalRef.componentInstance.isCombined = true;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      room.tables.push(receivedEntry);
      modalRef.close();
    });
  }

  editTable(room: Room, table: RoomTable) {
    const modalRef = this.modalService.open(AddTableComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.room = { ...room };
    modalRef.componentInstance.editTableData = { ...table };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe(async (receivedEntry: any) => {
      table = receivedEntry;
      // Find room index to update correspondent table
      const roomIndex = await this.rooms
        .map((x: any) => {
          return x.id;
        })
        .indexOf(room.id);
      // Find table index to update correspondent table
      const tableIndex = await this.rooms[roomIndex].tables
        .map((x: any) => {
          return x.id;
        })
        .indexOf(table.id);
      // Update table
      this.rooms[roomIndex].tables[tableIndex] = receivedEntry;
      const snackBarRef = this.snackBar.open('Tisch erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  deleteTable(tableId: number) {
    this.reservationService
      .deleteTable(tableId)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((response: any) => {
        // Delete from array
        // Find room index to update correspondent table
        const roomIndex = this.rooms
          .map((x: any) => {
            return x.id;
          })
          .indexOf(response.roomId);
        this.rooms[roomIndex].tables = this.rooms[roomIndex].tables.filter((obj: any) => {
          return obj.id !== response.id;
        });

        const snackBarRef = this.snackBar.open('Tisch erfolgreich gelöscht', 'Ok', {
          duration: 3000
        });
      });
  }
  getConnectedList(): any[] {
    return this.rooms.map((x: any) => `${x.id}`);
  }

  dropGroup(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rooms, event.previousIndex, event.currentIndex + 1);
    this.reservationService
      .setRoomOrder(event.container.data)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((receivedEntry: any) => {});
  }

  async dropItem(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      await moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.reservationService
        .saveTableOrder(event.container.data, +event.container.id)
        .takeUntil(this.ngUnsubscribe)
        .subscribe((receivedEntry: any) => {});
      /*
      this.reservationService
        .setTableOrder(event.item.data.id, event.item.data.roomId, event.previousIndex, event.currentIndex)
        .subscribe((receivedEntry: any) => {});
        */
    } else {
      await transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      await this.reservationService
        .saveTableOrder(event.container.data, +event.container.id)
        .takeUntil(this.ngUnsubscribe)
        .subscribe((receivedEntry: any) => {});
      // this.reservationService.saveTableOrder(event.previousContainer.data).subscribe((receivedEntry: any) => {});

      /*
      this.reservationService
        .setTableOrder(event.item.data.id, +event.container.id, event.previousIndex, event.currentIndex)
        .subscribe((receivedEntry: any) => {});
        */
    }
  }
  getReservationBookSettings() {
    this.reservationService
      .getReservationBookSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((settings: any) => {
        this.reservationBook = settings;
      });
  }

  async getSettings() {
    try {
      const settings = await this.reservationService
        .getSettings()
        .pipe(takeUntil(this.ngUnsubscribe))
        .toPromise();
      if (settings && settings.versionSettings) {
        this.combinationTables = settings.versionSettings.combinationTables;
      }
    } catch (error) {
      console.error('Error fetching settings', error);
    }
  }

  closeAlert(alert: any) {
    this.alert = '';
  }

  saveVersionSettings() {
    this.reservationService
      .saveVersionSettings({ combinationTables: this.combinationTables })
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.snackBar.open('Erfolgreich.', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        err => {
          console.log('err', err);
        }
      );
  }
}

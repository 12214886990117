var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, OnDestroy } from '@angular/core';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { NgbModal, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AddTableComponent } from './add-table/add-table.component';
import { AddRoomComponent } from './add-room/add-room.component';
import { MatSnackBar } from '@angular/material';
import { AddShiftComponent } from './add-shift/add-shift.component';
import { AddResSeriesComponent } from './add-res-series/add-res-series.component';
import 'devextreme-intl';
import { locale, loadMessages } from 'devextreme/localization';
import deMessages from 'devextreme/localization/messages/de.json';
import { AddNotificationComponent } from './add-notification/add-notification.component';
import { ReservationService } from '../reservation.service';
import { DxSchedulerComponent } from 'devextreme-angular/ui/scheduler';
import { AuthenticationService, CredentialsService, resStatus } from '@app/core';
import { LabelType } from 'ng5-slider';
import { ActivatedRoute, Router } from '@angular/router';
import { DxContextMenuComponent } from 'devextreme-angular/ui/context-menu';
import moment from 'moment';
moment.locale('de');
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { AddGlobalQuestionComponent } from './add-global-question/add-global-question.component';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { AddPaymentTemplateComponent } from './add-payment-template/add-payment-template.component';
import { TablePlanComponent } from './table-plan/table-plan.component';
import { Angular2CsvComponent } from 'angular2-csv';
import { RangesFooterNewComponent } from '../../../app/shared/ranges-footer-new/ranges-footer-new.component';
import { AddReservationAddOnTemplate } from './add-reservation-addon-template/add-reservation-addon-template.component';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AddResMenuOrders } from './add-res-menu-orders/add-res-menu-orders.component';
import { AddTimeFilterComponent } from './add-time-filter/add-time-filter.component';
import { environment } from '@env/environment';
import { CopyShiftLayerComponent } from './copy-shift-layer/copy-shift-layer.component';
import { AddStatusComponent } from './add-status/add-status.component';
export class ReservationSettingsComponent {
    constructor(modalService, snackBar, dropdownConfig, reservationService, authService, activatedRoute, route, credentialsService, formBuilder) {
        this.modalService = modalService;
        this.snackBar = snackBar;
        this.dropdownConfig = dropdownConfig;
        this.reservationService = reservationService;
        this.authService = authService;
        this.activatedRoute = activatedRoute;
        this.route = route;
        this.credentialsService = credentialsService;
        this.formBuilder = formBuilder;
        this.generalSettings = {
            isActive: true,
            isAutomatic: false,
            autoPlace: false,
            roomChoice: true,
            email: '',
            remindGuests: false,
            remindGuestsEmail: false,
            remindGuestsTextMessage: false,
            reminderDays: 1,
            reminderGroupSize: 1,
            reservationInactiveText: '',
            isGoogleReserve: false,
            showSubscribeNewsletter: true,
            reminderSMSText: '',
            reservationUntil: '',
            showStayTime: true,
            termsConditions: '',
            paymentTransactionFee: 0,
            showTranslation: false,
            defaultLang: 'de'
        };
        this.reservationBook = {
            timeInterval: 15,
            defaultStaytime: 60,
            requestStaff: false,
            confirmStatusChange: false,
            arrivedStatus: true,
            waitingStatus: false,
            showStatus: false,
            showWalkIns: true,
            startTime: 10,
            endTime: 24,
            showOccupancy: false,
            showTablePlan: false,
            includeShift: 0,
            showTimeFilter: false,
            showReservationId: false,
            phoneNoRequired: false
        };
        this.textPresets = {
            acceptText: '',
            declineText: '',
            cancelText: '',
            reminderText: '',
            infoText: '',
            noMoreReservationMsg: ''
        };
        this.tags = [];
        this.tag = {
            color: '#f44336',
            label: ''
        };
        this.globalQuestions = [];
        this.paymentTemplates = [];
        this.reservationAddOnTemplates = [];
        this.resMenuOrders = [];
        this.resTimeFilter = [];
        this.resStatusList = [];
        this.allStatus = resStatus;
        this.currentDate = new Date();
        this.showSMSServiceConfirmation = new Date('2022-11-01');
        this.showOldCalendar = false;
        this.alert = '';
        this.reminderTextError = '';
        this.stayTimeOptions = {
            floor: 15,
            ceil: 600,
            step: 15,
            showTicks: true,
            showSelectionBar: true,
            translate: (value, label) => {
                value = value / 60; // Convert to hours
                const fractionalPart = value % 1;
                const mainPart = parseInt(value + '', 10);
                switch (label) {
                    case LabelType.Floor:
                        if (value === 0.25) {
                            return '15 Minuten';
                        }
                        else if (value === 0.5) {
                            return '30 Minuten';
                        }
                        else if (value === 0.75) {
                            return '45 Minuten';
                        }
                        else if (value === 1) {
                            return value + ' Stunde';
                        }
                        else {
                            if (fractionalPart > 0) {
                                const stunde = mainPart === 1 ? `${mainPart} Stunde` : `${mainPart} Stunden`;
                                if (fractionalPart === 0.25) {
                                    return stunde + ' 15 Minuten';
                                }
                                else if (fractionalPart === 0.5) {
                                    return stunde + ' 30 Minuten';
                                }
                                else if (fractionalPart === 0.75) {
                                    return stunde + ' 45 Minuten';
                                }
                            }
                            else {
                                return value + ' Stunden';
                            }
                        }
                        break;
                    case LabelType.Ceil:
                        if (value === 0.25) {
                            return '15 Minuten';
                        }
                        else if (value === 0.5) {
                            return '30 Minuten';
                        }
                        else if (value === 0.75) {
                            return '45 Minuten';
                        }
                        else if (value === 1) {
                            return value + ' Stunde';
                        }
                        else {
                            if (fractionalPart > 0) {
                                const stunde = mainPart === 1 ? `${mainPart} Stunde` : `${mainPart} Stunden`;
                                if (fractionalPart === 0.25) {
                                    return stunde + ' 15 Minuten';
                                }
                                else if (fractionalPart === 0.5) {
                                    return stunde + ' 30 Minuten';
                                }
                                else if (fractionalPart === 0.75) {
                                    return stunde + ' 45 Minuten';
                                }
                            }
                            else {
                                return value + ' Stunden';
                            }
                        }
                        break;
                    case LabelType.Low:
                        if (value === 0.25) {
                            return '15 Minuten';
                        }
                        else if (value === 0.5) {
                            return '30 Minuten';
                        }
                        else if (value === 0.75) {
                            return '45 Minuten';
                        }
                        else if (value === 1) {
                            return value + ' Stunde';
                        }
                        else {
                            if (fractionalPart > 0) {
                                const stunde = mainPart === 1 ? `${mainPart} Stunde` : `${mainPart} Stunden`;
                                if (fractionalPart === 0.25) {
                                    return stunde + ' 15 Minuten';
                                }
                                else if (fractionalPart === 0.5) {
                                    return stunde + ' 30 Minuten';
                                }
                                else if (fractionalPart === 0.75) {
                                    return stunde + ' 45 Minuten';
                                }
                            }
                            else {
                                return value + ' Stunden';
                            }
                        }
                        break;
                    case LabelType.High:
                        if (value === 0.25) {
                            return '15 Minuten';
                        }
                        else if (value === 0.5) {
                            return '30 Minuten';
                        }
                        else if (value === 0.75) {
                            return '45 Minuten';
                        }
                        else if (value === 1) {
                            return value + ' Stunde';
                        }
                        else {
                            if (fractionalPart > 0) {
                                const stunde = mainPart === 1 ? `${mainPart} Stunde` : `${mainPart} Stunden`;
                                if (fractionalPart === 0.25) {
                                    return stunde + ' 15 Minuten';
                                }
                                else if (fractionalPart === 0.5) {
                                    return stunde + ' 30 Minuten';
                                }
                                else if (fractionalPart === 0.75) {
                                    return stunde + ' 45 Minuten';
                                }
                            }
                            else {
                                return value + ' Stunden';
                            }
                        }
                        break;
                    default:
                        return value + '';
                }
            }
        };
        this.languageArray = [
            {
                lang: 'NL',
                value: 'nl',
                flag: `${environment.baseUrl}assets/img/flags-icons/nl.png`
            },
            {
                lang: 'GB',
                value: 'en',
                flag: `${environment.baseUrl}assets/img/flags-icons/uk.png`
            },
            {
                lang: 'DE',
                value: 'de',
                flag: `${environment.baseUrl}assets/img/flags-icons/de.png`
            },
            {
                lang: 'ES',
                value: 'es',
                flag: `${environment.baseUrl}assets/img/flags-icons/es.png`
            },
            {
                lang: 'FR',
                value: 'fr',
                flag: `${environment.baseUrl}assets/img/flags-icons/fr.png`
            }
        ];
        this.timesArray = [];
        this.shiftContextMenuItems = [];
        // CSV Export
        this.reservations = [];
        this.csvReservations = [];
        this.csvOptions = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: false,
            headers: [
                'ID',
                'Datum',
                'Uhrzeit (von)',
                'Uhrzeit (bis)',
                'Personen',
                'Tisch',
                'Name',
                'Telefon',
                'Email',
                'Hinweise',
                'Admin-Hinweise'
            ],
            showTitle: false,
            title: '',
            useBom: false,
            removeNewLines: true,
            keys: [
                'id',
                'date',
                'start',
                'end',
                'peopleCount',
                'table',
                'guestName',
                'guestPhone',
                'guestEmail',
                'guestNotes',
                'adminNotes'
            ]
        };
        this.reminderDaysOptions = {
            floor: 1,
            ceil: 14,
            step: 1,
            showTicks: true,
            showSelectionBar: true,
            translate: (value) => {
                return value + '';
            }
        };
        this.reminderGroupSizeOptions = {
            floor: 1,
            ceil: 20,
            step: 1,
            showTicks: true,
            showSelectionBar: true,
            translate: (value) => {
                return value + '';
            }
        };
        this.rangesFooter = RangesFooterNewComponent;
        this.timeSeriesDate = {
            begin: Date,
            end: Date
        };
        this.ngUnsubscribe = new Subject();
        this.smsConfirmationSettings = {
            sendSMS: false
        };
        this.reservationUntilDays = [];
        this.onlineResURL = '';
        this.paymentTransactionFee = 0;
        dropdownConfig.placement = 'top-right';
        loadMessages(deMessages);
        locale('de');
        this.getTimesArray();
        this.smsSettingsFormGroup = this.formBuilder.group({
            reservationActive: [0],
            gateway: [0],
            senderId: [''],
            confirmationText: ['', [Validators.maxLength(160)]],
            requestText: ['', [Validators.maxLength(160)]]
        });
    }
    ngOnInit() {
        this.onlineResURL = environment.onlineResURL;
        this.authService
            .getActiveClient()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((clientData) => {
            this.activeClient = clientData;
            clientData.openingHours.forEach((time) => {
                if (!this.lowestTime || this.lowestTime.replace(':', '') < time.tFrom.replace(':', '')) {
                    this.lowestTime = time.tFrom;
                }
            });
        });
        // Get query params for tab change
        this.activatedRoute.queryParams.takeUntil(this.ngUnsubscribe).subscribe(params => {
            if (params.tab) {
                this.tab = params.tab;
                this.route.navigate([], {
                    queryParams: {
                        tab: null
                    },
                    queryParamsHandling: 'preserve'
                });
            }
        });
        this.setReservationUntilDays();
        this.getSMSConfirmationSettings();
        this.getSettings();
        this.getNotificationChannels();
        // this.getRooms();
        this.getShifts();
        this.getQuestions();
        this.getPaymentTemplates();
        this.getReservationAddOnTemplates();
        this.getAllResMenuOrders();
        // this.getTimetable();
        this.getReservationBookSettings();
        this.getTags();
        this.getTimeTableSeries();
        this.getSmsSettings();
        this.getResTimeFilter();
        this.getPhoneAssistantSettings();
        this.getResStatus();
        // CSV
        // this.getAllReservations();
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    setReservationUntilDays() {
        for (let i = 30; i <= 360; i += 10) {
            this.reservationUntilDays.push(i);
        }
    }
    getSMSConfirmationSettings() {
        this.reservationService
            .getSMSConfirmationSettings()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((data) => {
            if (data && Object.keys(data).length > 0) {
                this.smsConfirmationSettings = data;
            }
        });
    }
    saveSMSSettings() {
        this.reservationService
            .saveSMSConfirmationSettings(this.smsConfirmationSettings)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((resp) => {
            this.snackBar.open('Einstellungen erfolgreich aktualisiert', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
            });
        });
    }
    getTimeTableSeries(event = null) {
        /* New reservation series format*/
        this.timeSeriesDate.begin = event
            ? event.begin
            : moment()
                .startOf('week')
                .toDate();
        this.timeSeriesDate.end = event
            ? event.end
            : moment()
                .endOf('week')
                .toDate();
        let timeDifference = this.timeSeriesDate.end.getTime() - this.timeSeriesDate.begin.getTime();
        let differenceDays = Math.round(timeDifference / (1000 * 3600 * 24));
        if (differenceDays > 40) {
            this.timeSeriesDate.end = moment(this.timeSeriesDate.begin, 'DD-MM-YYYY')
                .add(40, 'days')
                .toDate();
        }
        this.getTimeTableCurrentWeek(this.timeSeriesDate.begin, this.timeSeriesDate.end);
    }
    getSettings() {
        this.reservationService
            .getSettings()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((settings) => {
            this.generalSettings = settings;
            console.log(this.generalSettings);
            this.generalSettings.reservationUntil = this.generalSettings.reservationUntil
                ? this.generalSettings.reservationUntil
                : '';
            this.textPresets.acceptText = settings.acceptText;
            this.textPresets.declineText = settings.declineText;
            this.textPresets.cancelText = settings.cancelText;
            this.textPresets.reminderText = settings.reminderText;
            this.textPresets.infoText = settings.infoText;
            this.textPresets.noMoreReservationMsg = settings.noMoreReservationMsg;
            this.paymentTransactionFee = this.generalSettings.paymentTransactionFee;
        });
    }
    getReservationBookSettings() {
        this.reservationService
            .getReservationBookSettings()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((settings) => {
            this.reservationBook = settings;
            console.log('this.reservationBook ===>', this.reservationBook);
        });
    }
    saveSettings() {
        if (this.reminderTextError) {
            this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
        this.reservationService
            .saveSettings(this.generalSettings)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((resp) => {
            this.alert = '';
            this.snackBar.open('Einstellungen erfolgreich aktualisiert', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
            });
        });
    }
    saveTextPresets() {
        // if (this.textPresets.acceptText && !this.textPresets.acceptText.includes('[zusatztext]')) {
        //   this.snackBar.open('Bitte fügen Sie Ihrem Bestätigungstext [zusatztext] hinzu.', '', {
        //     duration: 2000,
        //     panelClass: ['snackbar-error']
        //   });
        //   return;
        // }
        this.reservationService
            .saveSettings(this.textPresets)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((resp) => {
            const snackBarRef = this.snackBar.open('Textvorlagen erfolgreich gespeichert', '', {
                duration: 3000,
                panelClass: ['snackbar-success']
            });
        });
    }
    saveReservationBookSettings() {
        this.reservationService
            .saveReservationBookSettings(this.reservationBook)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((resp) => {
            this.reservationBook = resp;
        });
    }
    changeDefaultStaytime() {
        this.saveReservationBookSettings();
    }
    getRooms() {
        this.reservationService
            .getRooms()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((rooms) => {
            this.rooms = rooms;
        });
    }
    getShifts() {
        this.reservationService
            .getShifts()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((shifts) => {
            this.shifts = shifts;
            console.log('this.shifts  ===>', this.shifts);
        });
    }
    getQuestions() {
        this.reservationService
            .getQuestions()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((questions) => {
            this.globalQuestions = questions;
        });
    }
    getPaymentTemplates() {
        this.reservationService
            .getPaymentTemplates()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((paymentTemplates) => {
            this.paymentTemplates = paymentTemplates;
        });
    }
    getReservationAddOnTemplates() {
        this.reservationService
            .getReservationAddOnTemplates()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((reservationAddOnTemplates) => {
            this.reservationAddOnTemplates = reservationAddOnTemplates;
        });
    }
    getAllResMenuOrders() {
        this.reservationService
            .getAllResMenuOrders()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((resMenuOrders) => {
            this.resMenuOrders = resMenuOrders;
        });
    }
    getTimetable() {
        // this.timeplanData = this.reservationService.getAppointments();
        this.reservationService
            .getAppointments()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((timeplanData) => {
            timeplanData = timeplanData.filter((obj) => {
                return !(!obj.recurrenceRule && obj.recurrenceException);
            });
            timeplanData = timeplanData.map((obj) => {
                obj.startDate = new Date(obj.startDate.replace(/-/g, '/'));
                obj.endDate = new Date(obj.endDate.replace(/-/g, '/'));
                return obj;
            });
            this.timeplanData = timeplanData;
        });
    }
    selectedTabChange(e) {
        // if (e.index === 4) {
        //   this.scheduler.instance.repaint();
        // }
        // if (e.index === 2 && this.tablePlan) {
        //   this.tablePlan.getRooms();
        //   this.tablePlan.getAllTables();
        // }
    }
    addRoom() {
        const modalRef = this.modalService.open(AddRoomComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            receivedEntry.tables = [];
            this.rooms.push(receivedEntry);
            const snackBarRef = this.snackBar.open('Raum erfolgreich hinzugefügt', 'Ok', {
                duration: 3000
            });
            modalRef.close();
        });
    }
    editRoom(room, index) {
        const modalRef = this.modalService.open(AddRoomComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.editRoomData = Object.assign({}, room);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            if (receivedEntry && receivedEntry !== 'delete') {
                this.rooms[index] = receivedEntry;
                const snackBarRef = this.snackBar.open('Raum erfolgreich gespeichert', 'Ok', {
                    duration: 3000
                });
                modalRef.close();
            }
            else if (receivedEntry === 'delete') {
                this.rooms = this.rooms.filter((obj) => {
                    return obj.id !== room.id;
                });
                modalRef.close();
            }
        });
    }
    addTable(room) {
        const modalRef = this.modalService.open(AddTableComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.room = Object.assign({}, room);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            room.tables.push(receivedEntry);
            modalRef.close();
        });
    }
    addCombination(room) {
        const modalRef = this.modalService.open(AddTableComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.room = Object.assign({}, room);
        modalRef.componentInstance.isCombined = true;
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            room.tables.push(receivedEntry);
            modalRef.close();
        });
    }
    editTable(room, table) {
        const modalRef = this.modalService.open(AddTableComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.room = Object.assign({}, room);
        modalRef.componentInstance.editTableData = Object.assign({}, table);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => __awaiter(this, void 0, void 0, function* () {
            table = receivedEntry;
            // Find room index to update correspondent table
            const roomIndex = yield this.rooms
                .map((x) => {
                return x.id;
            })
                .indexOf(room.id);
            // Find table index to update correspondent table
            const tableIndex = yield this.rooms[roomIndex].tables
                .map((x) => {
                return x.id;
            })
                .indexOf(table.id);
            // Update table
            this.rooms[roomIndex].tables[tableIndex] = receivedEntry;
            const snackBarRef = this.snackBar.open('Tisch erfolgreich gespeichert', 'Ok', {
                duration: 3000
            });
            modalRef.close();
        }));
    }
    deleteTable(tableId) {
        this.reservationService
            .deleteTable(tableId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response) => {
            // Delete from array
            // Find room index to update correspondent table
            const roomIndex = this.rooms
                .map((x) => {
                return x.id;
            })
                .indexOf(response.roomId);
            this.rooms[roomIndex].tables = this.rooms[roomIndex].tables.filter((obj) => {
                return obj.id !== response.id;
            });
            const snackBarRef = this.snackBar.open('Tisch erfolgreich gelöscht', 'Ok', {
                duration: 3000
            });
        });
    }
    addGlobalQuestion() {
        const modalRef = this.modalService.open(AddGlobalQuestionComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.shifts = Object.assign({}, this.shifts);
        modalRef.componentInstance.tags = Object.assign({}, this.tags);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            this.globalQuestions.push(receivedEntry);
            const snackBarRef = this.snackBar.open('Abfrage erfolgreich erstellt', 'Ok', {
                duration: 3000
            });
            modalRef.close();
        });
    }
    editGlobalQuestion(question, index) {
        const modalRef = this.modalService.open(AddGlobalQuestionComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.editQuestionData = Object.assign({}, question);
        modalRef.componentInstance.shifts = Object.assign({}, this.shifts);
        modalRef.componentInstance.tags = Object.assign({}, this.tags);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            if (receivedEntry) {
                this.globalQuestions[index] = receivedEntry;
                const snackBarRef = this.snackBar.open('Abfrage erfolgreich gespeichert', 'Ok', {
                    duration: 3000
                });
                modalRef.close();
            }
        });
    }
    deleteGlobalQuestion(questionId) {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = 'Frage löschen';
        modalRef.componentInstance.message = `Willst du diese Frage löschen? Alle zugehörigen Antworten in den Reservierungen gehen verloren.`;
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = 'Ja';
        modalRef.result.then(result => {
            if (result === 'ok') {
                this.reservationService
                    .deleteQuestion(questionId)
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe((response) => {
                    this.globalQuestions = this.globalQuestions.filter((obj) => {
                        return obj.id !== questionId;
                    });
                    const snackBarRef = this.snackBar.open('Abfrage erfolgreich gelöscht', 'Ok', {
                        duration: 3000
                    });
                });
            }
        }, () => { });
    }
    addPaymentTemplate() {
        const modalRef = this.modalService.open(AddPaymentTemplateComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.clientData = Object.assign({}, this.activeClient);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            this.paymentTemplates.push(receivedEntry);
            const snackBarRef = this.snackBar.open('Anzahlungsvorlage erfolgreich erstellt', 'Ok', {
                duration: 3000
            });
            modalRef.close();
        });
    }
    editPaymentTemplate(paymentTemplate, index) {
        const modalRef = this.modalService.open(AddPaymentTemplateComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.clientData = Object.assign({}, this.activeClient);
        modalRef.componentInstance.editPaymentTemplateData = Object.assign({}, paymentTemplate);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            if (receivedEntry) {
                this.paymentTemplates[index] = receivedEntry;
                const snackBarRef = this.snackBar.open('Anzahlungsvorlage erfolgreich gespeichert', 'Ok', {
                    duration: 3000
                });
                modalRef.close();
            }
        });
    }
    deletePaymentTemplate(templateId) {
        this.reservationService
            .deletePaymentTemplate(templateId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response) => {
            this.paymentTemplates = this.paymentTemplates.filter((obj) => {
                return obj.id !== templateId;
            });
            const snackBarRef = this.snackBar.open('Anzahlungsvorlage erfolgreich gelöscht', 'Ok', {
                duration: 3000
            });
        });
    }
    getConnectedList() {
        return this.rooms.map((x) => `${x.id}`);
    }
    dropGroup(event) {
        moveItemInArray(this.rooms, event.previousIndex, event.currentIndex);
        this.reservationService
            .setRoomOrder(event.container.data)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((receivedEntry) => { });
    }
    dropItem(event) {
        return __awaiter(this, void 0, void 0, function* () {
            if (event.previousContainer === event.container) {
                yield moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
                this.reservationService
                    .saveTableOrder(event.container.data, +event.container.id)
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe((receivedEntry) => { });
                /*
                this.reservationService
                  .setTableOrder(event.item.data.id, event.item.data.roomId, event.previousIndex, event.currentIndex)
                  .subscribe((receivedEntry: any) => {});
                  */
            }
            else {
                yield transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
                yield this.reservationService
                    .saveTableOrder(event.container.data, +event.container.id)
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe((receivedEntry) => { });
                // this.reservationService.saveTableOrder(event.previousContainer.data).subscribe((receivedEntry: any) => {});
                /*
                this.reservationService
                  .setTableOrder(event.item.data.id, +event.container.id, event.previousIndex, event.currentIndex)
                  .subscribe((receivedEntry: any) => {});
                  */
            }
        });
    }
    addShift() {
        const copyShiftModalRef = this.modalService.open(CopyShiftLayerComponent, { windowClass: 'onboarding-modal' });
        copyShiftModalRef.componentInstance.shifts = this.shifts;
        copyShiftModalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
            if (receivedEntry == 'createNew') {
                const modalRef = this.modalService.open(AddShiftComponent, { size: 'lg' });
                modalRef.componentInstance.generalSettings = this.generalSettings;
                modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
                    this.shifts.push(receivedEntry);
                    const snackBarRef = this.snackBar.open('Vorlage erfolgreich hinzugefügt', 'Ok', {
                        duration: 3000
                    });
                    modalRef.close();
                });
            }
            else {
                this.shifts.push(receivedEntry);
                const snackBarRef = this.snackBar.open('Vorlage erfolgreich hinzugefügt', 'Ok', {
                    duration: 3000
                });
                copyShiftModalRef.close();
            }
        });
    }
    editShift(shift, index) {
        const modalRef = this.modalService.open(AddShiftComponent, { size: 'lg' });
        modalRef.componentInstance.editShiftData = Object.assign({}, shift);
        modalRef.componentInstance.generalSettings = this.generalSettings;
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            this.shifts[index] = receivedEntry;
            const snackBarRef = this.snackBar.open('Vorlage erfolgreich gespeichert', 'Ok', {
                duration: 3000
            });
            modalRef.close();
        });
    }
    getNotificationChannels() {
        this.reservationService
            .getNotificationChannels()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((channels) => {
            this.channels = channels;
        });
    }
    addNotificationChannel() {
        const modalRef = this.modalService.open(AddNotificationComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
            this.reservationService
                .addNotificationChannel(receivedEntry)
                .takeUntil(this.ngUnsubscribe)
                .subscribe((response) => {
                this.channels.push(receivedEntry);
                const snackBarRef = this.snackBar.open('Kanal erfolgreich hinzugefügt', 'Ok', {
                    duration: 3000
                });
                modalRef.close();
            });
        });
    }
    editNotificationChannel(channel, index) {
        const modalRef = this.modalService.open(AddNotificationComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.editChannelData = Object.assign({}, channel);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            this.reservationService
                .editNotificationChannel(receivedEntry)
                .takeUntil(this.ngUnsubscribe)
                .subscribe((response) => {
                this.channels[index] = receivedEntry;
                const snackBarRef = this.snackBar.open('Kanal erfolgreich gespeichert', 'Ok', {
                    duration: 3000
                });
                modalRef.close();
            });
            modalRef.close();
        });
    }
    deleteNotificationChannel(channelId) {
        this.reservationService
            .deleteNotificationChannel(channelId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(() => {
            // Delete from array
            this.channels = this.channels.filter((obj) => {
                return obj.id !== channelId;
            });
            const snackBarRef = this.snackBar.open('Vorlage erfolgreich gelöscht', 'Ok', {
                duration: 3000
            });
        });
    }
    onAppointmentContextMenu(e) {
        this.contextMenuAppointmentData = e.appointmentData;
        this.contextMenuTargetedAppointmentData = e.targetedAppointmentData;
        this.onShiftContextMenuShowing(e);
    }
    onAppointmentClick(e) {
        e.cancel = true;
    }
    // Called before the context menu for a shift is opened
    onShiftContextMenuShowing(e) {
        const basicMenuItems = [
            {
                text: 'Bearbeiten',
                onItemClick: () => this.scheduler.instance.showAppointmentPopup(this.contextMenuAppointmentData, false, this.contextMenuTargetedAppointmentData)
            }
        ];
        if (!e.appointmentData.isBlocked) {
            basicMenuItems.push({
                text: 'Blocken',
                onItemClick: () => this.blockTimeTableEntry(this.contextMenuAppointmentData, this.contextMenuTargetedAppointmentData)
            });
            basicMenuItems.push({
                text: 'Löschen',
                onItemClick: () => this.deleteTimeTableEntry(this.contextMenuAppointmentData)
            });
        }
        else {
            basicMenuItems.push({
                text: 'Entblocken',
                onItemClick: () => this.unblockTimeTableEntry(this.contextMenuAppointmentData)
            });
        }
        this.shiftContextMenu.instance.option('items', basicMenuItems);
    }
    onContextMenuItemClick(e) {
        e.itemData.onItemClick(e.itemData);
    }
    onAppointmentFormOpening(data) {
        const that = this, form = data.form;
        const startDate = data.appointmentData.startDate;
        let allItems = form.option('items');
        const formData = form.option('formData');
        allItems = allItems.filter((item, index) => {
            return item.dataField === 'recurrenceRule';
        });
        const min = new Date(startDate);
        min.setHours(this.reservationBook.startTime);
        min.setMinutes(0);
        min.setSeconds(0);
        min.setMilliseconds(0);
        const max = new Date(startDate);
        max.setHours(this.reservationBook.endTime);
        max.setMinutes(1);
        max.setSeconds(0);
        max.setMilliseconds(0);
        const endTime = {
            name: 'endTime',
            dataField: 'endDate',
            label: {
                text: 'Endzeit'
            },
            editorType: 'dxDateBox',
            editorOptions: {
                type: 'time',
                min: min,
                max: max,
                interval: 30
            },
            validationRules: [
                {
                    type: 'required'
                }
            ]
        };
        allItems.unshift(endTime);
        const startTime = {
            name: 'startTime',
            dataField: 'startDate',
            label: {
                text: 'Startzeit'
            },
            editorType: 'dxDateBox',
            editorOptions: {
                type: 'time',
                min: min,
                max: max,
                interval: 30
            },
            validationRules: [
                {
                    type: 'required'
                }
            ]
        };
        allItems.unshift(startTime);
        const shiftSelect = {
            colSpan: 2,
            label: {
                text: 'Vorlage'
            },
            editorType: 'dxSelectBox',
            dataField: 'shift',
            editorOptions: {
                items: [...that.shifts],
                displayExpr: function (item) {
                    const description = item && item.description ? ' (' + item.description + ')' : '';
                    return item && item.name + description;
                },
                valueExpr: 'id',
                onValueChanged: function (args) {
                    // movieInfo = that.getMovieById(args.value);
                }.bind(this)
            },
            validationRules: [
                {
                    type: 'required'
                }
            ]
        };
        allItems.unshift(shiftSelect);
        form.option({
            items: allItems,
            formData: formData
        });
    }
    parseTime(time) {
        const d = new Date();
        const split = time.split(':');
        d.setHours(+split[0]);
        d.setMinutes(+split[1]);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    }
    onAppointmentFormCreated(data) {
        const that = this, form = data.form;
        const startDate = data.appointmentData.startDate;
        let allItems = form.option('items');
        const formData = form.option('formData');
        allItems = allItems.filter((item, index) => {
            return item.dataField === 'recurrenceRule' || item.dataField === 'startDate' || item.dataField === 'endDate';
        });
        const shiftSelect = {
            colSpan: 2,
            label: {
                text: 'Vorlage'
            },
            editorType: 'dxSelectBox',
            dataField: 'shift',
            editorOptions: {
                items: [...that.shifts],
                displayExpr: 'name',
                valueExpr: 'id',
                onValueChanged: function (args) {
                    // movieInfo = that.getMovieById(args.value);
                }.bind(this)
            },
            validationRules: [
                {
                    type: 'required'
                }
            ]
        };
        allItems.unshift(shiftSelect);
        form.option({
            items: allItems,
            formData: formData
        });
    }
    getTimesArray() {
        let tStart = 0;
        const interval = 60;
        for (let i = 0; tStart <= 24 * 60; i++) {
            const hh = Math.floor(tStart / 60); // getting hours of day in 0-24 format
            const mm = tStart % 60; // getting minutes of the hour in 0-55 format
            const timeObj = {
                time: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
                value: hh
            };
            this.timesArray[i] = timeObj;
            tStart = tStart + interval;
        }
    }
    onAppointmentAdded(data) { }
    onAppointmentUpdating(data) {
        if (data.oldData.isBlocked && data.newData.isBlocked) {
            console.log(980, data);
            data.cancel = true;
            return false;
        }
        const shift = this.shifts.find((x) => x.id === data.newData.shift);
        data.cancel = this.reservationService.editTimeTableEntry(data.newData).then((res) => {
            this.getTimetable();
            data.newData.name = shift.name;
            data.newData.color = shift.color;
            const snackBarRef = this.snackBar.open('Zeit erfolgreich geändert', 'Ok', {
                duration: 3000
            });
            return !res;
        });
    }
    onAppointmentAdding(data) {
        const entryData = {
            shiftId: data.appointmentData.shift,
            startDate: data.appointmentData.startDate,
            endDate: data.appointmentData.endDate,
            recurrenceRule: data.appointmentData.recurrenceRule
        };
        const shift = this.shifts.find((x) => x.id === data.appointmentData.shift);
        data.cancel = this.reservationService.addTimeTableEntry(data.appointmentData).then((res) => {
            data.appointmentData.id = res.id;
            data.appointmentData.name = shift.name;
            data.appointmentData.color = shift.color;
            const snackBarRef = this.snackBar.open('Zeit erfolgreich hinzugefügt', 'Ok', {
                duration: 3000
            });
            return !res;
        });
    }
    onAppointmentDeleted(data) {
        this.reservationService
            .deleteTimeTableEntry(data.appointmentData.id)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(() => {
            data.component._appointmentTooltip.hide();
            const snackBarRef = this.snackBar.open('Zeit erfolgreich gelöscht', 'Ok', {
                duration: 3000
            });
            this.getTimetable();
        });
    }
    getTags() {
        this.reservationService
            .getTags()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((tags) => {
            this.tags = tags;
        });
    }
    addTag() {
        this.reservationService
            .addTag(this.tag)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response) => {
            this.tags.push(Object.assign({}, this.tag));
            const snackBarRef = this.snackBar.open('Tag erfolgreich hinzugefügt', 'Ok', {
                duration: 3000
            });
        });
    }
    deleteTag(tagId) {
        this.reservationService
            .deleteTag(tagId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(() => {
            // Delete from array
            this.tags = this.tags.filter((obj) => {
                return obj.id !== tagId;
            });
            const snackBarRef = this.snackBar.open('Tag erfolgreich gelöscht', 'Ok', {
                duration: 3000
            });
        });
    }
    changeTagColor(event) {
        this.tag.color = event;
    }
    deleteShift(shiftId) {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = 'Vorlage löschen';
        modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Vorlage unwiederruflich löschen möchten?`;
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = 'Ja';
        modalRef.result.then(result => {
            if (result === 'ok') {
                this.reservationService
                    .deleteShift(shiftId)
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe(() => {
                    // Delete from array
                    this.shifts = this.shifts.filter((obj) => {
                        return obj.id !== shiftId;
                    });
                    const snackBarRef = this.snackBar.open('Vorlage erfolgreich gelöscht', 'Ok', {
                        duration: 3000
                    });
                });
            }
        }, () => { });
    }
    editTimeTableEntry(entry) {
        this.scheduler.instance.showAppointmentPopup(this.getDataObj(entry), false);
    }
    blockTimeTableEntry(originalShift, shift) {
        const recurrenceExceptionArray = originalShift.recurrenceException
            ? originalShift.recurrenceException.split(',')
            : [];
        recurrenceExceptionArray.push(moment(shift.startDate).format('YYYYMMDDTHHmmss') + 'Z');
        originalShift.recurrenceException = recurrenceExceptionArray.join(',');
        shift.isBlocked = true;
        shift.recurrenceRule = null;
        this.reservationService.addTimeTableEntry(shift).then((res) => {
            this.reservationService.editTimeTableEntry(originalShift).then((result) => {
                this.getTimetable();
                const snackBarRef = this.snackBar.open('Zeit erfolgreich geblockt', 'Ok', {
                    duration: 3000
                });
                return !res;
            });
        });
    }
    unblockTimeTableEntry(shift) {
        shift.isBlocked = false;
        this.reservationService.unblockTimeTableEntry(shift).then((res) => {
            this.getTimetable();
            const snackBarRef = this.snackBar.open('Zeit erfolgreich entgeblockt', 'Ok', {
                duration: 3000
            });
            return !res;
        });
    }
    deleteTimeTableEntry(shift) {
        this.scheduler.instance.deleteAppointment(this.getDataObj(shift));
    }
    getDataObj(objData) {
        for (let i = 0; i < this.timeplanData.length; i++) {
            this.timeplanData[i].startDate = new Date(this.timeplanData[i].startDate);
            objData.startDate = new Date(objData.startDate);
            if (this.timeplanData[i].startDate.getTime() === objData.startDate.getTime()) {
                return this.timeplanData[i];
            }
        }
        return null;
    }
    blockTimetableEntry(shift) { }
    stopPropagation(e) {
        e.stopPropagation();
    }
    selectedCellDataChange(event) {
        this.selectedCellData = event;
    }
    mouseup() {
        /*
        // Just a hack to open form on drag release. Should be optimized with a better solution!
        if (this.selectedCellData && this.selectedCellData.length > 1) {
          this.scheduler.instance.showAppointmentPopup({
            startDate: this.selectedCellData[0].startDate,
            endDate: this.selectedCellData[this.selectedCellData.length - 1].endDate
          });
        }
        this.selectedCellData = [];
        */
    }
    isClosed(cellData) {
        if (cellData && this.activeClient && this.activeClient.specialOpeningHours) {
            // cellData.startDate;
            const foundObj = this.activeClient.specialOpeningHours.find((oh) => moment(cellData.startDate).isBetween(oh.fromDate, oh.toDate));
            if (!foundObj) {
                return false;
            }
            if (foundObj.closed && !foundObj.from1 && !foundObj.to1 && !foundObj.from2 && !foundObj.to2) {
                return true;
            }
            const from1 = foundObj.from1.replace(':', '');
            const to1 = foundObj.to1.replace(':', '');
            const from2 = foundObj.from2.replace(':', '');
            const to2 = foundObj.to2.replace(':', '');
            const time = moment(cellData.startDate)
                .format('HH:mm')
                .replace(':', '');
            if (foundObj.from1 && foundObj.to1 && !foundObj.from2 && !foundObj.to2 && (time < from1 || time > to1)) {
                return true;
            }
            if (foundObj.from1 &&
                foundObj.to1 &&
                foundObj.from2 &&
                foundObj.to2 &&
                (time < from1 || (time > to1 && time < from2) || time > to2)) {
                return true;
            }
            return false;
        }
    }
    downloadCsv() {
        this.getAllReservations().then(() => {
            setTimeout(() => {
                this.csvComponent.onDownload();
            }, 0);
        });
    }
    getAllReservations() {
        return __awaiter(this, void 0, void 0, function* () {
            const endDate = new Date(2030, 12, 31);
            yield this.reservationService
                .getAllReservations(this.currentDate, endDate, 0, 9999999)
                .toPromise()
                .then((data) => {
                this.reservations = data.reservations;
                this.reservations.sort(function (a, b) {
                    const keyA = new Date(a.reservedFor), keyB = new Date(b.reservedFor);
                    if (keyA < keyB) {
                        return -1;
                    }
                    if (keyA > keyB) {
                        return 1;
                    }
                    return 0;
                });
                this.reservations.forEach((reservation) => {
                    const tempReservation = {};
                    tempReservation.id = reservation.id;
                    tempReservation.date = moment(reservation.startDate).format('DD.MM.YYYY');
                    tempReservation.start = moment(reservation.startDate).format('HH:mm');
                    tempReservation.end = moment(reservation.endDate).format('HH:mm');
                    tempReservation.peopleCount = reservation.peopleCount;
                    if (reservation.tables.length) {
                        tempReservation.table =
                            (reservation.tables[0].room ? reservation.tables[0].room.name + ': ' : '') +
                                ' Tisch ' +
                                reservation.tables[0].name;
                    }
                    else {
                        tempReservation.table = '-';
                    }
                    tempReservation.guestName = reservation.guestData ? reservation.guestData.name : '';
                    tempReservation.guestEmail = reservation.guestData ? reservation.guestData.email : '';
                    tempReservation.guestPhone = reservation.guestData ? reservation.guestData.phone : '';
                    tempReservation.guestNotes =
                        reservation.guestData && reservation.guestData.notes ? reservation.guestData.notes : '';
                    tempReservation.adminNotes = reservation.notes || '';
                    this.csvReservations.push(tempReservation);
                });
            });
        });
    }
    /* New reservation series format*/
    addReservationSeries() {
        const modalRef = this.modalService.open(AddResSeriesComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.shifts = this.shifts;
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            this.reservationService.addTimeTableSeries(receivedEntry).then((response) => {
                const snackBarRef = this.snackBar.open('Zeit erfolgreich hinzugefügt.', 'Ok', {
                    duration: 3000,
                    panelClass: ['snackbar-success']
                });
                modalRef.close();
                this.getTimeTableSeries({ begin: this.timeSeriesDate.begin, end: this.timeSeriesDate.end });
            });
        });
    }
    getTimeTableCurrentWeek(startDateNew, endDateNew) {
        return __awaiter(this, void 0, void 0, function* () {
            const startDate = new Date(startDateNew);
            const endDate = new Date(endDateNew);
            const dateAfter10years = new Date();
            dateAfter10years.setFullYear(dateAfter10years.getFullYear() + 10);
            this.finalTimeSeries = [];
            this.reservationService
                .getTimeTableCurrentWeek(startDate, endDate)
                .takeUntil(this.ngUnsubscribe)
                .subscribe((timeplanData) => {
                // timeplanData = timeplanData.filter((obj: any) => {
                //   return !(!obj.recurrenceRule && obj.recurrenceException);
                // });
                timeplanData = timeplanData.map((obj) => {
                    obj.startDate = new Date(obj.startDate.replace(/-/g, '/'));
                    obj.endDate = new Date(obj.endDate.replace(/-/g, '/'));
                    let recurrenceRuleArray = obj.recurrenceRule ? obj.recurrenceRule.split(';') : [];
                    let recurrenceRule = [];
                    recurrenceRuleArray.map((item) => {
                        let itemData = item.split('=');
                        recurrenceRule[itemData[0]] = itemData[1];
                    });
                    let recurrenceExceptionArray = obj.recurrenceException ? obj.recurrenceException.split(',') : [];
                    recurrenceExceptionArray = recurrenceExceptionArray.map((item) => {
                        return moment(item).format('DD.MMM YYYY');
                    });
                    obj.recurrenceRuleFinal = recurrenceRule;
                    obj.recurrenceExceptionArray = recurrenceExceptionArray;
                    return obj;
                });
                if (timeplanData && timeplanData.length > 0) {
                    /*Calculate week days*/
                    const days = [
                        {
                            day: 'Sonntag',
                            value: 'SU'
                        },
                        {
                            day: 'Montag',
                            value: 'MO'
                        },
                        {
                            day: 'Dienstag',
                            value: 'TU'
                        },
                        {
                            day: 'Mittwoch',
                            value: 'WE'
                        },
                        {
                            day: 'Donnerstag',
                            value: 'TH'
                        },
                        {
                            day: 'Freitag',
                            value: 'FR'
                        },
                        {
                            day: 'Samstag',
                            value: 'SA'
                        }
                    ];
                    const weekDays = [];
                    let dt = startDate;
                    while (dt <= endDate) {
                        weekDays.push(new Date(dt));
                        dt.setDate(dt.getDate() + 1);
                    }
                    const finalTimeSeries = [];
                    const finalSeriesBlocked = [];
                    let isBlockedValue = false;
                    timeplanData = weekDays.map((obj) => {
                        let date = new Date(obj);
                        let dateShow = moment(date).format('DD. MMM YYYY');
                        let dayNameValue = days[date.getDay()];
                        finalTimeSeries[dayNameValue.day + ', ' + dateShow] = [];
                        timeplanData.map((item) => {
                            let seriesDate = new Date(item.startDate);
                            let endDateValue = item.recurrenceRuleFinal.UNTIL
                                ? moment(item.recurrenceRuleFinal.UNTIL).toDate()
                                : moment(dateAfter10years).toDate();
                            item.currentDate = date;
                            if (seriesDate.setHours(0, 0, 0, 0) <= date.setHours(0, 0, 0, 0) &&
                                item.isBlocked == 0 &&
                                item.parentId == 0 &&
                                endDateValue.setHours(0, 0, 0, 0) >= date.setHours(0, 0, 0, 0)) {
                                isBlockedValue =
                                    item.recurrenceExceptionArray &&
                                        item.recurrenceExceptionArray.indexOf(moment(date).format('DD.MMM YYYY')) > -1
                                        ? true
                                        : false;
                                if (item.recurrenceRuleFinal && Object.keys(item.recurrenceRuleFinal).length > 0) {
                                    if (item.recurrenceRuleFinal.FREQ == 'DAILY') {
                                        finalTimeSeries[dayNameValue.day + ', ' + dateShow].push(this.createSeriesDetail(item, isBlockedValue));
                                    }
                                    else if (item.recurrenceRuleFinal.FREQ == 'WEEKLY') {
                                        if (item.recurrenceRuleFinal.BYDAY && item.recurrenceRuleFinal.BYDAY.includes(dayNameValue.value)) {
                                            finalTimeSeries[dayNameValue.day + ', ' + dateShow].push(this.createSeriesDetail(item, isBlockedValue));
                                        }
                                    }
                                }
                                else {
                                    if (seriesDate.toDateString() == date.toDateString()) {
                                        finalTimeSeries[dayNameValue.day + ', ' + dateShow].push(this.createSeriesDetail(item, isBlockedValue));
                                    }
                                }
                            }
                            else if (seriesDate.setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0) &&
                                item.isBlocked != 0 &&
                                item.parentId != 0) {
                                isBlockedValue = true;
                                const parent = timeplanData.find((x) => x.id === item.parentId);
                                finalSeriesBlocked.push(this.createSpecialSeriesDetail(item, isBlockedValue, parent));
                            }
                        });
                    });
                    let obj = [];
                    Object.entries(finalTimeSeries).map((item, key) => {
                        obj[key] = item;
                        this.finalTimeSeries = obj;
                    });
                    if (finalSeriesBlocked.length > 0) {
                        this.finalTimeSeries.push(['Blocked', finalSeriesBlocked]);
                    }
                    console.log('return', this.finalTimeSeries);
                }
            });
        });
    }
    createSeriesDetail(item, isBlockedValue) {
        let finalItem = [];
        finalItem['value'] = moment(item.startDate).format('HH:mm') + ' -' + moment(item.endDate).format('HH:mm') + ' Uhr';
        finalItem['color'] = item.color;
        finalItem['shiftName'] = item.name;
        finalItem['isRWGShift'] = item.isRWGShift;
        finalItem['endingAt'] = item.recurrenceRuleFinal.UNTIL
            ? moment(item.recurrenceRuleFinal.UNTIL).format('DD.MMM YYYY')
            : '';
        finalItem['details'] = item;
        finalItem['currentDate'] = item.currentDate;
        finalItem['type'] = isBlockedValue ? 'blocked' : '';
        finalItem['shiftDescription'] = item.description;
        return finalItem;
    }
    createSpecialSeriesDetail(item, isBlockedValue, parent = []) {
        let finalItem = [];
        finalItem['value'] =
            moment(item.startDate).format('DD.MMM HH:mm') + ' -' + moment(item.endDate).format('DD.MMM HH:mm') + ' Uhr';
        finalItem['color'] = item.color;
        finalItem['shiftName'] = item.name;
        finalItem['isRWGShift'] = item.isRWGShift;
        finalItem['details'] = item;
        finalItem['currentDate'] = item.currentDate;
        finalItem['type'] = isBlockedValue ? 'blocked' : '';
        finalItem['shiftDescription'] = item.description;
        // finalItem['endingAt'] =
        //   parent.recurrenceExceptionArray && parent.recurrenceExceptionArray.length
        //     ? parent.recurrenceExceptionArray[parent.recurrenceExceptionArray.length - 1]
        //     : '';
        return finalItem;
    }
    editResTime(data, type, todayDate) {
        if (type) {
            this.blockResTimeEdit(data, todayDate);
            return;
        }
        const modalRef = this.modalService.open(AddResSeriesComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.shifts = this.shifts;
        modalRef.componentInstance.editResTimeData = Object.assign({}, data);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            this.reservationService.editTimeTableSeries(receivedEntry).then((response) => {
                const snackBarRef = this.snackBar.open('Uhrzeit erfolgreich aktualisiert.', 'Ok', {
                    duration: 3000,
                    panelClass: ['snackbar-success']
                });
                modalRef.close();
                this.getTimeTableSeries({ begin: this.timeSeriesDate.begin, end: this.timeSeriesDate.end });
            });
        });
    }
    deleteResTime(id) {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = 'Zeit löschen';
        modalRef.componentInstance.message = `Sind Sie sicher, dass Sie diese Reservierungs-Serie löschen möchten?`;
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = 'Ja';
        modalRef.result.then(result => {
            if (result === 'ok') {
                this.reservationService
                    .deleteTimeTableSeries(id)
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe(response => {
                    if (response) {
                        this.snackBar.open('Zeit erfolgreich gelöscht', '', {
                            duration: 2000,
                            panelClass: ['snackbar-success']
                        });
                        this.getTimeTableSeries({ begin: this.timeSeriesDate.begin, end: this.timeSeriesDate.end });
                    }
                });
            }
        }, () => { });
    }
    blockResTime(data, todayDate) {
        const modalRef = this.modalService.open(AddResSeriesComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.shifts = this.shifts;
        modalRef.componentInstance.editResTimeData = Object.assign({}, data, { blocked: true, todayDate: todayDate });
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            this.reservationService.blockTimeTableSeries(receivedEntry).then((response) => {
                const snackBarRef = this.snackBar.open('Zeit erfolgreich geblockt.', 'Ok', {
                    duration: 3000,
                    panelClass: ['snackbar-success']
                });
                modalRef.close();
                this.getTimeTableSeries({ begin: this.timeSeriesDate.begin, end: this.timeSeriesDate.end });
            });
        });
    }
    blockResTimeEdit(data, todayDate) {
        const modalRef = this.modalService.open(AddResSeriesComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.shifts = this.shifts;
        modalRef.componentInstance.editResTimeData = Object.assign({}, data, { blocked: true, todayDate: todayDate, singleBlockDate: true });
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            this.reservationService.blockTimeTableSeriesEdit(receivedEntry).then((response) => {
                const snackBarRef = this.snackBar.open('Zeit erfolgreich geblockt.', 'Ok', {
                    duration: 3000,
                    panelClass: ['snackbar-success']
                });
                modalRef.close();
                this.getTimeTableSeries({ begin: this.timeSeriesDate.begin, end: this.timeSeriesDate.end });
            });
        });
    }
    unBlockResTime(data) {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = 'Zeit entsperren';
        modalRef.componentInstance.message = `Möchten Sie diese Reservierungszeit wieder aktivieren?`;
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = 'Ja';
        modalRef.result.then(result => {
            if (result === 'ok') {
                this.reservationService.unBlockTimeTableSeries(data).then((response) => {
                    if (response) {
                        this.snackBar.open('Zeit erfolgreich entgeblockt.', '', {
                            duration: 2000,
                            panelClass: ['snackbar-success']
                        });
                        this.getTimeTableSeries({ begin: this.timeSeriesDate.begin, end: this.timeSeriesDate.end });
                    }
                });
            }
        }, () => { });
    }
    addReservationAddOnTemplate() {
        const modalRef = this.modalService.open(AddReservationAddOnTemplate, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.clientData = Object.assign({}, this.activeClient);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            this.reservationAddOnTemplates.push(receivedEntry);
            const snackBarRef = this.snackBar.open('Add-On erfolgreich erstellt', 'Ok', {
                duration: 3000
            });
            modalRef.close();
        });
    }
    editReservationAddOnTemplate(template, index) {
        const modalRef = this.modalService.open(AddReservationAddOnTemplate, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.clientData = Object.assign({}, this.activeClient);
        modalRef.componentInstance.editReservationAddOnTemplate = Object.assign({}, template);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            if (receivedEntry) {
                this.reservationAddOnTemplates[index] = receivedEntry;
                const snackBarRef = this.snackBar.open('Add-On erfolgreich gespeichert', 'Ok', {
                    duration: 3000
                });
                modalRef.close();
            }
        });
    }
    deleteReservationAddOnTemplate(templateId) {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = 'Add-On löschen';
        modalRef.componentInstance.message = `Bist du sicher, dass du dieses Add-On löschen möchtest?`;
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = 'Ja';
        modalRef.result.then(result => {
            if (result === 'ok') {
                this.reservationService
                    .deleteReservationAddOnTemplate(templateId)
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe((response) => {
                    this.reservationAddOnTemplates = this.reservationAddOnTemplates.filter((obj) => {
                        return obj.id !== templateId;
                    });
                    const snackBarRef = this.snackBar.open('Add-On erfolgreich gelöscht', 'Ok', {
                        duration: 3000
                    });
                });
            }
        }, () => { });
    }
    getSmsSettings() {
        this.reservationService
            .getSmsSettings()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((smsSettings) => {
            if (smsSettings) {
                this.smsSettingsFormGroup.addControl('id', new FormControl(smsSettings.id, Validators.required));
                this.showSmsSettings(smsSettings);
            }
        });
    }
    checkSenderIdValidation() {
        if (this.smsSettingsFormGroup.value.senderId && this.smsSettingsFormGroup.value.gateway) {
            if (isNaN(this.smsSettingsFormGroup.value.senderId)) {
                this.smsSettingsFormGroup.get('senderId').setValidators([Validators.maxLength(11)]);
                this.smsSettingsFormGroup.get('senderId').updateValueAndValidity();
            }
            else {
                this.smsSettingsFormGroup.get('senderId').setValidators([Validators.maxLength(16)]);
                this.smsSettingsFormGroup.get('senderId').updateValueAndValidity();
            }
        }
        else {
            this.smsSettingsFormGroup.get('senderId').clearValidators();
            this.smsSettingsFormGroup.get('senderId').setErrors(null);
        }
    }
    showSmsSettings(smsSettings) {
        smsSettings.gateway = smsSettings.gateway == 10 ? 0 : 1;
        this.smsSettingsFormGroup.get('reservationActive').setValue(smsSettings.reservationActive);
        this.smsSettingsFormGroup.get('gateway').setValue(smsSettings.gateway);
        this.smsSettingsFormGroup.get('senderId').setValue(smsSettings.senderId);
        this.smsSettingsFormGroup.get('confirmationText').setValue(smsSettings.confirmationText);
        this.smsSettingsFormGroup.get('requestText').setValue(smsSettings.requestText);
    }
    saveSmsSettings() {
        this.checkSenderIdValidation();
        if (!this.smsSettingsFormGroup.valid) {
            this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
        this.editObservableSmsSettings = this.reservationService
            .saveSmsSettings(this.smsSettingsFormGroup.value)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response) => {
            this.showSmsSettings(response);
            this.snackBar.open('Einstellungen erfolgreich aktualisiert', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
            });
        }, err => {
            console.log('err', err);
            this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
        });
    }
    changedSettings(isToggle) {
        console.log('isToggle ====>', isToggle);
        if (isToggle) {
            this.saveSettings();
        }
        else {
            this.alert = 'Du hast ungespeicherte Änderungen.';
        }
    }
    changedReminderSMS() {
        if (this.generalSettings.remindGuestsTextMessage &&
            (!this.generalSettings.reminderSMSText || this.generalSettings.reminderSMSText.trim() == '')) {
            this.reminderTextError = 'Bitte einen SMS Text für die Erinnerung eintragen.';
        }
        else if (this.generalSettings.reminderSMSText && this.generalSettings.reminderSMSText.length > 160) {
            this.reminderTextError = 'Maximal 160 Zeichen erlaubt.';
        }
        else {
            this.reminderTextError = '';
        }
    }
    saveShiftSettings(shiftId, type, value, clientId) {
        const settings = {
            shiftId,
            type,
            value,
            clientId
        };
        this.reservationService
            .saveShiftSettings(settings)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response) => {
            this.showSmsSettings(response);
            this.snackBar.open('Vorlage erfolgreich aktualisiert', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
            });
        }, err => {
            console.log('err', err);
            this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
        });
    }
    addResMenuOrders() {
        const modalRef = this.modalService.open(AddResMenuOrders, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.resMenuOrders = this.resMenuOrders;
        modalRef.componentInstance.clientData = Object.assign({}, this.activeClient);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            this.getAllResMenuOrders();
            const snackBarRef = this.snackBar.open('Order erfolgreich erstellt', 'Ok', {
                duration: 3000
            });
            modalRef.close();
        });
    }
    editResMenuOrders(order, index) {
        const modalRef = this.modalService.open(AddResMenuOrders, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.resMenuOrders = this.resMenuOrders;
        modalRef.componentInstance.clientData = Object.assign({}, this.activeClient);
        modalRef.componentInstance.editResMenuOrders = Object.assign({}, order);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            if (receivedEntry) {
                this.getAllResMenuOrders();
                const snackBarRef = this.snackBar.open('Order erfolgreich gespeichert', 'Ok', {
                    duration: 3000
                });
                modalRef.close();
            }
        });
    }
    deleteResMenuOrders(foreignId) {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = 'Order löschen';
        modalRef.componentInstance.message = `Bist du sicher, dass du diese Order löschen möchtest?`;
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = 'Ja';
        modalRef.result.then(result => {
            if (result === 'ok') {
                this.reservationService
                    .deleteResMenuOrders(foreignId)
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe((response) => {
                    this.getAllResMenuOrders();
                    const snackBarRef = this.snackBar.open('Order erfolgreich gelöscht', 'Ok', {
                        duration: 3000
                    });
                });
            }
        }, () => { });
    }
    addResTimeFilter() {
        const modalRef = this.modalService.open(AddTimeFilterComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            this.reservationService.addResTimeFilter(receivedEntry).then((response) => {
                this.resTimeFilter.push(response);
                const snackBarRef = this.snackBar.open('Erfolgreich.', 'Ok', {
                    duration: 3000,
                    panelClass: ['snackbar-success']
                });
                modalRef.close();
            });
        });
    }
    addStatus() {
        const modalRef = this.modalService.open(AddStatusComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            this.reservationService.addAndUpdateStatus(receivedEntry).then((response) => {
                this.getResStatus();
                const snackBarRef = this.snackBar.open('Erfolgreich.', 'Ok', {
                    duration: 3000,
                    panelClass: ['snackbar-success']
                });
                modalRef.close();
            });
        });
    }
    getResTimeFilter() {
        this.reservationService
            .getResTimeFilter()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((resTimeFilter) => {
            this.resTimeFilter = resTimeFilter;
        });
    }
    getResStatus() {
        this.reservationService
            .getResStatus()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((resStatusList) => {
            this.resStatusList = resStatusList;
            this.resStatusList = this.resStatusList.map((item) => {
                item['type'] = 'single';
                return Object.assign({}, item, { showActiveButton: true });
            });
            // this.resStatusList = this.resStatusList.concat(this.allStatus);
        });
    }
    editResStatus(data, index) {
        const modalRef = this.modalService.open(AddStatusComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.editStatusData = Object.assign({}, data);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            this.reservationService.addAndUpdateStatus(receivedEntry).then((response) => {
                this.getResStatus();
                const snackBarRef = this.snackBar.open('Erfolgreich.', 'Ok', {
                    duration: 3000,
                    panelClass: ['snackbar-success']
                });
                modalRef.close();
            });
        });
    }
    deleteResStatus(id) {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = 'Zeitbereich löschen';
        modalRef.componentInstance.message = `Sind Sie sicher, dass Sie diesen Zeitraum löschen möchten?`;
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = 'Ja';
        modalRef.result.then(result => {
            if (result === 'ok') {
                this.reservationService
                    .deleteResStatus(id)
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe(response => {
                    if (response) {
                        this.getResStatus();
                        this.snackBar.open('Erfolgreich.', '', {
                            duration: 2000,
                            panelClass: ['snackbar-success']
                        });
                    }
                });
            }
        }, () => { });
    }
    editResTimeFilter(data, index) {
        const modalRef = this.modalService.open(AddTimeFilterComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.editTimeFilterData = Object.assign({}, data);
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            this.reservationService.editResTimeFilter(receivedEntry).then((response) => {
                this.resTimeFilter[index] = receivedEntry;
                const snackBarRef = this.snackBar.open('Erfolgreich.', 'Ok', {
                    duration: 3000,
                    panelClass: ['snackbar-success']
                });
                modalRef.close();
            });
        });
    }
    deleteResTimeFilter(id) {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = 'Zeitbereich löschen';
        modalRef.componentInstance.message = `Sind Sie sicher, dass Sie diesen Zeitraum löschen möchten?`;
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = 'Ja';
        modalRef.result.then(result => {
            if (result === 'ok') {
                this.reservationService
                    .deleteResTimeFilter(id)
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe(response => {
                    if (response) {
                        this.resTimeFilter = this.resTimeFilter.filter((obj) => {
                            return obj.id !== id;
                        });
                        this.snackBar.open('Erfolgreich.', '', {
                            duration: 2000,
                            panelClass: ['snackbar-success']
                        });
                    }
                });
            }
        }, () => { });
    }
    getPhoneAssistantSettings() {
        this.reservationService
            .getPhoneAssistantSettings()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((phoneAssistant) => {
            this.phoneAssistantSettings = phoneAssistant;
        });
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import {
  MatIconModule,
  MatDatepickerModule,
  MatTabsModule,
  MatSnackBarModule,
  MatSlideToggleModule
} from '@angular/material';
import { DateFnsModule } from 'ngx-date-fns';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MomentModule } from 'ngx-moment';
import { VouchersRoutingModule } from '../vouchers-routing.module';
import { VouchersApiComponent } from './vouchers-api.component';
import { SatNativeDateModule, SatDatepickerModule } from 'saturn-datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { Angular2CsvModule } from 'angular2-csv';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    VouchersRoutingModule,
    MatIconModule,
    NgScrollbarModule,
    NgxDatatableModule,
    MomentModule,
    NgxDatatableModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDatepickerModule,
    MatTabsModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    NgSelectModule,
    FormsModule,
    FlexModule,
    DateFnsModule.forRoot(),
    Angular2CsvModule,
    ReactiveFormsModule,
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fas fa-spinner fa-spin button-loading"></span>'
    })
  ],
  declarations: [VouchersApiComponent],
  entryComponents: []
})
export class VouchersApiModule {}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Alert } from '../reservation-settings.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { ReservationService } from '@app/reservation/reservation.service';
import moment from 'moment';
moment.locale('de');
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-add-time-filter',
  templateUrl: './add-time-filter.component.html',
  styleUrls: ['./add-time-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddTimeFilterComponent implements OnInit, OnDestroy {
  @Input() public editTimeFilterData: any; // only if editing
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  resTimeFilterFormGroup: FormGroup;
  private ngUnsubscribe: Subject<any> = new Subject();
  time1: NgbTimeStruct;
  time2: NgbTimeStruct;

  constructor(
    public activeModal: NgbActiveModal,
    public formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private reservationService: ReservationService
  ) {}

  ngOnInit() {
    this.resTimeFilterFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      from: ['', Validators.required],
      to: ['', Validators.required]
    });

    if (this.editTimeFilterData) {
      let timeFrom = this.editTimeFilterData.from.split(':');
      this.time1 = {
        hour: parseInt(timeFrom[0]),
        minute: parseInt(timeFrom[1]),
        second: 0
      };
      let timeTo = this.editTimeFilterData.to.split(':');
      this.time2 = {
        hour: parseInt(timeTo[0]),
        minute: parseInt(timeTo[1]),
        second: 0
      };
      this.resTimeFilterFormGroup.addControl('id', new FormControl(this.editTimeFilterData.id, Validators.required));
      this.resTimeFilterFormGroup.get('from').setValue(this.time1);
      this.resTimeFilterFormGroup.get('to').setValue(this.time2);
      this.resTimeFilterFormGroup.get('name').setValue(this.editTimeFilterData.name);
    }
  }

  add() {
    if (!this.resTimeFilterFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben.', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.resTimeFilterFormGroup.value.from = `${String(this.resTimeFilterFormGroup.value.from.hour).padStart(
      2,
      '0'
    )}:${String(this.resTimeFilterFormGroup.value.from.minute).padStart(2, '0')}`;
    this.resTimeFilterFormGroup.value.to = `${String(this.resTimeFilterFormGroup.value.to.hour).padStart(
      2,
      '0'
    )}:${String(this.resTimeFilterFormGroup.value.to.minute).padStart(2, '0')}`;
    this.passEntry.emit(this.resTimeFilterFormGroup.value);
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
